import { colors } from '@material-ui/core';
import produce from 'immer';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spacer } from '../../components/Spacer/Spacer';
import { userActions } from '../../home/state/userActions';
import { OrderTransfer, TroyCase } from '../../home/state/userApi';
import { MapDispatch, RootState } from '../../store';
import styles from './AccountingPage.module.scss';
import { InvitePayoutList } from './components/InvitePayoutList/InvitePayoutList';
import { InvitePayouts } from './components/InvitePayouts/InvitePayouts';
import { OrderList } from './components/OrderList/OrderList';
import { PartnerList } from './components/PartnerList/PartnerList';
import { TransferSync } from './components/TransferSync/TransferSync';
import { TroySync } from './components/TroySync/TroySync';
import { AxiosError } from 'axios';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  setPaymentTransferList: userActions.setPaymentTransferList,
  searchPaymentManual: userActions.searchPaymentManual,
  searchPayment: userActions.searchPayment,
  fetchTroyCases: userActions.fetchTroyCases,
  syncTroyCases: userActions.syncTroyCases,
  getOrderList: userActions.getOrderList,
  getInvitePayoutList: userActions.getInvitePayoutList,
  getInviteList: userActions.getInviteList,
  getPartnerList: userActions.getPartnerList,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawAccountingPage: React.FC<Props> = ({
  setPaymentTransferList,
  searchPayment,
  searchPaymentManual,
  fetchTroyCases,
  syncTroyCases,
  getOrderList,
  getInvitePayoutList,
  getInviteList,
  getPartnerList,
}: Props) => {
  const [transfers, setTransfers] = useState<OrderTransfer[]>([]);
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [transferError, setTransferError] = useState<string>('');

  const [troyState, setTroyState] = useState<{
    cases?: TroyCase[];
    valid?: boolean;
    synced?: boolean;
  }>({ cases: [] });
  const [troyLoading, setTroyLoading] = useState<boolean>(false);
  const [troyError, setTroyError] = useState<string>('');

  const tempError = (fn: (s: string) => void, e: string) => {
    fn(e);
    setTimeout(() => {
      fn('');
    }, 10_000);
  };

  const onAddTransferCSV = async (csv: string) => {
    setTransferLoading(true);
    try {
      const pp = await searchPayment(csv);
      setTransfers(pp);
    } catch (error) {
      let errorText = 'Die csv konnte nicht eingelesen werden.';
      const apiError = error as AxiosError;
      if (!!apiError?.response?.data?.message) {
        errorText += ' ' + apiError?.response?.data?.message;
      }

      console.log('onAddTransferCSV', error);
      tempError(setTransferError, errorText);
    }
    setTransferLoading(false);
  };

  const onRemoveTransfer = (idx: number) => {
    setTransfers((s) =>
      produce(s, (draft) => {
        draft.splice(idx, 1);
      }),
    );
  };

  const onSyncTransfer = async () => {
    try {
      setTransferLoading(true);
      const r = await setPaymentTransferList(transfers);
      setTransfers(r);
    } catch (error) {
      let errorText = 'Überweisungen konnten nicht bestätigt werden.';
      const apiError = error as AxiosError;
      if (!!apiError?.response?.data?.message) {
        errorText += ' ' + apiError?.response?.data?.message;
      }

      console.log('onSyncTransfer', error);
      tempError(setTransferError, errorText);
    }
    setTransferLoading(false);
  };

  const onFilterConfirmedTransfers = () => {
    setTransfers((s) => s.filter((t) => !t.confirmed));
  };

  const onAddTroyCSV = async (csv: string) => {
    setTroyLoading(true);
    try {
      const r = await fetchTroyCases(csv);
      setTroyState(r);
    } catch (_) {
      tempError(setTroyError, 'Die csv konnte nicht eingelesen werden.');
    }
    setTroyLoading(false);
  };

  const onResetTroyCSV = async () => {
    setTroyState({});
  };

  const onTransferChanged = (tt: OrderTransfer[]) => {
    setTransfers(tt);
  };

  const onSyncTroy = async () => {
    const { cases, valid } = troyState;
    if (!cases || cases.length <= 0 || !valid) {
      return;
    }

    setTroyLoading(true);
    try {
      const { cases: syncedCases } = await syncTroyCases(troyState.cases);
      setTroyState((s) => ({ ...s, cases: syncedCases, synced: true }));

      if (!!syncedCases.find((c) => !!c.error)) {
        tempError(setTroyError, 'Bei der Synchronisation kam es zu Fehlern.');
      }
    } catch (err) {
      tempError(setTroyError, 'Die Synchronisation ist gescheitert: ' + err);
    }

    setTroyLoading(false);
  };

  return (
    <div className={styles.container}>
      <h1>Buchhaltung</h1>
      <div className={styles.divider} />
      <h3>Aktionen</h3>
      <div style={{ fontSize: 'small' }}>
        Zahlungen können entweder stoniert oder geschoben werden. Für beide
        Aktionen ist ein Kommentar notwendig. Außerdem muss in den Einstellungen
        ein Nutzername hinterlegt sein.
        <br />
        Idealerweise verlinkt man im Kommentar ein Support-Ticket oder sonstigen
        Kontext zum Eingriff.
        <br />
        <br />
        <b style={{ color: colors.red['800'] }}>
          Achtung: Die Aktionen können nicht rückgängig gemacht werden. Sie
          dürfen außerdem nur von Administratoren durchgeführt werden.
        </b>
      </div>
      <div className={styles.divider} />

      <div className={styles.actionContainer}>
        <TransferSync
          transfers={transfers}
          loading={transferLoading}
          error={transferError}
          onAddCSV={onAddTransferCSV}
          onRemove={onRemoveTransfer}
          onSync={onSyncTransfer}
          onFilter={onFilterConfirmedTransfers}
          onChange={onTransferChanged}
        />
      </div>

      <div className={styles.actionContainer}>
        <TroySync
          cases={troyState.cases ?? []}
          valid={troyState.valid}
          synced={troyState.synced}
          loading={troyLoading}
          error={troyError}
          onAddCSV={onAddTroyCSV}
          onResetCSV={onResetTroyCSV}
          onSync={onSyncTroy}
        />
      </div>

      <div className={styles.actionContainer}>
        <OrderList createList={getOrderList} />
      </div>

      <div className={styles.actionContainer}>
        <InvitePayoutList
          createList={getInvitePayoutList}
          createInviteList={getInviteList}
        />
      </div>

      <InvitePayouts />

      <div className={styles.actionContainer}>
        <PartnerList createList={getPartnerList} />
      </div>

      <Spacer size="xxxlarge" />
    </div>
  );
};

export const AccountingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawAccountingPage);
