import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../common/text';
import { VastTask } from '../../home/state/userApi';
import { Card } from '../Card/Card';
import { Input } from '../Input/Input';
import styles from './VastTaskChange.module.scss';

interface Props {
  task?: VastTask;
  deleteTask: (id: string) => void;
  updateStatus: (id: string, status: string) => void;
}

const emptyTask: VastTask = {
  id: '',
  createdAt: '',
  type: '',
  debug: true,
  idNr: '',
  userId: '',
  status: '',
  errors: [],
  lastErrorCode: 0,
};

export const VastTaskChange: React.FC<Props> = ({
  task: base,
  deleteTask,
  updateStatus,
}: Props) => {
  const [task, setTask] = useState(base || emptyTask);

  useEffect(() => {
    setTask(base || emptyTask);
    console.log('updated base');
  }, [base]);

  const handleValueUpdate =
    (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setTask({ ...task, [prop]: e.target.value });
    };

  const changed = JSON.stringify(base) !== JSON.stringify(task);

  const { id, createdAt, type, debug, status, idNr, errors } = task;
  return (
    <div>
      <Card size={'small'}>
        <div className={styles.container}>
          <div>ID</div>
          <div>{id}</div>
          <div>Status</div>
          <Input value={status} onChange={handleValueUpdate('status')} />
          <div>Erstellt</div>
          <div>{dateToString(createdAt)}</div>
          <div>Typ</div>
          <div>{type}</div>
          <div>Debug</div>
          <div>{debug}</div>
          <div>Steuer-ID</div>
          <div>{idNr}</div>
          <div>Fehler</div>
          <div>{errors?.join('\n') || 'Keine Fehler'}</div>

          <Button
            color="primary"
            disabled={!changed}
            onClick={() => updateStatus(id, status)}>
            Speichern
          </Button>
          <Button color="secondary" onClick={() => deleteTask(id)}>
            Löschen
          </Button>
        </div>
      </Card>
    </div>
  );
};
