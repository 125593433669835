import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { dateToString } from '../../../common/text';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Table, TableCol } from '../../../components/Table/Table';
import { MapDispatch, RootState } from '../../../store';
import { NotificationMessage } from '../../state/notificationApi';
import { notificationsActions } from '../../state/notificationsActions';
import { selectMessages } from '../../state/notificationsReducer';

const mapStateToProps = (state: RootState) => ({
  messages: selectMessages(state),
});

const mapDispatchToProps = {
  fetchMessages: notificationsActions.fetchMessages,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationMessages = ({ messages, fetchMessages }: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchMessages(); // TODO: handle error
      setLoading(false);
    };
    loadData();
  }, []);

  const cols: TableCol<NotificationMessage>[] = [
    { id: 'title', label: 'Titel' },
    {
      label: 'Erstellt',
      renderFunc: ({ created }) => dateToString(created || ''),
    },
    {
      label: 'Zuletzt geändert',
      renderFunc: ({ updated }) => dateToString(updated || ''),
    },
  ];

  const onRowClicked = (_: React.MouseEvent, idx: number) =>
    history.push(path + '/' + messages[idx].id);

  return (
    <div>
      <LoadingNavHeader
        title="Nachrichten"
        loading={loading}
        addPath={path + '/new'}
        backTitle="Übersicht"
        backPath="/notifications/dashboard"
      />

      <Table
        coloumns={cols}
        data={messages}
        emptyText="–"
        variant="material"
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationMessages);
