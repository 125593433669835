import React from 'react';

export const Card: React.FC<any> = ({
  title,
  children,
  className,
  onClick,
  size = 'medium',
}: {
  size?: 'small' | 'medium';
  title?: string;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: React.ReactNode;
}) => {
  return (
    <div
      style={{
        padding: size === 'medium' ? 16 : 5,
        border: '1px solid gray',
      }}
      onClick={onClick}
      className={className || ''}>
      {title && <h3 style={{ marginBottom: 10 }}>{title}</h3>}
      <div>{children}</div>
    </div>
  );
};
