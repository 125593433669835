import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { dateToString } from '../../../common/text';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Table, TableCol } from '../../../components/Table/Table';
import { MapDispatch, RootState } from '../../../store';
import { AudienceDefinition } from '../../state/notificationApi';
import { notificationsActions } from '../../state/notificationsActions';
import { selectAudiences } from '../../state/notificationsReducer';

const mapStateToProps = (state: RootState) => ({
  audiences: selectAudiences(state),
});

const mapDispatchToProps = {
  fetchAudiences: notificationsActions.fetchAudiences,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationAudiences = ({ audiences, fetchAudiences }: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchAudiences(); // TODO: handle error
      setLoading(false);
    };
    loadData();
  }, []);

  const cols: TableCol<AudienceDefinition>[] = [
    { label: 'Titel', id: 'title' },
    {
      label: 'Erstellt',
      renderFunc: ({ created }) => dateToString(created || ''),
    },
    {
      label: 'Zuletzt geändert',
      renderFunc: ({ updated }) => dateToString(updated || ''),
    },
  ];

  const onRowClicked = (_: React.MouseEvent, idx: number) =>
    history.push(path + '/' + audiences[idx].id);

  return (
    <div>
      <LoadingNavHeader
        title="Zielgruppen"
        loading={loading}
        addPath={path + '/new'}
        backTitle="Übersicht"
        backPath="/notifications/dashboard"
      />

      <Table
        coloumns={cols}
        data={audiences}
        emptyText="–"
        variant="material"
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationAudiences);
