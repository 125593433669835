import { AsyncAction } from '../../store';
import {
  AudienceDefinition,
  NotificationJob,
  NotificationJobStatus,
  NotificationMessage,
  notificationApi,
} from './notificationApi';
import {
  deleteAudience,
  deleteJob,
  deleteMessage,
  selectJobsByStatus,
  setAudienceSize,
  setAudiences,
  setJobProgress,
  setJobs,
  setMessages,
} from './notificationsReducer';

export const notificationsActions = {
  // audiences
  createAudience:
    (a: AudienceDefinition): AsyncAction<AudienceDefinition | ApiError> =>
    async (dispatch, _) => {
      try {
        const { audience } = await notificationApi.addAudienceDefinition(a);
        const obj = arrayToObject([audience], ({ id }) => id);
        dispatch(setAudiences(obj));
        return audience;
      } catch (err) {
        return { errorMessage: err };
      }
    },

  fetchAudiences: (): AsyncAction<MaybeError> => async (dispatch, _) => {
    try {
      const { definitions } = await notificationApi.getAudienceDefinitions();
      const obj = arrayToObject(definitions, ({ id }) => id);
      dispatch(setAudiences(obj));
    } catch (err) {
      return err;
    }
  },

  fetchAudience:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { audience } = await notificationApi.getAudienceDefinition(id);
        const obj = arrayToObject([audience], ({ id }) => id);
        dispatch(setAudiences(obj));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  fetchAudienceSize:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { size } = await notificationApi.computeAudienceDefinition(id);
        dispatch(setAudienceSize({ id, size }));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  updateAudience:
    (d: AudienceDefinition): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { audience } = await notificationApi.updateAudienceDefinition(d);
        const obj = arrayToObject([audience], ({ id }) => id);
        dispatch(setAudiences(obj));
        dispatch(notificationsActions.fetchAudienceSize(d.id));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  deleteAudience:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        await notificationApi.deleteAudienceDefinition(id);
        dispatch(deleteAudience(id));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  // jobs
  createJob:
    (j: NotificationJob): AsyncAction<NotificationJob | ApiError> =>
    async (dispatch, _) => {
      try {
        const { job } = await notificationApi.createNotificationJob(j);
        const obj = arrayToObject([job], ({ id }) => id);
        dispatch(setJobs(obj));
        return job;
      } catch (err) {
        return { errorMessage: err };
      }
    },

  fetchJobs: (): AsyncAction<MaybeError> => async (dispatch, _) => {
    try {
      const { jobs } = await notificationApi.getNotificationJobs();
      const obj = arrayToObject(jobs, ({ id }) => id);
      dispatch(setJobs(obj));
    } catch (err) {
      return { errorMessage: err };
    }
  },

  fetchJob:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { job } = await notificationApi.getNotificationJob(id);
        dispatch(setJobs(arrayToObject([job], ({ id }) => id)));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  fetchAllJobsProgress: (): AsyncAction<any> => async (dispatch, getState) => {
    const activeJobs = selectJobsByStatus(NotificationJobStatus.IN_PROGRESS)(
      getState(),
    );
    try {
      for (const { id } of activeJobs) {
        const progress = await notificationApi.getNotificationJobProgress(id);
        dispatch(setJobProgress({ [id]: progress }));
      }
    } catch (err) {
      return { errorMessage: err };
    }
  },

  fetchJobProgress:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const progress = await notificationApi.getNotificationJobProgress(id);
        dispatch(setJobProgress({ [id]: progress }));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  updateJob:
    (j: NotificationJob): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { job } = await notificationApi.updateNotificationJob(j);
        dispatch(setJobs(arrayToObject([job], ({ id }) => id)));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  deleteJob:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        await notificationApi.deleteNotificationJob(id);
        dispatch(deleteJob(id));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  // messages
  fetchMessages: (): AsyncAction<MaybeError> => async (dispatch, _) => {
    try {
      const { messages } = await notificationApi.getMessages();
      const obj = arrayToObject(messages, ({ id }) => id);
      dispatch(setMessages(obj));
    } catch (err) {
      return err;
    }
  },

  fetchMessage:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { message } = await notificationApi.getMessage(id);
        const obj = arrayToObject([message], ({ id }) => id);
        dispatch(setMessages(obj));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  createMessage:
    (m: NotificationMessage): AsyncAction<NotificationMessage | ApiError> =>
    async (dispatch, _) => {
      try {
        const { message } = await notificationApi.createMessage(m);
        const obj = arrayToObject([message], ({ id }) => id);
        dispatch(setMessages(obj));
        return message;
      } catch (err) {
        return { errorMessage: err };
      }
    },

  updateMessage:
    (m: NotificationMessage): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        const { message } = await notificationApi.updateMessage(m);
        const obj = arrayToObject([message], ({ id }) => id);
        dispatch(setMessages(obj));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  deleteMessage:
    (id: string): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        await notificationApi.deleteMessage(id);
        dispatch(deleteMessage(id));
      } catch (err) {
        return { errorMessage: err };
      }
    },

  sendMessage:
    (id: string, userIds: string[]): AsyncAction<MaybeError> =>
    async (dispatch, _) => {
      try {
        await notificationApi.sendMessage(id, userIds);
      } catch (err) {
        return { errorMessage: err };
      }
    },
};

export type MaybeError = ApiError | undefined;

export interface ApiError {
  errorMessage: string;
  errorCode?: string;
}

export function isApiError(object: any): object is ApiError {
  return 'errorMessage' in object;
}

function arrayToObject<T>(
  arr: T[],
  idxFn: (a: T) => string,
): { [key: string]: T } {
  if (!arr || arr.length <= 0) {
    return {};
  }
  return arr.reduce((p, c) => ({ ...p, [idxFn(c)]: c }), {});
}
