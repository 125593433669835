import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Assessment,
  Information,
  RefundResult,
} from '../../info/state/infoApi';
import { AssessmentDifferences } from '../AssessmentDifferences/AssessmentDifferences';
import { AssessmentTable } from '../AssessmentTable/AssessmentTable';
import styles from './AssessmentViewer.module.scss';
import { AssessmentDiffDefinition } from '../AssessmentDiffDefinition/AssessmentDiffDefinition';
import SettingsIcon from '@material-ui/icons/Settings';

export const AssessmentViewer = React.memo(function AssessmentViewer({
  assessment,
  infos,
  refund,
}: {
  assessment: Assessment[];
  infos: Information[];
  refund: RefundResult | null;
}) {
  const [showRaw, setShowRaw] = useState(false);
  const [debug, setDebug] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const remarks = assessment.find(
    ({ number, description }) => !number && !description,
  );

  return (
    <div className={styles.container}>
      <h2 onClick={() => setShowSettings((s) => !s)}>
        Einstellungen <SettingsIcon fontSize={'small'} />
      </h2>
      {showSettings && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            onClick={() => setShowRaw((s) => !s)}
            style={{ paddingRight: 20 }}>
            {showRaw ? 'Rohdaten ausblenden' : 'Rohdaten einblenden'}
          </div>
          <FormControlLabel
            control={
              <Switch checked={!!debug} onChange={() => setDebug((s) => !s)} />
            }
            label="Debug Modus"
          />
        </div>
      )}
      {showSettings && <AssessmentDiffDefinition />}

      <h2>Abweichungen</h2>
      {!!refund && (
        <AssessmentDifferences
          remarks={remarks?.value || ''}
          assessment={assessment}
          infos={infos}
          refund={refund}
        />
      )}
      {!refund && <CircularProgress size={20} />}

      <AssessmentTable assessment={assessment} debug={debug} />

      <h2>Hinweise</h2>
      <div className={styles.value}>{remarks?.value}</div>

      <h2 onClick={() => setShowRaw((s) => !s)}>Zeige Rohdaten</h2>

      <FormControlLabel
        control={
          <Switch checked={!!debug} onChange={() => setDebug((s) => !s)} />
        }
        label="Debug Modus"
      />

      {showRaw && (
        <div>
          {[...assessment].map(({ number, description, value }, idx) => (
            <div
              className={styles.row}
              key={idx + number + value + description}>
              {description && (
                <div className={styles.description}>{description}</div>
              )}
              <div className={styles.value}>{value}</div>
              {number && <div className={styles.number}>{number}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
