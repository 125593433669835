import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spacer } from '../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../store';
import { taskActions } from '../state/taskActions';
import styles from './TasksPage.module.scss';
import { Table, TableCol } from 'app/components/Table/Table';
import { dateToString } from 'app/common/text';
import { Link, useRouteMatch } from 'react-router-dom';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  getAllTasks: taskActions.fetchAllTasks,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawTasksPage: React.FC<Props> = ({ getAllTasks }: Props) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const match = useRouteMatch();

  useEffect(() => {
    const load = async () => {
      const { tasks } = await getAllTasks();
      setTasks(tasks || []);
    };
    load();
  }, []);

  const cols: TableCol[] = [
    { label: 'Typ', renderFunc: () => 'Sofort VaSt' },
    { label: 'Status', id: 'status' },
    { label: 'Geblockt', renderFunc: (v) => dateToString(v.updatedAt) },
    { label: 'Vorname', renderFunc: (v) => v.data.forename },
    { label: 'Nachname', renderFunc: (v) => v.data.surname },
    {
      label: 'Prüfung',
      renderFunc: (v) => <Link to={`${match.url}/${v.id}`}>{v.id}</Link>,
    },
  ];

  return (
    <div className={styles.container}>
      <h1>Tasks</h1>
      <Spacer size="xlarge" />
      <Table coloumns={cols} data={tasks} />
      <Spacer size="xxxlarge" />
    </div>
  );
};

export const TasksPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawTasksPage);
