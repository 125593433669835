import { isNullOrUndefined } from 'util';

const prefix = 'monitor-v2-';

export const StorageService = {
  store(key: StorageKey, value: any) {
    localStorage.setItem(prefix + key, JSON.stringify(value, null, 2));
  },
  restore(key: StorageKey, defaultValue: any) {
    const vs = localStorage.getItem(prefix + key);
    if (isNullOrUndefined(vs)) return defaultValue;
    let v: any | null;
    try {
      v = JSON.parse(vs);
    } catch (e) {
      console.log('could not parse', vs, e);
    }
    return !isNullOrUndefined(v) ? v : defaultValue;
  },
};

export enum StorageKey {
  API = 'api-url',
  UIDS = 'user-id',
  ACTIVE_USER_ID = 'active-user-id',
  BIDS = 'bot-id',
  VERSIONS = 'version',
  UID_LABELS = 'user-id-labels',
  BID_LABELS = 'bot-id-labels',
  SETTINGS = 'settings',
  SHOP = 'shop',
  USER_CREDENTIALS = 'user-credentials',
  INFO_SIDENAV = 'info-sidenav',
  INFO_PAGE = 'info-page',
  INFO_ASSESSMENT_CONFIG = 'info-assessment-config',
  NODE_FUNNELS = 'node-funnels',
}
