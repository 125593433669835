import { isNullOrUndefined, isNumber } from 'util';

export interface ExpressionResult {
  input: string;
  value?: number | string;
  missingIds: string[];
  debugValue: string;
}

export const evalExpression = (
  expr: string,
  valueFn: (id: string) => string | undefined,
  regexp: RegExp,
): ExpressionResult => {
  const input = `${expr}`; // copy string
  const missingIds: string[] = [];
  let foundAtLeastOne = false;

  for (const [o, id] of [...expr.matchAll(regexp)]) {
    const v = valueFn(id);
    if (isNullOrUndefined(v)) {
      missingIds.push(id);
      expr = (expr || '').replace(o, '0');
      continue;
    }
    foundAtLeastOne = true;
    expr = (expr || '').replace(o, v);
  }

  if (!foundAtLeastOne && missingIds.length > 0) {
    return {
      input,
      value: undefined,
      missingIds,
      debugValue: input,
    };
  }

  if (expr.startsWith("'")) {
    return {
      input,
      value: expr.substring(1),
      missingIds,
      debugValue: input,
    };
  }

  let value: number | undefined;
  let debugValue: string;

  try {
    const n = eval(expr); // eslint-disable-line
    value = isNumber(n) ? n : undefined;
    debugValue = value ? value + '' : expr;
  } catch {
    value = undefined;
    debugValue = expr;
  }

  return {
    input,
    value,
    missingIds,
    debugValue,
  };
};

export function deepCopy<T>(a: T): T {
  return JSON.parse(JSON.stringify(a));
}
