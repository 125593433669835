import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dateToString } from '../../common/text';
import { AssessmentYearsOverview } from '../../components/AssessmentYearsOverview/AssessmentYearsOverview';
import { Collapsable } from '../../components/Collapsable/Collapsable';
import { DeviceDetail } from '../../components/DeviceDetail/DeviceDetail';
import { ObjectTable } from '../../components/ObjectTable/ObjectTable';
import { PaymentsTable } from '../../components/PaymentsTable/PaymentsTable';
import { SelectableOrderTable } from '../../components/SelectableOrderTable/SelectableOrderTable';
import { SimpleTrust } from '../../components/SimpleTrust/SimpleTrust';
import { Table, TableCol } from '../../components/Table/Table';
import { selectSettingsUsername } from '../../settings/state/settingsReducer';
import {
  OrderUpdateType,
  ShopApi,
  ShopCredit,
  ShopPayment,
  SwLineItem,
  SwOrder,
} from '../../shop/state/shopApi';
import { fetchCustomer, selectCustomer } from '../../shop/state/shopReducer';
import { User, UserDevice, userApi } from '../state/userApi';
import styles from './CustomerPage.module.scss';
import { Input } from '../../components/Input/Input';

export const CustomerPage = (): any => {
  const { uid } = useParams<any>();

  const [profile, setProfile] = useState<User | null>(null);
  const [devices, setDevices] = useState<UserDevice[]>([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

  const customer = useSelector(selectCustomer(uid || ''));
  const monitorUser = useSelector(selectSettingsUsername);
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      if (!uid) return;
      setLoading(true);
      try {
        const [pr, dr] = await Promise.all([
          userApi.fetchProfile(uid),
          userApi.fetchDevices(uid),
        ]);
        setProfile(pr.user || null);
        setDevices(dr.devices || []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    f();
    dispatch(fetchCustomer(uid || ''));
  }, [dispatch, uid]);

  const handleToggleOrderSelection = (id: string) => {
    const idx = selectedOrders.indexOf(id);
    setSelectedOrders(
      idx >= 0
        ? [...selectedOrders].filter((s) => s !== id)
        : [...selectedOrders, id],
    );
  };

  const renderContent = () => {
    if (!customer || !devices || !profile || loading)
      return <CircularProgress />;

    const { credit, orders, swOrders, payments, trust, support } = customer;

    const emptySelectedOrders = selectedOrders.length <= 0;
    const showAllOrders =
      emptySelectedOrders || selectedOrders.length === orders.length;

    const selectedStatus = selectedStatusSet(selectedOrders, orders);

    const allowOrderModification =
      !updating &&
      selectedStatus.length === 1 &&
      ['pending_permission', 'in_review', 'cancelled', 'permitted'].includes(
        selectedStatus[0],
      );

    const comments = orders
      .filter(({ supportInformation }) => !!supportInformation)
      .reduce((p, c) => [...p, ...c.supportInformation], [] as any[])
      .reverse()
      .concat(support.entries)
      .filter((c: any) => !!c);

    const lineItems = getLineItems(orders, swOrders, selectedOrders);

    // TODO: Aktionen
    // TODO: Order Number Hover!!!
    // TODO: GoCardless Zustand.
    // TODO: Jahresinformationen und Vergleich über mehrere Jahre von relevanten Infos.

    const changeOrderState =
      (t: OrderUpdateType, createTicket?: boolean) => async () => {
        setUpdating(true);
        try {
          await ShopApi.updateOrders(
            profile.id,
            t,
            monitorUser,
            selectedOrders,
            selectedStatus[0],
            comment,
            createTicket,
          );
          await dispatch(fetchCustomer(uid || ''));
          setSelectedOrders([]);
          setComment('');
        } catch {
          // setError('Die Bestellung konnte nicht geändert werden');
          // clearTimeout(timeoutId);
          // timeoutId = setTimeout(() => setError(''), 3000);
        } finally {
          setUpdating(false);
        }
      };

    const createTicket = async () => {
      const oid = selectedOrders[0];
      if (!oid) return;

      setUpdating(true);
      try {
        await ShopApi.createTicket(oid);
        await dispatch(fetchCustomer(uid || ''));
        setSelectedOrders([]);
      } catch {
        // setError('Ticket konnte nicht erstellt werden');
        // clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => setError(''), 3000);
      } finally {
        setUpdating(false);
      }
    };

    return (
      <div className={styles.content}>
        <div style={{ display: 'flex' }}>
          <Profile profile={profile} />

          <div>
            <h3>Trust {`(${trust.score})`}</h3>
            <SimpleTrust trust={trust} />
          </div>
        </div>
        <h3>Kommentare {`(${comments.length})`}</h3>
        <Table
          coloumns={commentCols}
          data={comments}
          emptyText="Keine Kommentare vorhanden"
        />

        <div className={styles.actions}>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={
              !allowOrderModification || selectedStatus.includes('permitted')
            }
            onClick={changeOrderState(OrderUpdateType.PERMIT)}>
            Freigeben
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={
              !allowOrderModification || selectedStatus.includes('in_review')
            }
            onClick={changeOrderState(OrderUpdateType.REVIEW)}>
            Untersuchen
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={
              !allowOrderModification ||
              selectedStatus.includes('in_review') ||
              selectedOrders.length !== 1
            }
            onClick={changeOrderState(OrderUpdateType.REVIEW, true)}>
            Untersuchen + Ticket
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={updating || selectedOrders.length !== 1}
            onClick={createTicket}>
            Ticket erstellen
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={
              !allowOrderModification || selectedStatus.includes('cancelled')
            }
            onClick={changeOrderState(OrderUpdateType.CANCEL)}>
            Stornieren
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={
              !allowOrderModification || selectedStatus.includes('declined')
            }
            onClick={changeOrderState(OrderUpdateType.DECLINE)}>
            Ablehnen
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            disabled={updating}
            onClick={changeOrderState(OrderUpdateType.COMMENT)}>
            Kommentieren
          </Button>
          <Input
            placeholder={'Kommentar'}
            value={comment}
            className={styles.comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {updating && <CircularProgress size={20} />}
        </div>

        <h3>Bestellungen {`(${orders.length})`}</h3>
        <SelectableOrderTable
          onToggle={handleToggleOrderSelection}
          orders={orders}
          swOrders={swOrders}
          selected={selectedOrders}
        />
        <h3>
          {showAllOrders ? 'Alle' : 'Ausgewählte'} Artikel{' '}
          {`(${lineItems.length})`}
        </h3>
        <Table coloumns={articleCols} data={lineItems} />

        <Payments payments={payments || []} />

        <Credits credit={credit} />

        <Devices devices={devices} />

        <Submits profile={profile} />
      </div>
    );
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

export const articleCols: TableCol[] = [
  { id: 'orderNumber', label: 'Nummer' },
  { id: 'label', label: 'Produkt' },
  { id: 'quantity', label: 'Anzahl' },
  { id: 'unitPrice', label: 'Preis' },
  { id: 'totalPrice', label: 'Gesamtwert' },
];

export const commentCols: TableCol[] = [
  {
    label: 'Nutzer',
    renderFunc: ({ userAgent }) =>
      userAgent.replace('steuerbot-production: ', ''),
  },
  { label: 'Datum', renderFunc: (v) => dateToString(v['timestamp']) },
  { id: 'comment', label: 'Kommentar' },
  { id: 'previousValue', label: 'Alter Wert' },
  { id: 'value', label: 'Neuer Wert' },
];

const Profile = ({ profile }: { profile: User }) => {
  return (
    <div className={styles.sectionContainer}>
      <h3>Profil</h3>
      <div>
        <ObjectTable
          data={profile}
          rows={[
            { id: 'forename', label: 'Vorname' },
            { id: 'surname', label: 'Nachname' },
            { id: 'id', label: 'ID' },
            { id: 'email', label: 'E-Mail' },
            {
              id: 'added',
              label: 'Erstellt',
              renderFunc: (v) => <div>{dateToString(v['added'])}</div>,
            },
            {
              id: 'updated',
              label: 'Aktualisiert',
              renderFunc: (v) => <div>{dateToString(v['updated'])}</div>,
            },
          ]}
        />
      </div>
    </div>
  );
};

const Submits = ({ profile }: { profile: User }) => {
  return (
    <div className={styles.sectionContainer}>
      <h3>Abgaben</h3>
      <AssessmentYearsOverview profile={profile} />
    </div>
  );
};

const Devices = ({ devices }: { devices: UserDevice[] }) => {
  return (
    <Collapsable collapsable={devices.length > 3} collapsedHeight={200}>
      <div className={styles.sectionContainer}>
        <h3>Geräte {`(${devices.length})`}</h3>
        <div>
          {devices.map((d) => (
            <DeviceDetail key={d.id} device={d} minimal />
          ))}
        </div>
      </div>
    </Collapsable>
  );
};

const Credits = ({ credit }: { credit: ShopCredit }) => {
  const transactions = credit?.transactions || [];

  return (
    <Collapsable collapsable={transactions.length > 4} collapsedHeight={170}>
      <div className={styles.sectionContainer}>
        <h3>
          Credits{' '}
          {`(${credit.remaining} € verbleiben, ${credit.debt} € verbraucht)`}
        </h3>
        <div>
          {transactions.map((t) => (
            <div className={styles.credit} key={t.timestamp}>
              <ObjectTable
                data={t}
                rows={[
                  { id: 'type', label: 'Typ' },
                  { id: 'amount', label: 'Summe' },
                  { id: 'botId', label: 'Bot-ID' },
                  { id: 'submitLogId', label: 'Abgabe-ID' },
                  { id: 'assessmentId', label: 'Bescheid-ID' },
                  { id: 'orderId', label: 'Bestellung-ID' },
                  {
                    id: 'timestamp',
                    label: 'Hinzugefügt',
                    renderFunc: (v) => (
                      <div>{dateToString(v['timestamp'])}</div>
                    ),
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
    </Collapsable>
  );
};

const Payments = ({ payments }: { payments: ShopPayment[] }) => {
  return (
    <div className={styles.sectionContainer}>
      <h3>Bezahlungen {`(${payments.length})`}</h3>
      <PaymentsTable payments={payments} />
    </div>
  );
};

function selectedStatusSet(ids: string[], orders: any[]): string[] {
  let selectedStatus = ids
    .map((id) => orders.find(({ _id }) => _id === id))
    .filter((o) => !!o)
    .map(({ status }) => status);
  selectedStatus = selectedStatus.filter(
    (v, i) => selectedStatus.indexOf(v) === i,
  );
  return selectedStatus;
}

function getLineItems(
  orders: any[],
  swOrders: SwOrder[],
  selectedOrders: string[],
): SwLineItem[] {
  const orderNumberFromId = (qid: string) =>
    orders.find(({ _id }) => _id === qid)?.orderNumber || '';

  const showAll =
    selectedOrders.length <= 0 || selectedOrders.length === orders.length;

  const lineItems = swOrders
    .reduce((p, c) => [...p, ...c.lineItems], [] as SwLineItem[])
    .map((i) => ({ ...i, orderNumber: orderNumberFromId(i.orderId) }))
    .filter(({ orderId }) => showAll || selectedOrders.includes(orderId))
    .reverse();
  return lineItems;
}
