import { Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { all } from '../../common/style';
import { Divider } from '../../components/Divider/Divider';
import { ObjectTable } from '../../components/ObjectTable/ObjectTable';
import { Spacer } from '../../components/Spacer/Spacer';
import {
  ApiEndpoint,
  selectSettings,
  setActiveEndpointId,
} from '../state/settingsReducer';
import styles from './SettingsPage.module.scss';

export const SettingsPage = () => {
  const settings = useSelector(selectSettings);
  const { username: un, endpointIds, endpoints, activeEndpoint } = settings;
  const { keycloak } = useKeycloak();

  const dispatch = useDispatch();

  const selectEndpoint = (id: string) => () =>
    dispatch(setActiveEndpointId(id));

  const onToggleLogin = () => {
    if (keycloak.authenticated) {
      keycloak
        .logout()
        .then(() => console.log('success'))
        .catch(() => console.log('failure'));
      return;
    }

    keycloak
      .login()
      .then(() => console.log('success'))
      .catch(() => keycloak.clearToken());
  };

  const isLoggedIn = keycloak.authenticated;

  return (
    <div className={styles.container}>
      <h1>Einstellungen</h1>

      <h2>Login</h2>
      <Spacer />
      <Divider />
      <Spacer />
      <div className={styles.loginContainer}>
        {!isLoggedIn && (
          <div style={{ fontSize: 'small' }}>
            <b>Achtung:</b> Du wirst auf eine separate Seite weitergeleitet um
            dich dort einzuloggen. Anschließend kommst du automatisch zurück zum
            Monitor.
          </div>
        )}
        {isLoggedIn && (
          <>
            <div style={{ fontSize: 'small' }}>
              Du hast dich erfolgreich eingeloggt 🎉 Du kannst deinen Account{' '}
              <a
                href="https://keycloak.steuerbot.com/auth/realms/monitor/account/"
                target="_blank"
                rel="noopener noreferrer">
                hier
              </a>{' '}
              verwalten.
            </div>
            <Spacer />
            <ObjectTable
              data={keycloak.tokenParsed ?? {}}
              minimal
              rows={[
                { id: 'name', label: 'Name' },
                { id: 'email', label: 'E-Mail' },
                { id: 'sid', label: 'ID' },
                {
                  label: 'Rollen',
                  renderFunc: () => keycloak.realmAccess?.roles?.join(', '),
                },
              ]}
            />
          </>
        )}
      </div>

      <Spacer size="medium" />
      <div className={styles.buttonContainer}>
        <Button variant="outlined" onClick={onToggleLogin}>
          {!keycloak.authenticated ? 'Jetzt einloggen' : 'Jetzt ausloggen'}
        </Button>
      </div>

      <Spacer size="large" />
      <h2>Endpunkte</h2>
      <Spacer />
      <Divider />
      <Spacer />
      <div className={styles.section}>
        <div className={styles.endpointsContainer}>
          {endpointIds.map((id) => (
            <EndpointCard
              active={id === activeEndpoint}
              endpoint={endpoints[id]}
              key={id}
              onClick={selectEndpoint(id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const EndpointCard = ({
  endpoint,
  active,
  onClick,
}: {
  endpoint: ApiEndpoint;
  active: boolean;
  onClick: () => void;
}) => {
  const { label, url } = endpoint;

  return (
    <div
      className={all(styles.card, active ? styles.activeEndpoint : '')}
      onClick={onClick}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>{label}</div>
      </div>
      <div className={styles.divider}></div>

      <div className={styles.subtitle}>{url}</div>

      <div className={styles.spacer}></div>
    </div>
  );
};
