import { Button } from '@material-ui/core';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../../common/text';
import { DateInput } from '../../../components/DateInput/DateInput';
import { DurationInput } from '../../../components/DurationInput/DurationInput';
import { FormContainer } from '../../../components/FormContainer/FormContainer';
import { Input } from '../../../components/Input/Input';
import { Spacer } from '../../../components/Spacer/Spacer';
import {
  NotificationJob,
  NotificationJobStatus,
} from '../../state/notificationApi';
import AudienceSelection from '../AudienceSelection/AudienceSelection';
import MessageSelection from '../MessageSelection/MessageSelection';
import styles from './NotificationJobBuilder.module.scss';

export interface NotificationJobBuilderProps {
  job: NotificationJob;
  createMode: boolean;
  onUpdate: (c: NotificationJob) => void;
  onDelete: (id: string) => void;
}

export const NotificationJobBuilder: React.FC<NotificationJobBuilderProps> = ({
  job: baseJob,
  createMode,
  onUpdate,
  onDelete,
}: NotificationJobBuilderProps) => {
  const [job, setJob] = useState<NotificationJob>(baseJob);

  useEffect(() => {
    setJob(baseJob);
  }, [baseJob]);

  const onPropertyChange = (k: string, v: any) => {
    setJob((d) =>
      produce(d, (draft) => {
        draft[k] = v;
      }),
    );
  };

  const onDurationPropertyChange = (k: string, v: any) => {
    setJob((d) =>
      produce(d, (draft) => {
        draft[k] = v;
      }),
    );
  };

  const onHoursChange = (k: string, v: any) => {
    setJob((d) =>
      produce(d, (draft) => {
        if (draft.hours === undefined) {
          draft.hours = { from: 0, to: 0 };
        }
        draft.hours[k] = parseInt(v);
      }),
    );
  };

  const hasChanged = JSON.stringify(baseJob) !== JSON.stringify(job);

  const {
    title,
    status,
    audienceId,
    messageId,
    dryRun,

    created,
    updated,
    started,
    finished,
    runCounter,
    error,

    hours,
    repeatAfter,
    startDate,
    userNotificationGracePeriod,
    history,
  } = job;

  const deleteLabel = createMode
    ? 'Abbrechen'
    : status === NotificationJobStatus.ARCHIVED ||
      !history ||
      history.length <= 0
    ? 'Löschen'
    : 'Archivieren';

  return (
    <div className={styles.container}>
      <Spacer size="medium" />

      <h3>Basisdaten</h3>
      <Spacer size="small" />
      <FormContainer>
        <div>Titel</div>
        <Input
          placeholder="Kurzbeschreibung des Jobs"
          value={title}
          onChange={(e) => onPropertyChange('title', e.target.value)}
        />
        <div>Status</div>
        <select
          value={status}
          onChange={(e) => onPropertyChange('status', e.target.value)}>
          {Object.values(NotificationJobStatus).map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
        <div>Audience-ID</div>
        <AudienceSelection
          value={audienceId || ''}
          onChange={(e) => onPropertyChange('audienceId', e.target.value)}
        />
        <div>Message-ID</div>
        <MessageSelection
          value={messageId || ''}
          onChange={(e) => onPropertyChange('messageId', e.target.value)}
        />
        <div>Testmodus</div>
        <input
          type="checkbox"
          checked={!!dryRun}
          onChange={(e) => onPropertyChange('dryRun', e.target.checked)}
        />
      </FormContainer>

      <Spacer size="xlarge" />

      <h3>Versand</h3>
      <Spacer size="small" />
      <FormContainer>
        <div>Start am</div>
        <DateInput
          placeholder="Startzeitpunkt des Jobs z.B. 31.01.2020"
          isoDate={startDate}
          onValueChange={(v) => onDurationPropertyChange('startDate', v)}
        />

        <div>Von</div>
        <Input
          placeholder="Von"
          type="number"
          min={0}
          max={24}
          value={!isNaN(hours.from) ? hours.from : ''}
          onChange={(e) => onHoursChange('from', e.target.value)}
        />
        <div>Bis</div>
        <Input
          placeholder="Bis"
          type="number"
          min={0}
          max={24}
          value={!isNaN(hours.to) ? hours.to : ''}
          onChange={(e) => onHoursChange('to', e.target.value)}
        />

        <div>Wiederholen</div>
        <DurationInput
          placeholder="1m, 2h, 3d"
          duration={repeatAfter ?? 0}
          onValueChange={(v) => onDurationPropertyChange('repeatAfter', v)}
        />

        <div>Wartezeit</div>
        <DurationInput
          placeholder="1m, 2h, 3d"
          duration={userNotificationGracePeriod ?? 0}
          onValueChange={(v) =>
            onDurationPropertyChange('userNotificationGracePeriod', v)
          }
        />
      </FormContainer>

      <Spacer size="xlarge" />

      <h3>Zusatzinformationen</h3>
      <Spacer size="small" />
      <FormContainer>
        <div>Erstellt</div>
        <div>{dateToString(created)}</div>
        <div>Geändert</div>
        <div>{dateToString(updated)}</div>
        {!!started && (
          <>
            <div>Gestartet</div>
            <div>{dateToString(started)}</div>
          </>
        )}
        {!!finished && (
          <>
            <div>Beendet</div>
            <div>{dateToString(finished)}</div>
          </>
        )}
        {!!runCounter && (
          <>
            <div>Anzahl</div>
            <Input value={runCounter} disabled />
          </>
        )}
        {!!error && (
          <>
            <div>Fehler</div>
            <div>{error}</div>
          </>
        )}
      </FormContainer>

      <Spacer size="xlarge" />

      <div className={styles.buttonContainer}>
        <Button
          variant="outlined"
          disabled={!hasChanged}
          onClick={() => onUpdate(job)}
          style={{ marginRight: 8 }}>
          {createMode ? 'Erstellen' : 'Speichern'}
        </Button>

        <Spacer expander />

        <Button
          variant="outlined"
          onClick={() => onDelete(job.id)}
          style={{ marginRight: 8 }}>
          {deleteLabel}
        </Button>
      </div>
    </div>
  );
};
