import { Button, LinearProgress } from '@material-ui/core';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { OrderPayoutListRequest } from '../../../../home/state/userApi';
import styles from './InvitePayoutList.module.scss';

interface Props {
  createList: (data: OrderPayoutListRequest) => Promise<string>;
  createInviteList: (data: OrderPayoutListRequest) => Promise<string>;
}

export const InvitePayoutList = ({ createList, createInviteList }: Props) => {
  const [loadingPayout, setLoadingPayout] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [error, setError] = useState('');
  const [request, setRequest] = useState<OrderPayoutListRequest>({
    from: '01.07.2020',
    until: '30.06.2021',
    untilPayment: '30.06.2021',
  });

  const updateOrderRequest = (key: string, value: string) => {
    setRequest((s) => ({
      ...s,
      [key]: inputToGerString(value),
    }));
  };

  const onCreatePayoutClick = async () => {
    setLoadingPayout(true);
    try {
      const csv = await createList(request);
      if (csv !== '') {
        const blob = new Blob([csv || ''], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, getFilename('Einladungsauszahlungen', request));
      }
    } catch (e) {
      setError('Die Liste konnte nicht erstellt werden mit Fehler: ' + e);
    }
    setLoadingPayout(false);
  };

  const onCreateInviteClick = async () => {
    setLoadingInvite(true);
    try {
      const csv = await createInviteList(request);
      if (csv !== '') {
        const blob = new Blob([csv || ''], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, getFilename('Einladungen', request));
      }
    } catch (e) {
      setError('Die Liste konnte nicht erstellt werden mit Fehler: ' + e);
    }
    setLoadingInvite(false);
  };

  return (
    <div>
      <h3>Einladungsauswertung</h3>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Hier kann eine Liste der Einladungsauszahlungen erstellt werden. Bitte
        beachte, dass bei den Enddaten immer das Ende des Tages gilt.
      </div>
      <Spacer size="large" />
      <div className={styles.orderListInputContainer}>
        <div>Einladungsauszahlungen von</div>
        <Input
          type="date"
          value={gerStringToInput(request.from)}
          onChange={(e) => updateOrderRequest('from', e.target.value)}
        />
        <div> bis </div>
        <Input
          type="date"
          value={gerStringToInput(request.until)}
          onChange={(e) => updateOrderRequest('until', e.target.value)}
        />
        <div>Zahlungsschluss am</div>
        <Input
          type="date"
          value={gerStringToInput(request.untilPayment)}
          onChange={(e) => updateOrderRequest('untilPayment', e.target.value)}
        />
      </div>

      <Spacer size="large" />

      <div style={{ display: 'flex' }}>
        <Button
          className={styles.orderListButton}
          variant={'outlined'}
          size="small"
          disabled={loadingPayout}
          onClick={onCreatePayoutClick}>
          {!loadingPayout
            ? 'Auszahlungs-Auswertung'
            : 'Auswertung wird erstellt'}
        </Button>

        <Spacer />

        <Button
          className={styles.orderListButton}
          variant={'outlined'}
          size="small"
          disabled={loadingInvite}
          onClick={onCreateInviteClick}>
          {!loadingInvite
            ? 'Einladungs-Auswertung'
            : 'Auswertung wird erstellt'}
        </Button>

        <Spacer />

        {(loadingPayout || loadingInvite) && (
          <LinearProgress style={{ height: 4 }} />
        )}
      </div>
      {!!error && <div>{error}</div>}

      <Spacer />
    </div>
  );
};

function gerStringToInput(s: string) {
  const [day, month, year] = s.split('.');
  return [year, month, day].join('-');
}

function inputToGerString(s: string) {
  const [year, month, day] = s.split('-');
  return [day, month, year].join('.');
}

function getFilename(
  title: string,
  { from, until, untilPayment }: OrderPayoutListRequest,
): string {
  from = from.replaceAll('.', '_');
  until = until.replaceAll('.', '_');
  untilPayment = untilPayment.replaceAll('.', '_');

  return `${title}_von_${from}_bis_${until}_zahlungsschluss_${untilPayment}.csv`;
}
