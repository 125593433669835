import {
  Action,
  PayloadAction,
  ThunkAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  CustomerResponse,
  OrderCounts,
  OrderUpdateType,
  OrdersPayload,
  RichOrder,
  ShopApi,
  UserOrders,
} from './shopApi';
import { RootState } from '../../store';
import { StorageKey, StorageService } from '../../common/storage.service';
import { selectSettingsUsername } from '../../settings/state/settingsReducer';

const orderCountsEmpty: OrderCounts = {
  all: 0,
  pending: 0,
  review: 0,
  selected: 0,
};

export interface ShopState {
  userOrders: UserOrders[];
  customers: { [key: string]: CustomerResponse };
  orderIds: string[];
  orders: { [key: string]: RichOrder };
  counts: OrderCounts;
  states: string[];
  onlyMentioned?: boolean;
  query: string;
}

const { states } = restoreSettings();

const initialState: ShopState = {
  userOrders: [],
  customers: {},
  orderIds: [],
  orders: {},
  counts: orderCountsEmpty,
  states,
  query: '',
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    updateUserOrders(state: ShopState, action: PayloadAction<UserOrders[]>) {
      state.userOrders = action.payload || [];
    },
    updateOrders(
      state: ShopState,
      action: PayloadAction<{
        orderIds: string[];
        orders: { [key: string]: RichOrder };
      }>,
    ) {
      const { orderIds, orders } = action.payload;
      return { ...state, orderIds, orders };
    },
    updateOrder(state: ShopState, action: PayloadAction<RichOrder>) {
      const o = action.payload;
      const { orderIds, orders } = state;
      if (!orderIds.includes(o.order._id)) {
        orderIds.push(o.order._id);
      }
      orders[o.order._id] = o;
    },
    updateFilter(state: ShopState, action: PayloadAction<string[]>) {
      const states = action.payload;
      return { ...state, states };
    },
    updateCount(state: ShopState, action: PayloadAction<OrderCounts>) {
      return { ...state, counts: action.payload };
    },
    setMentioned(state: ShopState, action: PayloadAction<boolean>) {
      return { ...state, onlyMentioned: action.payload };
    },
    setCustomer(state: ShopState, action: PayloadAction<CustomerResponse>) {
      const c = action.payload;
      state.customers = { ...state.customers, [c.userId]: c };
    },
    setShopQuery(state: ShopState, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const { updateOrders, setMentioned, setShopQuery } = shopSlice.actions;

export default shopSlice.reducer;

// Selectors
export const selectShop = (state: RootState) => state.shop;

export const selectStates = createSelector(selectShop, ({ states }) => states);
export const selectCounts = createSelector(
  selectShop,
  ({ counts }) => counts || orderCountsEmpty,
);
export const selectMentioned = createSelector(
  selectShop,
  ({ onlyMentioned }) => onlyMentioned,
);
export const selectUserOrders = createSelector(
  selectShop,
  ({ userOrders }) => userOrders,
);
export const selectOrders = createSelector(
  selectShop,
  selectSettingsUsername,
  ({ orderIds, orders, onlyMentioned, states }, selectSettingsUsername) =>
    orderIds
      .map((id) => orders[id])
      .filter(filterMentioned(!!onlyMentioned, selectSettingsUsername))
      .filter(filterStates(states)),
);
export const selectOrder = (id: string) =>
  createSelector(selectShop, ({ orders, orderIds }) => {
    const o = orders[id];
    if (o) return o;
    return orderIds
      .map((id) => orders[id])
      .find((order) => order.order.orderNumber === id);
  });

export const selectCustomer = (uid: string) =>
  createSelector(selectShop, ({ customers }) => customers[uid]);

export const selectQuery = createSelector(selectShop, ({ query }) => query);

// Thunks
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const toggleFilter =
  (s: string): AppThunk =>
  async (dispatch, getState) => {
    const states = [...selectStates(getState())];
    const index = states.indexOf(s);
    if (index < 0) {
      states.push(s);
    } else {
      states.splice(index, 1);
    }
    dispatch(shopSlice.actions.updateFilter(states));
    storeSettings(states);
  };

export const fetchMatchingOrders =
  (states: string[], query: string, limit: number, offset: number): AppThunk =>
  async (dispatch) => {
    const p: OrdersPayload = {
      states: states.join(','),
      query,
      offset,
      limit,
    };

    try {
      const { orders, counts } = await ShopApi.fetchOrders(p);
      dispatch(shopSlice.actions.updateUserOrders(orders));
      dispatch(shopSlice.actions.updateCount(counts));
    } catch {
      dispatch(updateOrders({ orderIds: [], orders: {} }));
      dispatch(shopSlice.actions.updateCount(orderCountsEmpty));
      // throw new Error('fetchMatchingOrders');
    }
  };

export const fetchOrder =
  (id: string): AppThunk =>
  async (dispatch) => {
    const order = await ShopApi.fetchOrder(id);
    dispatch(shopSlice.actions.updateOrder(order));
  };

export const updateOrderStatus =
  (
    uid: string,
    oid: string,
    type: OrderUpdateType,
    comment: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    const monitorUser = selectSettingsUsername(getState());
    await ShopApi.updateOrder(oid, type, uid, monitorUser, comment);
    dispatch(fetchOrder(oid));
  };

export const fetchCustomer =
  (uid: string): AppThunk =>
  async (dispatch) => {
    const r = await ShopApi.fetchCustomer(uid);
    dispatch(shopSlice.actions.setCustomer(r));
  };

// Local Storage
export function storeSettings(states: string[]) {
  StorageService.store(StorageKey.SHOP, { states });
}

function restoreSettings(): { states: string[] } {
  const s: { states: string[] } = StorageService.restore(StorageKey.SHOP, {
    states: [],
  });
  return s;
}

function filterMentioned(
  m: boolean,
  username: string,
): (o: RichOrder) => boolean {
  return (o: RichOrder) => {
    if (!m) return true;
    return mentionedInComment(o.order, username);
  };
}

function filterStates(states: string[]): (o: RichOrder) => boolean {
  return (o: RichOrder) => {
    return !states || states.length <= 0 || states.includes(o.order.status);
  };
}

export function mentionedInComment(order: any, username: string): boolean {
  if (!username) return false;

  if (!order.supportInformation || order.supportInformation.length <= 0) {
    return false;
  }

  for (const i of order.supportInformation) {
    if (i.comment && i.comment.includes(username)) {
      return true;
    }
  }

  return false;
}
