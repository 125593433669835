import React, { useEffect, useState } from 'react';
import styles from './ExpansionPanel.module.scss';

interface Props {
  expanded?: boolean;
  header: React.ReactElement;
  content?: React.ReactElement;
  children?: React.ReactNode;
}

export const ExpansionPanel: React.FC<Props> = ({
  expanded: base,
  header,
  content,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(!!base);

  useEffect(() => setExpanded(!!base), [base]);

  return (
    <div className={styles.container}>
      <div
        onClick={() => setExpanded((s) => !s)}
        className={styles.expansionHeader}>
        {header}
      </div>
      <div
        style={{ display: expanded ? 'block' : 'none' }}
        className={styles.expansionContent}>
        {content}
        {children}
      </div>
    </div>
  );
};
