import { Button, CircularProgress } from '@material-ui/core';
import { dateToString, matchesQuery } from 'app/common/text';
import { Divider } from 'app/components/Divider/Divider';
import { ExpansionPanel } from 'app/components/ExpansionPanel/ExpansionPanel';
import { Table } from 'app/components/Table/Table';
import produce from 'immer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spacer } from '../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../store';
import { taskActions } from '../state/taskActions';
import styles from './TaskDetailPage.module.scss';
import { TaskAction } from '../state/taskApi';
import { ShortTextCopy } from 'app/components/ShortTextCopy/ShortTextCopy';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  fetchTask: taskActions.fetchTask,
  handleTask: taskActions.handleTask,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawTaskDetailPage: React.FC<Props> = ({
  fetchTask,
  handleTask,
}: Props) => {
  const [task, setTask] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorHint, setErrorHint] = useState<string>('');

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = async (tid: string) => {
    setLoading(true);
    try {
      const { task } = await fetchTask(tid);
      setTask(task);
    } catch (_) {
      setTemp(setError, 'Die Aktion konnte nicht durchgeführt werden.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <h1>Task Detail Page</h1>
        <Spacer size="xlarge" />
        <CircularProgress />
      </div>
    );
  }

  if (!task) {
    return (
      <div className={styles.container}>
        <h1>Task Detail Page Error</h1>
      </div>
    );
  }

  const onHandleTask = async (action: TaskAction) => {
    console.log('onHandleTask', action, errorHint);
    try {
      await handleTask(id, action, errorHint);
      loadData(id);
    } catch (_) {
      setTemp(setError, 'Die Aktion konnte nicht durchgeführt werden.');
    }
  };

  const { data: rd, kyc, pdf, status } = task;
  const { userData: ud } = kyc;
  const userBD = moment(rd.birthday, 'DD.MM.YYYY', true);
  const idNowBD = moment(ud.birthday, 'YYYY-MM-DD', true);

  const rawTask = produce(task, (draft: any) => {
    draft.pdf = '';
    draft.data.signature = '';
  });

  const comparisonData = [
    {
      label: 'Vorname',
      user: rd.forename,
      idNow: ud.forename,
      diff: matchesQuery(rd.forename, ud.forename) ? '✅' : '❗',
    },
    {
      label: 'Nachname',
      user: rd.surname,
      idNow: ud.surname,
      diff: matchesQuery(rd.surname, ud.surname) ? '✅' : '❗',
    },
    {
      label: 'Geburtsdatum',
      user: rd.birthday,
      idNow: idNowBD.format('DD.MM.YYYY'),
      diff: userBD.isSame(idNowBD) ? '✅' : '❗',
    },
  ];

  return (
    <div className={styles.container}>
      <h1>Status: {status}</h1>
      <Spacer />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          {[
            'Nutzer: ' + (task.type === 'user' ? 'Hauptnutzer' : 'Partner'),
            'Erstellt: ' + dateToString(task.createdAt),
            'Aktualisiert: ' + dateToString(task.updatedAt),
            'UserID: ' + task.userId,
          ].join(', ')}
        </div>
        <ShortTextCopy
          fontSize={14}
          text={`{userId: "${task.userId}", type: "${task.type}"}`}
          title=", MDB"
        />
      </div>
      <Spacer size="large" />
      <div style={{ maxWidth: 750 }}>
        <Table
          data={comparisonData}
          coloumns={[
            { label: 'Wert', id: 'label' },
            { label: 'Nutzerdaten', id: 'user' },
            { label: 'IDNow', id: 'idNow' },
            { label: 'Check', id: 'diff', width: 60 },
          ]}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <Button
          variant="outlined"
          disabled={status !== 'blocked'}
          onClick={() => onHandleTask(TaskAction.ACCEPT)}>
          Freigeben
        </Button>
        <Spacer />
        <Button
          variant="outlined"
          disabled={status !== 'blocked' || errorHint.length < 30}
          onClick={() => onHandleTask(TaskAction.DECLINE)}>
          Ablehnen
        </Button>
      </div>
      <Spacer />

      {!!error && (
        <>
          <div style={{ color: 'red' }}>{error}</div>
          <Spacer />
        </>
      )}

      <textarea
        placeholder="Fehlerhinweis (mindestens 30 Zeichen zum Ablehnen benötigt)"
        value={errorHint}
        style={{ fontSize: 'small', maxWidth: 725, height: 100, flex: 'none' }}
        onChange={(e) => setErrorHint(e.target.value)}
      />

      <Spacer size="xlarge" />

      <Divider />

      <Spacer size="xlarge" />
      <div style={{ flex: 'none', maxWidth: 750 }}>
        <ExpansionPanel
          header={<div>Unterschrift</div>}
          expanded={true}
          content={
            <embed
              style={{ width: '200px' }}
              src={`data:image/jpg;base64,${rd.signature}`}></embed>
          }
        />
      </div>
      <Spacer />

      <div style={{ flex: 'none', maxWidth: 750 }}>
        <ExpansionPanel
          header={<div>Vollmacht</div>}
          content={
            <embed
              style={{ width: 700, height: 990, flex: 'none' }}
              src={`data:application/pdf;base64,${pdf}`}></embed>
          }
        />
      </div>

      <Spacer />

      <div style={{ flex: 'none', maxWidth: 750 }}>
        <ExpansionPanel
          header={<div>Rohdaten</div>}
          content={<pre>{JSON.stringify(rawTask, null, 2)}</pre>}
        />
      </div>

      <Spacer size="xxxlarge" />
    </div>
  );
};

export const TaskDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawTaskDetailPage);

function setTemp(fn: (s: string) => void, e: string) {
  fn(e);
  setTimeout(() => {
    fn('');
  }, 6000);
}
