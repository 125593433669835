import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuery,
  selectStates,
  setShopQuery,
  toggleFilter,
} from '../../shop/state/shopReducer';
import { Input } from '../Input/Input';
import styles from './OrdersSidenav.module.scss';

interface Props {
  loadingOrders?: boolean;
}

export const OrdersSidenav = ({ loadingOrders }: Props) => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const query = useSelector(selectQuery);
  // const [query, setQuery] = useState('');

  const stateConfig = [
    { l: 'Warten auf Freigabe', s: 'pending_permission' },
    { l: 'In Prüfung', s: 'in_review' },
    { l: 'Freigegeben', s: 'permitted' },
    { l: 'Offene Bezahlung', s: 'open' },
    { l: 'Bezahlt', s: 'complete' },
    { l: 'Abgelehnt', s: 'declined' },
    { l: 'Storniert', s: 'cancelled' },
  ];

  const handleToggle = (s: string) => () => {
    dispatch(toggleFilter(s));
  };

  const handleQueryChange = (v: React.ChangeEvent<HTMLInputElement>) => {
    const value = v.target.value;
    // setQuery(value);
    dispatch(setShopQuery(value));
  };

  const handleQuerySubmit = ({ key }: React.KeyboardEvent) => {
    if (key === 'Enter') dispatch(setShopQuery(query));
  };

  return (
    <div className={styles.container}>
      <h4>Suche</h4>
      <Input
        placeholder="Bestellnummer/-ID oder User-ID"
        className={styles.query}
        value={query}
        onChange={handleQueryChange}
        onKeyDown={handleQuerySubmit}
      />

      <h4>Zustände Filter</h4>
      <div className={styles.checkboxContainer}>
        {stateConfig.map(({ l, s }) => (
          <Chip
            key={l}
            variant={states.includes(s) ? 'default' : 'outlined'}
            label={l}
            color={states.includes(s) ? 'primary' : 'default'}
            size="small"
            clickable
            style={{ marginRight: 4, marginBottom: 4 }}
            onClick={handleToggle(s)}
            onDelete={handleToggle(s)}
            deleteIcon={<DoneIcon />}
          />
        ))}
      </div>
    </div>
  );
};
