import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  realm: 'monitor',
  url: 'https://keycloak.steuerbot.com/auth/',
  clientId: process.env.REACT_APP_CLIENT_ID ?? 'monitor-local',
});

export interface AuthClientTokens {
  token: string;
  refreshToken: string;
  idToken: string;
}

export interface AuthClientMeta {
  userId: string;
  email: string;
  name: string;
  roles: string[];
}
