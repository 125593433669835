import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import produce from 'immer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatMonthly, parseMonthly } from '../../../common/date';
import { Divider } from '../../../components/Divider/Divider';
import { ExpansionPanel } from '../../../components/ExpansionPanel/ExpansionPanel';
import { FormContainer } from '../../../components/FormContainer/FormContainer';
import { Input } from '../../../components/Input/Input';
import { Spacer } from '../../../components/Spacer/Spacer';
import { Table, TableCol } from '../../../components/Table/Table';
import { CostScenario, Scenario, statsApi } from '../../api/statsApi';
import styles from './Scenarios.module.scss';

interface Props {
  active?: string;
  onSelect: (id?: string) => void;
}

export const Scenarios: React.FC<Props> = ({ active, onSelect }: Props) => {
  const [scenarios, setScenarios] = useState<Scenario[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const { scenarios } = await statsApi.getScenarios();
      setScenarios(scenarios || []);
    };
    loadData();
  }, []);

  const addScenario = async () => {
    const { scenario } = await statsApi.addScenario({ id: '' });
    setScenarios((s) => [...s, scenario]);
  };

  const select = (id?: string) => (ev: React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    onSelect(id);
  };

  const deleteScenario = (id: string) => async (ev: React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    await statsApi.deleteScenario(id);
    const { scenarios } = await statsApi.getScenarios();
    setScenarios(scenarios || []);
  };

  const updateScenario = async (s: Scenario) => {
    await statsApi.updateScenario(s);
  };

  const activeScenario = (scenarios || []).find((s) => s.id === active);

  return (
    <div className={styles.container}>
      <div className={styles.availableContainer}>
        <h4>Verfügbare Szenarien</h4>
        {(scenarios || []).map((s) => (
          <div key={s.id} onClick={select(s.id)} style={{ cursor: 'default' }}>
            <span
              style={{ display: 'inline-block', width: 30 }}
              onClick={select()}>
              {s.id === active ? '🟢' : ''}
            </span>
            <span style={{ display: 'inline-block', width: 250 }}>
              {s.label || s.id}
            </span>
            <span style={{ display: 'inline-block', width: 30 }}>📝</span>
            <span
              style={{ display: 'inline-block', width: 30 }}
              onClick={deleteScenario(s.id)}>
              ❌
            </span>
          </div>
        ))}
        <Spacer />
        <Button onClick={addScenario} variant="outlined">
          Neues Szenario anlegen
        </Button>
      </div>
      <Spacer />
      {!!activeScenario && (
        <div className={styles.editorContainer}>
          <h4>Szenario editieren</h4>
          <ScenarioEditor scenario={activeScenario} onUpdate={updateScenario} />
        </div>
      )}
    </div>
  );
};

const ScenarioEditor = ({
  scenario: base,
  onUpdate,
}: {
  scenario: Scenario;
  onUpdate: (s: Scenario) => void;
}) => {
  const [scenario, setScenario] = useState(base);
  useEffect(() => {
    setScenario(base);
  }, [base]);

  const {
    label,
    comment,
    cost,
    legacyMonthlySubmissions,
    retentionPerUserAge,
    submissionsPerUserAge,
  } = scenario;

  const onPropertyChange = (k: string, v: any) => {
    setScenario((s) => ({ ...s, [k]: v }));
  };

  return (
    <div>
      <FormContainer>
        <div>Titel</div>
        <Input
          value={label || ''}
          onChange={(e) => onPropertyChange('label', e.target.value)}
        />
        <div>Kommentar</div>
        <Input
          value={comment || ''}
          onChange={(e) => onPropertyChange('comment', e.target.value)}
        />
        <div>Retention</div>
        <AgeArray
          value={retentionPerUserAge || []}
          header={{ k: 'Generation', v: 'Retentionrate' }}
          onChange={(vv) => onPropertyChange('retentionPerUserAge', vv)}
        />
        <div>Abgaben</div>
        <AgeArray
          value={submissionsPerUserAge || []}
          header={{ k: 'Generation', v: 'Abgaben / Jahr' }}
          onChange={(vv) => onPropertyChange('submissionsPerUserAge', vv)}
        />
        <div>Abgaben</div>
        <YearMap
          value={legacyMonthlySubmissions || {}}
          onChange={(vv) => onPropertyChange('legacyMonthlySubmissions', vv)}
        />
      </FormContainer>
      <Spacer />
      <Divider />
      <Spacer />
      <ExpansionPanel
        header={<div>Monatliche Kosten</div>}
        content={
          <CostTable
            value={cost || {}}
            onChange={(vv) => onPropertyChange('cost', vv)}
          />
        }
      />
      <Spacer />
      <ExpansionPanel
        header={<div>Jährlich konfigurieren</div>}
        content={
          <YearlyScenarios onSubmit={(vv) => onPropertyChange('cost', vv)} />
        }
      />
      <Spacer />
      <Button onClick={() => onUpdate(scenario)} variant="outlined">
        Speichern
      </Button>
    </div>
  );
};

const AgeArray = ({
  onChange,
  value,
  header,
}: {
  onChange: (v: number[]) => void;
  value: number[];
  header?: { k: string; v: string };
}) => {
  const onEdit = (idx: number, v: any) => {
    value[idx] = parseFloat(v);
    onChange(value);
  };

  const onAdd = () => {
    onChange([...value, 1.01]);
  };

  return (
    <div className={styles.ageContainer}>
      {!!header && (
        <div className={styles.ageCol}>
          <div className={styles.ageArrayHeader}>{header.k}</div>
          <div className={styles.ageArrayValues}>{header.v}</div>
        </div>
      )}
      {value.map((v, idx) => (
        <div key={idx} className={styles.ageCol}>
          <div className={styles.ageArrayHeader}>{idx}</div>
          <div className={styles.ageArrayValues}>
            <Input
              size={12}
              step={0.01}
              style={{ width: 60, flex: 'none', border: 0 }}
              type="number"
              variant="minimal"
              value={v}
              onChange={(e) => onEdit(idx, e.target.value)}
            />
          </div>
        </div>
      ))}
      <Button onClick={onAdd}>
        <Add />
      </Button>
    </div>
  );
};

const YearMap = ({
  onChange,
  value,
}: {
  onChange: (v: { [key: number]: number }) => void;
  value: { [key: number]: number };
}) => {
  const onEdit = (k: number, v: any) => {
    value[k] = parseInt(v);
    onChange(value);
  };

  const onAdd = () => {
    const yy = Object.keys(value).map((k) => parseInt(k));
    let y: number;
    if (yy.length <= 0) {
      y = new Date().getFullYear();
    } else {
      y = Math.max(...yy) + 1;
    }
    onChange({ ...value, [y]: 2021 });
  };

  return (
    <div className={styles.ageContainer}>
      <div className={styles.ageCol}>
        <div className={styles.ageArrayHeader}>Jahr</div>
        <div className={styles.ageArrayValues}>verhält sich wie</div>
      </div>
      {Object.keys(value).map((k) => (
        <div key={k} className={styles.ageCol}>
          <div className={styles.ageArrayHeader}>{k}</div>
          <div className={styles.ageArrayValues}>
            <Input
              size={12}
              step={0.01}
              style={{ width: 60, flex: 'none', border: 0 }}
              type="number"
              variant="minimal"
              value={value[k]}
              onChange={(e) => onEdit(parseInt(k), e.target.value)}
            />
          </div>
        </div>
      ))}
      <Button onClick={onAdd}>
        <Add />
      </Button>
    </div>
  );
};

const emptyCostScenario = (): CostScenario => ({
  cpo: 24,
  marketing: 400_000,
  revPerSale: 24.61,
  submissionToSale: 0.76,
});

export const CostTable = ({
  onChange,
  value,
}: {
  onChange: (v: { [key: string]: CostScenario }) => void;
  value: { [key: string]: CostScenario };
}) => {
  const onEdit = (k: string, f: string, v: any) => {
    value[k][f] = v;
    onChange(value);
  };

  const onDelete = (k: string) => {
    delete value[k];
    onChange(value);
  };

  const cols: TableCol[] = [
    { label: 'Datum', id: 'key' },
    {
      label: 'Marketing in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.marketing || 0}
          onChange={(e) =>
            onEdit(v.key, 'marketing', parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      label: 'CPO in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.cpo || 0}
          onChange={(e) => onEdit(v.key, 'cpo', parseFloat(e.target.value))}
        />
      ),
    },
    {
      label: 'Sale pro Abgabe',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.submissionToSale || 0}
          onChange={(e) =>
            onEdit(v.key, 'submissionToSale', parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      label: 'Umsatz / Sale in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.revPerSale || 0}
          onChange={(e) =>
            onEdit(v.key, 'revPerSale', parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      label: 'Löschen',
      renderFunc: (v: any, idx: number) =>
        idx === data.length - 1 ? (
          <div
            onClick={() => onDelete(v.key)}
            style={{ cursor: 'default', userSelect: 'none' }}>
            𝗫
          </div>
        ) : (
          ''
        ),
    },
  ];

  const data = Object.keys(value)
    .map((k) => ({ k, t: parseMonthly(k) }))
    .sort((a, b) => a.t.valueOf() - b.t.valueOf())
    .map((v) => ({ ...value[v.k], key: v.k }));

  const onAdd = () => {
    if (data.length <= 0) {
      onChange({
        [moment().format('MM.YYYY')]: emptyCostScenario(),
      });
      return;
    }

    const kd = data[data.length - 1].key;
    const k = parseMonthly(kd).add({ months: 1 }).format('MM.YYYY');
    onChange({ ...value, [k]: emptyCostScenario() });
  };

  return (
    <div>
      <b>
        Monatliche Kosten{' '}
        <Button onClick={() => onAdd()}>
          <Add />
        </Button>
      </b>
      <div className={styles.costTableContainer}>
        <Table coloumns={cols} data={data} />
      </div>
    </div>
  );
};

export const YearlyScenarios = ({
  onSubmit,
}: {
  onSubmit: (v: { [key: string]: CostScenario }) => void;
}) => {
  const dist = '9,15,16,11,10,9,4,4,3,10,4,5';

  const [scenarios, setScenarios] = useState<
    {
      key: number;
      marketing?: number;
      cpo?: number;
      submissionToSale?: number;
      revPerSale?: number;
    }[]
  >([
    { key: 2022, marketing: 4_000 },
    { key: 2023 },
    { key: 2024 },
    { key: 2025 },
    { key: 2026 },
    { key: 2027 },
  ]);

  const [monthlyDistribution, setMonthlyDistribution] = useState(
    '9,15,16,11,10,9,4,4,3,10,4,5',
  );

  const onEdit = (i: number, k: string, v: any) => {
    setScenarios((s) =>
      produce(s, (draft) => {
        draft[i][k] = v;
      }),
    );
  };

  const onUpdate = () => {
    const result: { [key: string]: CostScenario } = {};
    const monthlyDist = monthlyDistribution
      .split(',')
      .map((s) => parseInt(s) / 100);

    for (const scenario of scenarios) {
      const {
        key: year,
        cpo,
        marketing,
        submissionToSale,
        revPerSale,
      } = scenario;

      const date = parseMonthly('07.' + (year - 1));
      const end = parseMonthly('06.' + year);

      while (date.isSameOrBefore(end)) {
        const key = formatMonthly(date);

        result[key] = {
          marketing: (marketing || 0) * 1000 * monthlyDist[date.month()],
          cpo: cpo || 1000,
          submissionToSale: submissionToSale || 1000,
          revPerSale: revPerSale || 1000,
        };

        date.add({ months: 1 });
      }
    }

    onSubmit(result);
  };

  const cols: TableCol[] = [
    {
      label: 'GJ',
      id: 'key',
    },
    {
      label: 'Marketing in T€',
      renderFunc: (v: any, idx: number) => (
        <Input
          size={12}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.marketing || 0}
          onChange={(e) => onEdit(idx, 'marketing', parseFloat(e.target.value))}
        />
      ),
    },
    {
      label: 'CPO in €',
      renderFunc: (v: any, idx: number) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.cpo || 0}
          onChange={(e) => onEdit(idx, 'cpo', parseFloat(e.target.value))}
        />
      ),
    },
    {
      label: 'Sale pro Abgabe',
      renderFunc: (v: any, idx: number) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.submissionToSale || 0}
          onChange={(e) =>
            onEdit(idx, 'submissionToSale', parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      label: 'Umsatz / Sale in €',
      renderFunc: (v: any, idx: number) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.revPerSale || 0}
          onChange={(e) =>
            onEdit(idx, 'revPerSale', parseFloat(e.target.value))
          }
        />
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Table coloumns={cols} data={scenarios} />
      <Input
        placeholder="Monatsverteilung Budget: 10,20,..."
        value={monthlyDistribution}
        onChange={(e) => setMonthlyDistribution(e.target.value)}
      />
      <Button onClick={onUpdate}>Update scenario</Button>
    </div>
  );
};
