import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Spacer } from '../../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../../store';
import { AudienceDefinition } from '../../state/notificationApi';
import {
  isApiError,
  notificationsActions,
} from '../../state/notificationsActions';
import {
  selectAudienceDefinition,
  selectAudienceSize,
} from '../../state/notificationsReducer';
import { AudienceDefinitionBuilder } from '../NotificationAudienceBuilder/NotificationAudienceBuilder';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  fetchAudience: notificationsActions.fetchAudience,
  fetchAudienceSize: notificationsActions.fetchAudienceSize,
  createAudience: notificationsActions.createAudience,
  updateAudience: notificationsActions.updateAudience,
  deleteAudience: notificationsActions.deleteAudience,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationDetail = ({
  fetchAudience,
  fetchAudienceSize,
  createAudience,
  updateAudience,
  deleteAudience,
}: Props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: audienceId } = useParams<{ id: string }>();
  const audience = useSelector(selectAudienceDefinition(audienceId));
  const audienceSize = useSelector(selectAudienceSize(audienceId));
  const [loading, setLoading] = useState(!audience);
  const backPath = path.replace(':id', '');

  const inCreateMode = audienceId === 'new';

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchAudience(audienceId);
      await fetchAudienceSize(audienceId);
      setLoading(false);
    };
    if (!inCreateMode) {
      loadData();
    }
  }, [audienceId]);

  const onDelete = async (d: AudienceDefinition) => {
    if (inCreateMode) {
      history.replace(backPath);
      return;
    }

    setLoading(true);
    const err = await deleteAudience(d.id);
    if (!err) {
      history.replace(backPath);
    }
    console.log(err);
  };

  const onUpdate = async (d: AudienceDefinition) => {
    setLoading(true);
    if (!inCreateMode) {
      await updateAudience(d); // TODO: handle error
      setLoading(false);
      return;
    }

    const res = await createAudience(d);
    if (isApiError(res)) {
      return; // TODO: handle error
    }

    setLoading(false);
    const newPath = path.replace(':id', res.id);
    history.replace(newPath);
  };

  return (
    <div>
      <LoadingNavHeader
        title="Zielgruppe bearbeiten"
        loading={loading}
        backPath={backPath}
        backTitle="Zielgruppen"
      />

      {!!audience && (
        <>
          {!inCreateMode && (!loading || audienceSize > -1) && (
            <h3>Größe {audienceSize ?? 0}</h3>
          )}
          <Spacer size="large" />

          <AudienceDefinitionBuilder
            definition={audience}
            onUpdateDefinition={onUpdate}
            onDeleteDefinition={onDelete}
            editMode={!inCreateMode}
          />
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
