import React from 'react';
import { TrustResult } from '../../shop/state/shopApi';
import { Table, TableCol } from '../Table/Table';

export const SimpleTrust = ({ trust }: { trust: TrustResult }) => {
  const cols: TableCol[] = [
    { id: 'name', label: 'Name' },
    { id: 'score', label: 'Bewertung' },
    { id: 'description', label: 'Beschreibung' },
    { id: 'annotation', label: 'Annotation' },
  ];

  return <Table coloumns={cols} data={trust.scores} noHeader />;
};
