import { Button, Divider, LinearProgress } from '@material-ui/core';
import FunnelGraph from 'funnel-graph-js';
import React, { useEffect, useState } from 'react';
import { deepCopy } from '../../common/eval';
import { StorageKey, StorageService } from '../../common/storage.service';
import { all } from '../../common/style';
import { Input } from '../../components/Input/Input';
import { SideNav } from '../../components/SideNav/SideNav';
import { Spacer } from '../../components/Spacer/Spacer';
import { FunnelNodeCount, userApi } from '../../home/state/userApi';
import styles from './FunnelPage.module.scss';

const defaultColors = [
  '#FF4589',
  '#FF5050',
  '#05DF9D',
  '#4FF2FD',
  '#2D9CDB',
  '#A0BBFF',
  '#FFD76F',
  '#F2C94C',
  '#FF9A9A',
  '#FFB178',
];

interface Funnel {
  name: string;
  botId: string;
  start: string;
  end: string;
  nodeIds: string[];
}

let graph: FunnelGraph | null = null;

const defaultDays = 7;

const defaultEmptyFunnel = {
  name: '',
  botId: '8e0ea668-4637-4c55-bbfe-88b938d67a34',
  start: dateToGerString(addDays(new Date(), -2 * defaultDays)),
  end: dateToGerString(addDays(new Date(), -defaultDays)),
  nodeIds: [],
};

export const FunnelPage: React.FC<void> = () => {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [storedFunnels, setStoredFunnels] = useState<Funnel[]>(
    StorageService.restore(StorageKey.NODE_FUNNELS, []),
  );

  const [editFunnel, setEditFunnel] = useState<Funnel>(
    storedFunnels[0] || defaultEmptyFunnel,
  );
  const [funnel, setFunnel] = useState<Funnel>(
    storedFunnels[0] || defaultEmptyFunnel,
  );
  const [nodes, setNodes] = useState<FunnelNodeCount[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const { botId, nodeIds, start, end } = funnel;
      if (nodeIds.length <= 0) {
        setNodes([]);
        return;
      }

      setLoading(true);
      const { data: funnelData } = await userApi.createFunnel(
        botId,
        nodeIds,
        start,
        end,
      );
      setNodes(funnelData);
      setLoading(false);
    };
    loadData();
  }, [funnel]);

  useEffect(() => {
    const data = {
      labels: [] as string[],
      colors: [] as string[],
      values: [] as number[],
    };

    nodes
      .filter((d) => !!d && !isNaN(d.count))
      .forEach(({ name, count }, index) => {
        data.labels.push(name);
        data.colors.push(defaultColors[index]);
        data.values.push(count);
      });

    if (nodes.length <= 0) {
      return;
    }

    if (!graph) {
      graph = new FunnelGraph({
        container: '.funnelGraph',
        gradientDirection: 'horizontal',
        data,
        displayPercent: true,
        direction: 'horizontal',
      });
      graph.draw();
    } else {
      graph.updateData(data);
    }
  }, [nodes]);

  const onKeyDown = async (e: React.KeyboardEvent) => {
    if (e.keyCode !== 13) {
      return;
    }
    setEditFunnel((s) => {
      const { nodeIds } = s;
      if (nodeIds.includes(input)) {
        return s;
      }
      return { ...s, nodeIds: [...nodeIds, input] };
    });
    setInput('');
  };

  const removeNodeId = (nid: string) =>
    setEditFunnel((s) => {
      return { ...s, nodeIds: deepCopy(s.nodeIds).filter((id) => id !== nid) };
    });

  const restoreFunnel = (f: Funnel) => {
    setEditFunnel({ ...f });
    setFunnel({ ...f });
  };

  const updateBotId = (botId: string) =>
    setEditFunnel((s) => ({ ...s, botId }));

  const updateFunnelName = (name: string) =>
    setEditFunnel((s) => ({ ...s, name }));

  const updateFunnelStart = (d: string) =>
    setEditFunnel((s) => ({ ...s, start: inputToGerString(d) }));

  const updateFunnelEnd = (d: string) =>
    setEditFunnel((s) => ({ ...s, end: inputToGerString(d) }));

  const saveFunnel = (edit: Funnel, current: Funnel, stored: Funnel[]) => {
    const idx = stored.findIndex(({ name }) => name === current.name);
    const newFunnels = deepCopy(stored);
    if (idx >= 0) {
      newFunnels[idx] = edit;
    } else {
      newFunnels.push(edit);
    }
    StorageService.store(StorageKey.NODE_FUNNELS, newFunnels);
    setStoredFunnels(newFunnels);
    setFunnel(edit);
  };

  const deleteFunnel = (e: React.MouseEvent, { name }: Funnel) => {
    e.preventDefault();
    e.stopPropagation();
    setStoredFunnels((s) => {
      const funnels = deepCopy(s).filter((f) => f.name !== name);
      StorageService.store(StorageKey.NODE_FUNNELS, funnels);
      return funnels;
    });
  };

  const createNewFunnel = () => {
    setEditFunnel(defaultEmptyFunnel);
    setFunnel(defaultEmptyFunnel);
  };

  return (
    <div className={styles.container}>
      <SideNav
        expanded={true}
        sideNav={
          <div className={styles.sideNavContent}>
            <h3>Funnels</h3>
            <Spacer />
            <div className={styles.funnelsContainer}>
              {storedFunnels.map((f) => (
                <div
                  className={all(
                    styles.funnelConfig,
                    f.name === editFunnel.name ? styles.selected : '',
                  )}
                  key={f.name}
                  onClick={() => restoreFunnel(f)}>
                  <div>{f.name}</div>
                  <Button size="small" onClick={(e) => deleteFunnel(e, f)}>
                    Löschen
                  </Button>
                </div>
              ))}
            </div>
            <Spacer />
            <Button onClick={createNewFunnel}>Neuer Funnel</Button>
          </div>
        }>
        <div className={styles.content}>
          <Spacer size="large" />
          <h2>Einstellungen</h2>
          <div className={styles.row}>
            <div>Name</div>
            <Input
              value={editFunnel.name}
              placeholder="Funnel Name setzen"
              onChange={(e) => updateFunnelName(e.target.value)}
              onKeyDown={onKeyDown}
            />
          </div>

          <div className={styles.row}>
            <div>Von</div>
            <Input
              type="date"
              placeholder="Startdatum"
              value={gerStringToInput(editFunnel.start)}
              onChange={(e) => updateFunnelStart(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <div>Bis</div>
            <Input
              type="date"
              placeholder="Enddatum"
              value={gerStringToInput(editFunnel.end)}
              onChange={(e) => updateFunnelEnd(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <div>Bot-ID</div>
            <Input
              value={editFunnel.botId}
              style={{ width: 250, fontSize: 'small' }}
              placeholder="Bot-ID"
              onChange={(e) => updateBotId(e.target.value)}
            />
          </div>

          <Spacer />
          <h3>Knoten</h3>
          <Divider />
          <div className={styles.nodeIdsContainer}>
            {editFunnel.nodeIds?.length > 0 &&
              editFunnel.nodeIds.map((id, idx) => (
                <div className={styles.nodeIdContainer} key={id}>
                  <div>{idx + 1}.</div>
                  <div>{id}</div>
                  <div>{nodeEntry(id, nodes)?.name}</div>
                  <Button size="small" onClick={() => removeNodeId(id)}>
                    Löschen
                  </Button>
                </div>
              ))}
            {editFunnel.nodeIds?.length <= 0 && (
              <div>Noch keine Knoten-IDs vorhanden</div>
            )}
          </div>
          <Input
            value={input}
            placeholder="Knoten-ID mit Enter hinzugfügen"
            disabled={!editFunnel.botId}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={onKeyDown}
          />
          <Spacer />
          <Divider />
          <Spacer size="large" />

          <Button
            onClick={() => saveFunnel(editFunnel, funnel, storedFunnels)}
            disabled={loading}
            style={{ alignSelf: 'flex-start' }}
            variant="outlined">
            Jetzt Aktualisieren
          </Button>

          <h2>Ergebnis</h2>
          {loading && <LinearProgress />}

          <div
            style={{
              width: '100%',
              visibility: nodes?.length > 0 && !loading ? 'visible' : 'hidden',
              height: 400,
              minHeight: 400,
              background: '#393862',
              paddingTop: 20,
              paddingBottom: 20,
              boxSizing: 'border-box',
            }}>
            <div
              className="funnelGraph"
              style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
              }}></div>
          </div>

          <Spacer size="xlarge" />
        </div>
      </SideNav>
    </div>
  );
};

const nodeEntry = (nid: string, nodes: FunnelNodeCount[]) =>
  nodes.find(({ id }) => id === nid);

function gerStringToInput(s: string) {
  const [day, month, year] = s.split('.');
  return [year, month, day].join('-');
}

function inputToGerString(s: string) {
  const [year, month, day] = s.split('-');
  return [day, month, year].join('.');
}

function dateToGerString(d: Date) {
  return [
    d.getDate().toString().padStart(2, '0'),
    (d.getMonth() + 1).toString().padStart(2, '0'),
    d.getFullYear(),
  ].join('.');
}

function addDays(date: Date, days: number): Date {
  const result = date;
  result.setDate(result.getDate() + days);
  return result;
}
