import {
  CircularProgress,
  LinearProgress,
  Link,
  MenuItem,
  Select,
} from '@material-ui/core';
import { current } from '@reduxjs/toolkit';
import { Input } from 'app/components/Input/Input';
import { Spacer } from 'app/components/Spacer/Spacer';
import { Table, TableCol } from 'app/components/Table/Table';
import { userActions } from 'app/home/state/userActions';
import { OrderTransfer } from 'app/home/state/userApi';
import { RootState } from 'app/store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NodeSummary, statsApi } from '../api/statsApi';
import styles from './BatchImportPage.module.scss';

export const BatchImportPage: React.FC<void> = () => {
  const [loading, setLoading] = useState(false);
  const [summaries, setSummaries] = useState<NodeSummary[]>([]);
  const [dateSelection, setDateSelection] = useState(1);

  const [dateRange, setDateRange] = useState<{ from: string; until: string }>({
    from: predefinedRanges[dateSelection].from,
    until: predefinedRanges[dateSelection].until,
  });

  useEffect(() => {
    if (dateSelection === 0) {
      return;
    }
    const { from, until } = predefinedRanges[dateSelection];
    setDateRange({ from, until });
  }, [dateSelection]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const r = await statsApi.batchImportReport(
          dateRange.from,
          dateRange.until,
        );
        setSummaries(r.summaries);
      } catch (e) {
        console.log(e);
        setSummaries([]);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [dateRange]);

  const handleRangeInput =
    (k: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      setDateRange((s) => ({ ...s, [k]: ev.target.value }));
    };

  const rangeIsDisabled = dateSelection !== 0;

  return (
    <div className={styles.container}>
      <h1>Datenübernahme</h1>

      <div className={styles.dateContainer}>
        <div className={styles.dateRangeContainer}>
          <Select
            className={styles.dateRangeSelection}
            value={dateSelection}
            autoWidth
            onChange={(e) => setDateSelection(e.target.value as number)}>
            {predefinedRanges.map((t, idx) => (
              <MenuItem key={t.label} value={idx}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
          <Spacer />
          <div>Von</div>
          <Spacer />
          <Input
            disabled={rangeIsDisabled}
            placeholder={'28.02.2023'}
            value={dateRange.from}
            onChange={handleRangeInput('from')}
          />
          <Spacer />
          <div>bis einschließlich </div>
          <Spacer />
          <Input
            disabled={rangeIsDisabled}
            placeholder={'28.02.2023'}
            value={dateRange.until}
            onChange={handleRangeInput('until')}
          />
        </div>
      </div>
      <Spacer size="xlarge" />

      {loading && <CircularProgress />}
      {!loading && !!summaries && <Table coloumns={cols} data={summaries} />}
    </div>
  );
};

const cols: TableCol<NodeSummary>[] = [
  {
    label: 'Name',
    renderFunc: ({ name, url }: NodeSummary) => (
      <Link href={url} target="_blank">
        {name}
      </Link>
    ),
    width: 500,
  },
  { label: 'Anzahl', id: 'total', width: 100 },
  {
    label: 'Akzeptiert',
    renderFunc: ({ acceptedRate }: NodeSummary) => `${acceptedRate}%`,
    width: 100,
  },
  {
    label: '',
  },
];

const today = moment().startOf('day');
const currentWeek = today.clone().isoWeekday(1);
const currentMonth = moment({
  month: today.month(),
  day: 1,
  year: today.year(),
});

function formatDate(d: moment.Moment): string {
  return d.format('DD.MM.YYYY');
}

const predefinedRanges: {
  label: string;
  from: string;
  until: string;
}[] = [
  {
    label: 'Custom',
    from: formatDate(currentMonth.clone().add({ months: -1 })),
    until: formatDate(currentMonth.clone().add({ days: -1 })),
  },
  {
    label: 'Letzte 7 Tage',
    from: formatDate(today.clone().add({ days: -6 })),
    until: formatDate(today),
  },
  {
    label: 'Diese Woche',
    from: formatDate(currentWeek),
    until: formatDate(today),
  },
  {
    label: 'Letzte Woche',
    from: formatDate(currentWeek.clone().add({ days: -7 })),
    until: formatDate(currentWeek.clone().add({ days: -1 })),
  },
  {
    label: 'Dieser Monat',
    from: formatDate(currentMonth),
    until: formatDate(currentMonth.clone().add({ month: 1, days: -1 })),
  },
  {
    label: 'Letzter Monat',
    from: formatDate(currentMonth.clone().add({ months: -1 })),
    until: formatDate(currentMonth.clone().add({ days: -1 })),
  },
];
