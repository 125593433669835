import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../common/text';
import { UserCoupon } from '../../home/state/userApi';
import { Card } from '../Card/Card';
import styles from './CouponChange.module.scss';

interface Props {
  coupon?: UserCoupon;
  deleteCoupon: (id: string) => void;
}

const emptyCoupon: UserCoupon = {
  id: '',
  userId: '',
  status: '',
  claimedAt: '',
  consumedAt: '',
  couponId: '',
  couponCode: '',
};

export const CouponChange: React.FC<Props> = ({
  coupon: base,
  deleteCoupon,
}: Props) => {
  const [coupon, setCoupon] = useState(base || emptyCoupon);

  useEffect(() => {
    setCoupon(base || emptyCoupon);
  }, [base]);

  const { id, status, couponCode, claimedAt, consumedAt } = coupon;
  return (
    <div>
      <Card size={'small'}>
        <div className={styles.container}>
          <div>ID</div>
          <div>{id}</div>
          <div>Status</div>
          <div>{status}</div>
          <div>Coupon Code</div>
          <div>{couponCode}</div>
          <div>Angenommen</div>
          <div>
            {claimedAt ? dateToString(claimedAt) : 'noch nicht eingelöst'}
          </div>
          <div>Eingelöst</div>
          <div>
            {consumedAt ? dateToString(consumedAt) : 'noch nicht eingelöst'}
          </div>
        </div>

        <Button color="secondary" onClick={() => deleteCoupon(id)}>
          Löschen
        </Button>
      </Card>
    </div>
  );
};
