import React from 'react';
import { dateToString } from '../../common/text';
import { ShopCredit } from '../../shop/state/shopApi';
import { Card } from '../Card/Card';
import { ObjectTable } from '../ObjectTable/ObjectTable';
import styles from './CreditDetails.module.scss';

interface Props {
  credit?: ShopCredit;
}

export const CreditDetails = ({ credit }: Props): any => {
  if (!credit) {
    return <div>Keine Informationen zum Guthaben</div>;
  }

  const { transactions } = credit;
  return (
    <div className={styles.container}>
      {/* <div className={styles.header}>
        <ObjectTable
          data={{ added: dateToString(added), updated: dateToString(updated) }}
          rows={[
            { id: 'added', label: 'Hinzugefügt' },
            { id: 'updated', label: 'Zuletzt geändert' },
          ]}
        />
      </div> */}
      <div className={styles.transactions}>
        {(transactions || []).map((t, idx) => (
          <Card
            key={t.timestamp}
            className={styles.creditCard}
            size="small"
            title={`${idx + 1}. ${t.type}`}>
            <ObjectTable
              data={t}
              rows={[
                { id: 'amount', label: 'Summe' },
                { id: 'botId', label: 'Bot-ID' },
                { id: 'submitLogId', label: 'Abgabe-ID' },
                { id: 'assessmentId', label: 'Bescheid-ID' },
                { id: 'orderId', label: 'Bestellung-ID' },
                {
                  id: 'timestamp',
                  label: 'Hinzugefügt',
                  renderFunc: (v) => <div>{dateToString(v['timestamp'])}</div>,
                },
              ]}
            />
          </Card>
        ))}
      </div>
    </div>
  );
};
