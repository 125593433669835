import { Button, Checkbox, Divider, MenuItem, Select } from '@material-ui/core';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../../common/text';
import { FormContainer } from '../../../components/FormContainer/FormContainer';
import { Input } from '../../../components/Input/Input';
import { Spacer } from '../../../components/Spacer/Spacer';
import {
  AudienceCondition,
  AudienceConditionType,
  AudienceDefinition,
} from '../../state/notificationApi';
import AudienceSelection from '../AudienceSelection/AudienceSelection';
import styles from './NotificationAudienceBuilder.module.scss';

export interface AudienceDefinitionBuilderProps {
  definition: AudienceDefinition;
  editMode: boolean;
  onUpdateDefinition: (c: AudienceDefinition) => void;
  onDeleteDefinition: (c: AudienceDefinition) => void;
}

const baseCondition: AudienceCondition = {
  type: AudienceConditionType.SUBMIT,
};

export const AudienceDefinitionBuilder: React.FC<AudienceDefinitionBuilderProps> =
  ({
    definition: baseDefinition,
    editMode,
    onUpdateDefinition,
    onDeleteDefinition,
  }: AudienceDefinitionBuilderProps) => {
    const [definition, setDefinition] =
      useState<AudienceDefinition>(baseDefinition);
    const [debug, setDebug] = useState<boolean>(false);

    useEffect(() => {
      setDefinition(baseDefinition);
    }, [baseDefinition, setDefinition]);

    useEffect(() => {
      const handlerFn = ({ code, ctrlKey }: KeyboardEvent) => {
        if (code === 'KeyD' && ctrlKey) {
          setDebug((s) => !s);
        }
      };

      document.addEventListener('keydown', handlerFn);
      return () => document.removeEventListener('keydown', handlerFn);
    }, []);

    const onPropertyChange = (k: string, v: any) => {
      setDefinition((d) =>
        produce(d, (draft) => {
          draft[k] = v;
        }),
      );
    };

    const onTitleChange = (title: string) => {
      onPropertyChange('title', title);
    };

    const onConditionChange = (
      i: number,
      j: number,
      condition: AudienceCondition,
    ) =>
      setDefinition(
        produce(definition, (draft) => {
          draft.conditions[i][j] = condition;
        }),
      );

    const onConditionAdd = (i: number) =>
      setDefinition(
        produce(definition, (draft) => {
          draft.conditions[i].push(baseCondition);
        }),
      );

    const onConditionAndAdd = () =>
      setDefinition(
        produce(definition, (draft) => {
          if (!draft.conditions) {
            draft.conditions = [];
          }
          draft.conditions.push([baseCondition]);
        }),
      );

    const onConditionDelete = (i: number, j: number) =>
      setDefinition(
        produce(definition, (draft) => {
          if (draft.conditions[i].length <= 1) {
            draft.conditions.splice(i, 1);
          } else {
            draft.conditions[i].splice(j, 1);
          }
        }),
      );

    const hasChanged =
      JSON.stringify(baseDefinition) !== JSON.stringify(definition);

    const { title, created, updated, conditions } = definition;

    const isValid = !!title && conditions && conditions.length > 0;

    return (
      <div className={styles.container}>
        <Spacer size="medium" />
        <h3>Basisinformationen</h3>
        <Spacer size="small" />
        <FormContainer>
          <div>Titel</div>
          <Input
            variant={'title'}
            placeholder="Titel der Zielgruppe"
            value={title}
            onChange={(e) => onTitleChange(e.target.value)}
          />
          <div>Erstellt</div>
          <div>{dateToString(created)}</div>
          <div>Geändert</div>
          <div>{dateToString(updated)}</div>
        </FormContainer>
        <Spacer size="large" />

        <h3>Konfiguration</h3>
        <Spacer size="medium" />
        <div>
          {conditions?.map((andCond, idx) => (
            <div key={'and' + idx}>
              <div className={styles.definitionAndContainer}>
                {andCond.map((condition, jdx) => (
                  <div key={jdx}>
                    <div className={styles.conditionContainer}>
                      <ConditionBuilder
                        condition={condition}
                        onChange={(c) => onConditionChange(idx, jdx, c)}
                      />
                      <div
                        className={styles.deleteCondition}
                        onClick={() => onConditionDelete(idx, jdx)}>
                        X
                      </div>
                    </div>
                    {jdx < andCond.length - 1 && (
                      <div className={styles.definitionOrSpacer}>Oder</div>
                    )}
                  </div>
                ))}

                <button
                  className={styles.definitionAddOr}
                  onClick={() => onConditionAdd(idx)}>
                  + Eine neue <b>oder</b> Bedingung hinzufügen
                </button>
              </div>

              {idx < conditions.length - 1 && (
                <div className={styles.definitionAndSpacer}>Und</div>
              )}
            </div>
          ))}
          <div className={styles.definitionAddAnd}>
            <button onClick={() => onConditionAndAdd()}>
              + Eine neue Bedingung hinzufügen
            </button>
          </div>

          <Spacer size="large" />

          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              disabled={!hasChanged || !isValid}
              onClick={() => onUpdateDefinition(definition)}
              style={{ marginRight: 8 }}>
              {editMode ? 'Speichern' : 'Erstellen'}
            </Button>

            <Button
              variant="outlined"
              onClick={() => onDeleteDefinition(definition)}
              style={{ marginRight: 8 }}>
              {editMode ? 'Löschen' : 'Abbrechen'}
            </Button>
          </div>
        </div>

        {!!debug && (
          <>
            <Spacer size="large" />
            <Divider />
            <Spacer size="large" />
            <pre className={styles.debugView}>
              {JSON.stringify(conditions, null, 2)}
            </pre>
          </>
        )}
      </div>
    );
  };

export interface AudienceBuilderProps {
  condition: AudienceCondition;
  onChange: (c: AudienceCondition) => void;
}

export const ConditionBuilder: React.FC<AudienceBuilderProps> = ({
  condition,
  onChange,
}: AudienceBuilderProps) => {
  const conditionTypes = [
    AudienceConditionType.PROFILE_ADDED,
    AudienceConditionType.SUBMIT,
    AudienceConditionType.ASSESSMENT,
    AudienceConditionType.VALIDATE,
    AudienceConditionType.PROFILE_STATE,
    AudienceConditionType.PLATFORM,
    AudienceConditionType.VERSION,
    AudienceConditionType.VAST_PERMISSION,
    AudienceConditionType.VAST_DOCUMENTS,
    AudienceConditionType.LAST_ACTIVE,
    AudienceConditionType.TAX_MANDATORY,
    AudienceConditionType.PARTNER,
    AudienceConditionType.PROGRESS,
    AudienceConditionType.LANGUAGE,
    AudienceConditionType.PAY_LATER,
    AudienceConditionType.COHORT,
    AudienceConditionType.AUDIENCE,
  ];

  const onValueChange = (k: string, v: any) => {
    onChange({ ...condition, [k]: v });
  };

  const onTypeChange = (type: AudienceConditionType) => {
    if (condition.type === type) {
      return;
    }
    onChange({ type });
  };

  const {
    type,
    from,
    until,
    platform,
    version,
    profileState,
    submitYear,
    exclude,
    vastStatus,
    refundAbove,
    refundBelow,
    differenceAbove,
    differenceBelow,
    taxMandatoryReason,
    partner,
    progress,
    language,
    cohortId,
    audienceId,
  } = condition;

  const showDate = [
    AudienceConditionType.REGISTRATION,
    AudienceConditionType.PROFILE_ADDED,
    AudienceConditionType.SUBMIT,
    AudienceConditionType.ASSESSMENT,
    AudienceConditionType.VALIDATE,
    AudienceConditionType.LAST_ACTIVE,
  ].includes(type);

  const showSubmitYear = [
    AudienceConditionType.SUBMIT,
    AudienceConditionType.ASSESSMENT,
    AudienceConditionType.VALIDATE,
    AudienceConditionType.VAST_DOCUMENTS,
    AudienceConditionType.TAX_MANDATORY,
    AudienceConditionType.PROGRESS,
    AudienceConditionType.COHORT,
  ].includes(type);

  const showRefund = [
    AudienceConditionType.SUBMIT,
    AudienceConditionType.VALIDATE,
  ].includes(type);

  const showDifference = [AudienceConditionType.ASSESSMENT].includes(type);

  return (
    <div className={styles.conditionBuilderContainer}>
      <Select
        value={type}
        autoWidth
        onChange={(e) => onTypeChange(e.target.value as AudienceConditionType)}>
        {conditionTypes.map((t) => (
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>

      {showDate && (
        <>
          <div>von</div>
          <Input
            value={from || ''}
            placeholder="dd.mm.yyyy oder today() - Tage"
            onChange={(e) => onValueChange('from', e.target.value)}
          />
          <div>bis</div>
          <Input
            value={until || ''}
            placeholder="dd.mm.yyyy oder today() - Tage"
            onChange={(e) => onValueChange('until', e.target.value)}
          />
        </>
      )}

      {showSubmitYear && (
        <Input
          type="number"
          value={submitYear ?? ''}
          placeholder="Steuerjahr"
          onChange={(e) =>
            onValueChange('submitYear', parseInt(e.target.value))
          }
        />
      )}

      {showRefund && (
        <>
          <Input
            type="number"
            value={refundAbove ?? ''}
            placeholder="Erstattung >="
            onChange={(e) =>
              onValueChange('refundAbove', parseInt(e.target.value))
            }
          />
          <Input
            type="number"
            value={refundBelow ?? ''}
            placeholder="Erstattung <="
            onChange={(e) =>
              onValueChange('refundBelow', parseInt(e.target.value))
            }
          />
        </>
      )}

      {showDifference && (
        <>
          <Input
            type="number"
            value={differenceAbove ?? ''}
            placeholder="Abweichung >="
            onChange={(e) =>
              onValueChange('differenceAbove', parseInt(e.target.value))
            }
          />
          <Input
            type="number"
            value={differenceBelow ?? ''}
            placeholder="Abweichung <="
            onChange={(e) =>
              onValueChange('differenceBelow', parseInt(e.target.value))
            }
          />
        </>
      )}

      {type === AudienceConditionType.PROFILE_STATE && (
        <Input
          value={profileState ?? ''}
          onChange={(e) => onValueChange('profileState', e.target.value)}
        />
      )}

      {type === AudienceConditionType.VAST_PERMISSION && (
        <Input
          value={vastStatus ?? ''}
          onChange={(e) => onValueChange('vastStatus', e.target.value)}
        />
      )}

      {type === AudienceConditionType.PLATFORM && (
        <Input
          value={platform ?? ''}
          onChange={(e) => onValueChange('platform', e.target.value)}
        />
      )}

      {type === AudienceConditionType.VERSION && (
        <Input
          value={version ?? ''}
          onChange={(e) => onValueChange('version', e.target.value)}
        />
      )}

      {type === AudienceConditionType.TAX_MANDATORY && (
        <Input
          value={taxMandatoryReason ?? ''}
          placeholder="shorttime|pension|any"
          onChange={(e) => onValueChange('taxMandatoryReason', e.target.value)}
        />
      )}

      {type === AudienceConditionType.PARTNER && (
        <Input
          value={partner ?? ''}
          onChange={(e) => onValueChange('partner', e.target.value)}
        />
      )}

      {type === AudienceConditionType.PROGRESS && (
        <Input
          value={progress ?? ''}
          onChange={(e) => onValueChange('progress', e.target.value)}
        />
      )}

      {type === AudienceConditionType.LANGUAGE && (
        <Input
          value={language ?? ''}
          onChange={(e) => onValueChange('language', e.target.value)}
        />
      )}

      {type === AudienceConditionType.COHORT && (
        <Input
          value={cohortId ?? ''}
          onChange={(e) => onValueChange('cohortId', e.target.value)}
        />
      )}

      {type === AudienceConditionType.AUDIENCE && (
        <AudienceSelection
          value={audienceId || ''}
          onChange={(e) => onValueChange('audienceId', e.target.value)}
        />
      )}

      <>
        <div>Auschließen</div>
        <Checkbox
          size={'small'}
          checked={!!exclude}
          onChange={(e) => onValueChange('exclude', !!e.target.checked)}
        />
      </>
    </div>
  );
};
