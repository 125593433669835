import React from 'react';
import { Table, TableCol } from '../Table/Table';
import { ShopPayment } from '../../shop/state/shopApi';
import { dateToString } from '../../common/text';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';

const gcUrl = 'https://manage.gocardless.com';

export const PaymentsTable = ({ payments }: { payments: ShopPayment[] }) => {
  const cols: TableCol[] = [
    {
      label: 'ID',
      renderFunc: ({ _id }: ShopPayment) => (
        <ShortTextCopy text={_id} fontSize={14} length={8} />
      ),
    },
    { id: 'status', label: 'Status' },
    { id: 'amount', label: 'Wert' },
    { id: 'reference', label: 'Referenz' },
    {
      label: 'Erstellt',
      renderFunc: ({ paymentCreatedAt }) => dateToString(paymentCreatedAt),
    },
    {
      label: 'Einzugszeitpunkt',
      renderFunc: ({ chargeDate }) => dateToString(chargeDate),
    },
    {
      label: 'GC-ID',
      renderFunc: ({ goCardlessPaymentId }: ShopPayment) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={gcUrl + '/payments/' + goCardlessPaymentId}>
          {goCardlessPaymentId}
        </a>
      ),
    },
    {
      label: 'GC-Mandat',
      renderFunc: ({ mandateId }: ShopPayment) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={gcUrl + '/mandates/' + mandateId}>
          {mandateId}
        </a>
      ),
    },
  ];

  return (
    <Table
      coloumns={cols}
      data={payments}
      emptyText="Keine Bezahlungen angelegt"
    />
  );
};
