import { Button } from '@material-ui/core';
import { Email, NotificationsActive, SendOutlined } from '@material-ui/icons';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../../common/text';
import { Divider } from '../../../components/Divider/Divider';
import { ExpansionPanel } from '../../../components/ExpansionPanel/ExpansionPanel';
import { FormContainer } from '../../../components/FormContainer/FormContainer';
import { Input } from '../../../components/Input/Input';
import { InputUserIds } from '../../../components/InputUserIds/InputUserIds';
import { Spacer } from '../../../components/Spacer/Spacer';
import {
  AudienceDefinition,
  NotificationMessage,
  PushChannel,
  PushNotification,
} from '../../state/notificationApi';
import styles from './NotificationMessageBuilder.module.scss';

export interface NotificationBuilderProps {
  notification: NotificationMessage;
  onUpdateNotification: (n: NotificationMessage) => void;
  onDeleteNotification: (id: string) => void;
  createMode?: boolean;
  audiences?: AudienceDefinition[];
  hasJobs?: boolean;
  onSendMessage: (userIds: string[]) => void;
}

export const NotificationMessageBuilder: React.FC<NotificationBuilderProps> = ({
  notification: baseNotification,
  onUpdateNotification,
  onDeleteNotification,
  createMode,
  hasJobs,
  onSendMessage,
}: NotificationBuilderProps) => {
  const [notification, setNotification] =
    useState<NotificationMessage>(baseNotification);

  const [userIds, setUserIds] = useState<string[]>([]);

  useEffect(() => {
    setNotification(baseNotification);
  }, [baseNotification]);

  const onPropertyChange = (k: string, v: any) => {
    setNotification((n) =>
      produce(n, (draft) => {
        draft[k] = v;
      }),
    );
  };

  const onPushPropertyChange = (k: string, v: any) => {
    setNotification((n) =>
      produce(n, (draft) => {
        if (!draft.push) {
          draft.push = { channel: PushChannel.HIGH };
        }
        draft.push[k] = v;
      }),
    );
  };

  const { id, title, created, updated, push: basePush } = notification;
  const push = basePush ?? { channel: PushChannel.HIGH };

  const onSaveClick = () => {
    onUpdateNotification(notification);
  };

  const hasChanged =
    JSON.stringify(baseNotification) !== JSON.stringify(notification);

  return (
    <div className={styles.container}>
      <Spacer size="medium" />

      <h3>Basisdaten</h3>
      <Spacer size="small" />
      <FormContainer>
        <div>Titel</div>
        <Input
          placeholder="Kurzbeschreibung des Jobs"
          value={title ?? ''}
          onChange={(e) => onPropertyChange('title', e.target.value)}
        />
        <div>Erstellt</div>
        <div>{dateToString(created)}</div>
        <div>Geändert</div>
        <div>{dateToString(updated)}</div>
      </FormContainer>
      <Spacer size="xlarge" />

      <h3>Inhalt</h3>
      <Spacer size="small" />

      <ExpansionPanel
        header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NotificationsActive style={{ marginRight: 8 }} />
            <div style={{ marginRight: 8 }}>Push-Benachrichtigung</div>
            <div style={{ color: push?.enabled ? 'green' : 'red' }}>
              {push?.enabled ? '(aktiviert)' : '(deaktiviert)'}
            </div>
          </div>
        }
        expanded
        content={
          <PushNotificationBuilder
            push={push}
            onPropertyChange={onPushPropertyChange}
          />
        }
      />

      <Spacer size="small" />
      <ExpansionPanel
        header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Email style={{ marginRight: 8 }} />
            <div style={{ marginRight: 8 }}>E-Mail-Benachrichtigung</div>
            <div>(kommt bald)</div>
          </div>
        }
        content={<div>TODO</div>}
      />

      <Spacer size="medium" />
      <Divider />
      <Spacer size="medium" />

      <div className={styles.buttonContainer}>
        <Button
          variant={'outlined'}
          disabled={!hasChanged}
          onClick={onSaveClick}>
          {createMode ? 'Erstellen' : 'Speichern'}
        </Button>
        <Spacer expander />
        {!hasJobs && (
          <Button
            variant={'outlined'}
            style={{ color: 'red' }}
            onClick={() => onDeleteNotification(id)}>
            {createMode ? 'Abbrechen' : 'Löschen'}
          </Button>
        )}
        {hasJobs && (
          <Button
            variant={'outlined'}
            style={{ color: 'red' }}
            onClick={() => onDeleteNotification(id)}>
            Archivieren
          </Button>
        )}
      </div>

      <Spacer size="medium" />
      <Divider />
      <Spacer size="medium" />

      <ExpansionPanel
        header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SendOutlined style={{ marginRight: 8 }} />
            <div style={{ marginRight: 8 }}>Testnachricht</div>
          </div>
        }>
        <FormContainer>
          <div>Empfänger</div>
          <InputUserIds
            userIds={userIds}
            onChange={(uids) => setUserIds(uids)}
          />
          <div></div>
          <Button
            variant={'outlined'}
            style={{ flex: 'none', maxWidth: 200 }}
            disabled={userIds.length <= 0}
            onClick={() => onSendMessage(userIds)}>
            Senden
          </Button>
        </FormContainer>
      </ExpansionPanel>

      <Spacer size="large" />
    </div>
  );
};

export interface PushNotificationBuilderProps {
  push: PushNotification;
  onPropertyChange: (k: string, v: any) => void;
}
export const PushNotificationBuilder = ({
  push,
  onPropertyChange,
}: PushNotificationBuilderProps) => {
  const { enabled, title, body, channel, data } = push;

  return (
    <>
      <h3>Push Nachricht</h3>
      <Spacer size="small" />
      <FormContainer>
        <div>Titel</div>
        <Input
          placeholder="Titel"
          value={title || ''}
          onChange={(e) => onPropertyChange('title', e.target.value)}
        />

        <div>Body</div>
        <textarea
          placeholder="Body"
          value={body ?? ''}
          onChange={(e) => onPropertyChange('body', e.target.value)}
        />

        <div>Aktion</div>
        <Input
          placeholder="vast | referral | taxYear | openBrowser"
          value={data?.action || ''}
          onChange={(e) =>
            onPropertyChange(
              'data',
              e.target.value ? { action: e.target.value } : {},
            )
          }
        />

        {data?.action === 'openBrowser' && (
          <>
            <div>URL</div>
            <Input
              placeholder="URL (optional)"
              value={data?.url || ''}
              onChange={(e) =>
                onPropertyChange('data', { ...data, url: e.target.value })
              }
            />
          </>
        )}

        {data?.action === 'taxYear' && (
          <>
            <div>Jahr</div>
            <Input
              placeholder="Wechsel in Jahr (optional)"
              value={data?.year || ''}
              onChange={(e) =>
                onPropertyChange('data', { ...data, year: e.target.value })
              }
            />
          </>
        )}

        <div>Channel</div>
        <select
          value={channel}
          onChange={(e) => onPropertyChange('channel', e.target.value)}>
          <option value={PushChannel.HIGH}>{PushChannel.HIGH}</option>
          <option value={PushChannel.MEDIUM}>{PushChannel.MEDIUM}</option>
          <option value={PushChannel.LOW}>{PushChannel.LOW}</option>
        </select>

        <div>Aktiviert</div>
        <input
          type="checkbox"
          checked={!!enabled}
          onChange={(e) => onPropertyChange('enabled', e.target.checked)}
        />
      </FormContainer>
    </>
  );
};
