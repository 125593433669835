import { Api } from 'index';

export const taskApi = {
  getAllBlockedTasks: (): Promise<{ tasks: any[] }> =>
    Api.fetch({
      method: 'GET',
      url: `tasks`,
    }),
  getTask: (id: string): Promise<{ task: any }> =>
    Api.fetch({
      method: 'GET',
      url: `tasks/${id}`,
    }),
  handleTask: (
    id: string,
    action: TaskAction,
    errorHint?: string,
  ): Promise<{ task: any }> =>
    Api.fetch({
      method: 'PATCH',
      url: `tasks/${id}`,
      data: { action, errorHint },
    }),
};

export enum TaskAction {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}
