import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EricCheckResponse, InfoApi } from '../../state/infoApi';
import { useSelector } from 'react-redux';
import { selectActiveId } from '../../../home/state/userReducer';
import { CircularProgress } from '@material-ui/core';
import styles from './ResultsPage.module.scss';
import { EricResult } from '../../../components/EricResult/EricResult';

export const ResultsPage = () => {
  const { bid } = useParams<{ bid: string }>();
  const uid = useSelector(selectActiveId);

  const [results, setResults] = useState<EricCheckResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitId, setSubmitId] = useState('');

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      const { data } = await InfoApi.ericResults(uid, bid || '');
      setResults(data || []);
      setLoading(false);
    };
    f();
  }, [bid, uid]);

  const handleResultClick = (id: string) => () => setSubmitId(id);

  const selected = results.find(({ _id }) => _id === submitId);

  return (
    <div className={styles.container}>
      <h1>Ergebnisse</h1>
      {loading && <CircularProgress size={30} />}
      <div className={styles.resultsContainer}>
        {results.map((r, idx) => (
          <div
            key={idx}
            className={styles.result}
            onClick={handleResultClick(r._id)}>
            {idx + 1}
          </div>
        ))}
      </div>

      {selected && (
        <>
          <h3>{submitId}</h3>
          <EricResult eric={selected} />
        </>
      )}
    </div>
  );
};
