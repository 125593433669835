import { all } from 'app/common/style';
import { memo } from 'react';
import styles from './Spacer.module.scss';

interface Props {
  size?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxxlarge';
  expander?: boolean;
}

export const Spacer = memo(({ size = 'small', expander }: Props) => {
  return (
    <div className={all(styles[size], !!expander ? styles.expander : '')}></div>
  );
});
