import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import React, { useEffect, useState } from 'react';
import { StorageKey, StorageService } from '../../common/storage.service';
import { all } from '../../common/style';
import styles from './SideNav.module.scss';

interface Props {
  children?: React.ReactNode;
  sideNav?: React.ReactNode;
  persist?: StorageKey;
  expanded?: boolean;
}

export const SideNav = ({ children, sideNav, persist, expanded }: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(
    persist ? StorageService.restore(persist, expanded) : expanded,
  );

  useEffect(() => {
    const handlerFn = ({ code, ctrlKey }) => {
      if (code === 'KeyN' && ctrlKey) {
        setExpanded((s) => !s);
      }
    };

    document.addEventListener('keydown', handlerFn);
    return () => window.removeEventListener('keydown', handlerFn);
  }, []);

  useEffect(() => {
    if (persist) {
      StorageService.store(persist, isExpanded);
    }
  }, [isExpanded, persist]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.content}>{children}</div>
      <div className={all(styles.container, isExpanded ? styles.expanded : '')}>
        {isExpanded && <div className={styles.sideNavContent}>{sideNav}</div>}
        {!isExpanded && (
          <div
            className={all(styles.sideNavContent, styles.collapsed)}
            onClick={() => setExpanded(!isExpanded)}></div>
        )}

        <div className={styles.footer} onClick={() => setExpanded(!isExpanded)}>
          <div
            className={all(
              styles.icon,
              isExpanded ? '' : styles.iconCollapsed,
            )}>
            <DoubleArrowIcon />
          </div>

          {isExpanded && <div>Einklappen</div>}
        </div>
      </div>
    </div>
  );
};
