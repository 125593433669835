import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../common/text';
import { VastPermission } from '../../home/state/userApi';
import { Card } from '../Card/Card';
import { Input } from '../Input/Input';
import styles from './VastPermissionChange.module.scss';

interface Props {
  permission?: VastPermission;
  updateStatus: (id: string, status: string) => void;
}

const emptyPermission: VastPermission = {
  idInternal: '',
  id: '',
  date: '',
  validUntil: '',
  status: '',
  approveUntil: '',
  type: '',
  process: '',
  userId: '',
  idNr: '',
  context: '',
  certificateMapping: '',
};

export const VastPermissionChange: React.FC<Props> = ({
  permission: base,
  updateStatus,
}: Props) => {
  const [task, setTask] = useState(base || emptyPermission);

  useEffect(() => {
    setTask(base || emptyPermission);
    console.log('updated base');
  }, [base]);

  const handleValueUpdate =
    (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setTask({ ...task, [prop]: e.target.value });
    };

  const changed = JSON.stringify(base) !== JSON.stringify(task);

  const {
    idInternal,
    id,
    status,
    date,
    validUntil,
    approveUntil,
    type,
    process,
    idNr,
    context,
    certificateMapping,
  } = task;
  return (
    <div>
      <Card size={'small'}>
        <div className={styles.container}>
          <div>ID (intern)</div>
          <div>{idInternal}</div>
          <div>ID</div>
          <div>{id}</div>
          <div>Status</div>
          <Input value={status} onChange={handleValueUpdate('status')} />
          <div>Datum</div>
          <div>{dateToString(date)}</div>
          <div>Gültig bis</div>
          <div>{dateToString(validUntil)}</div>
          <div>Bestätigen bis</div>
          <div>{dateToString(approveUntil)}</div>
          <div>Typ</div>
          <div>{type}</div>
          <div>Prozess</div>
          <div>{process}</div>
          <div>Steuer-ID</div>
          <div>{idNr}</div>
          <div>Context</div>
          <div>{context}</div>
          <div>Zertifikat</div>
          <div>{certificateMapping}</div>

          <Button
            color="primary"
            disabled={!changed}
            onClick={() => updateStatus(idInternal, status)}>
            Speichern
          </Button>
        </div>
      </Card>
    </div>
  );
};
