import React from 'react';
import { BankAccount } from '../../shop/state/shopApi';
import styles from './CompactBankAccount.module.scss';
import { all } from '../../common/style';

export interface CompactBankAccountProps {
  account: BankAccount;
  active?: boolean;
  onSelect?: () => void;
}

export const CompactBankAccount: React.FC<CompactBankAccountProps> = ({
  account,
  active,
  onSelect,
}: CompactBankAccountProps) => (
  <div
    className={all(styles.container, active ? styles.active : '')}
    onClick={onSelect}>
    <div className={styles.holder}>{account.accountHolder}</div>
    <div className={styles.iban}>{account.iban}</div>
    <div className={styles.bankName}>{account.bankName}</div>
  </div>
);
