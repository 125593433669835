import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../common/text';
import { Invite } from '../../home/state/userApi';
import { Card } from '../Card/Card';
import { Input } from '../Input/Input';
import styles from './InviteChange.module.scss';

interface Props {
  invited?: boolean;
  invite?: Invite;
  deleteInvite: (id: string) => void;
  updateInvite: (i: Invite) => void;
  addInvite: (i: Invite) => void;
}

const emptyInvite: Invite = {
  id: '',
  userId: '',
  senderId: '',
  senderName: '',
  status: '',
  acceptDate: '',
  redeemDate: '',
  botId: '',
  payoutDate: '',
};

export const InviteChange: React.FC<Props> = ({
  invite: base,
  invited,
  deleteInvite,
  updateInvite,
  addInvite,
}: Props) => {
  const [invite, setInvite] = useState(base || emptyInvite);
  const createMode = !base;

  useEffect(() => {
    setInvite(base || emptyInvite);
    console.log('updated base');
  }, [base]);

  const handleValueUpdate =
    (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setInvite({ ...invite, [prop]: e.target.value });
    };

  const changed = JSON.stringify(base) !== JSON.stringify(invite);

  const {
    id,
    status,
    senderId,
    senderName,
    userId,
    botId,
    acceptDate,
    redeemDate,
  } = invite;
  return (
    <div>
      <Card size={'small'}>
        <div className={styles.container}>
          {!createMode && (
            <>
              <div>ID</div>
              <div>{id}</div>
            </>
          )}
          <div>Status</div>
          <Input value={status} onChange={handleValueUpdate('status')} />
          {!createMode && (
            <>
              <div>Sender-ID</div>
              {!invited ? (
                <Input
                  value={senderId}
                  onChange={handleValueUpdate('senderId')}
                />
              ) : (
                <div>{senderId}</div>
              )}
              <div>Sender-Name</div>
              <Input
                value={senderName}
                onChange={handleValueUpdate('senderName')}
              />
            </>
          )}

          <div>User-ID</div>
          {invited || !createMode ? (
            <div>{userId}</div>
          ) : (
            <Input value={userId} onChange={handleValueUpdate('userId')} />
          )}

          <div>Bot-ID</div>
          <Input value={botId} onChange={handleValueUpdate('botId')} />
          {!createMode && (
            <>
              <div>Akzeptiert</div>
              <div>{dateToString(acceptDate)}</div>
              <div>Eingelöst</div>
              <div>{dateToString(redeemDate)}</div>
            </>
          )}
        </div>

        {!!createMode && (
          <Button
            color="primary"
            disabled={!changed}
            onClick={() => addInvite(invite)}>
            Hinzufügen
          </Button>
        )}

        {!createMode && (
          <>
            <Button
              color="primary"
              disabled={!changed}
              onClick={() => updateInvite(invite)}>
              Speichern
            </Button>
            <Button color="secondary" onClick={() => deleteInvite(invite.id)}>
              Löschen
            </Button>
          </>
        )}
      </Card>
    </div>
  );
};
