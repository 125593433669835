import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { all } from '../../common/style';
import { dateToString, euro } from '../../common/text';
import { BotStatus, DeliveryState } from '../../home/state/userApi';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { Spacer } from '../Spacer/Spacer';
import styles from './BotStatusSelection.module.scss';

interface Props {
  status: BotStatus;
  selected?: boolean;
  handleBotClick: () => void;
  handleBotReset?: React.EventHandler<any>;
  handleBotPay?: React.EventHandler<any>;
}

export const BotStatusSelection: React.FC<Props> = ({
  status: botStatus,
  selected,
  handleBotClick,
  handleBotReset,
}: Props) => {
  const {
    id,
    year,
    refund,
    submitDate,
    submitCount,
    faxCount,
    assessmentAvailable,
    paymentStatus,
    vastDocuments,
    purchaseInformation: pi,
  } = botStatus;
  const label = deliveryLabel(botStatus);
  const isTaxDeclaration = !!year && year !== 'Intro';
  const purchaseTitle =
    'Bezahlung: ' +
    paymentStatus +
    (pi && pi.identifier ? ` – ${pi.identifier}` : '');

  return (
    <div
      className={all(styles.botContainer, selected ? styles.selected : '')}
      key={id}
      onClick={handleBotClick}>
      {!!year && (
        <div className={all(styles.botTitle, selected ? styles.selected : '')}>
          {year}
        </div>
      )}
      <div className={all(styles.botStatus, selected ? styles.selected : '')}>
        {label}
      </div>
      <ShortTextCopy text={id} fontSize={11} length={24} />
      <Spacer />

      {isTaxDeclaration && (
        <>
          <div>Rückerstattung: {euro(refund) + ' €'}</div>
          {submitDate && <div>Abgabe: {dateToString(submitDate)}</div>}
          <SimpleLink
            to={`/information/${id}/submits`}
            title={`Abgaben: ${submitCount || 'keine'}`}
          />

          <SimpleLink
            to={{
              pathname: `/information/${id}/purchase`,
              state: { paymentStatus },
            }}
            title={purchaseTitle}
          />

          <SimpleLink
            to={`/information/${id}/faxes`}
            title={`Faxe: ${faxCount || 'keine vorhanden'}`}
          />
          <SimpleLink
            to={'hallo'}
            title={`Bescheid: ${
              assessmentAvailable ? 'vorhanden' : 'nicht vorhanden'
            }`}
          />

          {vastDocuments && (
            <SimpleLink
              to={{
                pathname: `/bots/${id}/vast-documents`,
                state: { documents: vastDocuments },
              }}
              title={`VaSt-Dokumente: ${vastDocuments.length}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export const deliveryLabel = ({
  deliveryState,
  authenticatedSendDate,
  faxSendDate,
  postalSendDate,
  hasElster2Submit,
  hasPostalSubmit,
  hasFaxSubmit,
  status,
}: BotStatus): string => {
  if (authenticatedSendDate) {
    return '⚡️ Elster2';
  }
  if (faxSendDate) {
    return '📠 Fax-Versand';
  }
  if (postalSendDate) {
    return '📮 Post-Versand';
  }

  if (hasElster2Submit) {
    return '⚡️ Elster2 – in Bearbeitung';
  }
  if (hasFaxSubmit) {
    return '📠 Fax-Versand – in Bearbeitung';
  }
  if (hasPostalSubmit) {
    return '📮 Post-Versand – in Bearbeitung';
  }

  if (status === 'open') {
    return '📝 Noch nicht gestartet';
  }
  if (status === 'editing') {
    return '📝 In Bearbeitung';
  }
  if (deliveryState === DeliveryState.START) {
    return '📝 Noch nicht versandt';
  }
  if (deliveryState === DeliveryState.PENDING) {
    return '📝 Noch nicht versandt';
  }
  if (deliveryState === DeliveryState.SENDING) {
    return '📠 Im Fax-Versand';
  }
  if (deliveryState === DeliveryState.ERROR) {
    return '⚠️ Fehler bei Fax-Versand';
  }
  return '? Unbekannt';
};

const SimpleLink = ({ title, to }: LinkProps) => (
  <div>
    <Link
      style={{ color: 'black', textDecoration: 'underline' }}
      to={to}
      onClick={(e) => e.stopPropagation()}>
      {title}
    </Link>
  </div>
);
