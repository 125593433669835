import moment from 'moment';
import { datePatternMinutes } from '../../common/date';
import {
  NotificationJob,
  NotificationJobStatus,
} from '../state/notificationApi';

export const nextNotification = ({
  status,
  history,
  hours,
  startDate,
}: NotificationJob) => {
  if (!startDate) {
    return 'Kein Startzeitpunkt';
  }

  const start = moment(startDate);

  if (
    status === NotificationJobStatus.CREATED ||
    status === NotificationJobStatus.DRAFT
  ) {
    const next = computeNextNotification(start, hours.from, hours.to);
    return next.format(datePatternMinutes);
  }

  if (status !== NotificationJobStatus.REPEAT) {
    return '–';
  }

  if (!history || history.length <= 0) {
    return '–';
  }

  const now = moment();
  const nextStart = now.set({
    hour: start.hour(),
    minute: start.minute(),
    second: start.second(),
  });
  if (now.isBefore(nextStart)) {
    return nextStart.format(datePatternMinutes);
  }
  return nextStart.add(1, 'day').format(datePatternMinutes);
};

export const computeNextNotification = (
  start: moment.Moment,
  from: number,
  to: number,
): moment.Moment => {
  const startDatum = moment({
    y: start.year(),
    M: start.month(),
    d: start.date(),
  });
  const fromHours = moment(startDatum).set('h', from);
  const toHours = moment(startDatum).set('h', to);

  if (start.isAfter(toHours)) {
    return fromHours.add(1, 'd');
  }

  if (start.isBefore(fromHours)) {
    return fromHours;
  }

  return start;
};
