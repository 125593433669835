import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from '../../components/Card/Card';
import { CompactBankAccount } from '../../components/CompactBankAccount/CompactBankAccount';
import { CouponChange } from '../../components/CouponChange/CouponChange';
import { DeviceDetail } from '../../components/DeviceDetail/DeviceDetail';
import { InfoPicker } from '../../components/InfoPicker/InfoPicker';
import { Input } from '../../components/Input/Input';
import { InviteChange } from '../../components/InviteChange/InviteChange';
import { InvitePayoutChange } from '../../components/InvitePayout/InvitePayoutChange';
import { ProfileDataTable } from '../../components/ProfileDataTable/ProfileDataTable';
import { VastPermissionChange } from '../../components/VastPermissionChange/VastPermissionChange';
import { VastTaskChange } from '../../components/VastTaskChange/VastTaskChange';
import { selectSettingsUsername } from '../../settings/state/settingsReducer';
import { BankInformation, ShopApi } from '../../shop/state/shopApi';
import { Invite, InvitePayout, SupportCoupon, userApi } from '../state/userApi';
import {
  fetchDevices,
  searchUser,
  selectDeletions,
  selectDevices,
  selectProfile,
  setActiveUserId,
} from '../state/userReducer';
import styles from './ProfilePage.module.scss';

export const ProfilePage: React.FC<any> = () => {
  const profile = useSelector(selectProfile);
  const devices = useSelector(selectDevices);
  const deletions = useSelector(selectDeletions);
  const monitorUser = useSelector(selectSettingsUsername);
  const { uid } = useParams<{ uid: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [bankInformation, setBankInformation] =
    useState<BankInformation | null>(null);
  const [comment, setComment] = useState('');
  const [activationLink, setActivationLink] = useState('');
  const [unblockBotId, setUnblockBotId] = useState('');
  const [unblockBotError, setUnblockBotError] = useState('');
  const [userDeleteConfirmation, setUserDeleteConfirmation] = useState('');
  const [deleteUserError, setDeleteUserError] = useState('');
  const [disableCodeError, setDisableCodeError] = useState('');

  const [bankHolder, setBankHolder] = useState('');
  const [iban, setIban] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankError, setBankError] = useState('');

  const [invitePayouts, setInvitePayouts] = useState<InvitePayout[]>([]);
  const [availableCoupons, setAvailableCoupons] = useState<SupportCoupon[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<string>('');

  const fetchBankAccount = useCallback(async () => {
    if (profile && profile.id) {
      const { bankInformation } = await ShopApi.fetchBankInformation(
        profile.id,
      );
      setBankInformation(bankInformation);
    }
  }, [profile]);

  const fetchInvitePayouts = useCallback(async () => {
    if (profile && profile.id) {
      const { payouts } = await userApi.getUserInvitePayouts(profile.id);
      setInvitePayouts(payouts);
    }
  }, [profile]);

  const fetchAvailableCoupons = useCallback(async () => {
    if (profile && profile.id) {
      const { coupons } = await userApi.getSupportCoupons(profile.id);
      if (coupons && coupons.length > 0) {
        setAvailableCoupons(coupons);
        setSelectedCoupon(coupons[0].id);
      } else {
        setAvailableCoupons([]);
        setSelectedCoupon('');
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!!uid) dispatch(setActiveUserId(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    if (!uid) return;
    const loadData = async () => {
      fetchBankAccount();
      fetchInvitePayouts();
      fetchAvailableCoupons();
      dispatch(fetchDevices());
    };
    loadData();
  }, [
    fetchBankAccount,
    fetchInvitePayouts,
    fetchAvailableCoupons,
    dispatch,
    uid,
  ]);

  useEffect(() => {
    const { hash } = history.location;
    if (!hash) {
      return;
    }
    setTimeout(() => {
      const id = hash.replace('#', '');
      document.getElementById(id)?.scrollIntoView();
    }, 0);
  }, [history, invitePayouts, devices, profile]);

  if (!!deletions) {
    return (
      <div>
        <h1>Nutzer gelöscht</h1>
        <pre>{JSON.stringify(deletions, null, 2)}</pre>
      </div>
    );
  }

  if (!profile) {
    return <h1>Kein Profil gefunden</h1>;
  }

  const handleBotSelected = (id: string) =>
    history.push(`/information?bid=${id}`);

  const handleLinkCreate = async () => {
    const { url } = await userApi.linkUser(profile.id);
    setActivationLink(url);
    setComment('');
  };

  const handleUnblockBot = async () => {
    try {
      await userApi.unlockUserBot(profile.id, unblockBotId);
      await dispatch(searchUser(profile.id));
      setUnblockBotId('');
    } catch {
      setUnblockBotError('Der Bot mit konnte nicht entsperrt werden');
      setTimeout(() => setUnblockBotError(''), 3000);
    }
  };

  const handleUnverifyUser = async () => {
    try {
      await userApi.unverifyUser(profile.id);
      await dispatch(searchUser(profile.id));
    } catch {
      setUnblockBotError('Der Nutzer konnte nicht entverifiziert werden.');
      setTimeout(() => setUnblockBotError(''), 3000);
    }
  };

  const handleAddSupportCoupon = async (cid: string) => {
    try {
      await userApi.addSupportCoupon(profile.id, cid);
      await dispatch(searchUser(profile.id));
    } catch {
      setUnblockBotError(
        'Dem Nutzer konnte kein Gutschein zugeschrieben werden.',
      );
      setTimeout(() => setUnblockBotError(''), 3000);
    }
  };

  const handleDeleteUser = async () => {
    const { id } = profile;
    try {
      await userApi.deleteUser(id);
      await dispatch(searchUser(id));
    } catch {
      setDeleteUserError('Der Nutzer konnte nicht gelöscht werden');
      setTimeout(() => setDeleteUserError(''), 3000);
    }
  };

  const handleDisableInviteCodes = async () => {
    const { id } = profile;
    try {
      await userApi.disableInviteCodes(id);
      await dispatch(searchUser(id));
    } catch {
      setDisableCodeError('Der Einladungscode konnte nicht gesperrt werden');
      setTimeout(() => setDisableCodeError(''), 3000);
    }
  };

  const handleAddBank = async () => {
    const { id } = profile;
    try {
      await ShopApi.addBankAccount(id, bankHolder, iban, bankName, monitorUser);
      await fetchBankAccount();
      setIban('');
      setBankHolder('');
      setBankName('');
    } catch {
      setBankError('Die Verbindung konnte nicht hinzugefügt werden');
      setTimeout(() => setBankError(''), 3000);
    }
  };

  const handleSetActiveBankAccount = async (aid: string) => {
    const { id } = profile;
    try {
      await ShopApi.setActiveBankAccount(id, aid);
      await fetchBankAccount();
    } catch {}
  };

  const handleDeleteInvite = async (inviteId: string) => {
    try {
      await userApi.deleteInvite(profile.id, inviteId);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleUpdateInvite = async (i: Invite) => {
    try {
      await userApi.updateInvite(profile.id, i);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleAddInvite = async (i: Invite) => {
    try {
      await userApi.addInvite(profile.id, i);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleAuthorizeInvitePayout = async (id: string) => {
    try {
      await userApi.acceptInvitePayout(profile.id, id);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const onDeleteCoupon = async (id: string) => {
    try {
      await userApi.deleteCoupon(profile.id, id);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const addVastUpdateTask = async (idNr: string, debug: boolean) => {
    try {
      await userApi.addVastUpdateTask(profile.id, idNr, debug);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleUpdateVastTaskStatus = async (id: string, status: string) => {
    try {
      await userApi.updateVastTask(profile.id, id, status);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleDeleteVastTask = async (id: string) => {
    try {
      await userApi.deleteVastTask(profile.id, id);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const handleUpdateVastPermissionStatus = async (
    id: string,
    status: string,
  ) => {
    try {
      await userApi.updateVastPermission(profile.id, id, status);
      await dispatch(searchUser(profile.id));
    } catch {}
  };

  const { forename, surname } = profile;

  const deleteEnabled =
    [forename, surname]
      .filter((s) => !!s)
      .map((s) => s.replaceAll(' ', ''))
      .join(' ') === userDeleteConfirmation;

  return (
    <div className={styles.container}>
      <h1>
        Profilübersicht von {forename} {surname}
      </h1>

      <h2>Profildaten</h2>
      <ProfileDataTable profile={profile} />

      <h2>Aktionen</h2>
      <div className={styles.actions} id="actions">
        <div className={styles.actionContainer}>
          <h3>Registrierungslink erstellen</h3>
          {!activationLink && (
            <Input
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              placeholder="Kommentar"
            />
          )}
          {activationLink && <div>{activationLink}</div>}
          <Button
            onClick={handleLinkCreate}
            disabled={!!activationLink}
            variant="outlined">
            {!activationLink ? 'Erstellen' : 'Erstellt'}
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Bot Bearbeitung freischalten</h3>
          <Input
            onChange={(e) => setUnblockBotId(e.target.value)}
            value={unblockBotId}
            placeholder="Bot-ID"
          />
          {unblockBotError && <div>{unblockBotError}</div>}
          <Button
            onClick={handleUnblockBot}
            disabled={!unblockBotId}
            variant="outlined">
            {!activationLink ? 'Freischalten' : 'Freigeschalten'}
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Verifikation entziehen</h3>
          <Button
            onClick={handleUnverifyUser}
            disabled={profile.verification !== 'phone'}
            variant="outlined">
            Entziehen
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Verfügbare Gutscheine</h3>
          <RadioGroup
            value={selectedCoupon}
            onChange={(e) => setSelectedCoupon(e.target.value)}>
            {availableCoupons.map(({ title, id }) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={title}
              />
            ))}
          </RadioGroup>

          <Button
            onClick={() => handleAddSupportCoupon(selectedCoupon)}
            disabled={
              !selectedCoupon ||
              !!profile?.coupons?.find(
                ({ couponId }) => selectedCoupon === couponId,
              )
            }
            variant="outlined">
            Gutschreiben
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Nutzer Account löschen</h3>
          <Input
            onChange={(e) => setUserDeleteConfirmation(e.target.value)}
            value={userDeleteConfirmation}
            placeholder="Vor- und Nachname"
          />
          {deleteUserError && <div>{deleteUserError}</div>}
          <Button
            onClick={handleDeleteUser}
            disabled={!deleteEnabled}
            variant="outlined">
            Nutzer löschen
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Bankverbindung hinzufügen</h3>
          <Input
            onChange={(e) => setBankHolder(e.target.value)}
            value={bankHolder}
            placeholder="Kontoinhaber"
          />
          <Input
            onChange={(e) => setIban(e.target.value)}
            value={iban}
            placeholder="IBAN"
          />
          <Input
            onChange={(e) => setBankName(e.target.value)}
            value={bankName}
            placeholder="Bankname"
          />
          {bankError && <div>{bankError}</div>}
          <Button onClick={handleAddBank} variant="outlined">
            Hinzufügen
          </Button>
        </div>

        <div className={styles.actionContainer}>
          <h3>Einladungscode Sperren</h3>
          <div>Achtung: Die Aktion kann nicht rückgängig gemacht werden.</div>
          <Button
            onClick={handleDisableInviteCodes}
            disabled={profile.inviteCodeDisabled}
            variant="outlined">
            Code sperren
          </Button>
          {disableCodeError && <div>{disableCodeError}</div>}
        </div>
      </div>

      <h2 id="invites">Gesendete Einladungen</h2>
      <div className={styles.invites}>
        {profile.sentInvites?.map((i) => (
          <InviteChange
            key={i.id}
            invite={i}
            addInvite={handleAddInvite}
            deleteInvite={handleDeleteInvite}
            updateInvite={handleUpdateInvite}
          />
        ))}
        <InviteChange
          addInvite={handleAddInvite}
          deleteInvite={handleDeleteInvite}
          updateInvite={handleUpdateInvite}
        />
      </div>

      <h2>Angenommene Einladung</h2>
      <div className={styles.invites}>
        {profile.usedInvite ? (
          <InviteChange
            invite={profile.usedInvite}
            invited
            addInvite={handleAddInvite}
            deleteInvite={handleDeleteInvite}
            updateInvite={handleUpdateInvite}
          />
        ) : (
          'Keine Einladung'
        )}
      </div>

      <h2 id="invite-payouts">Angefragte Einladungsauszahlungen</h2>
      <div className={styles.invites}>
        {invitePayouts ? (
          invitePayouts.map((p) => (
            <InvitePayoutChange
              key={p.id}
              payout={p}
              authorizePayout={handleAuthorizeInvitePayout}
            />
          ))
        ) : (
          <div>Keine Auszahlung</div>
        )}
      </div>

      <h2 id="coupons">Angenommene Coupons</h2>
      <div className={styles.invites}>
        {profile.coupons
          ? profile.coupons.map((c) => (
              <CouponChange
                key={c.id}
                coupon={c}
                deleteCoupon={onDeleteCoupon}
              />
            ))
          : 'Keine Coupons'}
      </div>

      <h2 id="vast">VaSt Aufgaben</h2>
      <div className={styles.invites}>
        {profile.vastTasks?.map((t) => (
          <VastTaskChange
            key={t.id}
            task={t}
            deleteTask={handleDeleteVastTask}
            updateStatus={handleUpdateVastTaskStatus}
          />
        ))}
        <CreateVastUpdateTask addTask={addVastUpdateTask} />
      </div>

      <h2>VaSt Genehmigungen</h2>
      <div className={styles.invites}>
        {profile.vastPermissions?.map((p) => (
          <VastPermissionChange
            key={p.id}
            permission={p}
            updateStatus={handleUpdateVastPermissionStatus}
          />
        ))}
      </div>

      <h2 id="submits">Abgaben</h2>
      <InfoPicker bots={profile.graphStatus} onSelected={handleBotSelected} />

      <h2 id="bank">Bankverbindungen</h2>
      {bankInformation && bankInformation.accounts && (
        <div className={styles.devices}>
          {bankInformation.accounts.map((a) => (
            <CompactBankAccount
              key={a.id}
              account={a}
              active={bankInformation.activeAccountId === a.id}
              onSelect={() => handleSetActiveBankAccount(a.id)}
            />
          ))}
        </div>
      )}

      <h2 id="devices">Geräte</h2>
      <div className={styles.devices}>
        {devices.map((d) => (
          <DeviceDetail key={d.id} device={d} />
        ))}
      </div>
    </div>
  );
};

const CreateVastUpdateTask = ({
  addTask,
}: {
  addTask: (idNr: string, debug: boolean) => void;
}) => {
  const [idNr, setIdNr] = useState('');
  const [debug, setDebug] = useState(true);

  return (
    <Card size={'small'}>
      <div className={styles.vastCreateContainer}>
        <div>Steuer-ID</div>
        <Input value={idNr} onChange={(e) => setIdNr(e.target.value)} />
        <div>Debug</div>
        <Checkbox value={debug} onChange={(e) => setDebug(e.target.checked)} />
        <div></div>
      </div>
      <Button onClick={() => addTask(idNr, debug)}>Aufgabe hinzufügen</Button>
    </Card>
  );
};
