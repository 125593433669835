import { Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  formatMonthly,
  formatWeekly,
  parseMonthly,
  parseWeekly,
} from '../../common/date';
import { Input } from '../../components/Input/Input';
import { Spacer } from '../../components/Spacer/Spacer';
import { statsApi } from '../api/statsApi';
import styles from './KpiPage.module.scss';
import { Scenarios } from './Scenarios/Scenarios';

export const KpiPage: React.FC<void> = () => {
  const [loading, setLoading] = useState(false);
  const [activeScenario, setActiveScenario] = useState<string | undefined>();
  const [intervall, setInterval] = useState<'daily' | 'weekly' | 'monthly'>(
    predefinedRanges[0].intervall,
  );
  const [dateRange, setDateRange] = useState<{ from: string; until: string }>({
    from: predefinedRanges[0].from,
    until: predefinedRanges[0].until,
  });

  const downloadReport = async () => {
    setLoading(true);
    const filename = 'report.xlsx';
    const parseFn = intervall === 'monthly' ? parseMonthly : parseWeekly;
    const stepSize = moment.duration({
      months: intervall === 'monthly' ? 1 : 0,
      days: intervall === 'monthly' ? 0 : 7,
    });

    const { from, until } = dateRange;
    const fromDate = parseFn(from);
    const untilDate = parseFn(until).add(stepSize);

    try {
      await statsApi.kpiReport(
        filename,
        fromDate.toDate(),
        untilDate.toDate(),
        intervall,
        activeScenario || '',
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const placeholder = intervall === 'monthly' ? '01.2021' : 'KW01.2021';

  const handleRangeInput =
    (k: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      setDateRange((s) => ({ ...s, [k]: ev.target.value }));
    };

  const updateIntervall = (i: 'daily' | 'weekly' | 'monthly') => {
    setInterval(i);
    setDateRange(i === 'monthly' ? defaultMonthRange : defaultWeekRange);
  };

  const predefinedSelected = (r: any) => {
    const { intervall, from, until } = r;
    setInterval(intervall);
    setDateRange({ from, until });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>KPI Tool</h1>

        <div className={styles.stepContainer}>
          <p>
            Mit diesem Tool können Auswertungen aus der Vergangenheit erstellt
            werden. Um vollständige Daten zu erhalten, müssen die entsprechenden{' '}
            <b>tatsächlichen Ausgaben und Umsätze</b> in den{' '}
            <Link to={'/kpi/settings'}>Einstellungen</Link> hinterlegt werden.
          </p>
          <p>
            Außerdem können Prognosen für die Zukunft erstellt werden. Hierzu
            kann ein Szenario angelegt und ausgewählt werden. Auch hier ist es
            wichtig die Marketingspendings und zusätzlich noch weitere Annahmen
            für die Zukunft zu treffen.
          </p>
        </div>

        <div className={styles.stepContainer}>
          <h3>1. Actuals anpassen (optional)</h3>
          <p>
            Falls es neue wöchentliche oder monatlichen tatsächlichen Werte
            gibt, müssen sie vor der Auswertung aktualisiert werden.
          </p>
          <Link to="/kpi/settings">
            <Button variant="outlined">Jetzt aktualisieren</Button>
          </Link>
        </div>

        <div className={styles.stepContainer}>
          <h3>2. Intervall wählen</h3>
          <div>
            <span>Wähle zwischen täglich, wöchentlich und monatlich: </span>
            <select
              value={intervall}
              onChange={(e) => updateIntervall(e.target.value as any)}>
              <option value={'monthly'}>{intervallMap['monthly']}</option>
              <option value={'weekly'}>{intervallMap['weekly']}</option>
              <option value={'daily'} disabled>
                {intervallMap['daily']}
              </option>
            </select>
          </div>
        </div>

        <div className={styles.stepContainer}>
          <h3>3. Zeitraum wählen</h3>
          <Spacer />
          <div className={styles.dateRangeContainer}>
            <div>Von</div>
            <Spacer />
            <Input
              placeholder={placeholder}
              value={dateRange.from}
              onChange={handleRangeInput('from')}
            />
            <Spacer />
            <div>bis einschließlich </div>
            <Spacer />
            <Input
              placeholder={placeholder}
              value={dateRange.until}
              onChange={handleRangeInput('until')}
            />
          </div>
          <Spacer size="medium" />
          <h4>Vordefinierte Zeiträume</h4>
          <div>
            {predefinedRanges.map((r) => (
              <Button
                size="small"
                variant="text"
                key={r.label}
                onClick={() => predefinedSelected(r)}>
                {r.label}
              </Button>
            ))}
          </div>
        </div>

        <div className={styles.stepContainer}>
          <h3>4. Szenario auswählen (nur wenn Prognose)</h3>
          <Scenarios active={activeScenario} onSelect={setActiveScenario} />
        </div>

        <div className={styles.stepContainer}>
          <h3>5. Auswertung erstellen</h3>
          <Spacer />
          <Button onClick={downloadReport} variant="outlined">
            {!loading ? 'Auswertung erstellen' : 'Auswertung wird erstellt'}
            {loading && (
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            )}
          </Button>
        </div>

        <Spacer size="xxlarge" />
      </div>
    </div>
  );
};

const defaultMonthRange = {
  from: '01.2021',
  until: moment().add({ months: -1 }).format('MM.YYYY'),
};

const defaultWeekRange = {
  from: `KW01.2022`,
  until: `KW02.2022`,
};

const intervallMap = {
  daily: 'täglich',
  weekly: 'wöchentlich',
  monthly: 'monatlich',
};

const today = moment().startOf('day');

const currentMonth = moment({
  month: today.month(),
  day: 1,
  year: today.year(),
}).add({ months: -1 });

const currentWeek = today.clone().isoWeekday(1).add({ days: -7 });

const predefinedRanges: {
  label: string;
  intervall: 'monthly' | 'weekly';
  from: string;
  until: string;
}[] = [
  {
    label: 'Letzte 24 Monate',
    intervall: 'monthly',
    from: formatMonthly(currentMonth.clone().add({ months: -23 })),
    until: formatMonthly(currentMonth),
  },
  {
    label: 'Letzte 4 Wochen',
    intervall: 'weekly',
    from: formatWeekly(currentWeek.clone().add({ days: -3 * 7 })),
    until: formatWeekly(currentWeek),
  },
];
