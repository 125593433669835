import { Checkbox } from '@material-ui/core';
import React from 'react';
import { dateToString } from '../../common/text';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { Table, TableCol } from '../Table/Table';

interface Props {
  orders: any[];
  swOrders: any[];
  selected: string[];
  onToggle: (id: string) => void;
}

export const shopUrl = 'https://shop.steuerbot.de';

export const SelectableOrderTable = ({
  orders,
  swOrders,
  selected,
  onToggle,
}: Props) => {
  const idToSW = (oid: string) => swOrders.find(({ id }) => id === oid);

  const cols: TableCol[] = [
    {
      label: 'Auswahl',
      width: 40,
      renderFunc: ({ _id: id }: any) => (
        <Checkbox
          style={{ height: 2, width: 10 }}
          size={'small'}
          checked={selected.includes(id)}
          onChange={() => onToggle(id)}
        />
      ),
    },
    {
      label: 'Nummer',
      renderFunc: ({ _id: id, orderNumber }: any) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shopUrl + '/admin#/sw/order/detail/' + id}>
          {orderNumber}
        </a>
      ),
    },
    { id: 'status', label: 'Status' },
    {
      label: 'SwStatus',
      renderFunc: ({ _id: id }) =>
        idToSW(id)?.stateMap?.order?.name || 'keine Information',
    },
    {
      label: 'Bezahlm.',
      renderFunc: ({ isRefundPayment }) =>
        isRefundPayment ? 'Soforters.' : 'Lastschrift',
    },
    { id: 'amount', label: 'Gesamt' },
    { id: 'refund', label: 'Erstattung' },
    {
      label: 'Zeitpunkt',
      renderFunc: ({ dateTime }) => dateToString(dateTime),
    },
    {
      label: 'ID',
      renderFunc: ({ _id }: any) => (
        <ShortTextCopy text={_id} length={9} fontSize={14} />
      ),
    },
  ];

  return <Table coloumns={cols} data={orders} />;
};
