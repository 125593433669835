import { AccountingPage } from 'app/accounting/AccountingPage/AccountingPage';
import { EditorPage } from 'app/editor/EditorPage/EditorPage';
import { MapperPage } from 'app/editor/EditorPage/MapperPage';
import { CustomerPage } from 'app/home/CustomerPage/CustomerPage';
import { HomePage } from 'app/home/HomePage/HomePage';
import { ProfilePage } from 'app/home/ProfilePage/ProfilePage';
import { InfoPage } from 'app/info/page/InfoPage/InfoPage';
import { PurchasePage } from 'app/info/page/PurchasePage/PurchasePage';
import { ResultsPage } from 'app/info/page/ResultsPage/ResultsPage';
import { VastDocumentsPage } from 'app/info/page/VastDocumentsPage/VastDocumentsPage';
import { MarketingPage } from 'app/marketing/MarketingPage/MarketingPage';
import { NotificationsPage } from 'app/notifications/NotificationsPage/NotificationsPage';
import { SettingsPage } from 'app/settings/SettingsPage/SettingsPage';
import { OrderDetailPage } from 'app/shop/page/OrderDetailPage/OrderDetailPage';
import { OrdersPage } from 'app/shop/page/OrdersPage/OrdersPage';
import { BatchImportPage } from 'app/statistics/BatchImportPage/BatchImportPage';
import { FunnelPage } from 'app/statistics/FunnelPage/FunnelPage';
import { KpiPage } from 'app/statistics/KpiPage/KpiPage';
import { KpiSettingsPage } from 'app/statistics/KpiSettingsPage/KpiSettingsPage';
import { StatisticsPage } from 'app/statistics/StatisticsPage/StatisticsPage';
import { TaskDetailPage } from 'app/task/TaskDetailPage/TaskDetailPage';
import { TasksPage } from 'app/task/TasksPage/TasksPage';
import { Route } from 'react-router-dom';

export const routes: { path: string; component: any; exact?: boolean }[] = [
  { path: '/', component: HomePage, exact: true },
  { path: '/users/:uid', component: ProfilePage },
  { path: '/settings', component: SettingsPage },
  { path: '/shop', component: OrdersPage, exact: true },
  { path: '/shop/customer/:uid', component: CustomerPage, exact: true },
  { path: '/shop/:oid', component: OrderDetailPage, exact: true },
  { path: '/accounting', component: AccountingPage, exact: true },
  { path: '/information', component: InfoPage, exact: true },
  { path: '/information/:bid/submits', component: ResultsPage },
  { path: '/information/:bid/purchase', component: PurchasePage },
  { path: '/bots/:bid/vast-documents', component: VastDocumentsPage },
  { path: '/statistics', component: StatisticsPage },
  { path: '/funnel', component: FunnelPage },
  { path: '/kpi', component: KpiPage, exact: true },
  { path: '/batch-import', component: BatchImportPage, exact: true },
  { path: '/kpi/settings', component: KpiSettingsPage },
  { path: '/notifications', component: NotificationsPage },
  { path: '/editor', component: EditorPage },
  { path: '/mapper', component: MapperPage },
  { path: '/marketing', component: MarketingPage },
  { path: '/tasks', component: TasksPage, exact: true },
  { path: '/tasks/:id', component: TaskDetailPage },
];
