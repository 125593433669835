import React from 'react';
import { useSelector } from 'react-redux';
import {
  Assessment,
  Information,
  RefundResult,
} from '../../info/state/infoApi';
import { KeywordDef, selectAssessmentDiff } from '../../info/state/infoReducer';
import { AssessmentDiffTable } from '../AssessmentDiffTable/AssessmentDiffTable';
import { Spacer } from '../Spacer/Spacer';
import { Table, TableCol } from '../Table/Table';
import styles from './AssessmentDifferences.module.scss';

interface Props {
  remarks: string;
  infos: Information[];
  assessment: Assessment[];
  refund: RefundResult;
}

export const AssessmentDifferences: React.FC<Props> = ({
  remarks,
  infos,
  assessment,
  refund,
}: Props) => {
  const { keywords } = useSelector(selectAssessmentDiff);

  const matchConfig: TableCol[] = [
    {
      label: 'Hinweis',
      renderFunc: (v) => (
        <div dangerouslySetInnerHTML={{ __html: v['match'] }} />
      ),
    },
    { id: 'comment', label: 'Kommentar', width: 200 },
  ];
  const rr = remarks.split('\n');

  const keywordMatches = extractParagraphs(keywords, rr);

  return (
    <div className={styles.container}>
      <AssessmentDiffTable
        assessment={assessment}
        infos={infos}
        refund={refund}
      />

      <Spacer size={'xlarge'} />

      {keywordMatches.length > 0 && (
        <Table coloumns={matchConfig} data={keywordMatches} />
      )}
    </div>
  );
};

function extractParagraphs(
  keywords: KeywordDef[],
  remarks: string[],
): { match: string; comment?: string }[] {
  const matches: { match: string; comment?: string }[] = [];

  const addIndex = (idx: number, kw: string, comment: string) => {
    const range = 2;
    let match = '';
    for (let i = idx - range; i <= idx + range; i++) {
      if (i < 0) continue;
      if (i >= remarks.length) continue;
      match += '\n' + remarks[i];
    }
    match = match.replace(kw, `<b>${kw}</b>`);
    matches.push({ match, comment });
  };

  remarks.forEach((r, idx) => {
    keywords.forEach((kw) => {
      let keyword = '';
      let comment = '';
      if (typeof kw === 'string') {
        keyword = kw;
      } else {
        keyword = kw.keyword;
        comment = kw.comment || '';
      }

      if (r.includes(keyword)) {
        addIndex(idx, keyword, comment);
      }
    });
  });

  return matches;
}
