import {
  Action,
  PayloadAction,
  ThunkAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { mapBotsYear } from '../../common/bot';
import { StorageKey, StorageService } from '../../common/storage.service';
import { RootState } from '../../store';
import {
  BotStatus,
  DeletedUserData,
  User,
  UserDevice,
  UserResponse,
  userApi,
} from './userApi';
import { BankInformation, ShopApi } from '../../shop/state/shopApi';

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type Thunk<R> = ThunkAction<R, RootState, undefined, Action>;

export interface UserState {
  activeUserId: string;
  profile?: User;
  deletedData?: DeletedUserData[];
  activeBotId?: string;
  profiles: User[];
  devices?: UserDevice[];
  payments?: string[];
  declarationPayments: any[];
  bankInformation?: BankInformation;
  gcUserToken?: string;
}

const activeUserId = StorageService.restore(StorageKey.ACTIVE_USER_ID, '');

const initialState: UserState = {
  activeUserId,
  profiles: [],
  payments: [],
  declarationPayments: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteUser() {
      return {
        activeUserId: '',
        profiles: [],
        payments: [],
        declarationPayments: [],
      };
    },
    updateUser(
      state: UserState,
      action: PayloadAction<{ user?: User; deletions?: DeletedUserData[] }>,
    ) {
      const { user, deletions } = action.payload;
      state.profile = user;
      state.deletedData = deletions;
    },
    setActiveUserId(state: UserState, action: PayloadAction<string>) {
      state.activeUserId = action.payload;
    },
    setActiveBotId(state: UserState, action: PayloadAction<string>) {
      state.activeBotId = action.payload;
    },
    setDevices(state: UserState, action: PayloadAction<UserDevice[]>) {
      state.devices = action.payload;
    },
    setPayments(state: UserState, action: PayloadAction<string[]>) {
      state.payments = action.payload;
    },
    setDeclarationPayments(state: UserState, action: PayloadAction<string[]>) {
      state.declarationPayments = action.payload;
    },
    setBankInformation(
      state: UserState,
      action: PayloadAction<BankInformation | null>,
    ) {
      state.bankInformation = action.payload ? action.payload : undefined;
    },
    setGcUserToken(state: UserState, action: PayloadAction<string>) {
      state.gcUserToken = action.payload;
    },
  },
});

export const {
  updateUser,
  setActiveBotId,
  setBankInformation,
  setGcUserToken,
} = userSlice.actions;

export default userSlice.reducer;

// Thunks
export const deleteUser = (): AppThunk => (dispatch) => {
  dispatch(userSlice.actions.deleteUser());
  StorageService.store(StorageKey.ACTIVE_USER_ID, '');
};

export const setActiveUserId =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(userSlice.actions.setActiveUserId(id));
    StorageService.store(StorageKey.ACTIVE_USER_ID, id);
  };

export const searchUser =
  (input: string): AppThunk =>
  async (dispatch, getState) => {
    const isEmail = input.includes('@');
    const fn = isEmail ? userApi.findProfile : userApi.fetchProfile;
    try {
      const { user, deletions } = await fn(input);
      dispatch(updateUser({ user, deletions }));
      const activeId = userIdFromResponse({ user, deletions });
      dispatch(setActiveUserId(activeId));

      if (!user) {
        return;
      }

      const oldId = selectActiveId(getState());
      if (oldId !== user.id) {
        dispatch(userSlice.actions.setDevices([]));
        dispatch(userSlice.actions.setPayments([]));
        dispatch(userSlice.actions.setBankInformation(null));
      }

      const { graphStatus } = user;
      const activeBotId = selectActiveBotId(getState());
      if (!activeBotId || !graphStatus.find(({ id }) => id === activeBotId)) {
        const activeBotId = user ? activeBotIdFromList(user.graphStatus) : '';
        dispatch(setActiveBotId(activeBotId));
      }

      // dispatch(fetchDevices());
      // dispatch(fetchBankInformation());
    } catch {
      dispatch(deleteUser());
    }
  };

export const fetchDevices = (): AppThunk => async (dispatch, getState) => {
  const uid = selectActiveId(getState());
  const { setDevices } = userSlice.actions;
  try {
    const { devices } = await userApi.fetchDevices(uid);
    dispatch(setDevices(devices));
  } catch {
    dispatch(setDevices([]));
  }
};

export const fetchBankInformation =
  (): AppThunk => async (dispatch, getState) => {
    const uid = selectActiveId(getState());
    const { setBankInformation } = userSlice.actions;
    try {
      const { bankInformation } = await ShopApi.fetchBankInformation(uid);
      dispatch(setBankInformation(bankInformation || null));
    } catch {
      dispatch(setBankInformation(null));
    }
  };

export const fetchPayments = (): AppThunk => async (dispatch, getState) => {
  const profile = selectProfile(getState());
  if (!profile) {
    return;
  }
  const { id: uid, graphStatus } = profile;
  const bots = (graphStatus || []).map(mapBotsYear).filter((b) => !!b.year);

  const r = await Promise.all(
    bots.map(async ({ id: bid }: BotStatus) => {
      const { hasPurchase } = await userApi.hasPurchase(uid, bid);
      return hasPurchase ? bid : '';
    }),
  );
  const { setPayments } = userSlice.actions;
  if (!r) {
    dispatch(setPayments([]));
    return;
  }
  dispatch(setPayments(r.filter((bid) => !!bid)));
};

export const fetchDeclarationPayments =
  (): AppThunk => async (dispatch, getState) => {
    const uid = selectActiveId(getState());
    const { setDeclarationPayments } = userSlice.actions;
    try {
      const { payments } = await userApi.fetchDeclarationPayments(uid);
      dispatch(setDeclarationPayments(payments));
    } catch {
      dispatch(setDeclarationPayments([]));
    }
  };

export const unblockBot =
  (bid: string): AppThunk =>
  async (dispatch, getState) => {
    const uid = selectActiveId(getState());
    await userApi.unlockUserBot(uid, bid);
    await dispatch(searchUser(uid));
  };

export const verifyUser = (): AppThunk => async (dispatch, getState) => {
  const uid = selectActiveId(getState());
  await userApi.verifyUserPhone(uid);
  await dispatch(searchUser(uid));
};

export const verifySendSMS =
  (phoneNo: string): AppThunk =>
  async (dispatch, getState) => {
    const uid = selectActiveId(getState());
    await userApi.verifySendSMS(uid, phoneNo);
    await dispatch(searchUser(uid));
  };

export const thunkSendMessage =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    return true;
  };

// Selectors
export const selectUser = (state: RootState) => state.user;
export const selectProfile = createSelector(
  selectUser,
  ({ profile }) => profile,
);
export const selectDeletions = createSelector(
  selectUser,
  ({ deletedData }) => deletedData,
);
export const selectActiveId = createSelector(
  selectUser,
  ({ activeUserId }) => activeUserId,
);
export const selectUserBots = createSelector(
  selectUser,
  ({ profile }) => profile?.graphStatus || [],
);
export const selectActiveBotId = createSelector(
  selectUser,
  ({ activeBotId }) => activeBotId,
);
export const selectDevices = createSelector(
  selectUser,
  ({ devices }) => devices || [],
);

export const selectBankInformation = createSelector(
  selectUser,
  ({ bankInformation }) => bankInformation,
);

export const selectGcUserToken = createSelector(
  selectUser,
  ({ gcUserToken }) => gcUserToken,
);

export const userSelectors = {
  selectPayments: createSelector(selectUser, ({ payments }) => payments || []),
};

const userIdFromResponse = ({ user, deletions }: UserResponse): string => {
  if (user) {
    return user.id;
  }
  if (!deletions || deletions.length <= 0) {
    return '';
  }
  return deletions[0].userId;
};

const activeBotIdFromList = (status: BotStatus[]): string => {
  for (const s of status) {
    if (s.active) {
      return s.id;
    }
  }
  return status.length <= 0 ? '' : status[0].id;
};
