import { queryParams } from '../../common/api';
import { Api } from '../../..';

export const InfoApi = {
  fetchInfo(i: Information[], uid: string, bid: string): Promise<InfoResponse> {
    let query: any = { bid, version: 1 };
    if (i && i.length > 0) {
      query = { ...query, lid: i[i.length - 1].id, last_count: i.length };
    }
    return Api.fetch({
      method: 'GET',
      url: `information/${uid}/data`,
      params: queryParams(query),
    });
  },

  fetchRefund(uid: string, bid: string): Promise<RefundResponse> {
    const query: any = { bid, version: 1 };
    return Api.fetch({
      method: 'GET',
      url: `information/${uid}/refund`,
      params: queryParams(query),
    });
  },

  ericDelete(uid: string, id: string, comment: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `information/${uid}/eric`,
      params: queryParams({ id, comment }),
    });
  },

  ericEdit(uid: string, id: string, comment: string, value: any): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `information/${uid}/eric`,
      data: queryParams({ id, comment, value }),
    });
  },

  ericCheck(
    uid: string,
    bid: string,
    version = 1,
    editor = '',
  ): Promise<EricCheckResponse> {
    return Api.fetch({
      method: 'GET',
      url: `information/${uid}/check`,
      params: queryParams({ bid, version, editor }),
    }).then((r) => ({ ...r, validateResult: r.ericResult }));
  },

  ericResults(
    uid: string,
    bid: string,
    version = 1,
  ): Promise<EricResultsResponse> {
    return Api.fetch({
      method: 'GET',
      url: `information/${uid}/results`,
      params: queryParams({ bid, version }),
    });
  },

  fetchAssessment(uid: string, bid: string): Promise<AssessmentResponse> {
    return Api.fetch({
      method: 'GET',
      url: `information/${uid}/assessment`,
      params: queryParams({ bid }),
    });
  },

  userMessages(
    uid: string,
    bid: string,
  ): Promise<{ messages: MessageElement[] }> {
    return Api.fetch({
      method: 'GET',
      url: `/messages/${uid}`,
      params: queryParams({ bid }),
    });
  },
};

export interface InfoResponse {
  data: Information[];
  append: boolean;
}

export interface Information {
  id: string;
  informationId: string;
  type: string;
  label: string;
  userId: string;
  botId: string;
  responseText: string;
  value: any;
  ericValue: any;
  rawValue: any;
  position: number[];
  persistent: boolean;
  global: boolean;
  context: string;
  ericId: string;
  supportInformation?: SupportInformation[];
}

export interface SupportInformation {
  userAgent: string;
  timestamp: string;
  comment: string;
  requestFeedback: string;
  value: any;
  previousValue: any;
}

export interface RefundResponse {
  result: RefundResult;
}

export interface EricResultsResponse {
  data: EricCheckResponse[];
}

export interface EricCheckResponse {
  _id: string;
  final: boolean;
  submitResult: any;
  mapperResponse: MapperResponse;
  ericResult: EricResponse;
  validateResult: EricResponse;
  timestamp: string;
  state: string;
  submitStarted: string;
  submitFinished: string;
  submitDetails?: SubmitDetails;
}

export interface MapperResponse {
  success: boolean;
  estdata: string;
  errorMessage: string;
  error_message: string;
  errorCode: number;
}

export interface EricResponse {
  success: boolean;
  error_code: number;
  error_message: string;
  response: string;
  serverresponse: string;
}

export interface SubmitDetails {
  transferTicket: string;
  receiptDate: string;
  telenummer: string;
  ordnungsbegriff: string;
}

export interface MessageElement {
  id: string;
  nodeId?: string;
  timestamp: string;
  from: string;
  to: string;
  graphId: string;
  sectionId: string;
  sectionTitle: string;
  message: OnlyMessage;
  request?: any;
  response: {
    id: string;
    value: any;
  };
  additionalData?: any;
  botId: string;
  version: number;
  resolve?: () => any;
  deleting?: boolean;
  error?: boolean;
  draft?: boolean;
  undeletable?: boolean;
  multidelete?: boolean;
  editState?: EditState;
  editInfo?: any;
}

export enum EditState {
  OLD = 1,
  NEW = 2,
  FIRST = 3,
  LAST = 4,
}

export type MessageType =
  | 'text'
  | 'string'
  | 'number'
  | 'date'
  | 'timespan'
  | 'address'
  | 'multipleChoice'
  | 'image'
  | 'route'
  | 'autocomplete'
  | 'form'
  | 'none';

export interface OnlyMessage {
  type: MessageType;
  text: string;
  info: {
    heading?: string;
    text?: string;
    url?: string;
  };
  address: any;
  destination?: any;
  start?: any;
  url?: string;
  height?: number;
  width?: number;
  level?: 'normal' | 'tip' | 'hint' | 'warn';
}

export interface AssessmentResponse {
  rows?: Assessment[];
}

export interface Assessment {
  number: string;
  description: string;
  value: string;
}

export interface RefundResult {
  // Bezahlte Steuern
  BeschLSt: number;
  BeschKiSt: number;
  BeschSoli: number;
  Brutto: number;
  // Einkommensteuervorauszahlungen (§ 37 EStG)
  Vorauszahlung: number;

  // Zwischenergebnisse
  AnpS: number;
  AnpP: number; // WKPausch bzw. Werbungskosten
  EinkuenfteANS: number;
  EinkuenfteANP: number; // Einkünfte aus nichtselbstständiger Arbeit → Arbeitnehmer
  RenteGesS: number;
  RenteGesP: number; // Sonstige Einkünfte: Gesetzliche Rente (Brutto)
  RenteGesFreiS: number;
  RenteGesFreiP: number; // Rentenfreibetrag
  RentePrivErtragS: number;
  RentePrivErtragP: number; // Ertragsanteil private Rentenversicherung
  SonstigeWKS: number;
  SonstigeWKP: number; // Sonstige Einkünfte: Werbungskosten (nicht nur für Rente)
  EinkuenfteSOS: number;
  EinkuenfteSOP: number; // Sonstige Einkünfte (ohne WK, ohne Freibeträge)
  EinkuenfteSonstigeG: number; // Sonstige Einkünfte: Gesamtbetrag
  EinkuenfteG: number; // Gesamtbetrag der Einkünfte
  ProgressionsEinkuenfte: number;

  // Beschr. Sonderausgaben
  AltersaufwSumme: number;
  AltersaufwAbziehbar: number;
  AltersaufwAg: number;
  AltersaufwRVVerbl: number;

  BasisKVBeitraege: number;
  BasisKVKuerzungsbetrag: number;
  BasisKVVerbl: number;
  PVBeitraege: number;
  KPVErstattet: number;
  KPVSumme: number;
  VorHoechstbetragSonstige: number;
  VorUebrige: number;
  RKPVSumme: number;

  // Unbeschr. Sonderausgaben
  SonderausgabenUnbeschr: number;
  AltersvorsorgeMax: number;
  AltersvorsorgeZul: number;
  AltersvorsorgeAb: number; // Riester
  HausHnaheDienstl: number;
  HausHnaheDienstlAb: number;
  Handwerkerleistung: number;
  HandwerkerleistungAb: number;
  ZumutbarerBetrag: number;
  Ueberbelastungsbetrag: number;

  // Kinder
  /*KinderFreibetragAnspruch, KindergeldAnspruch,*/
  KinderFreibetrag: number;
  KindergeldErh: number;
  KinderEntlastungsbetragAlleinErz: number;
  Kinderbetreuung: number;

  // Kirchensteuer
  KiStS: number;
  KiStP: number;

  // Sonstiges
  SoliAbzuege: number;

  // Warnungen   []struct{ Title, Description string }
  // Rechtstexte []struct{ Title, Description string }

  // Steuern
  Einkommen: number;
  Zve: number;
  LStTariflich: number;
  LSt: number;
  StSz: number;
  KiSt: number;
  BgKiSt: number;
  Soli: number;
  BmgSoli: number;

  Steuern: number;
  Refund: number;
}
