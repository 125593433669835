import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import styles from './BottomSheet.module.scss';

interface Props {
  title: React.ReactElement;
  children?: React.ReactElement;
}
export const BottomSheet = ({ title, children }: Props) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handlerFn = ({ code, ctrlKey }) => {
      if (code === 'KeyB' && ctrlKey) {
        setExpanded((s) => !s);
      }
    };

    document.addEventListener('keydown', handlerFn);
    return () => document.removeEventListener('keydown', handlerFn);
  }, []);

  const toggle = () => setExpanded(!expanded);

  return (
    <div
      className={styles.container}
      style={{ top: expanded ? '20%' : 'calc(100% - 45px)' }}>
      <div className={styles.header} onClick={toggle}>
        <div className={styles.title}>{title}</div>
        <ExpandMoreIcon
          style={{ transform: `rotate(${expanded ? 0 : 180}deg)` }}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
