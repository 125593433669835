import React from 'react';
import { Table, TableCol } from '../Table/Table';
import { UserOrders } from '../../shop/state/shopApi';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { euro } from '../../common/text';

interface Props {
  users: UserOrders[];
  onUserClick: (id: string) => void;
}

export const UserOrdersTable = ({ users, onUserClick }: Props): any => {
  // TODO: noch aktuellstes Datum anzeigen?
  const cols: TableCol[] = [
    {
      label: 'User-ID',
      width: 140,
      renderFunc: ({ userId }: UserOrders) => (
        <ShortTextCopy text={userId} length={9} fontSize={14} />
      ),
    },
    {
      label: 'Name',
      renderFunc: ({ userId, forename, surname }: UserOrders) => (
        <div onClick={() => onUserClick(userId)}>
          {forename + ' ' + surname}
        </div>
      ),
    },
    {
      label: 'Offen',
      width: 80,
      renderFunc: ({ orders }: UserOrders) => (
        <b>{orders.filter(statusFilter('pending_permission')).length || ''}</b>
      ),
    },
    {
      label: 'Review',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        (orders || []).filter(statusFilter('in_review')).length || '',
    },
    {
      label: 'Versandt',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        (orders || []).filter(statusFilter('open', 'complete')).length || '',
    },
    {
      label: 'Storniert',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        (orders || []).filter(statusFilter('cancelled')).length || '',
    },
    {
      label: 'Abgelehnt',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        (orders || []).filter(statusFilter('declined')).length || '',
    },
    {
      label: 'Offen €',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        euro(
          (orders || [])
            .filter(statusFilter('open', 'complete'))
            .reduce((p, c) => p + c.amount, 0),
        ),
    },
    {
      label: 'Gesamt €',
      width: 80,
      renderFunc: ({ orders }: UserOrders) =>
        euro((orders || []).reduce((p, c) => p + c.amount, 0)),
    },
  ];

  return (
    <div>
      <Table coloumns={cols} data={users} />
      {/* <pre>{JSON.stringify(users)}</pre> */}
    </div>
  );
};

const statusFilter =
  (...ss: string[]) =>
  ({ status }: any) => {
    return !ss || ss.includes(status);
  };
