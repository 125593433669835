import { colors } from '@material-ui/core';
import React from 'react';

export interface Props {
  children: React.ReactNode;
  emoji?: string;
  type: 'normal' | 'warning' | 'error';
}

export const InfoBox: React.FC<Props> = ({
  children,
  emoji,
  type = 'normal',
}: Props) => {
  const colorMap = {
    normal: colors.blue,
    warning: colors.orange,
    error: colors.red,
  };
  const palette = colorMap[type];

  return (
    <div
      style={{
        padding: '6px 12px',
        border: `2px solid ${palette[800]}`,
        borderRadius: 4,
        margin: 2,
        alignItems: 'top',
        lineHeight: '18px',
        display: 'flex',
        backgroundColor: palette[50],
      }}>
      {!!emoji && <div style={{ flex: 'none', marginRight: 4 }}>{emoji}</div>}
      <div>{children}</div>
    </div>
  );
};
