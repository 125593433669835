import { connect } from 'react-redux';
import { MapDispatch, RootState } from '../../../store';
import { selectAudiences } from '../../state/notificationsReducer';

const mapStateToProps = (state: RootState) => ({
  audiences: selectAudiences(state),
});

const mapDispatchToProps = {};

type Props = React.InputHTMLAttributes<any> &
  ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const AudienceSelection = ({ value, onChange, audiences }: Props) => {
  return (
    <select value={value} onChange={onChange}>
      <option disabled value="">
        -- Wähle eine Zielgruppe --
      </option>
      {audiences.map((v) => (
        <option key={v.id} value={v.id}>
          {v.title}
        </option>
      ))}
    </select>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceSelection);
