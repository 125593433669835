import React from 'react';
import { compareBots, mapBotsYear } from '../../common/bot';
import { BotStatus } from '../../home/state/userApi';
import { BotStatusSelection } from '../BotStatus/BotStatusSelection';
import styles from './InfoPicker.module.scss';

interface Props {
  bots: BotStatus[];
  selected?: string;
  onSelected: (id: string) => void;
  showAll?: boolean;
}

export const InfoPicker = ({ bots, selected, onSelected, showAll }: Props) => {
  const bb = [...bots]
    .sort(compareBots)
    .map(mapBotsYear)
    .filter(({ year }) => showAll || !!year);

  const handleBotClick = (id: string) => () => onSelected(id);
  return (
    <div className={styles.infoHeader}>
      {bb.map((s) => (
        <BotStatusSelection
          key={s.id}
          status={s}
          selected={s.id === selected}
          handleBotClick={handleBotClick(s.id)}
        />
      ))}
    </div>
  );
};
