import React, { useEffect, useState } from 'react';
import { userApi } from '../../home/state/userApi';
import { Input } from '../Input/Input';

interface CustomProps {
  userId: string;
  onUserIdChange: (uid: string) => void;
  clearAfterSelection?: boolean;
}

type Props = React.InputHTMLAttributes<any> & CustomProps;

export const InputUserId = ({
  userId,
  onUserIdChange,
  clearAfterSelection,
  ...rest
}: Props) => {
  const [raw, setRaw] = useState<string>('');

  useEffect(() => {
    const loadData = async () => {
      const id = await loadUserId(userId);
      setRaw(id ?? 'nicht gefunden');
    };

    if (!!userId) {
      loadData();
    } else {
      setRaw(userId);
    }
  }, [userId]);

  const loadUserId = async (q?: string): Promise<string | undefined> => {
    if (!q) {
      return undefined;
    }
    try {
      const { user } = await userApi.queryProfile(q);
      return user?.id;
    } catch (err) {
      return undefined;
    }
  };

  const handleQuerySubmit = async ({ key }: React.KeyboardEvent) => {
    if (key !== 'Enter') {
      return;
    }
    const id = await loadUserId(raw);
    if (!id) {
      return;
    }
    onUserIdChange(id);
    if (clearAfterSelection) {
      setRaw('');
    }
  };

  return (
    <Input
      value={raw}
      onChange={(e) => setRaw(e.target.value)}
      onKeyDown={handleQuerySubmit}
      {...rest}
    />
  );
};
