import moment from 'moment';

export const dateToStringPattern = (d: string, pattern: string): string => {
  return moment(d).format(pattern);
};

export const dateToString = (d: string, dateOnly?: boolean): string => {
  let pattern = 'DD.MM.YY, HH:mm';
  if (dateOnly) {
    pattern = 'DD.MM.YY';
  }
  return moment(d).format(pattern);
};

export const nowToString = () => {
  return moment().format('YYYYMMDD');
};

export const shortenText = (d: string, length: number) => {
  if (!d || d.length <= length) {
    return d;
  }
  return d.substring(0, length) + '…';
};

export const matchesQuery = (
  input: string,
  query: string,
  showIfEmpty?: boolean,
) => {
  if (showIfEmpty && !query) {
    return true;
  }
  if (!input || !query) {
    return false;
  }
  const lowerName = input.toLowerCase();
  const lowerQuery = query.toLowerCase();
  return lowerName.includes(lowerQuery);
};

export const ps = (v: any) => `${v}`.padStart(2, '0');

const euroIntl = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

export const euro = (amount: number): string =>
  euroIntl.format(amount).replace(/\D00$/, '');

export const formatNumber = (amount: number) =>
  new Intl.NumberFormat('de-DE').format(amount);
