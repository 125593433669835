import { User } from '../../home/state/userApi';
import { queryParams } from '../../common/api';
import { Api } from '../../..';
import { Information } from '../../info/state/infoApi';

export const shopUrl = 'https://shop.steuerbot.de';

export const ShopApi = {
  fetchCustomer(uid: string): Promise<CustomerResponse> {
    return Api.fetch({
      method: 'GET',
      url: `/shop/customers/${uid}`,
    });
  },

  fetchOrders(p: OrdersPayload): Promise<OrdersResponse> {
    return Api.fetch({
      method: 'GET',
      url: '/shop/orders',
      params: queryParams(p),
    });
  },

  fetchOrder(id: string): Promise<RichOrder> {
    return Api.fetch({
      method: 'GET',
      url: `/shop/orders/${id}`,
    });
  },

  updateOrder(
    oid: string,
    type: OrderUpdateType,
    userId: string,
    monitorUser: string,
    comment: string,
  ): Promise<RichOrder> {
    return Api.fetch({
      method: 'PATCH',
      url: `/shop/orders/${oid}`,
      data: { type, userId, monitorUser, comment },
    });
  },

  updateOrders(
    userId: string,
    type: OrderUpdateType,
    monitorUser: string,
    orderIds: string[],
    fromStatus: string,
    comment?: string,
    createTicket?: boolean,
  ): Promise<RichOrder> {
    return Api.fetch({
      method: 'PATCH',
      url: `/shop/customers/${userId}/orders`,
      data: { type, orderIds, monitorUser, fromStatus, comment, createTicket },
    });
  },

  fetchCredit(uid: string): Promise<{ credit: ShopCredit }> {
    return Api.fetch({
      method: 'GET',
      url: `/shop/customers/${uid}/credits`,
    });
  },

  fetchBankInformation(uid: string): Promise<{ bankInformation: any }> {
    return Api.fetch({
      method: 'GET',
      url: `/users/${uid}/bank-information`,
    });
  },

  addBankAccount(
    uid: string,
    holder: string,
    iban: string,
    bankName: string,
    monitorUser: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `users/${uid}/bank-information`,
      data: { holder, iban, bankName, monitorUser },
    });
  },

  setActiveBankAccount(uid: string, aid: string): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `users/${uid}/bank-information/${aid}/active`,
    });
  },

  blacklistUser(
    type: BlacklistUpdateType,
    value: any,
    comment: string,
  ): Promise<RichOrder> {
    return Api.fetch({
      method: 'PATCH',
      url: `shop/trust/blacklist`,
      data: { type, value, comment },
    });
  },

  createTicket(oid: string): Promise<RichOrder> {
    return Api.fetch({
      method: 'POST',
      url: `/shop/orders/${oid}/ticket`,
    });
  },
};

export enum OrderUpdateType {
  DECLINE = 'decline',
  CANCEL = 'cancel',
  PERMIT = 'permit',
  REVIEW = 'review',
  COMMENT = 'comment',
}

export enum BlacklistUpdateType {
  UID = 'userId',
  IBAN = 'iban',
  ID = 'idNr',
  EMAIL = 'email',
}

export interface CustomerResponse {
  userId: string;
  orders: any[];
  payments: ShopPayment[];
  trust: TrustResult;
  swOrders: SwOrder[];
  credit: ShopCredit;
  support: ShopSupport;
}

export interface SwOrder {
  id: string;
  lineItems: SwLineItem[];
}

export interface SwLineItem {
  orderId: string;
  orderNumber?: string;
  label: string;
  type: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

export interface OrdersResponse {
  orders: UserOrders[];
  counts: OrderCounts;
}

export interface OrderCounts {
  selected: number;
  all: number;
  pending: number;
  review: number;
}

export interface UserOrders {
  userId: string;
  forename: string;
  surname: string;
  orders: any[];
}

export interface RichOrder {
  order: any;
  profile?: User;
  trust?: TrustResult;
  swOrder?: any;
  assessments?: number;
  infos?: Information[];
}

export interface TrustResult {
  score: number;
  whitelisted: boolean;
  passedChecks: boolean;
  scores: {
    name: string;
    score: number;
    annotation: string;
    description: string;
  }[];
}

export interface OrdersPayload {
  userId?: string;
  orderId?: string;
  query?: string;
  limit?: number;
  offset?: number;
  pendingOnly?: boolean;
  states?: string;
}

export interface ShopCredit {
  userId: string;
  transactions: ShopTransaction[];
  added: string;
  updated: string;
  verified: boolean;
  remaining: number;
  debt: number;
  reserve: number;
}

export interface ShopTransaction {
  type: string;
  timestamp: string;
  botId: string;
  amount: number;
  submitLogId: string;
  assessmentId: string;
  orderId: string;
}

export interface ShopPayment {
  _id: string;
  userId: string;
  type: string;
  amount: number;
  currency: string;
  status: string;
  scheme: string;
  createdAt: string;
  updatedAt: string;
  paymentCreatedAt: string;
  finishedAt: string;
  lastTryAt: string;
  failCount: number;
  error: string;
  reference: string;
  chargeDate: string;
  description: string;
  internalNote: string;
  mandateId: string;
  goCardlessPaymentId: string;
  goCardlessStatus: string;
  goCardlessError: any;
  orderInstalments: any;
  trigger: string;
  taxAssessmentIds: string[];
}

export interface ShopSupport {
  userId: string;
  entries: any[];
}

export interface BankInformation {
  id: string;
  userId: string;
  goCardlessCustomerId: string;
  activeAccountId: string;
  accounts: BankAccount[];
  added: string;
}

export interface BankAccount {
  id: string;
  accountHolder: string;
  iban: string;
  bankName: string;
  bic: string;
  added: string;
  goCardlessMandateId: string;
  goCardlessBankAccountId: string;
}
