import React, { useEffect, useState } from 'react';
import {
  dateTimeToInputString,
  dateToISOString,
  inputStringToDateTime,
  isoStringToDate,
} from '../../common/date';
import { Input } from '../Input/Input';

interface CustomProps {
  isoDate?: string;
  onValueChange: (v: string | undefined) => void;
}

type Props = React.InputHTMLAttributes<any> & CustomProps;

export const DateInput = ({
  isoDate: externalValue,
  onValueChange,
  ...rest
}: Props) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const d = isoStringToDate(externalValue);
    if (!d) {
      return;
    }
    const s = dateTimeToInputString(d);
    setValue(s);
  }, [externalValue]);

  const onBlur = () => {
    const d = inputStringToDateTime(value);
    const newValue = dateToISOString(d);
    onValueChange(newValue);

    if (!newValue && !externalValue) {
      setValue('');
    }
  };

  return (
    <Input
      style={{ flex: 1 }}
      value={value}
      type="datetime-local"
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      {...rest}
    />
  );
};
