import React, { useEffect, useState } from 'react';
import { nanosecondToString, stringToNanosecond } from '../../common/date';
import { Input } from '../Input/Input';

interface CustomProps {
  duration: number;
  onValueChange: (v: number | undefined) => void;
}

type Props = React.InputHTMLAttributes<any> & CustomProps;

export const DurationInput = ({ duration, onValueChange, ...rest }: Props) => {
  const [raw, setRaw] = useState<string>('');

  useEffect(() => {
    const r = nanosecondToString(duration);
    if (!!r) {
      setRaw(r);
      return;
    }
    setRaw(''); // invalid duration value
  }, [duration]);

  const onBlur = () => {
    if (raw === '') {
      onValueChange(0);
      return;
    }
    const v = stringToNanosecond(raw);
    if (!!v) {
      onValueChange(v);
      return;
    }
    setRaw(nanosecondToString(duration) ?? '');
  };

  return (
    <Input
      value={raw}
      onChange={(e) => setRaw(e.target.value)}
      onBlur={onBlur}
      {...rest}
    />
  );
};
