import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { nanosecondToString } from '../../../common/date';
import { dateToString } from '../../../common/text';
import { Table, TableCol } from '../../../components/Table/Table';
import {
  NotificationJob,
  NotificationJobProgress,
  NotificationJobStatus,
} from '../../state/notificationApi';
import {
  selectAudienceObject,
  selectMessageObject,
} from '../../state/notificationsReducer';
import { nextNotification } from '../../util/jobs';

interface Props {
  path: string;
  jobs: NotificationJob[];
  jobProgress?: { [key: string]: NotificationJobProgress };
  showNext?: boolean;
  showProgress?: boolean;
  rowNo?: boolean;
}

export const NotificationJobTable: React.FC<Props> = ({
  path,
  jobs,
  rowNo,
  showNext,
  jobProgress = {},
}: Props) => {
  const history = useHistory();
  const audiences = useSelector(selectAudienceObject);
  const messages = useSelector(selectMessageObject);

  const onRowClicked = (_: React.MouseEvent, idx: number) => {
    history.push(path + '/' + jobs[idx].id);
  };

  const showProgress = jobs.find(
    ({ status }) => status === NotificationJobStatus.IN_PROGRESS,
  );

  const showNextCol = showNext && jobs.find(({ repeatAfter }) => !!repeatAfter);

  const showCounter = jobs.find(
    ({ runCounter }) => !!runCounter && runCounter > 1,
  );

  const opt = (cond: boolean, col: TableCol) => (cond ? [col] : []);

  const cols: TableCol<NotificationJob>[] = [
    { id: 'title', label: 'Titel' },
    { id: 'status', label: 'Status' },
    {
      label: 'Zielgruppe',
      renderFunc: ({ audienceId }) =>
        !!audienceId && !!audiences[audienceId]
          ? audiences[audienceId].title
          : '–',
    },
    {
      label: 'Nachricht',
      renderFunc: ({ messageId }) =>
        !!messageId && !!messages[messageId] ? messages[messageId].title : '–',
    },
    {
      label: 'Erstellt',
      renderFunc: ({ created }) => dateToString(created || ''),
    },
    {
      label: 'Zeitraum',
      renderFunc: ({ hours }) => hours.from + ' - ' + hours.to + ' Uhr',
    },
    {
      label: 'Intervall',
      renderFunc: ({ repeatAfter }) =>
        !!repeatAfter && repeatAfter > 0
          ? nanosecondToString(repeatAfter)
          : '–',
    },
    ...opt(!!showNextCol, {
      label: 'Nächste Nachricht',
      renderFunc: nextNotification,
    }),
    ...opt(!!showCounter, { label: 'Durchläufe', id: 'runCounter', width: 70 }),
    ...opt(!!showProgress, {
      label: 'Fortschritt',
      renderFunc: ({ id }) => jobProgress[id]?.progress ?? '–',
    }),
    {
      label: 'Testlauf',
      renderFunc: (n) => (n.dryRun ? 'JA' : 'NEIN'),
      width: 70,
    },
  ];

  return (
    <Table
      coloumns={cols}
      data={jobs}
      rowNo={rowNo}
      emptyText="–"
      variant="material"
      onRowClicked={onRowClicked}
    />
  );
};
