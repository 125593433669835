import React, { useEffect, useState } from 'react';
import styles from './Chat.module.scss';
import { InfoApi, MessageElement } from '../../info/state/infoApi';
import { all } from '../../common/style';
import { dateToString } from '../../common/text';
import { CircularProgress } from '@material-ui/core';

export const Chat = ({ uid, bid }: { uid: string; bid: string }) => {
  const [results, setResults] = useState<ExtendedMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      const { messages } = await InfoApi.userMessages(uid, bid);
      setResults(messages.map((m) => ({ ...m, userMessage: m.from === uid })));
      setLoading(false);
    };
    f();
  }, [bid, uid]);

  const toggle = (id: string) => () => {
    const idx = expanded.indexOf(id);
    setExpanded(
      idx >= 0 ? [...expanded].filter((s) => s !== id) : [...expanded, id],
    );
  };

  return (
    <div className={styles.container}>
      {loading && <CircularProgress size={24} />}
      {results.map((m: ExtendedMessage) => (
        <div
          key={m.id}
          className={all(
            styles.message,
            m.userMessage ? styles.userMessage : '',
            expanded.includes(m.id) ? styles.expanded : '',
          )}>
          <div className={styles.messageHeader}>
            <div className={styles.codePreviewButton} onClick={toggle(m.id)}>
              {'{ }'}
            </div>
            <div className={styles.messageTitle}>{messageTitle(m)}</div>
            <div>{dateToString(m.timestamp)}</div>
          </div>
          {expanded.includes(m.id) && (
            <pre className={styles.codePreview}>
              {JSON.stringify(m, null, 2)}
            </pre>
          )}
        </div>
      ))}
    </div>
  );
};

interface ExtendedMessage extends MessageElement {
  userMessage: boolean;
}

const messageTitle = (m: ExtendedMessage) => {
  const { message, request, userMessage } = m;
  if (message && message.text) {
    return message.text;
  }
  if (message && message.type) {
    return m.message.type + (userMessage ? '' : ' - Message');
  }
  if (request && request.type) {
    return m.request.type + ' - Request';
  }
  return 'unknown';
};
