import { Button, colors } from '@material-ui/core';
import { dateToString } from 'app/common/text';
import { Card } from 'app/components/Card/Card';
import { Divider } from 'app/components/Divider/Divider';
import { ObjectTable, TableRow } from 'app/components/ObjectTable/ObjectTable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spacer } from '../../components/Spacer/Spacer';
import { userActions } from '../../home/state/userActions';
import { Coupon } from '../../home/state/userApi';
import { MapDispatch, RootState } from '../../store';
import { CouponCreation } from './components/CouponCreation/CouponCreation';
import { CouponList } from './components/CouponList/CouponList';
import styles from './MarketingPage.module.scss';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  getCoupons: userActions.getCoupons,
  getCouponList: userActions.getCouponList,
  createCoupon: userActions.createCoupon,
  disableCoupon: userActions.disableCoupon,
  enableCoupon: userActions.enableCoupon,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawMarketingPage: React.FC<Props> = ({
  getCoupons,
  getCouponList,
  createCoupon,
  disableCoupon,
  enableCoupon,
}: Props) => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    loadCoupons();
  }, []);

  const loadCoupons = () => {
    const load = async () => {
      const { coupons } = await getCoupons();
      setCoupons(coupons || []);
    };
    load();
  };

  const onCouponDisable = (id: string) => {
    return async (d: 'acceptance' | 'consumption') => {
      await disableCoupon(id, d === 'acceptance', d === 'consumption');
      loadCoupons();
    };
  };

  const onCouponEnable = (id: string) => {
    return async () => {
      await enableCoupon(id);
      loadCoupons();
    };
  };

  const now = moment();
  const shownCoupons = showAll
    ? coupons
    : coupons.filter(({ acceptExpiryDate, consumeExpiryDate }) => {
        const isDisabled =
          !!acceptExpiryDate &&
          moment(acceptExpiryDate).isBefore(now) &&
          !!consumeExpiryDate &&
          moment(consumeExpiryDate).isBefore(now);
        return !isDisabled;
      });

  return (
    <div className={styles.container}>
      <h1>Marketing</h1>
      <div className={styles.divider} />

      <div className={styles.actionContainer}>
        <CouponList createList={getCouponList} coupons={coupons} />
      </div>

      <div className={styles.actionContainer}>
        <CouponCreation
          createCoupon={createCoupon}
          reloadCoupons={loadCoupons}
        />
      </div>

      <div className={styles.actionContainer}>
        <div>
          <h2>Gutscheine</h2>
          <Button
            onClick={() => setShowAll((s) => !s)}
            style={{ cursor: 'default' }}>
            {showAll ? 'Nur aktive anzeigen' : 'Alle anzeigen'}
          </Button>
          <Spacer />
          <div className={styles.couponContainer}>
            {shownCoupons.map((c) => (
              <CouponCard
                key={c.id}
                coupon={c}
                disableCoupon={onCouponDisable(c.id)}
                enableCoupon={onCouponEnable(c.id)}
              />
            ))}
          </div>
        </div>
      </div>

      <Spacer size="xxxlarge" />
    </div>
  );
};

export const MarketingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawMarketingPage);

const CouponCard = ({
  coupon,
  disableCoupon,
  enableCoupon,
}: {
  coupon: Coupon;
  disableCoupon: (d: 'acceptance' | 'consumption') => void;
  enableCoupon: () => void;
}) => {
  const properties: TableRow[] = [
    { label: 'Titel', id: 'title' },
    { label: 'Beschr.', id: 'description' },
    { label: 'Hinweis', id: 'hint' },
    {
      label: 'Codes',
      renderFunc: (v: Coupon) => (
        <div>{v.codes.slice(0, Math.min(v.codes.length, 5)).join(', ')}</div>
      ),
    },
  ];
  if (!!coupon.acceptExpiryDate) {
    properties.push({
      label: 'Annahme',
      renderFunc: (v: Coupon) =>
        !!v.acceptExpiryDate ? (
          <div>{dateToString(v.acceptExpiryDate)}</div>
        ) : null,
    });
  }
  if (!!coupon.consumeExpiryDate) {
    properties.push({
      label: 'Einlösen',
      renderFunc: (v: Coupon) =>
        !!v.consumeExpiryDate ? (
          <div>{dateToString(v.consumeExpiryDate)}</div>
        ) : null,
    });
  }

  return (
    <Card size="small">
      <ObjectTable minimal={true} data={coupon} rows={properties}></ObjectTable>
      <Spacer />
      <Divider />
      <div>
        <Spacer />
        <h3>Aktionen:</h3>
        <Spacer />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => disableCoupon('acceptance')}
            disabled={!!coupon.acceptExpiryDate}>
            Annahme sperren
          </Button>
          <Spacer />
          <Button
            size="small"
            variant="outlined"
            onClick={() => disableCoupon('consumption')}
            disabled={!!coupon.consumeExpiryDate}>
            Benutzung sperren
          </Button>
          <Spacer />
          <Button
            size="small"
            variant="outlined"
            onClick={enableCoupon}
            disabled={!coupon.acceptExpiryDate && !coupon.consumeExpiryDate}>
            Entsperren
          </Button>
        </div>
      </div>
    </Card>
  );
};
