import React from 'react';
import { RefundResult } from '../../info/state/infoApi';
import { Table, TableCol } from '../Table/Table';
import styles from './ComputationResult.module.scss';

export const ComputationResult = ({ result }: { result: RefundResult }) => {
  const col: TableCol[] = [
    { id: 'label', label: 'Label' },
    { id: 'value', label: 'Wert' },
  ];

  const data = Object.keys(result).map((id) => ({
    label: id,
    value: (result as any)[id],
  }));

  return (
    <div className={styles.container}>
      <Table coloumns={col} data={data} />
    </div>
  );
};
