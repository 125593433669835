import { Button, CircularProgress, colors } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import produce from 'immer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { euro } from '../../../../common/text';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { Table, TableCol } from '../../../../components/Table/Table';
import { OrderMapping, OrderTransfer } from '../../../../home/state/userApi';
import styles from './TransferSync.module.scss';

interface Props {
  loading?: boolean;
  error?: string;
  transfers: OrderTransfer[];
  onAddCSV: (csv: string) => void;
  onRemove: (idx: number) => void;
  onChange: (tt: OrderTransfer[]) => void;
  onSync: () => void;
  onFilter: () => void;
}

export const TransferSync = ({
  loading,
  error,
  transfers,
  onAddCSV,
  onRemove,
  onChange,
  onSync,
  onFilter,
}: Props) => {
  const [noInput, setNoInput] = useState<string>('');
  const [amountInput, setAmountInput] = useState<number | string>(29.95);
  const [draggedOverCSV, setDraggedOverCSV] = useState<boolean>(false);

  useEffect(() => {
    if (noInput.includes(' ')) {
      setNoInput(noInput.replace(' ', ''));
    }
  }, [noInput]);

  const handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = 'copy';
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDraggedOverCSV(true);
  };

  const handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDraggedOverCSV(false);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files || e.dataTransfer.files.length <= 0) {
      return;
    }
    const file = e.dataTransfer.files[0];
    e.dataTransfer.clearData();
    const reader = new FileReader();

    reader.onerror = () => {
      e?.dataTransfer?.clearData();
      setDraggedOverCSV(false);
    };

    reader.onload = function (event) {
      const res = event.target?.result?.toString();
      if (!!res && typeof res === 'string') {
        onAddCSV(res);
      }
      e?.dataTransfer?.clearData();
      setDraggedOverCSV(false);
    };

    reader.readAsText(file);
  };

  const onOrdersChanged = (idx: number) => (orders: OrderMapping[]) => {
    onChange(
      produce(transfers, (draft) => {
        draft[idx].orderMapping = orders;
        return draft;
      }),
    );
  };

  const onSuggestionSelected =
    (idx: number) => (no: string, amount: number) => {
      onChange(
        produce(transfers, (draft) => {
          const mapping: OrderMapping = {
            no,
            distributedAmount: amount,
            openAmount: amount,
          };
          if (!!draft[idx].orderMapping) {
            draft[idx].orderMapping?.push(mapping);
          } else {
            draft[idx].orderMapping = [mapping];
          }
          return draft;
        }),
      );
    };

  const cols: TableCol<OrderTransfer>[] = [
    { label: 'Status', id: 'status', width: 120 },
    { label: 'Sender', id: 'sender', width: 200 },
    {
      label: 'Datum',
      renderFunc: (t) => moment(t.date).format('DD.MM.YYYY'),
      width: 100,
    },
    {
      label: 'VIBAN',
      renderFunc: ({ viban }) => (viban ? '☑️' : ''),
      width: 70,
    },
    { label: 'Verwendungszweck', id: 'reference', width: 280 },
    {
      label: 'Überwiesen',
      renderFunc: (t) => (t.amount ? euro(t.amount) : '–'),
      width: 100,
    },
    {
      label: 'Differenz',
      renderFunc: (t) => euro(t.missingAmount || 0),
      width: 100,
    },
    {
      label: 'Bestellungen',
      renderFunc: (t, idx) => (
        <OrderDetailList
          orders={t.orderMapping || []}
          onOrdersChanged={onOrdersChanged(idx)}
        />
      ),
      width: 280,
    },
    {
      label: 'Vorschläge',
      renderFunc: (t, idx) => (
        <OrderSuggestionList
          suggestions={filterSuggestions(t.orderSuggestions, t.orderMapping)}
          onSelected={onSuggestionSelected(idx)}
        />
      ),
      width: 550,
    },
    { label: 'Fehler', id: 'error' },
    {
      label: 'Entfernen',
      width: 80,
      renderFunc: (_, idx) => (
        <div
          onClick={() => onRemove(idx)}
          style={{ display: 'block', height: '100%' }}>
          <DeleteOutline style={{ fontSize: 18 }} />
        </div>
      ),
    },
  ];

  const confirmedTransferCount = transfers.filter((t) => !!t.confirmed).length;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3>Überweisungen</h3>
        <Spacer />
        {!!loading && <CircularProgress size={20} />}
      </div>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Die Nutzer haben per Überweisung bezahlt und der Transfer ist
        eingegangen. Hier kann eine Liste der Bestellnummern angegeben werden,
        damit die Bezahlung im System bestätigt wird.
      </div>

      {(confirmedTransferCount > 0 || transfers.length > 0) && (
        <>
          <Spacer />
          <div className={styles.actionContainer}>
            {confirmedTransferCount > 0 && (
              <Button variant="outlined" size="small" onClick={onFilter}>
                {confirmedTransferCount} Bestätigte entfernen
              </Button>
            )}

            <Spacer />

            {transfers.length > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={onSync}
                disabled={transfers.length <= 0}>
                {transfers.length} Zahlungen bestätigen
              </Button>
            )}
          </div>
        </>
      )}

      <Spacer size="large" />

      {!!error && (
        <>
          <b className={styles.error} style={{ color: colors.red['800'] }}>
            {error}
          </b>
          <Spacer size="large" />
        </>
      )}

      <Table data={transfers} coloumns={cols} emptyText=" " rowNo />

      <div className={styles.addContainer}>
        {transfers.length <= 0 && (
          <div
            onDrop={handleOnDrop}
            onDragOver={handleOnDragOver}
            onDragEnter={handleOnDragEnter}
            onDragLeave={handleOnDragLeave}
            className={styles.dropContainer}
            style={{
              alignSelf: 'stretch',
              background: draggedOverCSV ? '#5fcc8040' : undefined,
            }}>
            Ziehe eine csv-Datei in das Feld oder gib die Überweisung manuell
            ein
          </div>
        )}
        <Spacer />
      </div>
    </div>
  );
};

const OrderDetailList = ({
  orders,
  onOrdersChanged,
}: {
  orders: OrderMapping[];
  onOrdersChanged: (orders: OrderMapping[]) => void;
}) => {
  const [newOrder, setNewOrder] = useState<OrderMapping | null>(null);

  const onAmountChanged =
    (idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      onOrdersChanged(
        produce(orders, (draft) => {
          draft[idx].distributedAmount = ev.target.valueAsNumber;
          return draft;
        }),
      );
    };

  const toggleNewOrder = () => {
    setNewOrder(
      !!newOrder ? null : { no: '', distributedAmount: 0, openAmount: 0 },
    );
  };

  const addNewOrder = () => {
    if (!newOrder) {
      return;
    }
    if (
      orders.find(({ no }) => no === newOrder.no) ||
      newOrder.openAmount < 10
    ) {
      return;
    }

    onOrdersChanged(
      produce(orders, (draft) => {
        draft.push(newOrder);
        return draft;
      }),
    );
    setNewOrder(null);
  };

  return (
    <div>
      {orders.map(({ no, openAmount, distributedAmount }, idx) => (
        <div key={no} style={{ display: 'flex', alignItems: 'baseline' }}>
          <div>
            {no} ({euro(openAmount)}):
          </div>
          <Spacer />
          <input
            style={{ width: 70 }}
            type="number"
            step={0.01}
            value={distributedAmount}
            onChange={onAmountChanged(idx)}
          />
          {idx === orders.length - 1 && (
            <div
              onClick={toggleNewOrder}
              style={{
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid lightgray',
              }}>
              {!!newOrder ? '➖' : '➕'}
            </div>
          )}
        </div>
      ))}

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        {!!newOrder && (
          <>
            <input
              style={{ width: 120 }}
              placeholder="Bestellnummer"
              value={newOrder.no}
              onChange={(e) => setNewOrder({ ...newOrder, no: e.target.value })}
            />
            <Spacer />
            <input
              style={{ width: 70 }}
              type="number"
              step={0.01}
              value={newOrder.openAmount}
              onChange={(e) =>
                setNewOrder({
                  ...newOrder,
                  openAmount: e.target.valueAsNumber,
                })
              }
            />
            <Spacer />
            <div onClick={addNewOrder}>💾</div>
            <Spacer />
          </>
        )}
        {orders.length <= 0 && (
          <div
            onClick={toggleNewOrder}
            style={{
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid lightgray',
            }}>
            {!!newOrder ? '➖' : '➕'}
          </div>
        )}
      </div>
    </div>
  );
};

const OrderSuggestionList = ({
  suggestions,
  onSelected,
}: {
  suggestions: OrderMapping[];
  onSelected: (no: string, amount: number) => void;
}) => {
  return (
    <div>
      {suggestions.map(({ no, openAmount, description }) => (
        <div key={no} style={{ display: 'flex', alignItems: 'baseline' }}>
          <div style={{ fontWeight: 'bold' }}>{no}:</div>
          <Spacer />
          <div>
            {euro(openAmount)} €, {description}
          </div>
          <Spacer />
          <div onClick={() => onSelected(no, openAmount)}>⬅️</div>
        </div>
      ))}
    </div>
  );
};

const filterSuggestions = (
  suggestions?: OrderMapping[],
  orders?: OrderMapping[],
) => {
  if (!suggestions) {
    return [];
  }
  if (!orders) {
    return suggestions;
  }
  return suggestions.filter((s) => !orders.find((o) => o.no === s.no));
};
