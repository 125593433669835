import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { readableDuration, timeDiff } from '../../../common/date';
import { formatNumber } from '../../../common/text';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Spacer } from '../../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../../store';
import { NotificationJobStatus } from '../../state/notificationApi';
import { notificationsActions } from '../../state/notificationsActions';
import {
  selectAllNotificationResults,
  selectJobProgress,
  selectJobs,
  selectJobsDays,
  selectJobsDaysCount,
  selectMessagesReachedDaysCount,
} from '../../state/notificationsReducer';
import { NotificationJobTable } from '../NotificationJobTable/NotificationJobTable';
import { NotificationResultTable } from '../NotificationResultTable/NotificationResultTable';
import styles from './NotificationDashboard.module.scss';

const mapStateToProps = (state: RootState) => ({
  jobs: selectJobs(state),
  jobProgress: selectJobProgress(state),
  jobResultsSuccess: selectAllNotificationResults('success', 7)(state),
  jobResultsFailed: selectAllNotificationResults('failed', 3)(state),
  jobCount24h: selectJobsDaysCount(1)(state),
  job7d: selectJobsDays(7)(state),
  reachedMessages24h: selectMessagesReachedDaysCount(1)(state),
  reachedMessages7d: selectMessagesReachedDaysCount(7)(state),
});

const mapDispatchToProps = {
  fetchJobs: notificationsActions.fetchJobs,
  fetchJobProgress: notificationsActions.fetchJobProgress,
  fetchAllJobsProgress: notificationsActions.fetchAllJobsProgress,
  fetchAudiences: notificationsActions.fetchAudiences,
  fetchMessages: notificationsActions.fetchMessages,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationDashboard = ({
  jobs,
  jobProgress,
  jobResultsSuccess,
  jobResultsFailed,
  jobCount24h,
  job7d,
  reachedMessages24h,
  reachedMessages7d,
  fetchJobs,
  fetchAllJobsProgress,
  fetchAudiences,
  fetchMessages,
}: Props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchJobs(); // TODO: handle error
      await fetchAudiences();
      await fetchMessages();
      setLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    const cancellation = setInterval(async () => {
      await fetchJobs();
      await fetchAllJobsProgress();
    }, 5000);
    return () => clearTimeout(cancellation);
  }, [fetchJobs, fetchAllJobsProgress]);

  const jobByStatus = (...status: NotificationJobStatus[]) =>
    jobs.filter((j) => status.includes(j.status));

  const planned = jobByStatus(
    NotificationJobStatus.CREATED,
    NotificationJobStatus.REPEAT,
  );
  const active = jobByStatus(NotificationJobStatus.IN_PROGRESS);
  const paused = jobByStatus(NotificationJobStatus.PAUSED);
  const draft = jobByStatus(NotificationJobStatus.DRAFT);

  const autoJobsCount = jobs.filter(({ repeatAfter }) => !!repeatAfter).length;
  const failedJobsCount = jobByStatus(NotificationJobStatus.FAILED).length;

  const jobCount7d = job7d.length;
  const longestJobDur = job7d.reduce((p, { started, finished }) => {
    if (!started || !finished) {
      return p;
    }
    const diff = timeDiff(started, finished);
    if (diff === undefined) {
      return p;
    }
    return p < diff ? diff : p;
  }, moment.duration(0));
  const longestJob7d = readableDuration(longestJobDur);

  let totalJobResults = 0;
  let totalJobFailures = 0;
  jobs.forEach(({ history, error }) => {
    if ((!history || history.length) <= 0 && !!error) {
      totalJobFailures++;
      return;
    }
    totalJobResults += history?.filter((j) => !!j.finished).length || 0;
    totalJobFailures += history?.filter((j) => !!j.error).length || 0;
  });

  return (
    <div>
      <LoadingNavHeader
        title="Automatisierte Benachrichtigungen"
        loading={loading}
        compact
      />

      <Spacer />

      <div className={styles.highlightContainer}>
        <div className={styles.highlightGrid}>
          <HighlightNumber
            title={formatNumber(autoJobsCount)}
            subtitle="Insgesamt automatisierte Jobs"
          />
          <HighlightNumber
            title={formatNumber(reachedMessages24h)}
            subtitle="Benachrichtigungen in den letzten 24 Stunden"
          />
          <HighlightNumber
            title={formatNumber(reachedMessages7d)}
            subtitle="Benachrichtigungen in den letzten 7 Tagen"
          />
          <HighlightNumber
            title={formatNumber(jobCount24h)}
            subtitle="Jobs in den letzten 24 Stunden"
          />
          <HighlightNumber
            title={formatNumber(jobCount7d)}
            subtitle="Jobs in den letzten 7 Tagen"
          />
          <HighlightNumber
            title={`${formatNumber(failedJobsCount)}`}
            subtitle="Fehlgeschlagene Jobs in den letzten 7 Tagen"
          />
          <HighlightNumber
            title={longestJob7d}
            subtitle="Längste Jobdauer in den letzten 7 Tagen"
          />
        </div>
        <Spacer />
      </div>

      <Spacer size="large" />

      {draft.length > 0 && (
        <>
          <h2>✏️ Entwürfe</h2>
          <NotificationJobTable jobs={draft} path={'/notifications/jobs'} />
          <Spacer size="large" />
        </>
      )}

      <h2>🏃‍♂️ Aktiv</h2>
      <NotificationJobTable
        jobs={active}
        path={'/notifications/jobs'}
        showProgress
        jobProgress={jobProgress}
      />
      <Spacer size="large" />

      <h2>⏳ Geplant</h2>
      <NotificationJobTable
        jobs={planned}
        path={'/notifications/jobs'}
        showNext
      />
      <Spacer size="large" />

      <h2>{`📫 Versendet (${totalJobResults})`}</h2>
      <NotificationResultTable results={jobResultsSuccess} />
      <Spacer size="large" />

      {jobResultsFailed.length > 0 && (
        <>
          <h2>{`❗️ Fehlgeschlagen ${totalJobFailures}`}</h2>
          <NotificationResultTable results={jobResultsFailed} />
          <Spacer size="large" />
        </>
      )}

      {paused.length > 0 && (
        <>
          <h2>💤 Pausiert</h2>
          <NotificationJobTable jobs={paused} path={'/notifications/jobs'} />
          <Spacer size="large" />
        </>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationDashboard);

const HighlightNumber = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => (
  <div className={styles.highlightNumber}>
    <h1>{title}</h1>
    <div>{subtitle ?? ''}</div>
  </div>
);
