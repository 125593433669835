import React from 'react';
import { isNullOrUndefined } from 'util';

export interface TableRow {
  id?: string;
  label: string;
  collapsable?: boolean;
  renderFunc?: (
    v: any,
  ) => React.ReactElement | string | number | null | undefined;
}

interface Props {
  rows: TableRow[];
  data: any;
  minimal?: boolean;
}

export const ObjectTable = ({
  rows,
  data,
  minimal,
}: Props): React.ReactElement => {
  return (
    <table>
      <tbody>
        {rows
          .filter(({ collapsable }) => !minimal || !collapsable)
          .filter(
            ({ id, renderFunc }) =>
              !isNullOrUndefined(data[id || '']) ||
              !isNullOrUndefined(renderFunc),
          )
          .map(({ id, label, renderFunc }) => (
            <tr key={id + '' + label}>
              <td>
                <b>{label}</b>
              </td>
              {!!renderFunc && <td>{renderFunc(data)}</td>}
              {!renderFunc && !!id && <td>{data[id]}</td>}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
