import { Button, Divider } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Spacer } from '../../components/Spacer/Spacer';

export const StatisticsPage: React.FC<void> = () => {
  const tools: {
    title: string;
    description: string;
    action: string;
    link: string;
  }[] = [
    {
      title: 'KPI Tool',
      description: 'Auswertungen auf den Daten inkl. Prognose und Szenarien.',
      action: 'Zum KPI Tool',
      link: '/kpi',
    },
    {
      title: 'Knoten-Funnel',
      description:
        'Eine Liste von Knoten in einem Bot, aus der ein Funnel aufgebaut werden kann.',
      action: 'Zum Knoten-Funnel',
      link: '/funnel',
    },
    {
      title: 'Datenübernahme',
      description: 'Detailinformationen zu Nutzung der Datenübernahme.',
      action: 'Zur Datenübernahme',
      link: '/batch-import',
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h1>Statisiken</h1>
      <div>
        Hier gibt es verschiedene Tools zur statistischen Auswertung unserer
        Daten. Einige Tools sind relativ teuer, weswegen man sie mit bedacht
        benutzen sollte.
      </div>
      <Spacer />
      <Divider />

      <div style={{ display: 'flex', marginTop: 20 }}>
        {tools.map(({ title, description, action, link }) => (
          <div
            key={title}
            style={{
              border: '1px solid lightgray',
              borderRadius: 6,
              width: 300,
              padding: 10,
              margin: 5,
            }}>
            <h2 style={{ marginTop: 10 }}>{title}</h2>
            <div>{description}</div>
            <Spacer />
            <Link to={link}>
              <Button>{action}</Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
