import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Spacer } from '../../components/Spacer/Spacer';
import { UserSearchInput } from '../../components/UserSearchInput/SearchInput';
import styles from './HomePage.module.scss';

export const HomePage: React.FC<void> = () => {
  return (
    <div className={styles.container}>
      <h1>Steuerbot Monitor</h1>
      <br />
      <br />
      <div className={styles.content}>
        <UserSearchInput></UserSearchInput>
      </div>

      <Spacer size="xlarge"></Spacer>

      <Link to="/information">
        <Button>Zur Infotabelle</Button>
      </Link>
      <Spacer size="xsmall"></Spacer>

      <Link to="/shop">
        <Button>Zur Shopverwaltung</Button>
      </Link>

      <Link to="/accounting">
        <Button>Zur Buchhaltung</Button>
      </Link>

      <Link to="/statistics">
        <Button>Zu den Statistiken</Button>
      </Link>

      <Link to="/notifications">
        <Button>Zu den Benachrichtigungen</Button>
      </Link>

      <Link to="/editor">
        <Button>Zu der Modellierung</Button>
      </Link>

      <Link to="/marketing">
        <Button>Zum Marketing</Button>
      </Link>

      <Link to="/tasks">
        <Button>Zum den Tasks</Button>
      </Link>
    </div>
  );
};
