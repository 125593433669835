import React, { useState } from 'react';
import { Button, Dialog, DialogProps } from '@material-ui/core';
import { Input } from '../Input/Input';
import styles from './InformationEditDialog.module.scss';

interface InfoEditProps {
  change?: { id?: string; before?: any; after?: any; label?: string };
  onSubmitChange: (id: string, after: any, comment: string) => void;
}

type Props = DialogProps & InfoEditProps;

export const InformationEditDialog = (p: Props) => {
  const { onSubmitChange, change, ...other } = p;
  const { id, before, after, label } = change || {};

  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    onSubmitChange(id || '', after, comment);
    setComment('');
  };

  return (
    <Dialog {...other}>
      <div className={styles.content}>
        <h3>{after ? 'Ändern' : 'Löschen'}</h3>
        <table>
          <tbody>
            <tr>
              <td
                colSpan={2}
                style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {label}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'crimson' }}>{before}</td>
              <td style={{ color: 'forestgreen' }}>{after || '-'}</td>
            </tr>
          </tbody>
        </table>
        <Input
          placeholder="Kommentar"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button onClick={handleSubmit}>Bestätigen</Button>
      </div>
    </Dialog>
  );
};
