import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@material-ui/core';
import {
  DashboardOutlined,
  ForumOutlined,
  GroupOutlined,
  WorkOutlined,
} from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Spacer } from '../../components/Spacer/Spacer';
import AudienceDetail from '../components/NotificationAudienceDetail/NotificationAudienceDetail';
import NotificationAudiences from '../components/NotificationAudiences/NotificationAudiences';
import NotificationDashboard from '../components/NotificationDashboard/NotificationDashboard';
import NotificationJobDetail from '../components/NotificationJobDetail/NotificationJobDetail';
import NotificationJobs from '../components/NotificationJobs/NotificationJobs';
import NotificationMessageDetail from '../components/NotificationMessageDetail/NotificationMessageDetail';
import NotificationMessages from '../components/NotificationMessages/NotificationMessages';
import styles from './NotificationsPage.module.scss';

const dashboardPath = '/dashboard';
const audiencesPath = '/audiences';
const messagesPath = '/messages';
const jobsPath = '/jobs';

const bottomBarItems = [
  { label: 'Übersicht', subPath: dashboardPath, icon: <DashboardOutlined /> },
  { label: 'Zielgruppen', subPath: audiencesPath, icon: <GroupOutlined /> },
  { label: 'Nachrichten', subPath: messagesPath, icon: <ForumOutlined /> },
  { label: 'Jobs', subPath: jobsPath, icon: <WorkOutlined /> },
];

const navRoutes = [
  { subPath: dashboardPath, exact: false, component: NotificationDashboard },
  { subPath: audiencesPath, exact: true, component: NotificationAudiences },
  { subPath: audiencesPath + '/:id', exact: true, component: AudienceDetail },
  { subPath: messagesPath, exact: true, component: NotificationMessages },
  {
    subPath: messagesPath + '/:id',
    exact: true,
    component: NotificationMessageDetail,
  },
  { subPath: jobsPath, exact: true, component: NotificationJobs },
  { subPath: jobsPath + '/:id', exact: true, component: NotificationJobDetail },
];

export const NotificationsPage: React.FC<void> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const subPath = history.location.pathname.replaceAll(path, '');
  const selectedPage = currentNavItem(subPath);

  useEffect(() => {
    if (selectedPage < 0) {
      history.push(path + '/dashboard');
    }
  }, [history]);

  const onNavigationClicked = (r: string) => history.push(path + r);

  return (
    <div className={styles.container}>
      <div className={styles.subPage}>
        <Switch>
          {navRoutes.map(({ subPath, exact, component }) => (
            <Route
              key={path}
              exact={exact}
              path={`${path}${subPath}`}
              component={component}
            />
          ))}
        </Switch>
        <Spacer size="xlarge" />
      </div>

      <Paper
        elevation={4}
        variant="outlined"
        className={styles.bottomNavigation}>
        <BottomNavigation showLabels value={selectedPage}>
          {bottomBarItems.map(({ label, subPath, icon }) => (
            <BottomNavigationAction
              key={label}
              label={label}
              icon={icon}
              onClick={() => onNavigationClicked(subPath)}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </div>
  );
};

const currentNavItem = (pathname: string): number => {
  const path = pathname.split('/').filter((r) => !!r);
  if (!path || path.length <= 0) {
    return -1;
  }
  const subPath = '/' + path[0];
  return bottomBarItems.findIndex((r) => r.subPath === subPath);
};
