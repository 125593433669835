import React, { useState } from 'react';
import styles from './Collapsable.module.scss';

export const Collapsable = ({
  children,
  collapsedHeight,
  collapsable,
}: {
  collapsable?: boolean;
  collapsedHeight: number;
  children: React.ReactChildren | React.ReactChild;
}) => {
  const [expanded, setExpanded] = useState(!collapsable);

  return (
    <div className={styles.container}>
      <div
        style={{
          maxHeight: expanded ? undefined : collapsedHeight,
          overflow: 'hidden',
        }}>
        {children}
      </div>
      {collapsable && (
        <div onClick={() => setExpanded((s) => !s)} className={styles.footer}>
          {expanded ? 'Einklappen' : 'Aufklappen'}
        </div>
      )}
    </div>
  );
};
