import { Api } from '../../..';
import FileSaver from 'file-saver';

export const statsApi = {
  addScenario(scenario: Scenario): Promise<{ scenario: Scenario }> {
    return Api.fetch({
      method: 'POST',
      url: `kpi/scenarios`,
      data: { scenario },
    });
  },

  updateScenario(scenario: Scenario): Promise<{ scenario: Scenario }> {
    return Api.fetch({
      method: 'PATCH',
      url: `kpi/scenarios/${scenario.id || ''}`,
      data: { scenario },
    });
  },

  getScenarios(): Promise<{ scenarios: Scenario[] }> {
    return Api.fetch({
      method: 'GET',
      url: `kpi/scenarios`,
    });
  },

  getScenario(id: string): Promise<{ scenario: Scenario }> {
    return Api.fetch({
      method: 'GET',
      url: `kpi/scenarios/${id}`,
    });
  },

  deleteScenario(id: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `kpi/scenarios/${id}`,
    });
  },

  getSettings(): Promise<{ settings: Settings }> {
    return Api.fetch({
      method: 'GET',
      url: `kpi/settings`,
    });
  },

  updateSettings(settings: Settings): Promise<{ settings: Settings }> {
    return Api.fetch({
      method: 'PATCH',
      url: `kpi/settings`,
      data: { settings },
    });
  },

  kpiReport(
    filename: string,
    startDate: Date,
    endDate: Date,
    intervall: string,
    scenarioId: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `kpi/report`,
      responseType: 'blob',
      data: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        intervall,
        scenarioId,
      },
    }).then((response) => {
      FileSaver(response, filename);
    });
  },

  batchImportReport(
    from: string,
    until: string,
  ): Promise<{ summaries: NodeSummary[] }> {
    return Api.fetch({
      method: 'POST',
      url: `statistics/batch-import`,
      data: { from, until },
    });
  },
};

export interface Scenario {
  id: string;
  label?: string;
  legacyMonthlySubmissions?: { [key: string]: number };
  submissionsPerUserAge?: number[];
  retentionPerUserAge?: number[];
  cost?: { [key: string]: CostScenario };
  comment?: string;
}

export interface CostScenario {
  marketing: number;
  cpo: number;
  submissionToSale: number;
  revPerSale: number;
}

export interface Settings {
  actuals?: { [key: string]: ActualValues };
}

export interface ActualValues {
  intervall: string;
  marketing: number;
  fixedCost: number;
  revenue: number;
}

export interface NodeSummary {
  name: string;
  url: string;
  acceptedRate: number;
  total: number;
  accepted: number;
  denied: number;
}
