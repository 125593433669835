import { Button, LinearProgress } from '@material-ui/core';
import { FormContainer } from 'app/components/FormContainer/FormContainer';
import { Input } from 'app/components/Input/Input';
import { Spacer } from 'app/components/Spacer/Spacer';
import { Coupon } from 'app/home/state/userApi';
import { useState } from 'react';
import styles from './CouponCreation.module.scss';
import imgCouponUse from './coupon-use.jpeg';
import imgCouponAccept from './coupon-accept.jpeg';

interface Props {
  createCoupon: (coupon: Coupon) => Promise<string>;
  reloadCoupons: () => void;
}

const emptyCoupon = () => ({
  id: '',
  codes: [''],
  title: '',
  description: '',
  hint: '',
});

export const CouponCreation = ({ createCoupon, reloadCoupons }: Props) => {
  const [coupon, setCoupon] = useState<Coupon>(emptyCoupon());
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const { codes, title, description, hint } = coupon;

  const onCreateCoupon = async () => {
    setLoading(true);
    try {
      const finalCoupon = { ...coupon, codes: codes.filter((code) => !!code) };
      await createCoupon(finalCoupon);
      setCoupon(emptyCoupon());
      setTemp(setResult, 'Der Coupon wurde erstellt.');
      reloadCoupons();
    } catch (e) {
      setTemp(
        setError,
        'Der Coupon konnte nicht erstellt werden mit Fehler: ' + e,
      );
    }
    setLoading(false);
  };

  const setTemp = (fn: (s: string) => void, e: string) => {
    fn(e);
    setTimeout(() => {
      fn('');
    }, 6000);
  };

  const isValid = !!title && !!description && !!hint && !!codes[0];

  return (
    <div>
      <h3>Gutschein erstellen</h3>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Hier kann eine Liste der eingelösten Gutscheine erstellt werden. Bitte
        beachte, dass bei den Enddaten immer das Ende des Tages gilt.
      </div>
      <Spacer size="large" />
      <div className={styles.contentContainer}>
        <div className={styles.inputContainer}>
          <FormContainer>
            <div>Titel</div>
            <Input
              value={coupon.title}
              onChange={(e) => setCoupon({ ...coupon, title: e.target.value })}
              placeholder="Das wird beim Einlösen oben als Titel angezeigt (sollte kurz sein)."
            />
            <div>Beschr.</div>
            <textarea
              value={coupon.description}
              onChange={(e) =>
                setCoupon({ ...coupon, description: e.target.value })
              }
              placeholder="Das wird beim Einlösen unter dem Titel angezeigt (kann länger sein)."
            />
            <div>Hinweis</div>
            <Input
              value={coupon.hint}
              onChange={(e) => setCoupon({ ...coupon, hint: e.target.value })}
              placeholder="Das wird beim Bezahlen in der App unten angezeigt."
            />
            <div>Codes</div>
            <Input
              value={coupon.codes.join(',')}
              onChange={(e) =>
                setCoupon({
                  ...coupon,
                  codes: e.target.value
                    .replaceAll(' ', '')
                    .toUpperCase()
                    .split(','),
                })
              }
              placeholder='Codes zum Einlösen, getrennt durch "," ohne Leerzeichen.'
            />
          </FormContainer>
        </div>
        <div className={styles.preview}>
          <PreviewFrame background={imgCouponAccept}>
            <div className={styles.previewAcceptContainer}>
              <div className={styles.previewTitleHeader}>
                Rabattcode eingelöst
              </div>
              <div className={styles.previewTitle}>{title}</div>
              <Spacer />
              <div className={styles.previewDescription}>{description}</div>
            </div>
          </PreviewFrame>
        </div>
        <div className={styles.preview}>
          <PreviewFrame background={imgCouponUse}>
            <div className={styles.previewAcceptHint}>{hint}</div>
          </PreviewFrame>
        </div>
      </div>

      <Spacer size="large" />

      <div style={{ display: 'flex' }}>
        <Button
          className={styles.createButton}
          variant={'outlined'}
          size="small"
          disabled={loading || !isValid}
          onClick={onCreateCoupon}>
          {!loading ? 'Coupon erstellen' : 'Coupon wird erstellt'}
        </Button>

        <Spacer />

        {loading && <LinearProgress style={{ height: 4 }} />}
      </div>

      <Spacer />

      {!!error && <div style={{ color: 'red' }}>{error}</div>}
      {!!result && <div style={{ color: 'green' }}>{result}</div>}

      <Spacer />
    </div>
  );
};

export const PreviewFrame = ({
  children,
  background,
}: {
  background?: string;
  children?: React.ReactNode;
}) => (
  <div
    className={styles.frame}
    style={{ backgroundImage: `url(${background})` }}>
    <div className={styles.content}>{children}</div>
  </div>
);
