import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { Textsms } from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareBots, mapBotsYear } from '../../common/bot';
import { dateToString, matchesQuery } from '../../common/text';
import {
  BotStatus,
  PhoneConfirmation,
  User,
  userApi,
} from '../../home/state/userApi';
import {
  selectActiveBotId,
  selectActiveId,
  selectProfile,
  selectUserBots,
  setActiveBotId,
  unblockBot,
  userSelectors,
  verifySendSMS,
  verifyUser,
} from '../../home/state/userReducer';
import { BotStatusSelection } from '../BotStatus/BotStatusSelection';
import { Input } from '../Input/Input';
import { ObjectTable } from '../ObjectTable/ObjectTable';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { Spacer } from '../Spacer/Spacer';
import { Table } from '../Table/Table';
import styles from './InfoSidenav.module.scss';

interface Props {
  autorefresh?: boolean;
  toggleAutorefresh: () => void;
  editorId?: string;
  onEditorIdChange?: (v: string) => void;
}

export const InfoSidenav: React.FC<Props> = ({
  autorefresh,
  toggleAutorefresh,
  editorId,
  onEditorIdChange,
}: Props) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [activationLink, setActivationLink] = useState('');
  const bots = useSelector(selectUserBots);
  const payments = useSelector(userSelectors.selectPayments);
  const activeBotId = useSelector(selectActiveBotId);
  const activeUserId = useSelector(selectActiveId);
  const profile = useSelector(selectProfile);

  useEffect(() => {
    setActivationLink('');
  }, [activeUserId]);

  const onBotSelect = (id: string) => () => {
    dispatch(setActiveBotId(id));
  };

  const onBotReset = (id: string) => (ev: React.MouseEvent) => {
    ev.stopPropagation();
    dispatch(unblockBot(id));
  };

  const onBotPay = (id: string) => (ev: React.MouseEvent) => {
    ev.stopPropagation();
    // dispatch(grantPayment(id));
  };

  const onUserLink = () => async (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const { url } = await userApi.linkUser(activeUserId);
    setActivationLink(url);
  };

  const onSupportNumberClicked = (no: string) => () => {
    navigator.clipboard.writeText(phoneSupportMessage(no));
  };

  const handleVerifyUser = async () => {
    await dispatch(verifyUser());
  };

  const handleSendConfirmation = async (
    ev: React.MouseEvent,
    phoneNo: string,
  ) => {
    ev.stopPropagation();
    ev.preventDefault();

    const supportSnippet = `ich habe den SMS Versand an deine Nummer ${phoneNo} gerade manuell angestoßen. Hast du jetzt eine SMS bekommen?\n\nFalls ja kannst du den Code aus der SMS in der App eingeben. Es kann sein, dass du dafür erneut eine SMS "anfordern" musst um zur Eingabe zu kommen. Das ist aber kein Problem, du kannst dort dann den Code aus der SMS eingeben, die du bekommen hast. Wenn das nicht mehr geht, schicke uns über den Support den Code damit wir dich manuell freischalten können.\n\nFalls es wieder nicht geklappt hat, melde dich bitte direkt wieder bei uns.`;
    navigator.clipboard.writeText(supportSnippet);
    await dispatch(verifySendSMS(phoneNo));
  };

  const isVerified =
    profile &&
    profile.phoneConfirmations &&
    profile.phoneConfirmations.find(({ status }) => status === 'confirmed');

  const passwordless = profile && !profile.passwordSet;

  return (
    <div className={styles.container}>
      {!!profile && (
        <>
          <h3>Nutzer</h3>
          <div className={styles.profile}>
            <ObjectTable
              rows={[
                { id: 'email', label: 'Mail' },
                { id: 'state', label: 'Status' },
                { id: 'verification', label: 'Verifikation' },
                {
                  label: 'Added',
                  renderFunc: ({ added }: User) => <>{dateToString(added)}</>,
                },
                { label: 'ID', renderFunc: displayId },
              ]}
              data={profile}
            />
            {!!profile.phoneConfirmations &&
              profile.phoneConfirmations.length > 0 && (
                <>
                  <Table
                    collapsed={true}
                    noHeader={false}
                    data={profile.phoneConfirmations}
                    coloumns={[
                      {
                        label: 'Nummer',
                        renderFunc: (v: PhoneConfirmation) => (
                          <div onClick={onSupportNumberClicked(v.phoneNo)}>
                            <span>{v.phoneNo}</span>

                            {!isVerified && !!v.phoneNo && (
                              <span
                                onClick={(e) =>
                                  handleSendConfirmation(e, v.phoneNo)
                                }>
                                <Textsms style={{ fontSize: 12 }} />
                              </span>
                            )}
                          </div>
                        ),
                      },
                      { label: 'Status', id: 'status' },
                      {
                        label: 'Support',
                        renderFunc: (v: PhoneConfirmation) => (
                          <>
                            {!!v.smsService && <div>{v.smsService}</div>}
                            {v.status === 'pending' && !v.smsService && (
                              <div onClick={onSupportNumberClicked(v.phoneNo)}>
                                <FileCopyIcon style={{ fontSize: 10 }} />
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </>
              )}
          </div>
        </>
      )}

      <h3>Aktionen</h3>

      <div className={styles.activationContainer}>
        <h4>Aktivierungslink / Passwort vergessen</h4>
        {passwordless && (
          <>
            <div
              style={{ fontWeight: 'bold', color: 'red', fontSize: 'small' }}>
              Achtung: Passwortloser Login genutzt
            </div>
            <Spacer />
          </>
        )}
        {!activationLink && (
          <Button variant={'outlined'} onClick={onUserLink()}>
            Erstellen
          </Button>
        )}
        {activationLink && (
          <ShortTextCopy text={activationLink} length={24} fontSize={14} />
        )}
      </div>
      <Spacer />

      {!isVerified && (
        <div className={styles.activationContainer}>
          <h4>Verifizieren</h4>
          <Button variant={'outlined'} onClick={handleVerifyUser}>
            Verifizieren
          </Button>
        </div>
      )}

      <h3>Verhalten</h3>
      <FormControlLabel
        control={
          <Switch checked={!!autorefresh} onChange={toggleAutorefresh} />
        }
        label="Infos automatisch laden"
      />

      <h3>Editor-ID</h3>
      <div className={styles.inputContainer}>
        <Input
          placeholder="Editor-ID"
          value={editorId}
          onChange={(e) =>
            !!onEditorIdChange && onEditorIdChange(e.target.value || '')
          }
        />
      </div>

      <h3>Bots</h3>
      <div className={styles.inputContainer}>
        <Input
          placeholder="ID oder Jahr"
          value={query}
          onChange={(e) => setQuery(e.target.value)}></Input>
      </div>

      {bots
        .map(mapBotsYear)
        .filter(filterBots(query))
        .sort(compareBots)
        .map((s) => {
          return (
            <BotStatusSelection
              key={s.id}
              status={s}
              selected={s.id === activeBotId}
              handleBotClick={onBotSelect(s.id)}
              handleBotReset={onBotReset(s.id)}
              handleBotPay={
                payments.includes(s.id) || s.year === 'Intro'
                  ? undefined
                  : onBotPay(s.id)
              }
            />
          );
        })}
    </div>
  );
};

const filterBots =
  (query: string) =>
  ({ id, year }: BotStatus) =>
    matchesQuery(id, query, true) || matchesQuery(year || '', query, true);

function displayId(v: User): React.ReactElement {
  return <ShortTextCopy fontSize={12} text={v.id} length={10} />;
}

function phoneSupportMessage(no: string): string {
  return `wir haben an deine Nummer (${no}) mehrere SMS versendet und auch eine Bestätigung erhalten. Bist du dir bei der Nummer 100% sicher und kannst sicher darüber SMS erhalten? In machen Fällen scheint es zu helfen kurz in den Flugzeugmodus zu wechseln.\n\nFalls das nicht klappt, kannst du uns ein Foto deiner Lohnbescheinigung schicken um trotzdem digital abgeben zu können.\n\nAlternativ kannst du die Erklärung natürlich immer selbst ausdrucken und per Post einreichen.`;
}
