import { Button, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { EricResult } from '../../components/EricResult/EricResult';
import { ShortTextCopy } from '../../components/ShortTextCopy/ShortTextCopy';
import { Spacer } from '../../components/Spacer/Spacer';
import { EricCheckResponse, InfoApi } from '../../info/state/infoApi';

export const MapperPage: React.FC<void> = () => {
  const [input, setInput] = useState<string>('');
  const [botId, setBotId] = useState<string>(
    'a5817b9d-5061-4cfc-809e-340ed966c4bd',
  );
  const [result, setResult] = useState<{ [key: string]: EricCheckResponse }>(
    {},
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<{ [key: string]: boolean }>({});
  const [onlyFailed, setOnlyFailed] = useState<boolean>(false);

  let parsedInput: string[] | undefined = [];
  try {
    parsedInput = JSON.parse(input);
  } catch (_) {
    parsedInput = undefined;
  }

  const listIsValid = !!parsedInput && parsedInput.length > 0;

  const checkUsers = async () => {
    if (!listIsValid) {
      return;
    }
    setResult({});

    setLoading(true);
    for (const id of parsedInput || []) {
      const r = await InfoApi.ericCheck(id, botId);
      setResult((s) => {
        return { ...s, [id]: r };
      });
    }

    setLoading(false);
  };

  const checkId = (id: string) => {
    const r = result[id];
    if (!r) {
      return '⏳';
    }
    return r?.ericResult?.success ? '🟢' : '🔴';
  };

  const toggleVisible = (id: string) => {
    setVisible((s) => {
      return { ...s, [id]: !s[id] };
    });
  };

  const emptyResults = Object.keys(result).length <= 0;

  const filterResults = (id: string) => !onlyFailed || checkId(id) !== '🟢';

  return (
    <div
      style={{
        display: 'block',
        width: '100%',
        height: '100%',
      }}>
      <h1>Nutzer Überprüfung</h1>
      <Divider />
      <Spacer size="large" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          padding: '20px 20px 0 20px',
          boxSizing: 'border-box',
        }}>
        <div style={{ flex: 'none' }}>
          <h2>Nutzerliste</h2>
          <textarea
            placeholder='Nutzerliste hinzufügen: ["id1", "id2"]'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={{ flex: 'none', width: 300, fontSize: 'small', height: 500 }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 'none',
          }}>
          <Spacer size="large" />
          <Divider orientation="vertical" flexItem />
          <Spacer size="large" />
        </div>
        <div style={{ flex: 'none' }}>
          <h2>Bot</h2>
          <div>{`1. Liste ist valide ${listIsValid ? '✅' : '🔴'}`}</div>
          <Spacer />
          <div>
            2. BotID:{' '}
            <input
              value={botId || ''}
              disabled={!listIsValid}
              style={{ fontSize: 'small', width: 250 }}
              onChange={(e) => setBotId(e.target.value)}
            />
          </div>
          <Spacer />
          <div>
            3.{' '}
            <Button
              variant="outlined"
              size="small"
              disabled={!botId}
              onClick={checkUsers}>
              Überprüfung starten
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 'none',
          }}>
          <Spacer size="large" />
          <Divider orientation="vertical" flexItem />
          <Spacer size="large" />
        </div>
        <div style={{ flex: 1, display: 'block' }}>
          <h2>Ergebnis</h2>
          {emptyResults && !loading && <div>Noch keine Daten</div>}
          {(!emptyResults || loading) && (
            <>
              <Button
                onClick={() => setOnlyFailed((s) => !s)}
                variant="outlined"
                size="small">
                {onlyFailed ? 'Zeige alle' : 'Zeige nur fehlgeschlagen'}
              </Button>
              <Spacer />

              <div style={{ fontSize: 'small' }}>
                {parsedInput?.filter(filterResults).map((id, idx) => (
                  <div key={idx}>
                    <div>
                      <span style={{ display: 'inline-block', width: 30 }}>{`${
                        idx + 1
                      }.`}</span>
                      <span
                        style={{
                          display: 'inline-block',
                          width: 300,
                        }}>
                        <ShortTextCopy text={id} fontSize={12} />
                      </span>
                      <span
                        style={{
                          userSelect: 'none',
                        }}
                        onClick={() => toggleVisible(id)}>
                        {checkId(id)}
                      </span>
                    </div>
                    {!!visible[id] && (
                      <div>
                        {!result[id] && <pre>Noch kein Ergebnis</pre>}
                        {!!result[id] && <EricResult eric={result[id]} />}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <Spacer size="xxxlarge" />
              <Spacer size="xxxlarge" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
