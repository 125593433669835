/* eslint-disable prettier/prettier */
import {
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import user from './home/state/userReducer';
import info from './info/state/infoReducer';
import settings from './settings/state/settingsReducer';
import notifications from './notifications/state/notificationsReducer';
import shop from './shop/state/shopReducer';

const rootReducer = combineReducers({ user, settings, shop, info, notifications });

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({ reducer: rootReducer });

export type AsyncAction<R = any> = ThunkAction<
  Promise<R>,
  RootState,
  undefined,
  AnyAction
>;

type BuildFunction<Parameters, Result> = (...Parameters) => Result;
export type IgnoreMiddleware<F extends (...args) => AsyncAction> =
  BuildFunction<Parameters<F>, ReturnType<ReturnType<F>>>;

export type MapDispatch<Type extends { [key: string]: any }> = {
  [key in keyof Type]: IgnoreMiddleware<Type[key]>;
};
