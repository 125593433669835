import React from 'react';
import styles from './Input.module.scss';
import { all } from '../../common/style';

interface CustomProps {
  minimal?: boolean;
  variant?: 'title' | 'minimal';
}

type Props = React.InputHTMLAttributes<any> & CustomProps;

export const Input = (v: Props) => {
  const { className, minimal, variant, ...other } = v;
  return (
    <input
      autoComplete="off"
      className={all(
        styles.input,
        className || '',
        minimal || variant === 'minimal' ? styles.small : '',
        variant === 'title' ? styles.title : '',
      )}
      {...other}
    />
  );
};
