export const Divider = () => {
  return (
    <div
      style={{
        flex: 'none',
        width: '100%',
        height: 1,
        background: 'lightgray',
      }}></div>
  );
};
