import { BotStatus } from '../home/state/userApi';

const botIdYearMap: { [key: string]: string } = {
  'a5817b9d-5061-4cfc-809e-340ed966c4bd': '2022',
  '237f6193-f4e9-4258-b0f5-1f428e63013a': '2021',
  '732370ac-b0ff-4e9f-a74d-ed261d390c3a': '2020',
  '8e0ea668-4637-4c55-bbfe-88b938d67a34': '2019',
  '0fb373d3-8ed3-4728-8ae6-5ab7fb827b12': '2018',
  '71a87caa-d36e-4f07-b5aa-3e6811aaac0e': '2017',
  '89e5ef89-fa1e-40c0-9c0c-66bc828c7068': '2016',
  'e5f806de-ae7d-4ceb-82d3-aef40e616680': '2015',
  '8304363c-c1ee-41cb-b7ec-99527e4cb4a1': 'Intro',
};

const botOrder = Object.keys(botIdYearMap);

export const botIdToYear = (id: string, defaultValue = '') => {
  const y = botIdYearMap[id];
  return y || defaultValue;
};

export const yearToBotId = (y: string) => {
  const id = Object.keys(botIdYearMap).find((id) => botIdYearMap[id] === y);
  return id || '';
};

export function arrayToObject<T>(
  arr: T[],
  idxFn: (a: T) => string,
): { obj: { [key: string]: T }; ids: string[] } {
  if (!arr || arr.length <= 0) {
    return { obj: {}, ids: [] };
  }
  const obj = arr.reduce((p, c) => ({ ...p, [idxFn(c)]: c }), {});
  const ids = arr.map(idxFn);
  return { obj, ids };
}

export const sortBots = (bots: BotStatus[]) => {
  const order = Object.keys(botIdYearMap);
  return [...bots].sort(({ id: a }, { id: b }) => {
    const ai = order.indexOf(a);
    const bi = order.indexOf(b);
    if (ai < 0) return 1;
    if (bi < 0) return -1;
    if (ai === bi) return 0;
    return ai < bi ? -1 : 1;
  });
};

export const compareBots = ({ id: a }: BotStatus, { id: b }: BotStatus) => {
  const ai = botOrder.indexOf(a);
  const bi = botOrder.indexOf(b);
  if (ai < 0) return 1;
  if (bi < 0) return -1;
  if (ai === bi) return 0;
  return ai < bi ? -1 : 1;
};

export const mapBotsYear = (s: BotStatus): BotStatus => {
  const year = botIdToYear(s.id, '');
  if (!year) return s;
  return { ...s, year };
};
