import React, { memo, useEffect, useState } from 'react';
import { compareBots, mapBotsYear } from '../../common/bot';
import { dateToString } from '../../common/text';
import { BotStatus, User } from '../../home/state/userApi';
import { InfoApi, Information } from '../../info/state/infoApi';
import { deliveryLabel } from '../BotStatus/BotStatusSelection';
import { SingleFaxViewer } from '../FaxViewer/FaxViewer';
import { InfoOverview } from '../InfoOverview/InfoOverview';
import { ObjectTable, TableRow } from '../ObjectTable/ObjectTable';
import styles from './AssessmentYearsOverview.module.scss';

interface Props {
  profile: User;
}

export const AssessmentYearsOverview = memo(({ profile }: Props) => {
  const { id: userId } = profile;
  const [infos, setInfos] = useState<{ [key: string]: Information[] }>({});
  const [assessments, setAssessments] = useState<{ [key: string]: boolean }>(
    {},
  );
  const bots = filteredBots(profile);

  useEffect(() => {
    const loadData = async () => {
      const bb = botIds(filteredBots(profile));

      if (!bb) {
        return;
      }

      for (const bid of bb) {
        const { data } = await InfoApi.fetchInfo([], profile.id, bid);
        setInfos((s) => ({ ...s, [bid]: data }));
        try {
          const { rows } = await InfoApi.fetchAssessment(profile.id, bid);
          setAssessments((s) => ({ ...s, [bid]: !!rows }));
        } catch {}
      }
    };
    loadData();
  }, [profile]);

  return (
    <div className={styles.container}>
      {bots.map((s) => (
        <BotStatusOverview
          key={s.id}
          bot={s}
          userId={userId}
          infos={infos[s.id] || []}
          assessment={assessments[s.id]}
        />
      ))}
    </div>
  );
});

const BotStatusOverview = memo(
  ({
    bot,
    userId,
    infos,
    assessment,
  }: {
    bot: BotStatus;
    userId: string;
    infos: Information[];
    assessment?: boolean;
  }) => {
    return (
      <div className={styles.overviewContainer}>
        <BotStatusOverviewHeader bot={bot} assessment={assessment} />
        {!!bot.faxId && (
          <div className={styles.faxContainer}>
            <SingleFaxViewer botStatus={bot} userId={userId} />
            <span>(Fax)</span>
          </div>
        )}
        <div className={styles.overviewInfos}>
          <InfoOverview ii={infos} />
        </div>
      </div>
    );
  },
);

const BotStatusOverviewHeader = ({
  bot,
  assessment,
}: {
  bot: BotStatus;
  assessment?: boolean;
}) => {
  const { year, refund, submitDate } = bot;
  const label = deliveryLabel(bot);
  const rows: TableRow[] = [
    {
      label: 'Status',
      renderFunc: () => <div>{label}</div>,
    },
    {
      label: 'Rückerstattung',
      renderFunc: () => <div>{refund}</div>,
    },
    {
      label: 'Abgabe',
      renderFunc: () => <div>{dateToString(submitDate)}</div>,
    },
    {
      label: 'Bescheid',
      renderFunc: () => <div>{assessment ? 'JA' : 'NEIN'}</div>,
    },
  ];

  return (
    <div className={styles.overviewHeader}>
      <h4 className={styles.year}>{year}</h4>
      <h4 className={styles.year}>{year}</h4>
      <ObjectTable rows={rows} data={bot} />
    </div>
  );
};

function filteredBots({ graphStatus }: User) {
  return graphStatus
    .sort(compareBots)
    .map(mapBotsYear)
    .filter(({ year }) => !!year && year !== 'Intro');
}

function botIds(bb: BotStatus[]) {
  return bb.map(({ id }) => id);
}
