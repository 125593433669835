import { Button, CircularProgress, colors } from '@material-ui/core';
import React, { useState } from 'react';
import { all } from '../../../../common/style';
import { dateToString, euro } from '../../../../common/text';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { Table, TableCol } from '../../../../components/Table/Table';
import { TroyCase } from '../../../../home/state/userApi';
import styles from './TroySync.module.scss';

interface Props {
  cases: TroyCase[];
  valid?: boolean;
  synced?: boolean;
  loading?: boolean;
  error?: string;
  onAddCSV: (csv: string) => void;
  onResetCSV: () => void;
  onSync: () => void;
}

export const TroySync = ({
  cases,
  valid,
  synced,
  loading,
  error,
  onAddCSV,
  onResetCSV,
  onSync,
}: Props) => {
  const [draggedOverCSV, setDraggedOverCSV] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = 'copy';
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDraggedOverCSV(true);
  };

  const handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDraggedOverCSV(false);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files || e.dataTransfer.files.length <= 0) {
      return;
    }
    const file = e.dataTransfer.files[0];
    e.dataTransfer.clearData();
    const reader = new FileReader();

    reader.onerror = () => {
      e?.dataTransfer?.clearData();
      setDraggedOverCSV(false);
    };

    reader.onload = function (event) {
      const res = event.target?.result?.toString();
      if (!!res && typeof res === 'string') {
        onAddCSV(res);
      }
      e?.dataTransfer?.clearData();
      setDraggedOverCSV(false);
    };

    reader.readAsText(file);
  };

  const cols: TableCol[] = [
    { label: 'AZ', id: 'az', width: 100 },
    { label: 'User-ID', id: 'userId', width: 300 },
    {
      label: 'HF (Steuerbot)',
      renderFunc: (v) => `${euro(v.amount)} €`,
      width: 100,
    },
    {
      label: 'Bezahlt',
      renderFunc: (v) => `${euro(v.collectedAmount)} €`,
      width: 100,
    },
    {
      label: 'Saldo',
      renderFunc: (v) => `${euro(v.openAmount)} €`,
      width: 100,
    },
    {
      label: 'HF (Troy)',
      renderFunc: (v) => `${euro(v.takenAmount)} €`,
      width: 100,
    },
    {
      label: 'Synchronisiert',
      renderFunc: (v) => (!!v.synced ? '✅' : '❌'),
      width: 110,
    },
    { label: 'Schließungsgrund', id: 'reason', width: 250 },
    {
      label: 'Weitergabedatum',
      renderFunc: ({ passDate }) =>
        passDate ? dateToString(passDate, true) : '–',
      width: 140,
    },
    {
      label: 'Abschlussdatum',
      renderFunc: ({ closeDate }) =>
        closeDate ? dateToString(closeDate, true) : '–',
      width: 140,
    },
    {
      label: 'Bestell-Nr.',
      renderFunc: (v) => v.orderNumbers?.join(', ') || '–',
    },
    { label: 'Fehler', renderFunc: (v) => v.error || '–' },
  ];

  const isReady = cases.length > 0;
  const openCases = cases.filter((r) => !r.error && !r.closeDate);
  const closedCases = cases.filter((r) => !r.error && !!r.closeDate);
  const failedCases = cases.filter((r) => !!r.error);
  const shownCases = showAll ? closedCases : closedCases.slice(0, 10);

  const totalAmount = closedCases.reduce((p, c) => (p += c.amount), 0);

  const collectedAmount = closedCases.reduce(
    (p, c) => (p += c.collectedAmount),
    0,
  );

  const openAmount = closedCases.reduce((p, c) => (p += c.openAmount), 0);

  const summary = closedCases.reduce((p, c) => {
    const pr = p[c.reason] ?? 0;
    return { ...p, [c.reason]: pr + 1 };
  }, {});

  const buttonText = !!loading
    ? 'Wird synchronisiert'
    : synced
    ? 'Synchronisation erfolgreich 🕺'
    : 'Synchronisation starten';

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3>Troy Synchronisation</h3>
      </div>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Bei Troy geschlossene Fälle können hier mit unseren Daten abgeglichen
        werden.
      </div>
      <Spacer size="large" />

      {!!error && (
        <>
          <b className={styles.error} style={{ color: colors.red['800'] }}>
            {error}
          </b>
          <Spacer size="large" />
        </>
      )}

      <div className={styles.addContainer}>
        {!isReady && (
          <div
            onDrop={handleOnDrop}
            onDragOver={handleOnDragOver}
            onDragEnter={handleOnDragEnter}
            onDragLeave={handleOnDragLeave}
            className={all(
              styles.dropContainer,
              draggedOverCSV ? styles.draggedOver : '',
            )}>
            {loading
              ? 'Die csv-Datei wird eingelesen'
              : 'Ziehe die Troy ClearingClosings csv-Datei in das Feld'}
            {loading && !valid && (
              <CircularProgress size={20} style={{ marginLeft: 8 }} />
            )}
          </div>
        )}
        {!!isReady && (
          <div
            className={all(styles.dropContainer, styles.dropContainerFull)}
            onClick={onResetCSV}>
            ✅ csv-Datei konnte erfolgreich geladen werden
          </div>
        )}
      </div>

      <Spacer size="large" />

      {shownCases.length > 0 && (
        <>
          <h3>
            Geschlossene Fälle{' '}
            <span
              style={{ textDecoration: 'underline' }}
              onClick={() => setShowAll((s) => !s)}>
              {!showAll && closedCases.length > shownCases.length
                ? `(${closedCases.length} – mehr anzeigen)`
                : `(${closedCases.length} – weniger anzeigen)`}
            </span>
          </h3>
          <Spacer size="small" />
          <Table coloumns={cols} data={shownCases} emptyText="–" />
          <Spacer size="large" />
        </>
      )}

      {failedCases.length > 0 && (
        <>
          <h3>Nicht automatisch synchronisierbar ({failedCases.length})</h3>
          <Spacer size="small" />
          <Table coloumns={cols} data={failedCases} />
          <Spacer size="large" />
        </>
      )}

      {!!isReady && !!summary && (
        <div className={styles.summaryContainer}>
          <div className={styles.summaryItem}>
            <div>{euro(totalAmount)} €</div>
            <div>Geschlossen Gesamt</div>
          </div>
          <div className={styles.summaryItem}>
            <div style={{ color: 'green' }}>{euro(collectedAmount)} €</div>
            <div>Geschlossen mit Bezahlung</div>
          </div>
          <div className={styles.summaryItem}>
            <div style={{ color: 'red' }}>{euro(openAmount)} €</div>
            <div>Geschlossen ohne Bezahlung</div>
          </div>
          <div className={styles.summaryItem}>
            <div>{closedCases.length}</div>
            <div>Geschlossene Fälle insgesamt</div>
          </div>
          <div className={styles.summaryItem}>
            <div>{openCases.length}</div>
            <div>Offene Fälle</div>
          </div>
          <div className={styles.summaryItem}>
            <div>{cases.length}</div>
            <div>Fälle Gesamt</div>
          </div>
          {Object.keys(summary).map((k) => (
            <div key={k} className={styles.summaryItem}>
              <div>{summary[k]}</div>
              <div>{k}</div>
            </div>
          ))}
        </div>
      )}

      {isReady && (
        <>
          <Spacer size="large" />
          <Button
            variant={'outlined'}
            size="small"
            onClick={onSync}
            disabled={!valid || !!synced || loading}>
            {buttonText}
            {loading && valid && (
              <CircularProgress size={20} style={{ marginLeft: 8 }} />
            )}
          </Button>
          <Spacer size="large" />
        </>
      )}
    </div>
  );
};
