import { Difference } from '../../info/state/infoReducer';

export const initialKeywords = [
  'Verpflegung',
  '32b',
  'Arbeitsmittel',
  'Hinterbliebenen-Pauschbetrag',
  'Wege zwischen Wohnung und erster Tätigkeitsstätte',
];

// prettier-ignore
export const initialDiff: Difference[] = [
    // Info Data
    { description: 'Bruttolohn S', assessmentId: '0650000163', otherId: '0200201|S' },
    { description: 'Bruttolohn P', assessmentId: '0650000164', otherId: '0200201|P' },
    { description: 'KV S', assessmentId: '0690000400', otherId: '2001203|S' },
    { description: 'KV P', assessmentId: '0690000403', otherId: '2001203|P' },
    { description: 'PV S', assessmentId: '0690000402', otherId: '2001505|S' },
    { description: 'PV P', assessmentId: '0690000405', otherId: '2001505|P' },
  
    // Basic Data
    { description: 'Lohnsteuer Abzug', assessmentId: '0650000650', otherId: 'BeschLSt' },
    { description: 'KiSt Abzug', assessmentId: '0660000106', otherId: 'BeschKiSt' },
    { description: 'Soli Abzug', assessmentId: '0660000976', otherId: 'BeschSoli' },
    { description: 'Progressions-Eink.', assessmentId: '0650000615', otherId: 'ProgressionsEinkuenfte' },
    
    // Income
    { description: 'WK S', assessmentId: '0650000172', otherId: 'AnpS' },
    { description: 'WK P', assessmentId: '0650000173', otherId: 'AnpP' },
    { description: 'Einkünfte S', assessmentId: '0650000331', otherId: 'EinkuenfteANS' },
    { description: 'Einkünfte P', assessmentId: '0650000332', otherId: 'EinkuenfteANP' },
    // { description: 'Einkünfte Gesamt', assessmentId: '$0650000331 + $0650000332', otherId: 'EinkuenfteG' },
    { description: 'Altersaufw. Summe', assessmentId: '0650000638', otherId: 'AltersaufwSumme' },
    { description: 'Altersaufw. Abziehbar', assessmentId: '0650000643', otherId: 'AltersaufwAbziehbar' },
    { description: 'Altersaufw. Verbleibend', assessmentId: '0650000645', otherId: 'AltersaufwRVVerbl' },
    { description: 'KV Basis', assessmentId: '0690000400', otherId: 'BasisKVBeitraege' },
    { description: 'KV Kürzung', assessmentId: '0690000417', otherId: 'BasisKVKuerzungsbetrag' },
    { description: 'KV Verbl.', assessmentId: '0690000426', otherId: 'BasisKVVerbl' },
    { description: 'PV Beiträge', assessmentId: '0690000402', otherId: 'PVBeitraege' },
    { description: 'KVPV Abziehbar', assessmentId: '0690000430', otherId: 'KPVSumme' },
    { description: 'Summe Sonderausgaben', assessmentId: '0650000458', otherId: 'RKPVSumme' },
    { description: 'Sonderausgaben Pausch', assessmentId: '0650000400', otherId: 'SonderausgabenUnbeschr' },
    { description: 'Einkommen', assessmentId: '0650000500', otherId: 'Einkommen' },
    { description: 'Entl. alleinerz.', assessmentId: '0650000319', otherId: 'KinderEntlastungsbetragAlleinErz' },
    { description: 'ZVE', assessmentId: '0650000522', otherId: 'Zve' },
    
    // Result
    { description: 'Lohnsteuer Festgesetzt', assessmentId: '0650000613', otherId: 'LSt' },
    { description: 'KiSt Festgesetzt', assessmentId: '0660000104', otherId: 'KiSt' },
    { description: 'Soli Festgesetzt', assessmentId: '0660000975', otherId: 'Soli' },
    { description: 'Rückerstattung', assessmentId: '0680000850', otherId: 'Refund'},
  ];

export const initialAssessmentTable = [
  ['###Festsetzung', 'EinkSt', 'KiSt', 'Soli'],
  ['Festgesetzt', '$0650000613', '$0660000104', '$0660000975'],
  ['Abzug vom Lohn', '$0650000650', '$0660000106', '$0660000976'],
  ['Verbleibend', '$0650000654', '$0660000108', '$0660000978'],
  ['####Erst./Nachzahlung', '$0680000850'],

  ['###Besteuerungsgrundlagen'],
  ['####Einkünfte aus nichtselbst.'],
  ['', 'Ehemann', 'Ehefrau', 'Insgesamt'],
  ['Bruttoarbeitslohn', '$0650000163', '$0650000164'],
  ['Fahrten', '$0650000194', '$0650000195'],
  ['Fahrtkostenersatz', '$0670000181', '$0670000182'],
  ['Berufsverbände', '$0650000198'],
  ['Arbeitsmittel', '$0650000200', '$0650000201'],
  ['Übrige WK', '$0650000188', '$0650000189'],
  ['Versorgungsfreibetrag', '$0650000167', '$0650000168'],
  ['Werbungskosten-Pauschbetrag zu Renten', '$0650000278', '$0650000279'],
  ['Mindestens', '$0650000172', '$0650000173'],
  ['####Einkünfte', '$0650000161', '$0650000162'],
  ['####Sonstige Einkünfte'],
  ['Leibrente', '$0690000520', '$0690000521'],
  ['steuerfr. Rente', '$0690000530', '$0690000531'],
  ['steuerpfl. Rente', '$0690000540', '$0690000541'],
  ['ab WK-Pauschbetrag', '$0650000277', '$0650000278'],
  ['####Sonstige Einkünfte', '$0650000261', '$0650000262'],
  [
    '####Summe Einkünfte',
    '$0650000310',
    '$0650000311',
    '$0650000310 + $0650000311',
  ],
  ['Entlastungsbetrag alleinerz.', '$0650000319'],
  [
    '####Gesamtbetrag Einkünfte',
    '$0650000331',
    '$0650000332',
    '$0650000331 + $0650000332',
  ],
  [''],
  [''],
  [''],
  [''],
  ['###Sonderausgaben'],
  ['Summe Altersvorsorge', '$0650000638'],
  ['Davon $0650000642%', '$0650000643'],
  ['abzüglich AG', '$0650000644'],
  ['verbleiben', '$0650000645', '$0650000645'],
  [''],
  [''],
  ['KV', '$0690000419', '$0690000423'],
  ['####Beiträge KV'],
  [' - Ehemann', '$0690000400'],
  [' - Ehefrau', '$0690000403'],
  ['Summe KV-Beiträge', '$0690000414'],
  ['ab Kürzungsbetrag nach § 10', '$0690000417'],
  ['Gesamtbeiträge zur Basiskrankenversicherung', '$0690000426'],
  [''],
  [''],
  ['####Beiträge PV'],
  [' - Ehemann', '$0690000402'],
  [' - Ehefrau', '$0690000405'],
  ['Summe PV-Beiträge', '$0690000415', '$0690000432'],

  ['Summe Beiträge § 10', '$0690000430'],
  // ['Beitragrückerstattung', '$0690000421'],
  ['Beitragrückerstattung', '$0690000416'],
  [''],
  [''],
  ['verbleiben', '$0690000431'],
  ['zzgl. übrige Vorsorgeaufw.', '$0650000646'],
  [''],
  [''],
  ['Summe', '$0690000431 + $0650000646'],
  ['davon abziehbar', '', '$0650000647'],
  [''],
  [''],
  ['Summe der abziehbaren Sonderausgaben', '', '$0650000458'],
  ['Sonderausgaben-Pauschbetrag', '', '$0650000400'],
  [''],
  ['Kinderbetreuungskosten', '$0650000399'],
  ['unbeschränkt abzugsfähige', '$0650000413'],
  [''],
  [''],
  ['####Einkommen', '', '', '$0650000500'],
  ['####zu versteuerndes Einkommen', '', '', '$0650000522'],
  ['zu versteuerndes Einkommen nach Tabelle', '', '', '$0650000530'],
  [''],
  [''],
  ['###Berechnung der Einkommenssteuer'],
  ['Steuer nach Tabelle', '$0650000551'],
  ['Prozentsatz Progressionsvorbehalt', '$0650000552'],
  ['Grundtarif (= 1) oder Splittingtarif (= 2)', '$0650000553'],
  ['tarifliche Einkommensteuer', '$0650000584'],
  ['Steuer nach Abzug der Ermäßigungen', '$0650000611'],
  [''],
  [''],
  ['###Berechnung der Kirchensteuer'],
  ['Summe der Kinderfreibeträge', '$0660000998'],
  ['zu versteuerndes Einkommen', '$0650000882'],
  ['Korrektur durch Halbeink', '$0650000883'],
  [''],
  [''],
  ['###Berechnung des Solidaritätszuschlags'],
  ['####Einkommensteuer', '', '', '$0650000613'],
  ['####Bemessungsgrundlage', '', '', '?$0650000613?'],
  ['davon $0660000979', '', '', '$0660000961'],
  ['höchstens 20% von ...', '', '', '$0660000962'],
  [''],
  [''],
  ['###Arbeitnehmer Sparzulage'],
  ['####Ehemann'],
  ['gez. vermögenswirks. Leist.', '$0660000726'],
  ['festgesetzte Sparzul.', '$0660000515'],

  ['###Sonstiges'],
  ['Progressionsvorbehalt', '$0650000615'],
];
