import { Button, CircularProgress, colors } from '@material-ui/core';
import { ShortTextCopy } from 'app/components/ShortTextCopy/ShortTextCopy';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { botIdToYear } from '../../../common/bot';
import { all } from '../../../common/style';
import { dateToString, dateToStringPattern, euro } from '../../../common/text';
import { CompactBankAccount } from '../../../components/CompactBankAccount/CompactBankAccount';
import { InfoBox } from '../../../components/InfoBox/InfoBox';
import { Input } from '../../../components/Input/Input';
import { Spacer } from '../../../components/Spacer/Spacer';
import { Table, TableCol } from '../../../components/Table/Table';
import { userActions } from '../../../home/state/userActions';
import {
  HasPurchaseResponse,
  Payment,
  PriceDiscount,
} from '../../../home/state/userApi';
import {
  selectActiveId,
  selectBankInformation,
  selectProfile,
  setActiveBotId,
} from '../../../home/state/userReducer';
import { MapDispatch, RootState } from '../../../store';
import styles from './PurchasePage.module.scss';

const mapStateToProps = (state: RootState) => ({
  uid: selectActiveId(state),
  bankInformation: selectBankInformation(state),
  profile: selectProfile(state),
});

const mapDispatchToProps = {
  cancelPayment: userActions.cancelPayment,
  delayPayment: userActions.delayPayment,
  grantPayment: userActions.grantPayment,
  delayPaymentReminder: userActions.delayPaymentReminder,
  hasPurchased: userActions.hasPurchase,
  setActiveBankAccount: userActions.setActiveBankAccount,
  restartPayment: userActions.restartPayment,
  refundPayment: userActions.refundPayment,
  resetPayLaterPayment: userActions.resetPayLaterPayment,
  finalizePayLaterPayment: userActions.finalizePayLaterPayment,
  fetchBankInformation: userActions.fetchBankInformation,
  createOrderInvoice: userActions.createOrderInvoice,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

export const RawPurchasePage: React.FC<Props> = ({
  bankInformation,
  profile,
  cancelPayment,
  delayPayment,
  grantPayment,
  delayPaymentReminder,
  hasPurchased,
  setActiveBankAccount,
  restartPayment,
  refundPayment,
  resetPayLaterPayment,
  finalizePayLaterPayment,
  fetchBankInformation,
  createOrderInvoice,
}: Props) => {
  const { bid } = useParams<{ bid: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<HasPurchaseResponse | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [date, setDate] = useState<string>(dateToInputString(new Date()));
  const [comment, setComment] = useState<string>('');
  const [skipRefund, setSkipRefund] = useState<boolean>(false);
  const [dateDelayReminder, setDateDelayReminder] = useState<string>(
    dateToInputString(new Date()),
  );
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceError, setInvoiceError] = useState<string>('');
  const [finalizeRefund, setFinalizeRefund] =
    useState<number | undefined>(undefined);

  useEffect(() => {
    dispatch(setActiveBotId(bid));
  }, [dispatch, bid]);

  const loadInfos = useCallback(async () => {
    if (!bid) {
      return;
    }
    setLoading(true);
    const r = await hasPurchased(bid);
    setResponse(r);
    setSkipRefund(!r.refundable);

    await fetchBankInformation().catch(console.log);
    setLoading(false);
  }, [bid, hasPurchased, fetchBankInformation, setSkipRefund]);

  useEffect(() => {
    loadInfos();
  }, [loadInfos]);

  const onBotPay = (id: string) => async (ev: React.MouseEvent) => {
    if (!comment) {
      setErrors(['Bitte gib einen Kommentar ein.']);
      setTimeout(() => setErrors([]), 2000);
      return;
    }
    ev.stopPropagation();
    await grantPayment(id);
    loadInfos();
    setComment('');
  };

  const renderContent = (): React.ReactElement | null => {
    if (loading) {
      return null;
    }

    if (!response) {
      return <div>Informationen konnten nicht geladen werden.</div>;
    }

    const { hasPurchase, purchaseInformation } = response;
    if (!hasPurchase) {
      return (
        <>
          <div className={styles.divider} />
          <h3>Bezahlstatus</h3>
          <Spacer />
          <div className={styles.statusContainer}>
            <div>
              <span>Der Nutzer hat noch nicht bezahlt – </span>
              <b style={{ color: colors.red['800'] }}>nicht bezahlt</b>
            </div>
          </div>
        </>
      );
    }

    if (!purchaseInformation) {
      return (
        <>
          <div className={styles.divider} />
          <h3>Bezahlstatus</h3>
          <Spacer />
          <div className={styles.statusContainer}>
            <div>
              <span>Fehler beim Laden der Daten – </span>
              <b style={{ color: colors.green['800'] }}>bezahlt</b>
            </div>
            <div>
              Hat bezahlt aber Informationen konnten nicht geladen werden.
            </div>
          </div>
        </>
      );
    }

    const {
      status,
      failed,
      refunded,
      orderPayments,
      cancelable,
      delayable,
      restartable,
      refundable,
      refundMethod,
      reminderDelayable,
      nextReminder,
      payLaterResettable,
      payLaterFinalizable,
    } = response;

    const { id, identifier, provider, timestamp, priceInformation } =
      purchaseInformation;

    const onCancel = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        const { errors } = await cancelPayment(id, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
      } catch (err: any) {
        setErrors([
          err ? err.message : 'Die Bezahlung konnte nicht abgebrochen werden',
        ]);
      }
    };

    const onDelay = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        const d = new Date(date);
        const dateString = dateToGerString(d);
        await delayPayment(id, dateString, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
        navigator.clipboard.writeText(delaySupportText(dateString));
      } catch (err) {
        setErrors(['Die Bezahlung konnte nicht verzögert werden']);
      }
    };

    const onDelayPaymentReminder = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        const d = new Date(dateDelayReminder);
        const dateString = dateToGerString(d);
        await delayPaymentReminder(id, dateString, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
        navigator.clipboard.writeText(delayReminderSupportText(dateString));
      } catch (err) {
        setErrors(['Die Zahlungserinnerung konnte nicht verzögert werden']);
      }
    };

    const onRestart = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        await restartPayment(id, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
      } catch (err) {
        setErrors(['Die Bezahlung konnte nicht neu gestartet werden']);
      }
    };

    const onRefund = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        await refundPayment(id, refundMethod, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
      } catch (err) {
        setErrors(['Die Bezahlung konnte nicht erstattet werden']);
      }
    };

    const onPayLaterReset = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        await resetPayLaterPayment(id, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
        navigator.clipboard.writeText(resetPayLaterSupportSnippet);
      } catch (err) {
        setErrors(['Die Bezahlung konnte nicht reduziert werden']);
      }
    };

    const onPayLaterFinalize = async () => {
      if (!comment) {
        setErrors(['Bitte gib einen Kommentar ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      if (finalizeRefund === undefined) {
        setErrors(['Bitte gib eine Bescheid-Erstattung ein.']);
        setTimeout(() => setErrors([]), 2000);
        return;
      }
      try {
        await finalizePayLaterPayment(id, finalizeRefund, comment);
        setErrors(errors || []);
        loadInfos();
        setComment('');
        navigator.clipboard.writeText(finalizePayLaterSupportSnippet);
      } catch (err) {
        setErrors(['Die Bezahlung konnte nicht finalisiert werden']);
      }
    };

    const onCreateInvoice = async (ev: React.MouseEvent) => {
      if (invoiceLoading) {
        return;
      }
      setInvoiceLoading(true);
      const filename = `${dateToStringPattern(
        timestamp,
        'YYYYMMDD',
      )}_Steuererklärung_${year}_${identifier}.pdf`;
      try {
        await createOrderInvoice(identifier, filename);
        setInvoiceError('');
      } catch (err: any) {
        setInvoiceError('Die Rechnung konnte nicht erstellt werden.');
      }
      setInvoiceLoading(false);
    };

    const actionsAvailable =
      provider === 'GoCardless' || provider === 'PayLater';
    const readableRefundMethod = readableRefundMethodMap[refundMethod];
    const isVividOrder = identifier.startsWith('PV');

    return (
      <>
        <div className={styles.divider} />
        <h3>Bezahlstatus</h3>
        <Spacer />

        <div className={styles.statusContainer}>
          <div>
            <b>Rechnungsnummer {identifier} – </b>
            <b
              style={{
                color: failed ? colors.red['800'] : colors.green['800'],
              }}>
              {status}
            </b>
          </div>
          <div style={{ fontSize: 'small' }}>{`Am ${dateToString(
            timestamp,
            true,
          )} mit ${provider}`}</div>

          {!!refunded && (
            <b style={{ fontSize: 'small', color: colors.red['800'] }}>
              Achtung: Die Bezahlung wurde bereits erstattet. Siehe GoCardless
              für Details.
            </b>
          )}

          <div
            style={{
              fontSize: 'small',
              textDecoration: 'underline',
              cursor: 'default',
            }}
            onClick={onCreateInvoice}>
            Rechnung herunterladen{' '}
            {!!invoiceLoading && <CircularProgress size={14} />}
          </div>

          {!!invoiceError && (
            <div style={{ fontSize: 'small', color: colors.red['800'] }}>
              {invoiceError}
            </div>
          )}

          <div
            style={{
              fontSize: 'small',
              cursor: 'default',
            }}>
            {!!profile && !!profile.viban && !!priceInformation && (
              <ShortTextCopy
                title="Überweisung Snippet"
                fontSize={14}
                text={transferPaymentSnippet(
                  profile.viban,
                  identifier,
                  euro(priceInformation.final || priceInformation.total),
                )}
              />
            )}
          </div>
        </div>

        {!!orderPayments && orderPayments.length > 0 && (
          <>
            <div className={styles.divider} />
            <h3>GoCardless Bezahlungen</h3>
            <Spacer />
            <OrderPayment
              payments={orderPayments}
              nextReminder={nextReminder}
            />
          </>
        )}

        {!!priceInformation &&
          !!priceInformation.discounts &&
          priceInformation.discounts.length > 0 && (
            <>
              <div className={styles.divider} />
              <h3>Rabatte</h3>
              <Spacer />
              <PriceDiscounts discounts={priceInformation.discounts} />
            </>
          )}

        {!!actionsAvailable && (
          <>
            <div className={styles.divider} />
            <h3>Aktionen</h3>
            <div style={{ fontSize: 'small' }}>
              Zahlungen können entweder stoniert oder geschoben werden. Für
              beide Aktionen ist ein Kommentar notwendig. Außerdem muss in den
              Einstellungen ein Nutzername hinterlegt sein.
              <br />
              Idealerweise verlinkt man im Kommentar ein Support-Ticket oder
              sonstigen Kontext zum Eingriff.
              <br />
              <br />
              <b style={{ color: colors.red['800'] }}>
                Achtung: Die Aktionen können nicht rückgängig gemacht werden.
                Sie dürfen außerdem nur von Administratoren durchgeführt werden.
              </b>
            </div>
            <Spacer size="large" />

            <Input
              className={styles.comment}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Kommentar*"
            />

            <Spacer />
            <div className={styles.actionContainer}>
              {cancelable && (
                <div>
                  <h4>Stornieren</h4>
                  <div style={{ fontSize: 'small' }}>
                    Die Zahlung wird abgebrochen. Außerdem werden alle internen
                    Zustände der Zahlung als abgebrochen markiert. Damit muss
                    der Nutzer bei der nächsten Abgabe wieder zahlen.
                  </div>
                  <Spacer size="large" expander />
                  <Button onClick={onCancel} variant={'outlined'} size="small">
                    Jetzt Stornieren
                  </Button>
                </div>
              )}

              {delayable && (
                <div>
                  <h4>Verzögern</h4>
                  <div style={{ fontSize: 'small' }}>
                    Die Zahlung wird auf das angegebene Datum geschoben. Der
                    Bezahlungsstatus steht weiterhin auf bezahlt. Die Zahlung
                    darf nicht länger als einen Monat geschoben werdenn.
                  </div>
                  <Spacer size="large" />
                  Neuer Zahlungszeitpunkt
                  <input
                    type="date"
                    placeholder="Neues Datum"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <Spacer size="large" expander />
                  <Button onClick={onDelay} variant={'outlined'} size="small">
                    Jetzt Verzögern
                  </Button>
                </div>
              )}

              {reminderDelayable && (
                <div>
                  <h4>Zahlungserinnerungen Verzögern</h4>
                  <div style={{ fontSize: 'small' }}>
                    Ein neues Datum wird als Zahlungsziel eingetragen. Erst 7
                    Tage nach dem neuen Datum gehen die Erinnerungen weiter.
                    Allerdings dann direkt mit der nächsten Stufe – also nicht
                    neu von Anfang.
                  </div>
                  <Spacer size="large" />
                  Neuer Zahlungszeitpunkt
                  <input
                    type="date"
                    placeholder="Neues Datum"
                    value={dateDelayReminder}
                    onChange={(e) => setDateDelayReminder(e.target.value)}
                  />
                  <Spacer size="large" expander />
                  <Button
                    onClick={onDelayPaymentReminder}
                    variant={'outlined'}
                    size="small">
                    Jetzt Verzögern
                  </Button>
                </div>
              )}

              {restartable && (
                <div>
                  <h4>Neustarten</h4>
                  <div style={{ fontSize: 'small' }}>
                    Die Zahlung wird erneut mit der ausgewählten Bankverbindung
                    gestartet. Hierbei wird die aktuell angelegte Zahlung
                    gestoppt, falls dies noch möglich ist. Wenn die Bezahlung
                    den Status <i>submitted</i> oder <i>paid_out</i> hat, ist
                    neustarten nicht möglich.
                  </div>
                  <Spacer size="large" />
                  <div>
                    Bankverbindung
                    {bankInformation?.accounts.map((a) => (
                      <CompactBankAccount
                        key={a.id}
                        account={a}
                        onSelect={() => setActiveBankAccount(a.id)}
                        active={bankInformation?.activeAccountId === a.id}
                      />
                    ))}
                  </div>
                  <Spacer size="large" />
                  <Button onClick={onRestart} variant={'outlined'} size="small">
                    Jetzt Neustarten
                  </Button>
                </div>
              )}

              {refundable && (
                <div>
                  <h4>Bezahlung erstatten</h4>
                  <div style={{ fontSize: 'small' }}>
                    {refundDescription(refundMethod)}
                  </div>
                  <Spacer size="medium" />
                  <InfoBox type={refundMethod ? 'normal' : 'error'}>
                    {readableRefundMethod}
                  </InfoBox>
                  <Spacer size="medium" expander />
                  <Button onClick={onRefund} variant={'outlined'} size="small">
                    Jetzt Erstatten
                  </Button>
                </div>
              )}

              {payLaterResettable && (
                <div>
                  <h4>Auf 29,95 € reduzieren</h4>
                  <div style={{ fontSize: 'small' }}>
                    Die ursprüngliche Bestellung wird auf 29,95 € reduziert.
                    Buchhalterisch wird die erste Bestellung erstattet und eine
                    neue Bestellung erstellt.
                  </div>
                  <Spacer size="medium" expander />
                  <Button
                    onClick={onPayLaterReset}
                    variant={'outlined'}
                    size="small">
                    Jetzt Reduzieren
                  </Button>
                </div>
              )}

              {payLaterFinalizable && (
                <div>
                  <h4>Später bezahlen finalisieren</h4>
                  <div style={{ fontSize: 'small' }}>
                    Die Bezahlung freigeben, falls der Nutzer einen Bescheid
                    bekommen hat. Wir aber nicht.
                    <br />
                    <br />
                    <b>Sehr wichtig:</b> die korrekte Erstattung aus dem
                    Bescheid eintragen. Die Aktion kann nicht rückgängig gemacht
                    werden.
                  </div>
                  <Spacer size="medium" expander />
                  <Input
                    type="number"
                    placeholder="Erstattung laut Nutzer-Bescheid"
                    value={finalizeRefund}
                    onChange={(e) =>
                      setFinalizeRefund(parseFloat(e.target.value))
                    }
                    step={0.01}></Input>
                  <Spacer size="small" />
                  <Button
                    onClick={onPayLaterFinalize}
                    variant={'outlined'}
                    size="small">
                    Jetzt Finalisieren
                  </Button>
                </div>
              )}
            </div>
          </>
        )}

        {isVividOrder && (
          <div
            className={all(
              styles.actionContainer,
              styles.actionContainerVivid,
            )}>
            <div style={{ width: 600 }}>
              <h4>Bezahlung erstattet</h4>
              <div style={{ fontSize: 'small' }}>
                Hier bitte bestätigen wenn die Bezahlung zurück überwiesen
                wurde.
              </div>
              <Spacer size="medium" />
              <InfoBox type={refundMethod ? 'normal' : 'error'}>
                {readableRefundMethod}
              </InfoBox>
              <Spacer size="medium" />
              <Input
                className={styles.commentVivid}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Kommentar*"
              />
              <Spacer />
              <Button
                onClick={onRefund}
                variant={'outlined'}
                size="small"
                disabled={!refundable}>
                Jetzt Erstatten
              </Button>
            </div>
          </div>
        )}
        <Spacer size="large" />

        {!!errors && errors.length > 0 && (
          <div>
            {errors.map((e, idx) => (
              <div style={{ color: colors.red['800'] }} key={idx}>
                {e}
              </div>
            ))}
          </div>
        )}

        <Spacer size="xxlarge" />
      </>
    );
  };

  const year = botIdToYear(bid);
  return (
    <div className={styles.container}>
      <Link to={'/information'}>Zurück</Link>
      <h1>Zahlungsinformationen – Steuerjahr {year}</h1>

      {loading && <CircularProgress size={30} />}

      {renderContent()}
    </div>
  );
};

const OrderPayment = ({
  payments,
  nextReminder,
}: {
  payments: Payment[];
  nextReminder?: string;
}) => {
  const cols: TableCol[] = [
    { label: 'ID', id: 'id' },
    {
      label: 'Status',
      renderFunc: ({ status, goCardlessStatus }: Payment) =>
        goCardlessStatus || status,
    },
    { label: 'GoCardless ID', id: 'goCardlessPaymentId' },
    { label: 'Summe', id: 'amount' },
    {
      label: 'Einzugszeitpunkt',
      renderFunc: ({ chargeDate }: Payment) =>
        dateToString(chargeDate || '', true),
    },
    { label: 'Referenz', id: 'reference' },
    {
      label: 'Überwiesen',
      renderFunc: ({ paidByTransfer }: Payment) =>
        paidByTransfer ? 'Ja' : 'Nein',
    },
    {
      label: 'Inkasso',
      renderFunc: ({ reminder }: Payment) =>
        reminder?.status ? reminder.status : '-',
    },
    {
      label: 'Erinnerungen',
      renderFunc: ({ reminder }: Payment) =>
        reminder?.notifications
          ? reminder.notifications.length
          : nextReminder
          ? 'noch keine'
          : '-',
    },
    {
      label: 'Letzte Erinnerung',
      renderFunc: ({ reminder }: Payment) =>
        reminder?.notifications && reminder.notifications.length > 0
          ? dateToString(
              reminder.notifications[reminder.notifications.length - 1] || '',
              true,
            )
          : '-',
    },
    {
      label: 'Nächste Erinnerung',
      renderFunc: () => (nextReminder ? dateToString(nextReminder, true) : '-'),
    },
  ];

  return <Table data={payments} coloumns={cols} />;
};

const PriceDiscounts = ({ discounts }: { discounts: PriceDiscount[] }) => {
  const cols: TableCol[] = [
    { label: 'Typ', id: 'type' },
    { label: 'Hinweis', id: 'hint' },
    {
      label: 'Wert in €',
      renderFunc: ({ amountInCent }: PriceDiscount) =>
        amountInCent > 0 ? euro(amountInCent / 100) : '',
    },
    { label: 'Prozent', id: 'percent' },
    { label: 'Komplett', id: 'complete' },
  ];

  return <Table data={discounts} coloumns={cols} />;
};

export const PurchasePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawPurchasePage);

const dateToGerString = (d: Date) =>
  [
    d.getDate().toString().padStart(2, '0'),
    (d.getMonth() + 1).toString().padStart(2, '0'),
    d.getFullYear(),
  ].join('.');

const dateToInputString = (d: Date) =>
  [
    d.getFullYear().toString(),
    (d.getMonth() + 1).toString().padStart(2, '0'),
    d.getDate().toString().padStart(2, '0'),
  ].join('-');

const delaySupportText = (date: string) =>
  `danke für deine Nachricht. Das ist kein Problem 😊 Ich habe die Lastschrift auf den ${date} verschoben. Der Betrag wird dann erst an dem Tag von deinem Konto abgebucht.`;

const delayReminderSupportText = (date: string) =>
  `danke für deine Nachricht. Das ist kein Problem 😊 Ich habe es vermerkt, dass du am ${date} zahlen wirst. Bis zu dem Zeitpunkt solltest du keine weiteren Zahlungserinnerungen bekommen.`;

const refundDescription = (method: string) => {
  if (['preliminary', 'preliminary_reset', 'skip'].includes(method)) {
    return `Die Zahlung wird auf 0 gesetzt und nicht wirklich erstattet.`;
  }

  if (['transfer'].includes(method)) {
    return `Die eigentliche Erstattung muss manuell per Überweisung durchgeführt werden.`;
  }

  return `Die Zahlung wird an das Konto erstattet, von welchem die
    Bezahlung getätigt wurde. Dies ist nur im Status
    paid_out möglich.`;
};

const readableRefundMethodMap = {
  '': 'Keine Erstattung möglich',
  skip: 'Ohne Auszahlung der Erstattung',
  transfer: 'Erstattung per Überweisung',
  gocardless: 'Erstattung per GoCardless',
  stop: 'GoCardless Bezahlung stoppen',
  vivid: 'Rücküberweisung für Vivid',
  preliminary:
    'Nur wenn nach Bescheid keine Zahlungspflicht vorliegt benutzen. Bitte davor überprüfen.',
  preliminary_reset:
    'Nur wenn nach Bescheid keine Zahlungspflicht vorliegt benutzen. Bitte davor überprüfen.',
};

const transferPaymentSnippet = (
  viban: string,
  orderNumber: string,
  amount: string,
) => `
danke für deine Nachricht. Dass die Zahlung nicht geklappt hat, ist kein Problem. Du kannst den Betrag auch einfach überweisen. Dazu kannst du folgende Überweisungsdaten verwenden:

Kontoinhaber: Steuerbot GmbH
IBAN: ${viban}
Betrag: ${amount} €
Verwendungszweck: <b>${orderNumber}</b>

Wir benachrichtigen dich, sobald deine Überweisung zugeordnet werden konnte.`;

const resetPayLaterSupportSnippet = `
wir haben deine Bestellung auf <b>29,95 €</b> reduziert 🙂

In der App sollte jetzt der entsprechende Betrag angezeigt werden. Bitte schließe die App und öffne sie erneut, falls bei dir noch der alte Wert steht. Bitte folge den Anweisungen in der App um die Bezahlung abzuschließen.

Du kannst dich gerne direkt wieder bei uns melden, falls du weitere Fragen hast.
`;

const finalizePayLaterSupportSnippet = `
vielen Dank für deine Hilfe 🙂

Wir haben den Bescheid in unser System übertragen und die Bezahlung ist jetzt freigeschalten.
`;
