import { Chip } from '@material-ui/core';
import produce from 'immer';
import React, { useState } from 'react';
import { InputUserId } from '../InputUserId/InputUserId';
import { Spacer } from '../Spacer/Spacer';

interface Props {
  userIds: string[];
  onChange: (userIds: string[]) => void;
}

export const InputUserIds = ({ userIds, onChange }: Props) => {
  const [userId, _] = useState('');

  const onDelete = (idx: number) => {
    onChange(
      produce(userIds, (draft) => {
        draft.splice(idx, 1);
      }),
    );
  };

  const onUserIdChange = (userId: string) => {
    onChange([...userIds, userId]);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {userIds.map((uid, idx) => (
        <Chip
          key={uid}
          label={uid}
          onDelete={() => onDelete(idx)}
          style={{ marginRight: 10 }}
        />
      ))}
      <Spacer size="small" />
      <InputUserId
        style={{ width: 300, flex: 'none', fontSize: 'small' }}
        userId={userId}
        onUserIdChange={onUserIdChange}
        clearAfterSelection
        placeholder="Nach Nutzer suchen"
      />
      <Spacer size="small" />
    </div>
  );
};
