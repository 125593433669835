import React from 'react';
import { isNullOrUndefined } from 'util';
import { Assessment } from '../../info/state/infoApi';
import styles from './AssessmentTable.module.scss';
import { euro } from '../../common/text';
import { useSelector } from 'react-redux';
import { selectAssessmentDiff } from '../../info/state/infoReducer';
import { evalExpression } from '../../common/eval';

const regexp = new RegExp(/\$(\d*)/g);

export const AssessmentTable: React.FC<any> = ({
  assessment,
  debug,
}: {
  assessment: Assessment[];
  debug?: boolean;
}) => {
  const { table } = useSelector(selectAssessmentDiff);

  const assessmentObj: { [key: string]: Assessment } = assessment
    .filter(({ number }) => !!number)
    .reduce((p, c) => ({ ...p, [c.number]: c }), {});

  const findValue = (
    id: string,
  ): { value?: any; debugValue?: any; text?: any } => {
    const isValue = id.includes('$');
    if (!isValue) return { text: formatText(id) };

    const { value, debugValue } = evalExpression(
      id,
      (i) => assessmentObj[i]?.value,
      regexp,
    );

    return {
      value: isNullOrUndefined(value)
        ? undefined
        : typeof value === 'number'
        ? euro(value)
        : value,
      debugValue,
    };
  };

  const valueTable = table
    .map((r) => r.map((id) => ({ id, ...findValue(id) })))
    .filter(
      (r) =>
        debug ||
        !!r.find(({ value }) => !!value) ||
        !r.find(({ id }) => id.includes('$')), // only headlines
    );

  return (
    <div className={styles.container}>
      <table>
        <tbody>
          {valueTable.map((r, idx) => (
            <tr
              key={idx}
              style={{
                fontWeight: r[0] && r[0].id.startsWith('#') ? 'bold' : 'normal',
              }}>
              {r.map(({ id, value, debugValue, text }, idx) => (
                <td key={id + idx}>
                  {!debug ? value || text : value || debugValue || text}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const formatText = (t: string) => {
  if (t.startsWith('####')) return <h4>{t.substr(4)}</h4>;
  if (t.startsWith('###')) return <h3>{t.substr(3)}</h3>;
  if (t.startsWith('##')) return <h2>{t.substr(2)}</h2>;
  if (t.startsWith('#')) return <h1>{t.substr(1)}</h1>;
  return t;
};
