import React from 'react';
import { useHistory } from 'react-router-dom';
import { timeDiffReadable } from '../../../common/date';
import { dateToString, formatNumber, shortenText } from '../../../common/text';
import { Table, TableCol } from '../../../components/Table/Table';
import { NotificationResult } from '../../state/notificationModels';

interface Props {
  results: NotificationResult[];
  rowNo?: boolean;
  showStatus?: boolean;
}

export const NotificationResultTable: React.FC<Props> = ({
  results,
  rowNo,
}: Props) => {
  const history = useHistory();

  const onRowClicked = (_: React.MouseEvent, idx: number) => {
    history.push('jobs/' + results[idx].jobId);
  };

  const cols: TableCol<NotificationResult>[] = [
    { label: 'Job', id: 'jobTitle' },
    { label: 'Zielgruppe', renderFunc: (v) => v.audienceTitle ?? '–' },
    { label: 'Nachricht', renderFunc: (v) => v.messageTitle ?? '–' },
    { label: 'Typ', renderFunc: (v) => v.messageType ?? '–', width: 90 },
    { label: 'Nutzer', renderFunc: (v) => formatNumber(v.totalUsers) },
    {
      label: 'Erreicht',
      renderFunc: (v) => {
        if (v.totalUsers <= 0) {
          return '–';
        }
        return `${formatNumber(
          Math.round(100 * (v.reachedUsers / v.totalUsers)),
        )} %`;
      },
    },
    {
      label: 'Start',
      renderFunc: ({ started }) => dateToString(started || ''),
    },
    {
      label: 'Dauer',
      renderFunc: ({ started, finished }) =>
        timeDiffReadable(started, finished),
    },
    {
      label: 'Status',
      renderFunc: ({ error }: NotificationResult) =>
        error ? (
          <div style={{ fontSize: 10 }}>{shortenText(error, 25)}</div>
        ) : (
          'Erfolgreich'
        ),
    },
    { label: 'Durchlauf', id: 'counter', width: 70 },
    {
      label: 'Testlauf',
      renderFunc: (v) => (v.dryRun ? 'JA' : 'NEIN'),
      width: 70,
    },
  ];

  return (
    <Table
      coloumns={cols}
      data={results}
      rowNo={rowNo}
      emptyText="–"
      variant="material"
      onRowClicked={onRowClicked}
    />
  );
};
