import React from 'react';
import { BotStatus } from '../../home/state/userApi';
import { InfoBox } from '../InfoBox/InfoBox';
import { Spacer } from '../Spacer/Spacer';
import styles from './PaymentReasons.module.scss';

export interface Props {
  status: BotStatus;
}

export const PaymentReasons: React.FC<Props> = ({ status }: Props) => {
  const {
    assessmentAvailable,
    shownPaymentReasons: spr = [],
    assessmentPaymentReasons: apr = [],
  } = status;

  const containsStkl = spr && spr.join('').includes('Steuerklasse');

  const missingShown = spr.filter((r) => !apr.includes(r));

  const missingAssessment = apr.filter((r) => !spr.includes(r));

  const sameReasons = missingShown.length <= 0 && missingAssessment.length <= 0;
  const additionalAssessment =
    missingShown.length <= 0 && missingAssessment.length > 0;

  const differentReasons = missingShown.length > 0;

  const possibleRefund =
    !containsStkl && differentReasons && apr.length <= 0 && assessmentAvailable;

  return (
    <div className={styles.container}>
      <h2>Gründe für Bezahlpflicht</h2>

      <h3>Bei Bezahlung</h3>
      <Spacer size="small" />
      {spr.length <= 0
        ? '-'
        : spr.map((r, idx) => (
            <div key={idx}>
              {idx + 1}. {r}
            </div>
          )) || '-'}

      <Spacer size="large" />

      <h3>Nach Bescheid</h3>
      <Spacer size="small" />
      {!assessmentAvailable && 'Noch nicht verfügbar'}

      {assessmentAvailable && (
        <>
          {apr.length <= 0
            ? '-'
            : apr.map((r, idx) => (
                <div key={idx}>
                  {idx + 1}. {r}
                </div>
              )) || '-'}

          <Spacer size="large" />

          {sameReasons && (
            <InfoBox type="normal" emoji="✅">
              Die Begründungen stimmen in beiden Fällen überein.
            </InfoBox>
          )}

          {additionalAssessment && (
            <InfoBox type="warning" emoji="⚠️">
              Es gibt laut Bescheid noch zusätzliche Gründe für die Bezahlung.
            </InfoBox>
          )}

          {!possibleRefund && differentReasons && (
            <InfoBox type="error" emoji="❗">
              Die Begründungen unterscheiden sich.
            </InfoBox>
          )}

          {possibleRefund && (
            <InfoBox type="error" emoji="💶">
              Keine Bezahlpflicht laut Bescheid. Damit ein möglicher
              Erstattungsfall.
            </InfoBox>
          )}

          {containsStkl && (
            <InfoBox type="warning" emoji="⚠️">
              Die Abgabepflicht entstand aus der <b>Steuerklasse</b> (entweder
              3, 5 oder 6). Dies kann im Bescheid nicht überprüft werden.
              <br />
              <br />
              Es ist aber davon auszugehen, dass es weiterhin zutrifft.
            </InfoBox>
          )}
        </>
      )}
    </div>
  );
};
