import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useQueryUserId } from '../../common/router';
import {
  deleteUser,
  searchUser,
  selectUser,
  setActiveUserId,
} from '../../home/state/userReducer';
import { selectSupportLoggedIn } from '../../settings/state/settingsReducer';
import { Input } from '../Input/Input';
import styles from './SearchInput.module.scss';

interface Props {
  hideButton?: boolean;
}

let timeout: any;
export const UserSearchInput = ({ hideButton }: Props) => {
  const dispatch = useDispatch();

  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const queryUserId = useQueryUserId();

  const { profile, deletedData, activeUserId } = useSelector(selectUser);
  const loggedIn = useSelector(selectSupportLoggedIn);

  const find = useCallback(
    async (id: string) => {
      setLoading(true);
      clearTimeout(timeout);
      setError('');
      try {
        await dispatch(searchUser(id));
      } catch {
        setError('Der Nutzer konnte nicht gefunden werden');
        timeout = setTimeout(() => setError(''), 3000);
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (queryUserId) {
      dispatch(setActiveUserId(queryUserId));
    }
  }, [dispatch, queryUserId]);

  useEffect(() => {
    if (profile) {
      const { id, forename, surname } = profile;
      setInput(`${forename} ${surname} - ${id}`);
      return;
    }
    if (deletedData) {
      setError('Nutzer gelöscht');
      return;
    }
    if (activeUserId) {
      setInput(activeUserId);
      find(activeUserId);
    }
  }, [profile, deletedData, activeUserId, find]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = (value || '').replace(/\s/g, '');
    setInput(value);
    if (activeUserId) {
      dispatch(deleteUser());
    }
  };

  const handleSubmit = async (ev: React.FormEvent) => {
    if (loading) {
      return;
    }
    if (profile) {
      find(profile.id);
      return;
    }
    ev.stopPropagation();
    ev.preventDefault();
    find(input);
  };

  const userExists = profile || deletedData;

  return (
    <form className={styles.inputContainer} onSubmit={handleSubmit}>
      <Input
        value={
          loggedIn ? input : 'Bitte einloggen um suchen zu können (oben rechts)'
        }
        disabled={loading || !loggedIn}
        onChange={handleChange}
        style={{ backgroundColor: userExists ? 'lightgray' : '' }}
        placeholder={'Nutzersuche'}></Input>
      {hideButton && profile && (
        <Tooltip title="Zur Profilübersicht">
          <Link to={`/users/${profile?.id}`} className={styles.profileIcon}>
            <IconButton color="primary">
              <FaceIcon />
            </IconButton>
          </Link>
        </Tooltip>
      )}

      {!hideButton && (
        <>
          {!loggedIn && (
            <Link to="/settings">
              <Button variant="contained" color="primary">
                Einloggen
              </Button>
            </Link>
          )}
          {loggedIn && profile && (
            <Link to={`/users/${profile.id}`}>
              <Button variant="contained" color="primary">
                Details
              </Button>
            </Link>
          )}
          {loggedIn && !profile && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!loading}>
              Nutzer Suchen
            </Button>
          )}
        </>
      )}
      {loading && <CircularProgress className={styles.progress} size={30} />}

      {error && (
        <Alert className={styles.alert} severity="warning">
          {error}
        </Alert>
      )}
    </form>
  );
};
