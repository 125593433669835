import {
  Action,
  PayloadAction,
  ThunkAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Assessment, InfoApi, Information } from './infoApi';
import {
  selectActiveBotId,
  selectActiveId,
} from '../../home/state/userReducer';
import { StorageKey, StorageService } from '../../common/storage.service';
import {
  initialAssessmentTable,
  initialDiff,
  initialKeywords,
} from '../../components/AssessmentDiffDefinition/initial';

export enum InfoPageTab {
  INFOS = 'Infos',
  CHAT = 'Chat',
  REFUND = 'Refund',
  ASSESSMENT = 'Bescheid',
  PAYMENT = 'Bezahlung',
}

export type KeywordDef = string | { keyword: string; comment?: string };

interface InfoState {
  infos: Information[];
  rows: Assessment[];
  ui: {
    tabs: InfoPageTab[];
  };
  assessment: {
    diff: Difference[];
    keywords: KeywordDef[];
    table: string[][];
  };
}

const initialUiState = {
  tabs: [InfoPageTab.INFOS],
};

const initialAssessmentState = {
  diff: initialDiff,
  keywords: initialKeywords,
  table: initialAssessmentTable,
};

const initialState: InfoState = {
  infos: [],
  rows: [],
  ui: restoreSettings(),
  assessment: restoreAssessment(),
};

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    addInfos(state: InfoState, action: PayloadAction<Information[]>) {
      const infos = action.payload;
      state.infos.push(...infos);
    },
    setInfos(state: InfoState, action: PayloadAction<Information[]>) {
      state.infos = action.payload;
    },
    setAssessment(state: InfoState, action: PayloadAction<Assessment[]>) {
      state.rows = action.payload;
    },
    updateInfo(
      state: InfoState,
      action: PayloadAction<{ id: string; ericValue: any }>,
    ) {
      const { id, ericValue } = action.payload;
      state.infos = state.infos.map((i) =>
        i.id === id ? { ...i, ericValue } : i,
      );
    },
    toggleTab(state: InfoState, action: PayloadAction<InfoPageTab>) {
      const t = action.payload;
      const tabs = [...state.ui.tabs];
      const index = tabs.indexOf(t);
      if (index < 0) {
        tabs.push(t);
      } else {
        tabs.splice(index, 1);
      }
      state.ui.tabs = tabs;
      storeSettings(state.ui);
    },
    setDiff(state: InfoState, action: PayloadAction<Difference[]>) {
      state.assessment.diff = action.payload;
      storeAssessment(state.assessment);
    },
    setKeywords(state: InfoState, action: PayloadAction<string[]>) {
      state.assessment.keywords = action.payload;
      storeAssessment(state.assessment);
    },
    setAssessmentTable(state: InfoState, action: PayloadAction<string[][]>) {
      state.assessment.table = action.payload;
      storeAssessment(state.assessment);
    },
  },
});

export const { toggleTab, setDiff, setKeywords, setAssessmentTable } =
  infoSlice.actions;

export default infoSlice.reducer;

// Selector
const selectInformationState = (s: RootState) => s.info;

export const selectInfos = createSelector(
  selectInformationState,
  selectActiveId,
  selectActiveBotId,
  ({ infos }, uid, bid) =>
    infos.filter(({ userId, botId }) => userId === uid && botId === bid),
);

const selectInfoFetchState = createSelector(
  selectInformationState,
  selectActiveId,
  selectActiveBotId,
  ({ infos }, uid, bid) => ({ infos, bid, uid }),
);

export const selectAssessment = createSelector(
  selectInformationState,
  ({ rows }) => rows,
);

export const selectTabs = createSelector(
  selectInformationState,
  ({ ui }) => ui.tabs,
);

export const selectAvailableTabs = createSelector(
  selectInformationState,
  ({ rows }) =>
    Object.values(InfoPageTab).filter(
      (t) => t !== InfoPageTab.ASSESSMENT || (!!rows && rows.length > 0),
    ),
);

export const selectAssessmentDiff = createSelector(
  selectInformationState,
  ({ assessment }) => assessment,
);

// Thunks
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const fetchInfos = (): AppThunk => async (dispatch, getState) => {
  const { bid, uid } = selectInfoFetchState(getState());
  const infos = selectInfos(getState());
  if (!uid || !bid) {
    return;
  }
  const { setInfos, addInfos } = infoSlice.actions;
  const { append, data } = await InfoApi.fetchInfo(infos, uid, bid);
  const f = append ? addInfos : setInfos;
  dispatch(f(data || []));
};

export const fetchAssessment = (): AppThunk => async (dispatch, getState) => {
  const { bid, uid } = selectInfoFetchState(getState());
  if (!uid || !bid) {
    return;
  }
  try {
    const { rows } = await InfoApi.fetchAssessment(uid, bid);
    dispatch(infoSlice.actions.setAssessment(rows || []));
  } catch {
    dispatch(infoSlice.actions.setAssessment([]));
  }
};

export const clearInfos = (): AppThunk => async (dispatch, getState) => {
  const { setInfos } = infoSlice.actions;
  dispatch(setInfos([]));
};

export const infoEditEric =
  (uid: string, id: string, comment: string, ericValue: any): AppThunk =>
  async (dispatch) => {
    if (ericValue) {
      await InfoApi.ericEdit(uid, id, comment, ericValue);
    } else {
      await InfoApi.ericDelete(uid, id, comment);
    }
    const { updateInfo } = infoSlice.actions;
    dispatch(updateInfo({ id, ericValue }));
  };

// Local Storage

function storeSettings(s: any) {
  StorageService.store(StorageKey.INFO_PAGE, s);
}

function restoreSettings(): any {
  return StorageService.restore(StorageKey.INFO_PAGE, initialUiState);
}

function storeAssessment(s: any) {
  StorageService.store(StorageKey.INFO_ASSESSMENT_CONFIG, s);
}

function restoreAssessment(): any {
  return StorageService.restore(
    StorageKey.INFO_ASSESSMENT_CONFIG,
    initialAssessmentState,
  );
}

export interface Difference {
  description: string;
  assessmentId: string;
  assessmentValue?: any;
  otherId: string;
  otherValue?: any;
  onlyBoth?: boolean;
  snippet?: string;
  index?: number;
}
