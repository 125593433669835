import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Spacer } from '../../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../../store';
import { NotificationJob } from '../../state/notificationApi';
import {
  isApiError,
  notificationsActions,
} from '../../state/notificationsActions';
import {
  selectNotificationJob,
  selectNotificationResults,
} from '../../state/notificationsReducer';
import { NotificationJobBuilder } from '../NotificationJobBuilder/NotificationJobBuilder';
import { NotificationResultTable } from '../NotificationResultTable/NotificationResultTable';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  fetchJob: notificationsActions.fetchJob,
  createJob: notificationsActions.createJob,
  updateJob: notificationsActions.updateJob,
  deleteJob: notificationsActions.deleteJob,
  fetchAudiences: notificationsActions.fetchAudiences,
  fetchMessages: notificationsActions.fetchMessages,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationJobDetail = ({
  fetchJob,
  createJob,
  updateJob,
  deleteJob,
  fetchAudiences,
  fetchMessages,
}: Props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: jobId } = useParams<{ id: string }>();
  const job = useSelector(selectNotificationJob(jobId));
  const jobHistory = useSelector(selectNotificationResults(jobId));
  const [loading, setLoading] = useState(false);
  const jobsPath = path.replaceAll('/:id', '');

  const inCreateMode = jobId === 'new';

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const err = await fetchJob(jobId); // TODO: handle error
      if (!!err) {
        history.replace(jobsPath);
        return;
      }
      setLoading(false);
    };
    if (!inCreateMode) {
      loadData();
    }
  }, [jobId]);

  useEffect(() => {
    fetchAudiences();
    fetchMessages();
  }, []);

  const onUpdate = async (j: NotificationJob) => {
    setLoading(true);
    if (!inCreateMode) {
      await updateJob(j); // TODO: handle error
      setLoading(false);
      return;
    }

    const res = await createJob(j);
    if (isApiError(res)) {
      return; // TODO: handle error
    }
    setLoading(false);
    const newPath = path.replace(':id', res.id);
    history.replace(newPath);
  };

  const onDelete = async (id: string) => {
    const newPath = jobsPath;
    if (inCreateMode) {
      history.replace(newPath);
      return;
    }

    setLoading(true);
    const err = await deleteJob(id);
    if (!err) {
      history.replace(newPath);
    }
  };

  return (
    <div>
      <LoadingNavHeader
        title="Job Bearbeiten"
        loading={loading}
        backPath={jobsPath}
        backTitle="Jobs"
      />

      {!!job && (
        <NotificationJobBuilder
          job={job}
          createMode={inCreateMode}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}

      {!!job?.history && job?.history.length > 0 && (
        <>
          <Spacer size="large" />
          <h2>📫 Versendet</h2>
          <NotificationResultTable results={jobHistory} />
        </>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationJobDetail);
