import { Api } from '../../..';

export const notificationApi = {
  addAudienceDefinition(
    definition: AudienceDefinition,
  ): Promise<{ audience: AudienceDefinition }> {
    return Api.fetch({
      method: 'POST',
      url: `notifications/audiences`,
      data: { definition },
    });
  },

  getAudienceDefinitions(): Promise<{ definitions: AudienceDefinition[] }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/audiences`,
    });
  },

  updateAudienceDefinition(
    definition: AudienceDefinition,
  ): Promise<{ audience: AudienceDefinition }> {
    return Api.fetch({
      method: 'PATCH',
      url: `notifications/audiences/${definition.id || ''}`,
      data: { definition },
    });
  },

  getAudienceDefinition(
    did: string,
  ): Promise<{ audience: AudienceDefinition }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/audiences/${did}`,
    });
  },

  computeAudienceDefinition(did: string): Promise<{ size: number }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/audiences/${did}/size`,
    });
  },

  deleteAudienceDefinition(
    did: string,
  ): Promise<{ audience: AudienceDefinition }> {
    return Api.fetch({
      method: 'DELETE',
      url: `notifications/audiences/${did}`,
    });
  },

  createMessage(
    message: NotificationMessage,
  ): Promise<{ message: NotificationMessage }> {
    return Api.fetch({
      method: 'POST',
      url: `notifications/messages`,
      data: { message },
    });
  },

  getMessages(): Promise<{ messages: NotificationMessage[] }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/messages`,
    });
  },

  getMessage(id: string): Promise<{ message: NotificationMessage }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/messages/${id}`,
    });
  },

  updateMessage(
    message: NotificationMessage,
  ): Promise<{ message: NotificationMessage }> {
    return Api.fetch({
      method: 'PATCH',
      url: `notifications/messages/${message.id}`,
      data: { message },
    });
  },

  deleteMessage(id: string): Promise<void> {
    return Api.fetch({
      method: 'DELETE',
      url: `notifications/messages/${id}`,
    });
  },

  sendMessage(id: string, userIds: string[]): Promise<void> {
    return Api.fetch({
      method: 'POST',
      url: `notifications/messages/${id}/send`,
      data: { userIds },
    });
  },

  createNotificationJob(
    job: NotificationJob,
  ): Promise<{ job: NotificationJob }> {
    return Api.fetch({
      method: 'POST',
      url: `notifications/jobs`,
      data: { job },
    });
  },

  getNotificationJobs(): Promise<{ jobs: NotificationJob[] }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/jobs`,
    });
  },

  getNotificationJob(id: string): Promise<{ job: NotificationJob }> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/jobs/${id}`,
    });
  },

  getNotificationJobProgress(id: string): Promise<NotificationJobProgress> {
    return Api.fetch({
      method: 'GET',
      url: `notifications/jobs/${id}/progress`,
    });
  },

  updateNotificationJob(
    job: NotificationJob,
  ): Promise<{ job: NotificationJob }> {
    return Api.fetch({
      method: 'PATCH',
      url: `notifications/jobs/${job.id}`,
      data: { job },
    });
  },

  deleteNotificationJob(id: string): Promise<void> {
    return Api.fetch({
      method: 'DELETE',
      url: `notifications/jobs/${id}`,
    });
  },

  sendPushNotification(
    notification: PushNotification,
    userId?: string,
  ): Promise<{ devices: number; users: number; dryRun: boolean }> {
    return Api.fetch({
      method: 'POST',
      url: `notifications${!!userId ? '/' + userId : ''}`,
      data: { notification },
    });
  },
};

export interface AudienceDefinition {
  id: string;
  title: string;
  created: string;
  updated: string;
  conditions: AudienceCondition[][];
}

export interface AudienceCondition {
  type: AudienceConditionType; // registration, submit, profile_state, platform
  exclude?: boolean;
  from?: string; // 02.01.2006 pattern
  until?: string; // 02.01.2006 pattern
  submitYear?: number;
  profileState?: string;
  vastStatus?: string;
  platform?: string;
  version?: string;
  size?: number;
  refundAbove?: number;
  refundBelow?: number;
  differenceAbove?: number;
  differenceBelow?: number;
  taxMandatoryReason?: string;
  partner?: string;
  progress?: string;
  language?: string;
  cohortId?: string;
  audienceId?: string;
}

export enum AudienceConditionType {
  REGISTRATION = 'registration',
  PROFILE_ADDED = 'profile_added',
  SUBMIT = 'submit',
  ASSESSMENT = 'assessment',
  VALIDATE = 'validate',
  PROFILE_STATE = 'profile_state',
  PLATFORM = 'platform',
  VERSION = 'version',
  VAST_PERMISSION = 'vast_permission',
  VAST_DOCUMENTS = 'vast_documents',
  LAST_ACTIVE = 'last_active',
  TAX_MANDATORY = 'tax_mandatory',
  PARTNER = 'partner',
  PROGRESS = 'progress',
  LANGUAGE = 'language',
  PAY_LATER = 'pay_later',
  COHORT = 'cohort',
  AUDIENCE = 'audience',
}

export enum PushChannel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export interface NotificationMessage {
  id: string;
  title: string;
  created: string;
  updated: string;
  final?: boolean;
  defaultAudienceId?: string;

  push?: PushNotification;
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface PushNotification {
  enabled?: boolean;
  title?: string;
  body?: string;
  channel: PushChannel;
  data?: { [key: string]: string };
  url?: string;
  navigateToYear?: number;
}

export interface NotificationJobRequest {
  notificationId: string;
  audienceId: string;
  dryRun: boolean;
  type: 'manual' | 'auto';
  users: string[];
}

export interface NotificationJob {
  id: string;
  title: string;
  status: NotificationJobStatus;

  messageId?: string;
  audienceId?: string;
  userIds?: string[];

  dryRun?: boolean;
  totalUsers?: number;

  hours: { from: number; to: number };
  repeatAfter?: number;
  runCounter?: number;
  history?: NotificationJobHistoryEntry[];
  userNotificationGracePeriod?: number;
  startDate?: string;

  created: string;
  updated: string;
  started?: string;
  finished?: string;

  failed?: string;
  error?: string;
}

export interface NotificationJobProgress {
  reachedUsers: number;
  progress: string;
}

export enum NotificationJobStatus {
  DRAFT = 'draft',
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  REPEAT = 'repeat',
  FINISHED = 'finished',
  PAUSED = 'paused',
  FAILED = 'failed',
  ARCHIVED = 'archived',
}

export interface NotificationJobHistoryEntry {
  started: string;
  finished?: string;
  failed?: string;
  totalUsers: number;
  reachedUsers: number;
  error?: string;
  dryRun?: boolean;
}
