import { Button, LinearProgress } from '@material-ui/core';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { PartnerListRequest } from '../../../../home/state/userApi';
import styles from './PartnerList.module.scss';

interface Props {
  createList: (data: PartnerListRequest) => Promise<string>;
}

export const PartnerList = ({ createList }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [request, setRequest] = useState<PartnerListRequest>({
    from: '01.07.2021',
    until: '30.06.2022',
    partnerId: partners[0].id,
  });

  const updateRequest = (key: string, value: string) => {
    setRequest((s) => ({
      ...s,
      [key]: inputToGerString(value),
    }));
  };

  const updatePartnerId = (value: string) => {
    setRequest((s) => ({
      ...s,
      couponId: value,
    }));
  };

  const onCreatePayoutClick = async () => {
    setLoading(true);
    try {
      const csv = await createList(request);
      if (csv !== '') {
        const blob = new Blob([csv || ''], {
          type: 'text/csv;charset=utf-8',
        });
        const coupon = partners.find(({ id }) => id === request.partnerId);
        FileSaver.saveAs(
          blob,
          getFilename('Partnerauswertung_' + coupon?.title, request),
        );
      }
    } catch (e) {
      setError('Die Liste konnte nicht erstellt werden mit Fehler: ' + e);
    }
    setLoading(false);
  };

  return (
    <div>
      <h3>Partnerauswertung</h3>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Hier kann eine Liste der Abgaben über das Partner-SDK erstellt werden.
        Bitte beachte, dass bei den Enddaten immer das Ende des Tages gilt.
      </div>
      <Spacer size="large" />
      <div className={styles.orderListInputContainer}>
        <div>Partnerauswertung von</div>
        <Input
          type="date"
          value={gerStringToInput(request.from)}
          onChange={(e) => updateRequest('from', e.target.value)}
        />
        <div> bis </div>
        <Input
          type="date"
          value={gerStringToInput(request.until)}
          onChange={(e) => updateRequest('until', e.target.value)}
        />
        <div>Partner</div>
        <select
          value={request.partnerId}
          onChange={(e) => updatePartnerId(e.target.value)}>
          {partners.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </select>
      </div>

      <Spacer size="large" />

      <div style={{ display: 'flex' }}>
        <Button
          className={styles.orderListButton}
          variant={'outlined'}
          size="small"
          disabled={loading}
          onClick={onCreatePayoutClick}>
          {!loading ? 'Auswertung erstellen' : 'Auswertung wird erstellt'}
        </Button>

        <Spacer />

        {loading && <LinearProgress style={{ height: 4 }} />}
      </div>
      {!!error && <div>{error}</div>}

      <Spacer />
    </div>
  );
};

function gerStringToInput(s: string) {
  const [day, month, year] = s.split('.');
  return [year, month, day].join('-');
}

function inputToGerString(s: string) {
  const [year, month, day] = s.split('-');
  return [day, month, year].join('.');
}

function getFilename(
  title: string,
  { from, until }: PartnerListRequest,
): string {
  from = from.replaceAll('.', '_');
  until = until.replaceAll('.', '_');

  return `${title}_von_${from}_bis_${until}.csv`;
}

const partners = [{ id: 'vivid', title: 'Vivid' }];
