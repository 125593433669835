import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { Spacer } from '../../../components/Spacer/Spacer';
import { MapDispatch, RootState } from '../../../store';
import { NotificationJobStatus } from '../../state/notificationApi';
import { notificationsActions } from '../../state/notificationsActions';
import { selectJobs } from '../../state/notificationsReducer';
import { NotificationJobTable } from '../NotificationJobTable/NotificationJobTable';

const mapStateToProps = (state: RootState) => ({
  jobs: selectJobs(state),
});

const mapDispatchToProps = {
  fetchJobs: notificationsActions.fetchJobs,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationJobs = ({ jobs, fetchJobs }: Props) => {
  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchJobs(); // TODO: handle error
      setLoading(false);
    };
    loadData();
  }, []);

  const active = jobs.filter(
    (j) => j.status !== NotificationJobStatus.ARCHIVED,
  );
  const archived = jobs.filter(
    (j) => j.status === NotificationJobStatus.ARCHIVED,
  );

  return (
    <div>
      <LoadingNavHeader
        title="Jobs"
        loading={loading}
        addPath={path + '/new'}
        backTitle="Übersicht"
        backPath="/notifications/dashboard"
      />

      <NotificationJobTable jobs={active} path={path} />

      {archived.length > 0 && (
        <>
          <Spacer size="large" />
          <h3>Archiviert</h3>
          <Spacer size="small" />
          <NotificationJobTable jobs={archived} path={path} />
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationJobs);
