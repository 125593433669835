import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../common/text';
import { InvitePayout } from '../../home/state/userApi';
import { Card } from '../Card/Card';
import styles from './InvitePayoutChange.module.scss';

interface Props {
  payout?: InvitePayout;
  authorizePayout: (id: string) => void;
}

const emptyPayout: InvitePayout = {
  id: '',
  userId: '',
  status: '',
  amount: 0,
  requested: '',
  inviteIds: [],
};

export const InvitePayoutChange: React.FC<Props> = ({
  payout: base,
  authorizePayout,
}: Props) => {
  const [payout, setPayout] = useState(base || emptyPayout);

  useEffect(() => {
    setPayout(base || emptyPayout);
  }, [base]);

  const {
    id,
    status,
    userId,
    requested,
    approved,
    bankAccountHolder,
    iban,
    amount,
  } = payout;

  return (
    <div>
      <Card size={'small'}>
        <div className={styles.container}>
          <div>ID</div>
          <div>{id}</div>
          <div>Status</div>
          <div>{status}</div>
          <div>User-ID</div>
          <div>{userId}</div>
          <div>Wert</div>
          <div>
            <b>{`${amount / 100} €`}</b>
          </div>
          <div>Kontoinhaber</div>
          <div>
            <b>{bankAccountHolder}</b>
          </div>
          <div>IBAN</div>
          <div>
            <b>{iban}</b>
          </div>
          <div>Angefragt</div>
          <div>{dateToString(requested)}</div>
          <div>Akzeptiert</div>
          <div>{approved ? dateToString(approved) : 'Noch nicht'}</div>
        </div>

        <Button
          color="primary"
          disabled={!!approved}
          onClick={() => authorizePayout(id)}>
          Akzeptieren
        </Button>
      </Card>
    </div>
  );
};
