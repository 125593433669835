import axios, { AxiosRequestConfig } from 'axios';
import { selectActiveEndpoint } from '../settings/state/settingsReducer';
import { RootState } from '../store';

type callback = (id: string) => void;

export const apiService = (
  keycloak: Keycloak.KeycloakInstance,
  getState: () => RootState,
  unauthorizedCallback: callback,
) => {
  const instance = axios.create();

  instance.interceptors.response.use(
    (r) => r,
    (err) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        unauthorizedCallback(err);
      }
      return Promise.reject(err);
    },
  );

  instance.interceptors.request.use(
    (config) => {
      const e = selectActiveEndpoint(getState());

      config.baseURL = e.url;

      const token = keycloak.token;

      if (!token) {
        return Promise.reject({ response: { status: 403 } });
      }

      config.headers['Authorization'] = 'Bearer ' + token;
      return config;
    },

    (error) => Promise.reject(error),
  );

  return {
    fetch: (config: AxiosRequestConfig) => {
      return instance(config).then((r) => r.data);
    },
  };
};

export const queryParams = (obj: { [key: string]: any }) =>
  Object.keys(obj)
    .filter((k) => obj[k] !== null && obj[k] !== undefined)
    .reduce((pre, k) => ({ ...pre, [k]: obj[k] }), {});
