import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useQueryUserId = () => {
  const q = useQuery();
  return q.get('uid');
};

export const useQueryBotId = () => {
  const q = useQuery();
  return q.get('bid');
};

export const useQueryYear = () => {
  const q = useQuery();
  return q.get('year');
};

export const useQueryPage = () => {
  const q = useQuery();
  const qp = q.get('page');
  if (qp === null) {
    return 0;
  }
  return parseInt(qp) || 0;
};
