import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dateToString } from '../../../../common/text';
import { ShortTextCopy } from '../../../../components/ShortTextCopy/ShortTextCopy';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { Table, TableCol } from '../../../../components/Table/Table';
import { userActions } from '../../../../home/state/userActions';
import { InvitePayout } from '../../../../home/state/userApi';
import { MapDispatch, RootState } from '../../../../store';
import styles from './InvitePayouts.module.scss';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  getPendingInvitePayouts: userActions.getPendingInvitePayouts,
  invitePayoutChangeStatus: userActions.invitePayoutChangeStatus,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawInvitePayouts: React.FC<Props> = ({
  getPendingInvitePayouts,
  invitePayoutChangeStatus,
}: Props) => {
  const [pendingPayouts, setPendingPayouts] = useState<InvitePayout[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadPendingPayouts();
  }, [getPendingInvitePayouts]);

  const loadPendingPayouts = useCallback(async () => {
    const { payouts } = await getPendingInvitePayouts('json');
    setPendingPayouts(payouts || []);
  }, [getPendingInvitePayouts]);

  const changeInviteStatus =
    (payoutId: string, status: 'permitted' | 'declined') => async () => {
      setIsLoading(true);
      await invitePayoutChangeStatus(payoutId, status);
      await loadPendingPayouts();
      setIsLoading(false);
    };

  const cols: TableCol[] = [
    {
      label: 'User-ID',
      renderFunc: ({ userId }: any) => (
        <ShortTextCopy fontSize={12} text={userId} />
      ),
    },
    { label: 'Kontoinhaber', id: 'bankAccountHolder' },
    { label: 'IBAN', id: 'iban' },
    {
      label: 'Betrag',
      renderFunc: ({ amount }) => `${amount / 100} €`,
    },
    {
      label: 'Beantragt',
      renderFunc: ({ requested }) => dateToString(requested),
    },
    { label: 'Status', id: 'status' },
    {
      label: 'Aktionen',
      renderFunc: (v) => (
        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            disabled={isLoading}
            onClick={changeInviteStatus(v.id, 'permitted')}>
            Akzeptieren
          </Button>
          <Spacer />
          <Button
            size="small"
            disabled={isLoading}
            onClick={changeInviteStatus(v.id, 'declined')}>
            Ablehnen
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.actionContainer}>
      <div>
        <h3>Einladungsauszahlungen</h3>
        <Spacer size="small" />
        <div style={{ fontSize: 'small' }}>
          Hier werden die offenen, angeforderten Einladungsauszahlungen
          aufgelistet. Diese können als XML für SEPA Sammelüberweisungen
          exportiert werden. Nach der Überweisung müssen die Einladungen dann
          bestätigt werden.
        </div>
        <Spacer size="large" />

        <Table coloumns={cols} data={pendingPayouts} rowNo />
        <Spacer />
      </div>
    </div>
  );
};

export const InvitePayouts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawInvitePayouts);
