import {
  Action,
  PayloadAction,
  ThunkAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { StorageKey, StorageService } from '../../common/storage.service';
import { AuthClientMeta, AuthClientTokens, keycloak } from '../../keycloak';
import { userApi } from '../../home/state/userApi';

const endpointIds = ['production', 'staging', 'local'];
const availableEndpoints: { [key: string]: ApiEndpoint } = {
  local: {
    id: 'local',
    label: 'Localhost',
    url: 'http://localhost:8080',
  },
  staging: {
    id: 'staging',
    label: 'Staging',
    url: 'https://monitor-api.steuerbot.com/staging',
  },
  production: {
    id: 'production',
    label: 'Production',
    url: 'https://monitor-api.steuerbot.com/production',
  },
};

export interface ApiEndpoint {
  id: string;
  label: string;
  url: string;
}

type PermissionHint = 'missing-permission' | 'logged_out' | undefined;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

interface SettingsState {
  username: string;
  activeEndpoint?: string;
  endpointIds: string[];
  endpoints: { [key: string]: ApiEndpoint };
  permissionHint?: PermissionHint;
}

const initialState: SettingsState = restoreSettings();

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    deleteCredentials(
      state: SettingsState,
      action: PayloadAction<{ id: string; type: 'support' | 'shop' }>,
    ) {
      const { id, type } = action.payload;
      state.endpoints[id][type] = undefined;
    },
    setActiveEndpoint(state: SettingsState, action: PayloadAction<string>) {
      const id = action.payload;
      state.activeEndpoint = id;
    },
    setUsername(state: SettingsState, action: PayloadAction<string>) {
      const username = action.payload;
      state.username = username;
    },
    setPermissionHint(
      state: SettingsState,
      action: PayloadAction<PermissionHint>,
    ) {
      state.permissionHint = action.payload;
    },
  },
});

export const { deleteCredentials, setUsername: setSettingsUsername } =
  settingsSlice.actions;

export default settingsSlice.reducer;

export const selectSettings = (state: RootState) => state.settings;

export const selectActiveEndpoint = createSelector(
  selectSettings,
  ({ endpointIds, endpoints, activeEndpoint }) => {
    const eid = activeEndpoint || endpointIds[0];
    return endpoints[eid];
  },
);

export const selectSupportLoggedIn = createSelector(
  selectActiveEndpoint,
  ({}) => true,
);

export const selectSettingsUsername = createSelector(
  selectSettings,
  ({ username }) => username,
);

export const selectPermissionHint = createSelector(
  selectSettings,
  ({ permissionHint }) => permissionHint,
);

// thunks
export const handleUnauthorizedRequest =
  (h: PermissionHint): AppThunk =>
  (dispatch, getState) => {
    dispatch(settingsSlice.actions.setPermissionHint(h));
  };

export const setActiveEndpointId =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(settingsSlice.actions.setActiveEndpoint(id));

    const { settings } = getState();
    storeSettings(settings);
  };

// Local Storage
export function storeSettings(state: SettingsState) {
  StorageService.store(StorageKey.SETTINGS, state);
}

function restoreSettings(): SettingsState {
  const s: SettingsState = StorageService.restore(StorageKey.SETTINGS, {});

  const endpoints: { [key: string]: ApiEndpoint } = {};
  endpointIds.forEach((id) => {
    endpoints[id] = availableEndpoints[id];
    if (s && s.endpoints && s.endpoints[id]) {
    }
  });

  const activeEndpoint = s.activeEndpoint || endpointIds[0];

  return {
    username: s ? s.username : '',
    activeEndpoint,
    endpointIds,
    endpoints,
  };
}
