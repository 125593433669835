import {
  Action,
  PayloadAction,
  ThunkAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { stringToNanosecond } from '../../common/date';
import { RootState } from '../../store';
import {
  AudienceDefinition,
  NotificationJob,
  NotificationJobProgress,
  NotificationJobStatus,
  NotificationMessage,
} from './notificationApi';
import { NotificationResult } from './notificationModels';

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type Thunk<R> = ThunkAction<R, RootState, undefined, Action>;

export interface NotificationsState {
  audienceSize: { [key: string]: number };
  audiences: { [key: string]: AudienceDefinition };
  jobs: { [key: string]: NotificationJob };
  jobProgress: { [key: string]: NotificationJobProgress };
  messages: { [key: string]: NotificationMessage };
}

const initialState: NotificationsState = {
  audienceSize: {},
  audiences: {},
  jobs: {},
  jobProgress: {},
  messages: {},
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setAudiences(
      state: NotificationsState,
      action: PayloadAction<{ [key: string]: AudienceDefinition }>,
    ) {
      state.audiences = { ...state.audiences, ...action.payload };
    },
    setAudienceSize(
      state: NotificationsState,
      action: PayloadAction<{ id: string; size: number }>,
    ) {
      const { id, size } = action.payload;
      state.audienceSize[id] = size;
    },
    deleteAudience(state: NotificationsState, action: PayloadAction<string>) {
      delete state.audiences[action.payload];
    },
    setJobs(
      state: NotificationsState,
      action: PayloadAction<{ [key: string]: NotificationJob }>,
    ) {
      state.jobs = { ...state.jobs, ...action.payload };
    },
    deleteJob(state: NotificationsState, action: PayloadAction<string>) {
      delete state.jobs[action.payload];
    },
    setJobProgress(
      state: NotificationsState,
      action: PayloadAction<{ [key: string]: NotificationJobProgress }>,
    ) {
      state.jobProgress = { ...state.jobProgress, ...action.payload };
    },
    setMessages(
      state: NotificationsState,
      action: PayloadAction<{ [key: string]: NotificationMessage }>,
    ) {
      state.messages = { ...state.messages, ...action.payload };
    },
    deleteMessage(state: NotificationsState, action: PayloadAction<string>) {
      delete state.messages[action.payload];
    },
  },
});

export const {
  setAudiences,
  setAudienceSize,
  deleteAudience,
  setJobs,
  deleteJob,
  setJobProgress,
  setMessages,
  deleteMessage,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

const selectNotificationsState = (s: RootState) => s.notifications;

export const selectAudiences = createSelector(
  selectNotificationsState,
  ({ audiences }) => Object.values(audiences).sort(sortAudiences),
);

export const selectAudienceObject = createSelector(
  selectNotificationsState,
  ({ audiences }) => audiences,
);

export const selectAudienceDefinition = (id: string) =>
  createSelector(selectNotificationsState, ({ audiences }) => {
    if (id === 'new') {
      return {
        id: '',
        title: '',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        conditions: [],
      };
    }
    return audiences[id];
  });

export const selectAudienceSize = (id: string) =>
  createSelector(selectNotificationsState, (s) => s.audienceSize[id] ?? -1);

export const selectJobs = createSelector(selectNotificationsState, ({ jobs }) =>
  Object.values(jobs).sort((a, b) => b.created.localeCompare(a.created)),
);

export const selectNotificationJob = (id: string) =>
  createSelector<RootState, NotificationsState, NotificationJob | undefined>(
    selectNotificationsState,
    ({ jobs }) => {
      if (id === 'new') {
        return {
          id: '',
          title: '',
          status: NotificationJobStatus.DRAFT,
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          dryRun: true,
          hours: { from: 0, to: 24 },
          userNotificationGracePeriod: stringToNanosecond('1w'),
        };
      }
      return jobs[id];
    },
  );

export const selectAllNotificationResults = (
  status: 'success' | 'failed',
  limit?: number,
) =>
  createSelector<RootState, NotificationsState, NotificationResult[]>(
    selectNotificationsState,
    ({ jobs, audiences, messages }) => {
      const r = Object.values(jobs)
        .filter((j) => !!j.history)
        .flatMap((j) => toNotificationResult(j.id, jobs, audiences, messages))
        .filter(
          (r) =>
            !!r.started &&
            ((status === 'failed' && !!r.error) ||
              (status === 'success' && !r.error)),
        )
        .sort(sortResults);
      if (!!limit && limit > 0 && r.length > limit) {
        r.length = limit;
      }
      return r;
    },
  );

export const selectNotificationResults = (id: string) =>
  createSelector<RootState, NotificationsState, NotificationResult[]>(
    selectNotificationsState,
    ({ jobs, audiences, messages }) =>
      toNotificationResult(id, jobs, audiences, messages),
  );

export const selectMessages = createSelector(
  selectNotificationsState,
  ({ messages }) => Object.values(messages),
);

export const selectMessageObject = createSelector(
  selectNotificationsState,
  ({ messages }) => messages,
);

export const selectMessage = (id: string) =>
  createSelector(selectNotificationsState, ({ messages }) => {
    if (id === 'new') {
      return {
        id: '',
        title: '',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
      };
    }
    return messages[id];
  });

export const selectJobsByStatus = (...ss: NotificationJobStatus[]) =>
  createSelector(selectJobs, (jobs) =>
    jobs.filter(({ status }) => ss.includes(status)),
  );

export const selectJobProgress = createSelector(
  selectNotificationsState,
  ({ jobProgress }) => jobProgress,
);

// Dashboard
export const selectJobsDays = (days?: number) =>
  createSelector(selectAllNotificationResults('success', 100), (r) => {
    if (days === undefined) {
      return [];
    }
    const cutoff = moment().add(-1 * days, 'day');
    return r.filter(({ started }) => moment(started).isAfter(cutoff));
  });

export const selectJobsDaysCount = (days?: number) =>
  createSelector(selectJobsDays(days), (r) => r.length);

export const selectMessagesReachedDaysCount = (days?: number) =>
  createSelector(selectJobsDays(days), (r) => {
    return r
      .filter((r) => !r.dryRun)
      .reduce((p, c) => (p += c.reachedUsers), 0);
  });

// Helper methods

const toNotificationResult = (
  jobId: string,
  jobs: { [key: string]: NotificationJob },
  audiences: { [key: string]: AudienceDefinition },
  messages: { [key: string]: NotificationMessage },
): NotificationResult[] => {
  if (!jobs) {
    return [];
  }
  const job = jobs[jobId];
  if (!job || !job.history || job.history.length <= 0) {
    return [];
  }
  const audience = audiences[job.audienceId ?? ''];
  const message = messages[job.messageId ?? ''];
  const messageType = message?.push ? 'Push' : '–';

  return job.history.map((h, idx) => ({
    ...h,
    counter: idx + 1,
    jobId: job.id,
    jobTitle: job.title,
    audienceId: job.audienceId,
    audienceTitle: audience?.title,
    messageId: job.messageId,
    messageTitle: message?.title,
    messageType,
  }));
};

const sortResults = (
  { started: a }: NotificationResult,
  { started: b }: NotificationResult,
) => {
  if (!a || !b) return 0;
  const d = moment(a).diff(b);
  return d < 0 ? 1 : d > 0 ? -1 : 0;
};

function sortAudiences(a: AudienceDefinition, b: AudienceDefinition) {
  const ay = a.title.startsWith('20');
  const by = b.title.startsWith('20');
  if (ay && by) {
    return a.title < b.title ? 1 : -1;
  }
  return ay && !by ? -1 : !ay && by ? 1 : a.title < b.title ? -1 : 1;
}
