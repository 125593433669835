import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ObjectTable } from '../../../components/ObjectTable/ObjectTable';
import { Spacer } from '../../../components/Spacer/Spacer';
import styles from './VastDocumentsPage.module.scss';

export const VastDocumentsPage = () => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const toggle = (id: string) => () => {
    const idx = expanded.indexOf(id);
    setExpanded(
      idx >= 0 ? [...expanded].filter((s) => s !== id) : [...expanded, id],
    );
  };

  const history = useHistory();
  let documents: any[] = [];
  let year = 20;
  if (history && history.location && history.location.state) {
    documents = (history.location.state as any).documents || [];
    if (documents.length > 0) {
      year = documents[0].TaxYear;
    }
  }

  return (
    <div className={styles.container}>
      <h1>Dokumente für Steuerjahr {year}</h1>
      {documents.map((d) => (
        <div key={d.OID} className={styles.documentContainer}>
          <ObjectTable
            data={d}
            rows={[
              { id: 'UserType', label: 'UserType' },
              { id: 'IDNr', label: 'IDNr' },
              { id: 'DocumentType', label: 'DocumentType' },
              { id: 'DocumentVersion', label: 'DocumentVersion' },
            ]}
          />

          <Spacer size="small" />

          <div className={styles.codeContainer}>
            <div className={styles.codeContainerHeader} onClick={toggle(d.OID)}>
              <div>
                Dokument {expanded.includes(d.OID) ? 'verstecken' : 'anzeigen'}
              </div>
              <div className={styles.codePreviewButton}>{'{ }'}</div>
            </div>

            {expanded.includes(d.OID) && (
              <pre className={styles.codePreview}>
                {JSON.stringify(d.Document, null, 2)}
              </pre>
            )}
          </div>
        </div>
      ))}
      <Spacer size="xxlarge" />
    </div>
  );
};
