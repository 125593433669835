import React, { useCallback, useEffect, useState } from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/Logo.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { selectActiveEndpoint } from '../../settings/state/settingsReducer';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { UserSearchInput } from '../UserSearchInput/SearchInput';
import { useQueryBotId, useQueryYear } from '../../common/router';
import { yearToBotId } from '../../common/bot';
import { setActiveBotId } from '../../home/state/userReducer';
import { useKeycloak } from '@react-keycloak/web';

export const Header: React.FC<any> = () => {
  const history = useHistory();
  const activeEndpoint = useSelector(selectActiveEndpoint);
  const dispatch = useDispatch();
  const queryBotId = useQueryBotId();
  const queryYear = useQueryYear();
  const { keycloak } = useKeycloak();

  const [hideUserSelection, setHideUserSelection] = useState<boolean>(true);

  const goToPage = useCallback(
    (page: string) => {
      const { pathname } = history.location;
      if (pathname !== page) {
        history.push(page);
      }
    },
    [history],
  );

  const goBack = useCallback(history.goBack, [history]);

  useEffect(() => {
    const dontShow = ['/', '/settings', '/shop/', '/accounting'];
    const unlisten = history.listen((location) => {
      setHideUserSelection(dontShow.includes(location.pathname));
    });
    setHideUserSelection(dontShow.includes(history.location.pathname));
    return unlisten;
  }, [history]);

  useEffect(() => {
    const yearId = yearToBotId(queryYear || '');
    if (yearId) {
      dispatch(setActiveBotId(yearId));
      return;
    }
    if (queryBotId) {
      dispatch(setActiveBotId(queryBotId));
    }
  }, [dispatch, queryBotId, queryYear]);

  const loggedIn = keycloak.authenticated;

  return (
    <div className={styles.container}>
      <Button onClick={goBack} className={styles.iconContainer}>
        {'<'}
      </Button>
      <Button onClick={() => goToPage('/')} className={styles.iconContainer}>
        <img src={logo} className={styles.icon} alt="Steuerbot Logo"></img>
      </Button>
      {!hideUserSelection && (
        <div className={styles.searchContainer}>
          <UserSearchInput hideButton></UserSearchInput>
        </div>
      )}

      {hideUserSelection && <div className={styles.spacer}></div>}

      <Button
        size="small"
        className={styles.endpoint}
        onClick={() => goToPage('/settings')}
        endIcon={
          <div>
            {loggedIn ? (
              <CheckCircleIcon style={{ color: 'green' }} fontSize="small" />
            ) : (
              <ErrorOutlineIcon style={{ color: 'orange' }} fontSize="small" />
            )}
          </div>
        }>
        {!loggedIn ? 'Einloggen' : activeEndpoint.label}
      </Button>

      <IconButton aria-label="menu" onClick={() => goToPage('/settings')}>
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};
