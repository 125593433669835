import { selectSettingsUsername } from '../../settings/state/settingsReducer';
import { BankInformation, ShopApi } from '../../shop/state/shopApi';
import { AsyncAction } from '../../store';
import {
  Coupon,
  CouponListRequest,
  HasPurchaseResponse,
  InvitePayout,
  OrderPayoutListRequest,
  OrderTransfer,
  PartnerListRequest,
  TroyCase,
  User,
  userApi,
} from './userApi';
import {
  fetchBankInformation,
  selectActiveBotId,
  selectActiveId,
  selectBankInformation,
  setBankInformation,
} from './userReducer';

export const userActions = {
  searchProfile:
    (query: string): AsyncAction<{ user?: User }> =>
    async (_, _getState) => {
      const { user } = await userApi.fetchProfile(query);
      return { user };
    },

  cancelPayment:
    (paymentId: string, comment: string): AsyncAction<{ errors: any[] }> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.cancelPayment(
        paymentId,
        uid,
        bid,
        monitorUser,
        comment,
      );
      return r;
    },

  grantPayment:
    (bid: string): AsyncAction =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const monitorUser = selectSettingsUsername(getState());
      await userApi.grantPayment(uid, bid, monitorUser);
    },

  hasPurchase:
    (bid: string): AsyncAction<HasPurchaseResponse> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      return userApi.hasPurchase(uid, bid);
    },

  delayPayment:
    (
      paymentId: string,
      date: string,
      comment: string,
    ): AsyncAction<{ errors: any[] }> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.delayPayment(
        paymentId,
        uid,
        bid,
        monitorUser,
        date,
        comment,
      );
      return r;
    },

  delayPaymentReminder:
    (
      paymentId: string,
      date: string,
      comment: string,
    ): AsyncAction<{ errors: any[] }> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.delayPaymentReminder(
        paymentId,
        uid,
        bid,
        monitorUser,
        date,
        comment,
      );
      return r;
    },

  fetchBankInformation:
    (): AsyncAction<{
      bankInformation: BankInformation;
    }> =>
    async (dispatch, getState) => {
      const uid = selectActiveId(getState());
      const { bankInformation } = await ShopApi.fetchBankInformation(uid);
      if (bankInformation) {
        dispatch(setBankInformation(bankInformation));
      } else {
        dispatch(setBankInformation(null));
      }
      return { bankInformation };
    },

  setActiveBankAccount:
    (aid: string): AsyncAction<any> =>
    async (dispatch, getState) => {
      const uid = selectActiveId(getState());
      const r = await ShopApi.setActiveBankAccount(uid, aid);
      dispatch(fetchBankInformation());
      return r;
    },

  restartPayment:
    (paymentId: string, comment: string): AsyncAction<any> =>
    async (_dispatch, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const aid = selectBankInformation(getState())?.activeAccountId || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.restartPurchase(
        paymentId,
        uid,
        bid,
        aid,
        monitorUser,
        comment,
      );
      return r;
    },

  refundPayment:
    (
      paymentId: string,
      refundMethod: string,
      comment: string,
    ): AsyncAction<any> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.refundPurchase(
        paymentId,
        uid,
        bid,
        monitorUser,
        refundMethod,
        comment,
      );
      return r;
    },

  resetPayLaterPayment:
    (paymentId: string, comment: string): AsyncAction<any> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.resetPayLaterPurchase(
        paymentId,
        uid,
        bid,
        monitorUser,
        comment,
      );
      return r;
    },

  finalizePayLaterPayment:
    (paymentId: string, refund: number, comment: string): AsyncAction<any> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.finalizePayLaterPurchase(
        paymentId,
        uid,
        refund,
        monitorUser,
        comment,
      );
      return r;
    },

  setPaymentTransfer:
    (orderNo: string): AsyncAction<any> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      return userApi.confirmTransfer(orderNo, monitorUser);
    },

  setPaymentTransferList:
    (list: OrderTransfer[]): AsyncAction<any> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      return userApi.confirmTransferList(list, monitorUser);
    },

  createOrderInvoice:
    (orderNo: string, filename: string): AsyncAction<any> =>
    async (_, getState) => {
      const uid = selectActiveId(getState());
      const bid = selectActiveBotId(getState()) || '';
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.createOrderInvoice(
        orderNo,
        uid,
        monitorUser,
        filename,
      );
      return r;
    },

  searchPaymentManual:
    (orderNo: string, amount: number): AsyncAction<OrderTransfer[]> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.searchPurchase(
        orderNo,
        amount,
        undefined,
        monitorUser,
      );
      console.log(r);
      return r;
    },

  searchPayment:
    (orderList: string): AsyncAction<OrderTransfer[]> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.searchPurchase(
        undefined,
        undefined,
        orderList,
        monitorUser,
      );
      return r;
    },

  getOrderListStatus:
    (id: string): AsyncAction<any> =>
    async (_, getState) => {
      return userApi
        .getOrderListStatus(id)
        .catch(() => userApi.getOrderListStatus(id))
        .catch(() => userApi.getOrderListStatus(id));
    },

  getOrderList:
    (data: OrderPayoutListRequest): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.getOrderList(data);
    },

  getInvitePayoutList:
    (data: OrderPayoutListRequest): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.getInvitePayoutList(data);
    },

  getInviteList:
    (data: OrderPayoutListRequest): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.getInviteList(data);
    },

  getCoupons: (): AsyncAction => async (_, _getState) => {
    return userApi.getCoupons();
  },

  getCouponList:
    (data: CouponListRequest): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.getCouponList(data);
    },

  createCoupon:
    (coupon: Coupon): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.createCoupon(coupon);
    },

  disableCoupon:
    (
      id: string,
      acceptance?: boolean,
      consumption?: boolean,
    ): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.disableCoupon(id, acceptance, consumption);
    },

  enableCoupon:
    (id: string): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.enableCoupon(id);
    },

  getPartnerList:
    (data: PartnerListRequest): AsyncAction<any> =>
    async (_, _getState) => {
      return userApi.getPartnerList(data);
    },

  fetchTroyCases:
    (csv: string): AsyncAction<{ cases: TroyCase[]; valid: boolean }> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.fetchTroyCases(csv, monitorUser);
      return r;
    },

  syncTroyCases:
    (cases: TroyCase[]): AsyncAction<{ cases: TroyCase[] }> =>
    async (_, getState) => {
      const monitorUser = selectSettingsUsername(getState());
      const r = await userApi.syncTroyCases(cases, monitorUser);
      return r;
    },

  getPendingInvitePayouts:
    (
      format: 'json' | 'csv' | 'xml',
      ids?: string[],
    ): AsyncAction<{ payouts: InvitePayout[]; csv: any; xml: any }> =>
    async (_, _getState) => {
      return userApi.getPendingInvitePayouts(
        format,
        ids?.filter((id) => !!id).join(','),
      );
    },

  invitePayoutChangeStatus:
    (
      payoutId: string,
      status: string,
    ): AsyncAction<{ result: { [key: string]: string } }> =>
    async () => {
      return userApi.invitePayoutChangeStatus(payoutId, status);
    },
};
