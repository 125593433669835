import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAssessmentDiff,
  setAssessmentTable,
  setDiff,
  setKeywords,
} from '../../info/state/infoReducer';
import styles from './AssessmentDiffDefinition.module.scss';
import { all } from '../../common/style';

export const AssessmentDiffDefinition: React.FC = () => {
  const dispatch = useDispatch();

  const { keywords, diff, table } = useSelector(selectAssessmentDiff);

  const defaultDiff = JSON.stringify(diff, null, 2);
  const [diffRaw, setDiffRaw] = useState(defaultDiff);
  const [errDiff, setErrDiff] = useState('');

  const defaultKeywords = JSON.stringify(keywords, null, 2);
  const [keywordsRaw, setKeywordsRaw] = useState(defaultKeywords);
  const [errKeywords, setErrKeywords] = useState('');

  const defaultTable = JSON.stringify(table, null, 2);
  const [tableRaw, setTableRaw] = useState(defaultTable);
  const [errTable, setErrTable] = useState('');

  const handleSaveKeywords = () => {
    const json = keywordsRaw || defaultKeywords;
    try {
      const v = JSON.parse(json);
      dispatch(setKeywords(v));
      setErrKeywords('');
    } catch {
      setErrKeywords('invalid json');
    }
  };

  const handleSaveDiff = () => {
    const json = diffRaw || defaultDiff;
    try {
      const v = JSON.parse(json);
      dispatch(setDiff(v));
      setErrDiff('');
    } catch {
      setErrDiff('invalid json');
    }
  };

  const handleSaveTable = () => {
    const json = tableRaw || defaultTable;
    try {
      const v = JSON.parse(json);
      dispatch(setAssessmentTable(v));
      setErrTable('');
    } catch {
      setErrTable('invalid json');
    }
  };

  return (
    <div className={styles.container}>
      <h3>Abweichungansicht</h3>
      <div className={all(styles.jsonContainer, styles.diffContainer)}>
        <textarea
          value={diffRaw}
          onChange={(e) => setDiffRaw(e.target.value)}
        />
        {!!errDiff && <div style={{ color: 'red' }}>{errDiff}</div>}
        <button onClick={handleSaveDiff}>Speichern</button>
      </div>

      <h3>Abweichung Schlagwörter</h3>
      <div className={styles.jsonContainer}>
        <textarea
          value={keywordsRaw}
          onChange={(e) => setKeywordsRaw(e.target.value)}
        />
        {!!errKeywords && <div style={{ color: 'red' }}>{errKeywords}</div>}
        <button onClick={handleSaveKeywords}>Speichern</button>
      </div>

      <h3>Bescheidansicht</h3>
      <div className={all(styles.jsonContainer, styles.diffContainer)}>
        <textarea
          value={tableRaw}
          onChange={(e) => setTableRaw(e.target.value)}
        />
        {!!errTable && <div style={{ color: 'red' }}>{errTable}</div>}
        <button onClick={handleSaveTable}>Speichern</button>
      </div>
    </div>
  );
};
