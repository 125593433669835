import {
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import HistoryIcon from '@material-ui/icons/History';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StorageKey } from '../../../common/storage.service';
import { AssessmentViewer } from '../../../components/AssessmentViewer/AssessmentViewer';
import { BottomSheet } from '../../../components/BottomSheet/BottomSheet';
import { Chat } from '../../../components/Chat/Chat';
import { EricResult } from '../../../components/EricResult/EricResult';
import { InformationEditDialog } from '../../../components/InformationEditDialog/InformationEditDialog';
import { InfoSidenav } from '../../../components/InfoSidenav/InfoSidenav';
import { Input } from '../../../components/Input/Input';
import { ShortTextCopy } from '../../../components/ShortTextCopy/ShortTextCopy';
import { SideNav } from '../../../components/SideNav/SideNav';
import { Table } from '../../../components/Table/Table';
import {
  selectActiveBotId,
  selectActiveId,
  selectUserBots,
} from '../../../home/state/userReducer';
import {
  EricCheckResponse,
  InfoApi,
  Information,
  RefundResult,
} from '../../state/infoApi';
import {
  InfoPageTab,
  clearInfos,
  fetchAssessment,
  fetchInfos,
  infoEditEric,
  selectAssessment,
  selectAvailableTabs,
  selectInfos,
  selectTabs,
  toggleTab,
} from '../../state/infoReducer';
import styles from './InfoPage.module.scss';
import { ComputationResult } from '../../../components/ComputationResult/ComputationResult';
import { dateToString, shortenText } from '../../../common/text';
import { useHistory } from 'react-router-dom';
import { PurchasePage } from '../PurchasePage/PurchasePage';
import { PaymentReasons } from '../../../components/PaymentReasons/PaymentReasons';

export const InfoPage: React.FC<any> = () => {
  const activeBotId = useSelector(selectActiveBotId);
  const activeUserId = useSelector(selectActiveId);
  const infos = useSelector(selectInfos);
  const assessment = useSelector(selectAssessment);
  const tabs = useSelector(selectTabs);
  const availableTabs = useSelector(selectAvailableTabs);
  const bots = useSelector(selectUserBots);
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [autorefresh, setAutoreload] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [editorId, setEditorId] = useState('');
  const [changes, setChanges] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState('');
  const [eric, setEric] = useState<EricCheckResponse | undefined>(undefined);
  const [refund, setRefund] = useState<RefundResult | null>(null);

  // TODO: find cleaner solution.
  const selected = infos.find((i) => i.id === selectedId);
  const changedValue = changes[selectedId];

  const dispatch = useDispatch();

  const loadInfos = useCallback(
    async (uid, bid, editorId) => {
      if (!uid || !bid) {
        dispatch(clearInfos());
        return;
      }

      dispatch(fetchInfos());
      InfoApi.ericCheck(uid, bid, 1, editorId).then(setEric);
    },
    [dispatch],
  );

  useEffect(() => {
    let intervalId: any;
    if (autorefresh) {
      intervalId = setInterval(
        () => loadInfos(activeUserId, activeBotId, editorId),
        4000,
      );
    }
    loadInfos(activeUserId, activeBotId, editorId);
    // fetch assessment only once
    dispatch(fetchAssessment());

    return () => clearInterval(intervalId);
  }, [activeBotId, activeUserId, editorId, autorefresh, dispatch, loadInfos]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { result } = await InfoApi.fetchRefund(
          activeUserId,
          activeBotId || '',
        );
        setRefund(result);
      } catch {}
    };
    loadData();
  }, [activeBotId, activeUserId]);

  useEffect(() => {
    if (!!activeBotId) {
      history.push({ pathname: '/information', search: `bid=${activeBotId}` });
    }
  }, [activeBotId, history]);

  const onEricFocus = (id: string) => () => setSelectedId(id);

  const onEricBlur = () => setSelectedId('');

  const onEricChange = (id: string) => (value: any) => {
    const i = infos.find((i) => i.id === id);
    if (!i) {
      return;
    }
    setSelectedId(i.id);
    setChanges((s) => ({ ...s, [i.id]: value }));
    setDialogOpen(true);
  };

  const onEricDelete = (id: string) => () => {
    const i = infos.find((i) => i.id === id);
    if (!i) {
      return;
    }
    setSelectedId(i.id);
    setChanges((s) => ({ ...s, [i.id]: undefined }));
    setDialogOpen(true);
  };

  const cols = createCols(
    ericValueInput(onEricFocus, onEricBlur, onEricChange, onEricDelete),
  );

  const handleSubmit = async (id: string, after: any, comment: string) => {
    setDialogOpen(false);
    try {
      await dispatch(infoEditEric(activeUserId, id, comment, after));
      setError('Die Information wurde erfolgreich gespeichert');
      setTimeout(() => setError(''), 4000);
    } catch {
      setError('Die Information konnte nicht geändert werden');
      setTimeout(() => setError(''), 4000);
    }
  };

  const handleToggleTab = (t: InfoPageTab) => () => dispatch(toggleTab(t));

  const activeBot = bots.find(({ id }) => id === activeBotId);

  // TODO: find cleaner solution.
  const changesPayload = {
    id: selected?.id,
    before: selected?.ericValue,
    after: changedValue,
    label: selected?.label,
  };

  const ericIsValid = !!eric && !!eric.ericResult && eric.ericResult.success;
  const handleAutorefreshToggle = () => setAutoreload((s) => !s);

  const assessmentAvailable = assessment && assessment.length;
  const assessmentVisible =
    assessmentAvailable && tabs.includes(InfoPageTab.ASSESSMENT);
  const visibleTabs = tabs.filter(
    (t) =>
      t !== InfoPageTab.ASSESSMENT ||
      (t === InfoPageTab.ASSESSMENT && assessmentVisible),
  ).length;

  return (
    <>
      <SideNav
        expanded={!assessmentVisible}
        sideNav={
          <InfoSidenav
            autorefresh={autorefresh}
            toggleAutorefresh={handleAutorefreshToggle}
            editorId={editorId}
            onEditorIdChange={setEditorId}
          />
        }
        persist={StorageKey.INFO_SIDENAV}>
        <div className={styles.content}>
          {tabs.includes(InfoPageTab.INFOS) && (
            <div className={styles.table}>
              <Table
                data={infos}
                coloumns={cols}
                collapsed={visibleTabs > 1}></Table>
            </div>
          )}
          {tabs.includes(InfoPageTab.CHAT) && !!activeBotId && (
            <div className={styles.chat}>
              <Chat uid={activeUserId} bid={activeBotId} />
            </div>
          )}
          {tabs.includes(InfoPageTab.REFUND) && !!refund && (
            <div className={styles.refund}>
              <ComputationResult result={refund} />
            </div>
          )}
          {!!assessmentVisible && (
            <div className={styles.assessment}>
              <AssessmentViewer
                assessment={assessment}
                infos={infos}
                refund={refund}
              />
            </div>
          )}
          {!!activeBot && tabs.includes(InfoPageTab.PAYMENT) && !!refund && (
            <div className={styles.refund}>
              <PaymentReasons status={activeBot} />
            </div>
          )}
          <BottomSheet
            title={
              <div>
                ERIC-Zustand:{' '}
                <span style={{ color: ericIsValid ? 'green' : 'red' }}>
                  {ericIsValid ? 'OK' : 'Nicht valide'}
                </span>
              </div>
            }>
            {eric && <EricResult eric={eric} />}
          </BottomSheet>
          <div className={styles.dataButtons}>
            {availableTabs.map((t) => (
              <Button
                style={{ backgroundColor: 'white' }}
                variant={'outlined'}
                onClick={handleToggleTab(t)}
                key={t}>
                {t}
                {tabs.includes(t) && (
                  <CheckCircleIcon
                    style={{ color: 'green' }}
                    fontSize="small"
                  />
                )}
              </Button>
            ))}
          </div>
        </div>
      </SideNav>
      <InformationEditDialog
        change={changesPayload}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmitChange={handleSubmit}
      />
      <Snackbar open={!!error} message={error} />
    </>
  );
};

function displayId(v: Information): React.ReactElement {
  return <ShortTextCopy fontSize={12} text={v.informationId} length={10} />;
}

export interface EricProps {
  value: any;
  onFocus: () => void;
  onBlur: () => void;
  onEdit: (value: any) => void;
  onDelete: () => void;
}

export const EricInput: React.FC<EricProps> = ({
  value,
  onFocus,
  onBlur,
  onEdit,
  onDelete,
}: EricProps) => {
  const [input, setInput] = useState(value);

  const edit = () => onEdit(input);
  const clear = () => setInput(value);

  const isEmpty = (v: any) => v === null || v === undefined || v === '';

  const editMode = !!input && input !== value;
  const deleteMode = isEmpty(input) && !isEmpty(value);

  const unchanged = !editMode && !deleteMode;

  const onClick = deleteMode ? onDelete : edit;
  const toolTip = deleteMode ? 'Löschen' : 'Änderung speichern';

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Input
        type={inputType(value)}
        style={{ fontSize: 'small' }}
        value={input}
        onFocus={onFocus}
        onBlur={onBlur}
        minimal
        onChange={(e) => setInput(e.target.value)}
      />
      {!unchanged && (
        <>
          <Tooltip title={toolTip}>
            <IconButton size="small" onClick={onClick}>
              {deleteMode ? <HighlightOffIcon /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Zurücksetzen">
            <IconButton size="small" onClick={clear}>
              <BlockIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
};

function inputType(value: any): 'number' | 'text' {
  if (typeof value === 'number') {
    return 'number';
  }
  return 'text';
}

const ericValueInput =
  (onFocus: any, onBlur: any, onChange: any, onDelete: any) =>
  (i: Information) => {
    const { ericValue, id, supportInformation } = i;
    if (typeof ericValue === 'object') {
      return <div>{JSON.stringify(ericValue, null, 2)}</div>;
    }
    const supportTooltip =
      !supportInformation || supportInformation.length <= 0 ? null : (
        <React.Fragment>
          <Typography color="inherit">Änderungen</Typography>
          {supportInformation.map((s, idx) => (
            <div
              key={idx}
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                borderTop: idx > 0 ? '1px solid lightgray' : 0,
              }}>
              <div>
                <b>{s.userAgent}</b>
                {` (${dateToString(s.timestamp)}): ${s.previousValue}`}
              </div>
              <i>{s.comment}</i>
            </div>
          ))}
        </React.Fragment>
      );

    const LightTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        width: 300,
        fontSize: 11,
      },
    }))(Tooltip);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EricInput
          key={id}
          value={ericValue}
          onFocus={onFocus(id)}
          onBlur={onBlur}
          onEdit={onChange(id)}
          onDelete={onDelete(id)}
        />
        {!!supportTooltip && (
          <LightTooltip
            placement="right"
            title={supportTooltip}
            style={{
              cursor: 'default',
            }}>
            <HistoryIcon />
          </LightTooltip>
        )}
      </div>
    );
  };

const createCols = (evi: any) => [
  { id: 'informationId', label: 'ID', renderFunc: displayId, width: 90 },
  { id: 'label', label: 'Beschreibung' },
  {
    id: 'value',
    label: 'Wert',
    renderFunc: displayValue('value'),
    collapsable: true,
  },
  { id: 'ericValue', label: 'ERIC-Wert', renderFunc: evi },
  { id: 'type', label: 'Typ', width: 60, collapsable: true },
  {
    id: 'position',
    label: 'Position',
    width: 55,
    renderFunc: displayValue('position'),
  },
  { id: 'context', label: 'Kontext', width: 50 },
  { id: 'ericId', label: 'ERIC-ID', width: 110 },
  {
    label: 'Berechnung',
    width: 78,
    renderFunc: displaySchema,
  },
];

export const displayValue =
  (id: string) =>
  (v: any): React.ReactElement => {
    const value = v[id];
    const t = typeof value;
    if (Array.isArray(value)) {
      return <div>{value.join(', ')}</div>;
    }
    if (t === 'object') {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    }
    return <div>{value}</div>;
  };

export const displaySchema = (v: any): React.ReactElement | null => {
  const { schemaSection } = v;
  if (!schemaSection) {
    return null;
  }
  return (
    <Tooltip title={v['schemaSection']} style={{ cursor: 'default' }}>
      <div>{shortenText(schemaSection, 8)}</div>
    </Tooltip>
  );
};
