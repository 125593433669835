import React, { useEffect, useState } from 'react';
import { BotStatus, userApi } from '../../home/state/userApi';
import { compareBots, mapBotsYear } from '../../common/bot';
import styles from './FaxViewer.module.scss';

export const FaxViewer = ({
  userId,
  botStatus,
}: {
  userId: string;
  botStatus: BotStatus[];
}) => {
  const [faxId, setFaxId] = useState('');
  const [faxData, setFaxData] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const {
        fax: { document },
      } = await userApi.faxPdf(userId, faxId);
      // console.log(document);
      setFaxData(document);
      // downloadPDF(document);
    };
    if (faxId && userId) loadData();
    else {
      setFaxData('');
      setFaxId('');
    }
    // loadData();
  }, [faxId, userId]);

  const bots = [...botStatus]
    .sort(compareBots)
    .map(mapBotsYear)
    .filter(({ year, faxId }) => !!faxId && !!year);

  const handleYearClick = (id: string) => () =>
    setFaxId((s) => (id === s ? '' : id));

  const data = `data:application/pdf;base64,${faxData}`;

  const empty = !botStatus || !userId;

  return (
    <div className={styles.container}>
      {empty && <div>Keine Faxe</div>}
      {!empty && (
        <div>
          <div className={styles.faxHeader}>
            {bots.map(({ id, year, faxId: fid }) => (
              <div
                key={id}
                style={{
                  fontWeight: fid === faxId ? 'bold' : 'normal',
                  cursor: 'regular',
                }}
                onClick={handleYearClick(fid || '')}>
                {year}
              </div>
            ))}
          </div>
          {faxData && (
            <object
              data={data}
              type="application/pdf"
              className={styles.faxObject}>
              PDF
            </object>
          )}
        </div>
      )}
    </div>
  );
};

export const SingleFaxViewer = ({
  userId,
  botStatus,
}: {
  userId: string;
  botStatus: BotStatus;
}) => {
  const [faxId, setFaxId] = useState('');
  const [faxData, setFaxData] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const {
        fax: { document },
      } = await userApi.faxPdf(userId, faxId);
      // console.log(document);
      setFaxData(document);
      // downloadPDF(document);
    };
    if (faxId && userId) loadData();
    else {
      setFaxData('');
      setFaxId('');
    }
    // loadData();
  }, [faxId, userId]);

  const handleYearClick = (id: string) => () =>
    setFaxId((s) => (id === s ? '' : id));

  const data = `data:application/pdf;base64,${faxData}`;

  const empty = !botStatus || !userId;
  const { id, year, faxId: fid } = botStatus;
  return (
    <div className={styles.container}>
      {empty && <div>Keine Faxe</div>}
      {!empty && (
        <div>
          <div className={styles.faxHeader}>
            <div
              key={id}
              style={{
                fontWeight: fid === faxId ? 'bold' : 'normal',
                cursor: 'regular',
              }}
              onClick={handleYearClick(fid || '')}>
              {year}
            </div>
          </div>
          {faxData && (
            <object
              data={data}
              type="application/pdf"
              className={styles.faxObject}>
              PDF
            </object>
          )}
        </div>
      )}
    </div>
  );
};
