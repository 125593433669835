import { AsyncAction } from '../../store';
import { TaskAction, taskApi } from './taskApi';

export const taskActions = {
  fetchAllTasks: (): AsyncAction => async (_, _getState) => {
    return taskApi.getAllBlockedTasks();
  },
  fetchTask:
    (id: string): AsyncAction =>
    async (_, _getState) => {
      return taskApi.getTask(id);
    },
  handleTask:
    (id: string, action: TaskAction, errorHint?: string): AsyncAction =>
    async (_, _getState) => {
      return taskApi.handleTask(id, action, errorHint);
    },
};
