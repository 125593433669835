import React from 'react';
import { UserDevice } from '../../home/state/userApi';
import styles from './DeviceDetail.module.scss';
import { Card } from '../Card/Card';
import { ObjectTable, TableRow } from '../ObjectTable/ObjectTable';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';

interface Props {
  minimal?: boolean;
  device: UserDevice;
}

const config: TableRow[] = [
  { label: 'Model', id: 'model' },
  { label: 'Brand', id: 'brand' },
  { label: 'Platform', id: 'platform' },
  { label: 'Name', id: 'name', collapsable: true },
  { label: 'Version', id: 'version', collapsable: true },
  { label: 'Type', id: 'type', collapsable: true },
  { label: 'PushId', id: 'pushId', collapsable: true },
  { label: 'AppName', id: 'appName' },
  { label: 'AppVersion', id: 'appVersion' },
  { label: 'OS', id: 'os', collapsable: true },
  { label: 'OS-Version', id: 'osVersion' },
  { label: 'Sprache', id: 'lang' },
  {
    label: 'ID',
    id: 'id',
    collapsable: true,
    renderFunc: (v) => (
      <ShortTextCopy text={v['id']} length={10} fontSize={14} />
    ),
  },
];

export const DeviceDetail = ({ device, minimal }: Props) => {
  return (
    <Card className={styles.container} size={'small'}>
      <ObjectTable data={device} rows={config} minimal={minimal} />
    </Card>
  );
};
