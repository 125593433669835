import { Button, LinearProgress } from '@material-ui/core';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { OrderPayoutListRequest } from '../../../../home/state/userApi';
import styles from './OrderList.module.scss';

interface Props {
  createList: (data: OrderPayoutListRequest) => Promise<string>;
}

const today = moment().startOf('D');
const monthStart = moment().add(-1, 'M').startOf('M');
const monthEnd = moment().add(-1, 'M').endOf('M');

export const OrderList = ({ createList }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [request, setRequest] = useState<OrderPayoutListRequest>({
    from: monthStart.format('DD.MM.YYYY'),
    until: monthEnd.format('DD.MM.YYYY'),
    untilPayment: today.format('DD.MM.YYYY'),
  });

  const updateOrderRequest = (key: string, value: string) => {
    setRequest((s) => ({
      ...s,
      [key]: inputToGerString(value),
    }));
  };

  const onCreateClick = async () => {
    setLoading(true);
    try {
      const csv = await createList(request);
      if (csv !== '') {
        const blob = new Blob([csv || ''], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, getFilename('Bestellungen', request));
      }
    } catch (e) {
      setError('Die Liste konnte nicht erstellt werden mit Fehler: ' + e);
    }
    setLoading(false);
  };

  return (
    <div>
      <h3>Bestellauswertung</h3>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Hier kann eine Liste der Bestellungen inkl. Zahlungszuständen erstellt
        werden. Bitte beachte, dass bei den Enddaten immer das Ende des Tages
        gilt.
      </div>
      <Spacer size="large" />
      <div className={styles.orderListInputContainer}>
        <div>Bestellungen von</div>
        <Input
          type="date"
          value={gerStringToInput(request.from)}
          onChange={(e) => updateOrderRequest('from', e.target.value)}
        />
        <div> bis </div>
        <Input
          type="date"
          value={gerStringToInput(request.until)}
          onChange={(e) => updateOrderRequest('until', e.target.value)}
        />
        <div>Zahlungsschluss am</div>
        <Input
          type="date"
          value={gerStringToInput(request.untilPayment)}
          onChange={(e) => updateOrderRequest('untilPayment', e.target.value)}
        />
      </div>

      <Spacer size="large" />

      <div>
        <Button
          className={styles.orderListButton}
          variant={'outlined'}
          size="small"
          disabled={loading}
          onClick={onCreateClick}>
          {!loading ? 'Auswertung erstellen' : 'Auswertung wird erstellt'}
        </Button>
        {loading && <LinearProgress style={{ height: 4 }} />}
      </div>
      {!!error && <div>{error}</div>}

      <Spacer />
    </div>
  );
};

function gerStringToInput(s: string) {
  const [day, month, year] = s.split('.');
  return [year, month, day].join('-');
}

function inputToGerString(s: string) {
  const [year, month, day] = s.split('-');
  return [day, month, year].join('.');
}

function getFilename(
  title: string,
  { from, until, untilPayment }: OrderPayoutListRequest,
): string {
  from = from.replaceAll('.', '_');
  until = until.replaceAll('.', '_');
  untilPayment = untilPayment.replaceAll('.', '_');

  return `${title}_von_${from}_bis_${until}_zahlungsschluss_${untilPayment}.csv`;
}
