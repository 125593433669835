import { ReactKeycloakProvider } from '@react-keycloak/web';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { apiService } from './app/common/api';
import { keycloak } from './app/keycloak';
import { handleUnauthorizedRequest } from './app/settings/state/settingsReducer';
import store from './app/store';
import './index.scss';
import * as serviceWorker from './serviceWorker';

export const Api = apiService(keycloak, store.getState, (_) => {
  if (!keycloak.authenticated) {
    store.dispatch(handleUnauthorizedRequest('logged_out'));
    return;
  }
  store.dispatch(handleUnauthorizedRequest('missing-permission'));
});

const eventLogger = (event: unknown, error: unknown) => {
  // console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens: any) => {
  // console.log('onKeycloakTokens', tokens);
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
    }}
    LoadingComponent={<div>Loading</div>}
    onEvent={eventLogger}
    onTokens={tokenLogger}>
    <Provider store={store}>
      <App />
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
