import { CircularProgress, IconButton } from '@material-ui/core';
import { AddCircleOutline, ArrowBackIos } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Spacer } from '../Spacer/Spacer';

interface Props {
  title: string;
  backPath?: string;
  backTitle?: string;
  loading?: boolean;
  addPath?: string;
  compact?: boolean;
}

export const LoadingNavHeader = ({
  title,
  backPath,
  backTitle,
  loading,
  addPath,
  compact,
}: Props) => {
  return (
    <div>
      {!!backPath && !!backTitle && (
        <Link
          to={backPath}
          style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
          <ArrowBackIos style={{ fontSize: 14 }} /> {backTitle}
        </Link>
      )}
      <Spacer size="small" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ margin: 0 }}>{title}</h1>
        <Spacer />
        {loading && <CircularProgress size={24} />}
        {!loading && !!addPath && (
          <Link to={{ pathname: addPath }}>
            <IconButton color="primary" size="small">
              <AddCircleOutline style={{ fontSize: 30 }} />
            </IconButton>
          </Link>
        )}
      </div>
      {!compact && <Spacer size="xlarge" />}
    </div>
  );
};
