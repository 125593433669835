import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  formatMonthly,
  formatWeekly,
  parseMonthly,
  parseWeekly,
} from '../../common/date';
import { Input } from '../../components/Input/Input';
import { Spacer } from '../../components/Spacer/Spacer';
import { Table, TableCol } from '../../components/Table/Table';
import { ActualValues, Settings, statsApi } from '../api/statsApi';
import styles from './KpiSettingsPage.module.scss';

export const KpiSettingsPage: React.FC<void> = () => {
  const [loading, setLoading] = useState(false);
  const [intervall, setIntervall] = useState<'monthly' | 'weekly'>('monthly');
  const [settings, setSettings] = useState<Settings | undefined>();
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const { settings: s } = await statsApi.getSettings();
      setLoading(false);
      setSettings(s);
    };
    loadData();
  }, []);

  const onChange = async (actuals: { [key: string]: ActualValues }) => {
    setSettings((s) => ({ ...s, actuals }));
  };

  const onSave = async () => {
    const { settings: newSettings } = await statsApi.updateSettings(
      settings || {},
    );
    setSettings(newSettings);
  };

  let actuals = settings?.actuals || {};
  actuals = { ...actuals, ...createIntervalls(actuals, 'monthly') };
  actuals = { ...actuals, ...createIntervalls(actuals, 'weekly') };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>KPI Einstellungen</h1>

        <div className={styles.stepContainer}>
          <p>
            Hier können die tatsächlichen Werte der Vergangenheit eingetragen
            werden, welche nicht über die Datenbank oder APIs erreichbar sind.
          </p>
        </div>

        <div className={styles.stepContainer}>
          <h3>Werte</h3>
          <Spacer size="large" />

          <div className={styles.intervallSelector}>
            <h3
              className={
                intervall === 'monthly' ? styles.intervallSelected : ''
              }
              onClick={() => setIntervall('monthly')}>
              Monatlich
            </h3>
            <Spacer size="large" />
            <h3
              className={intervall === 'weekly' ? styles.intervallSelected : ''}
              onClick={() => setIntervall('weekly')}>
              Wöchentlich
            </h3>
          </div>
          <Spacer />
          {!!settings && (
            <CostTable
              intervall={intervall}
              value={actuals}
              onChange={onChange}
            />
          )}

          <Spacer size="large" />
          <Button variant="outlined" onClick={onSave}>
            Speichern
          </Button>
        </div>
      </div>
    </div>
  );
};

export const CostTable = ({
  onChange,
  value,
  intervall,
}: {
  intervall: 'monthly' | 'weekly';
  onChange: (v: { [key: string]: ActualValues }) => void;
  value: { [key: string]: ActualValues };
}) => {
  const onEdit = (k: string, f: string, v: any) => {
    value[k][f] = v;
    onChange(value);
  };

  const cols: TableCol[] = [
    { label: 'Datum', id: 'key' },
    {
      label: 'Marketing in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.marketing || 0}
          onChange={(e) =>
            onEdit(v.key, 'marketing', parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      label: 'Umsatz in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.revenue || 0}
          onChange={(e) => onEdit(v.key, 'revenue', parseFloat(e.target.value))}
        />
      ),
    },
    {
      label: 'Fixkosten in €',
      renderFunc: (v: any) => (
        <Input
          size={12}
          step={0.01}
          style={{ width: 90, flex: 'none', border: 0 }}
          type="number"
          variant="minimal"
          value={v.fixedCost || 0}
          onChange={(e) =>
            onEdit(v.key, 'fixedCost', parseFloat(e.target.value))
          }
        />
      ),
    },
  ];

  const parseFn = intervall === 'monthly' ? parseMonthly : parseWeekly;

  const data = Object.keys(value)
    .filter((k) => value[k].intervall === intervall)
    .map((k) => ({ k, t: parseFn(k) }))
    .sort((a, b) => a.t.valueOf() - b.t.valueOf())
    .map((v) => ({ ...value[v.k], key: v.k }));

  return (
    <div className={styles.costTableContainer}>
      <Table coloumns={cols} data={data} />
    </div>
  );
};

const emptyMonthlyValues = (): ActualValues => ({
  intervall: 'monthly',
  marketing: 0,
  fixedCost: 0,
  revenue: 0,
});

const emptyWeeklyValues = (): ActualValues => ({
  intervall: 'weekly',
  marketing: 0,
  fixedCost: 0,
  revenue: 0,
});

const createIntervalls = (
  actuals: { [key: string]: ActualValues },
  intervall: 'weekly' | 'monthly',
) => {
  const until = moment();
  let stepSize = moment.duration({ months: 1 });
  let newValueFn = emptyMonthlyValues;
  let formatFn = formatMonthly;
  let parseFn = parseMonthly;

  if (intervall === 'weekly') {
    stepSize = moment.duration({ days: 7 });
    newValueFn = emptyWeeklyValues;
    formatFn = formatWeekly;
    parseFn = parseWeekly;
  }

  const values = Object.keys(actuals)
    .filter((k) => actuals[k].intervall === intervall)
    .map(parseFn)
    .sort((a, b) => a.valueOf() - b.valueOf());
  let initialValue = moment('01.01.2018', 'DD.MM.YYYY');
  if (values && values.length > 0) {
    initialValue = values[values.length - 1].clone().add(stepSize);
  }

  const start = initialValue.clone();
  const end = initialValue.clone().add(stepSize);
  const res: { [key: string]: ActualValues } = {};
  while (end.isSameOrBefore(until)) {
    res[formatFn(start)] = newValueFn();
    start.add(stepSize);
    end.add(stepSize);
  }

  return res;
};
