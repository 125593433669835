import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { routes } from 'routes';
import './App.scss';
import { Header } from './app/components/Header/Header';
import { PermissionSnackbar } from './app/components/PermissionSnackbar/PermissionSnackbar';

function App(): React.ReactElement {
  return (
    <Router>
      <Header></Header>
      <div className="content">
        <Switch>
          {routes.map(({ path, component, exact }) => (
            <Route key={path} path={path} component={component} exact={exact} />
          ))}
        </Switch>
        <PermissionSnackbar />
      </div>
    </Router>
  );
}

export default App;
