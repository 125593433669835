import React, { useCallback, useEffect, useState } from 'react';
import {
  Difference,
  selectAssessmentDiff,
  setDiff,
} from '../../info/state/infoReducer';
import {
  Assessment,
  Information,
  RefundResult,
} from '../../info/state/infoApi';
import { isNull, isNullOrUndefined } from 'util';
import { deepCopy, evalExpression } from '../../common/eval';
import styles from './AssessmentDiffTable.module.scss';
import { all } from '../../common/style';
import { MapDispatch, RootState } from '../../store';
import { connect } from 'react-redux';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';

const mapStateToProps = (state: RootState) => ({
  diffState: selectAssessmentDiff(state),
});

const mapDispatchToProps = {
  storeDiff: setDiff,
};

export interface ExternalProps {
  infos: Information[];
  assessment: Assessment[];
  refund: RefundResult;
}

type Props = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const RawAssessmentDiffTable: React.FC<Props> = ({
  diffState,
  infos,
  assessment,
  refund,
  storeDiff,
}: Props) => {
  const [diffDef, setDiffDef] = useState<Difference[]>([]);
  const [expandedRow, setExpandedRow] = useState(-1);

  useEffect(() => {
    setDiffDef(deepCopy(diffState.diff));
  }, [diffState]);

  const handleSaveDiff = useCallback(() => {
    try {
      storeDiff(diffDef);
      setExpandedRow(-1);
    } catch (e) {
      console.log('could not store diffDef', e);
    }
  }, [diffDef, storeDiff]);

  const handleDiffChange =
    (
      idx: number,
      prop: 'assessmentId' | 'otherId' | 'description' | 'snippet',
    ) =>
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const v = ev.target.value;
      setDiffDef((s) => {
        const newState = [...s];
        newState[idx][prop] = v;
        return newState;
      });
    };

  const renderDiff = (d: Difference) => {
    const {
      description,
      assessmentId,
      assessmentValue,
      otherId,
      otherValue,
      snippet,
      index,
    } = d;
    if (isNullOrUndefined(index)) {
      throw new Error('missing index of diff' + d);
    }
    const absDiff = difference(d);
    const expanded = expandedRow === index;
    const assessmentValueString = numberValue(assessmentValue);
    const otherValueString = numberValue(otherValue);
    const absDiffString = numberValue(absDiff);

    const replacementSnippet = (snippet || '')
      .replace(/\${BESCH}/g, assessmentValueString)
      .replace(/\${SB}/g, otherValueString)
      .replace(/\${DIFF}/g, absDiffString);

    const replacementSnippetHTML = replacementSnippet.replace(
      /(?:\r\n|\r|\n)/g,
      '<br>',
    );

    return (
      <div className={styles.rowContainer} key={index}>
        <div className={all(styles.row, !!snippet ? styles.hasSnippet : '')}>
          <div
            className={styles.colExpand}
            onClick={() => setExpandedRow((s) => (s === index ? -1 : index))}>
            {expanded ? '-' : '+'}
          </div>
          <div className={styles.colDesc}>{description}</div>
          <div className={styles.colValue}>{assessmentValueString}</div>
          <div className={styles.colValue}>{otherValueString}</div>
          <div
            className={all(
              styles.colValue,
              absDiff < -10 ? styles.negative : '',
              absDiff > 10 ? styles.positive : '',
            )}>
            {absDiffString}
          </div>
          <div className={styles.colValue}>
            {!!snippet && (
              <ShortTextCopy
                text={replacementSnippet}
                length={0}
                fontSize={12}
              />
            )}
          </div>
        </div>
        {expanded && (
          <div className={styles.expandedContainer}>
            <div className={styles.expandRow}>
              <div>Beschreibung</div>
              <input
                value={description}
                onChange={handleDiffChange(index, 'description')}
              />
            </div>
            <div className={styles.expandRow}>
              <div>Bescheiddefinition</div>
              <input
                value={assessmentId}
                placeholder="Bescheid-ID in der Form '${ID}'"
                onChange={handleDiffChange(index, 'assessmentId')}
              />
            </div>
            <div className={styles.expandRow}>
              <div>Vergleichswert</div>
              <input
                value={otherId}
                placeholder="Information-ID oder Berechnungsproperty in der Form '${ID}'"
                onChange={handleDiffChange(index, 'otherId')}
              />
            </div>
            <div className={styles.expandRow}>
              <div>Snippet</div>
              <textarea
                value={snippet}
                placeholder="Dein Support Snippet mit Platzhalter Werten ${BESCH}, ${SB} und ${DIFF}"
                onChange={handleDiffChange(index, 'snippet')}
              />
            </div>
            <div className={styles.expandRow}>
              <div>Support-Snippet</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: replacementSnippetHTML,
                }}
                style={{
                  background: 'white',
                  color: 'black',
                  padding: '8px 12px',
                  border: '1px solid lightgray',
                }}></div>
            </div>
            <button onClick={handleSaveDiff}>Speichern</button>
          </div>
        )}
      </div>
    );
  };

  const diff = computeDifferences(diffDef, infos, assessment, refund);
  return (
    <div>
      <div className={all(styles.row, styles.header)}>
        <div className={styles.colExpand}></div>
        <div className={styles.colDesc}>Beschreibung</div>
        <div className={styles.colValue}>Besch.-Wert</div>
        <div className={styles.colValue}>SB-Wert</div>
        <div className={styles.colValue}>Abweichung</div>
        <div className={styles.colValue}>Snippet</div>
      </div>
      {diff.map(renderDiff)}
    </div>
  );
};

export const AssessmentDiffTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawAssessmentDiffTable);

const regexp = new RegExp(/\${(.*?)}/g);

function computeDifferences(
  diff: Difference[],
  infos: Information[],
  assessment: Assessment[],
  refund: RefundResult,
): Difference[] {
  const res: Difference[] = [];

  let index = -1;
  for (const {
    description,
    assessmentId,
    otherId,
    onlyBoth,
    snippet,
  } of diff) {
    index++;
    // legacy definitions
    if (!assessmentId.includes('$') || !otherId.includes('$')) {
      const d = legacyComputation(
        infos,
        assessment,
        refund,
        description,
        assessmentId,
        otherId,
        onlyBoth,
      );
      if (d) {
        res.push({ ...d, index, snippet });
      }
      continue;
    }

    const assessmentValueFn = (id: string) => {
      const av = assessment.find(({ number }) => number === id);
      return av?.value;
    };

    const otherValueFn = (id: string) => {
      const [iid, context] = id.split('|');
      const otherValue = findOtherValue(infos, refund, iid, context, id);
      return isNull(otherValue) ? undefined : otherValue + '';
    };

    const { value: assessmentValue } = evalExpression(
      assessmentId,
      assessmentValueFn,
      regexp,
    );
    const valueNil =
      isNullOrUndefined(assessmentValue) || assessmentValue === 0;

    const otherValue = evalExpression(otherId, otherValueFn, regexp).value;
    const otherNil = isNullOrUndefined(otherValue) || otherValue === 0;

    if (valueNil && otherNil) {
      continue;
    }

    if (onlyBoth && (otherNil || valueNil)) {
      continue;
    }

    if (valueNil) {
      res.push({
        description,
        assessmentId,
        assessmentValue: '-',
        otherId,
        otherValue,
        snippet,
        index,
      });
      continue;
    }

    if (otherNil) {
      res.push({
        description,
        assessmentId,
        assessmentValue,
        otherId,
        otherValue: '-',
        snippet,
        index,
      });
      continue;
    }

    if (
      isNullOrUndefined(assessmentValue) ||
      isNullOrUndefined(otherValue) ||
      typeof assessmentValue === 'string' ||
      typeof otherValue === 'string'
    ) {
      continue;
    }

    if (Math.abs(assessmentValue - otherValue) < 5) {
      continue;
    }

    res.push({
      description,
      assessmentId,
      assessmentValue,
      otherId,
      otherValue,
      snippet,
      index,
    });
  }

  return res;
}

function findOtherValue(
  infos: Information[],
  refund: RefundResult,
  iid: string,
  context: string,
  otherId: string,
): number | null {
  const iv = infos.find(
    ({ id, ericId, context: ctx }) =>
      (id === iid || ericId === iid) && (!context || ctx === context),
  );
  const rv = findRefundValue(refund, otherId);
  const otherValue = !isNullOrUndefined(rv)
    ? parseFloat(rv)
    : parseFloat(iv?.ericValue);
  return isNaN(otherValue) ? null : otherValue;
}

function findRefundValue(r: RefundResult, k: string): any {
  return (r as { [key: string]: any })[k];
}

const numberValue = (v?: number): string => {
  const value = v;
  if (!v) {
    return '-';
  }
  if (typeof value !== 'number') {
    return value + '';
  }
  return (Math.round(value * 100) / 100).toLocaleString();
};

function difference({ assessmentValue, otherValue }: Difference): any {
  if (typeof assessmentValue !== 'number') {
    assessmentValue = 0;
  }
  if (typeof otherValue !== 'number') {
    otherValue = 0;
  }
  return Math.round((assessmentValue - otherValue) * 100) / 100;
}

function legacyComputation(
  infos: Information[],
  assessment: Assessment[],
  refund: RefundResult,
  description: string,
  assessmentId: string,
  otherId: string,
  onlyBoth?: boolean,
): Difference | null {
  const [iid, context] = otherId.split('|');

  const negativeDiff = assessmentId.startsWith('-');
  const aId = negativeDiff ? assessmentId.slice(1) : assessmentId;

  const av = assessment.find(({ number }) => number === aId);

  const value: string | null = av ? av.value : null;
  const otherValue = findOtherValue(infos, refund, iid, context, otherId);

  const otherNil = otherValue === null || otherValue === 0;

  if (value === null && otherNil) {
    return null;
  }

  if (onlyBoth && (otherNil || value === null)) {
    return null;
  }

  if (value === null) {
    return {
      description,
      assessmentId,
      assessmentValue: '-',
      otherId,
      otherValue,
    };
  }

  const numberValue = parseFloat(value) * (negativeDiff ? -1 : 1);
  if (otherValue === null) {
    return {
      description,
      assessmentId,
      assessmentValue: numberValue,
      otherId,
      otherValue: '-',
    };
  }

  const diff = numberValue - otherValue;
  if (Math.abs(diff) < 5) {
    return null;
  }

  return {
    description,
    assessmentId,
    assessmentValue: numberValue,
    otherId,
    otherValue,
  };
}
