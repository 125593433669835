import React, { useState } from 'react';
import { dateToString } from '../../common/text';
import { EricCheckResponse } from '../../info/state/infoApi';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import styles from './EricResult.module.scss';
import { all } from '../../common/style';

interface Props {
  eric: EricCheckResponse;
}

export const EricResult = ({ eric: result }: Props) => {
  const [snippetHover, setSnippetHover] = useState(false);
  const [snippetClicked, setSnippetClicked] = useState(false);

  const sd = result.submitDetails;
  let submitSnippet = '';
  if (sd) {
    submitSnippet = [
      `• Datum und Uhrzeit der Übermittlung: ${dateToString(sd.receiptDate)}`,
      `• Telenummer der Übermittlung: ${sd.telenummer}`,
      sd.ordnungsbegriff
        ? `• Ordnungsbegriff der Übermittlung: ${sd.ordnungsbegriff}`
        : '',
    ]
      .filter((s) => !!s)
      .join('\n');
  }

  const onSnippetClick = () => {
    setSnippetClicked(true);
    navigator.clipboard.writeText(submitSnippet);
    setTimeout(() => {
      setSnippetClicked(false);
    }, 75);
  };

  return (
    <div className={styles.container}>
      {renderLabelValue('Zustand', result.state)}

      {result.submitDetails && (
        <>
          <div className={styles.label}>Abgabesnippet</div>
          <pre
            className={all(
              styles.snippet,
              snippetClicked ? styles.snippetClicked : '',
            )}
            onMouseEnter={() => setSnippetHover(true)}
            onMouseLeave={() => setSnippetHover(false)}
            onClick={onSnippetClick}>
            {submitSnippet}
            {(snippetHover || snippetClicked) && (
              <FileCopyIcon fontSize={'small'} className={styles.snippetIcon} />
            )}
          </pre>
        </>
      )}

      {renderLabelValue('Letzte Änderung', result.timestamp)}
      {renderLabelValue('Abgabe gestartet', result.submitStarted)}
      {renderLabelValue('Abgabe beendet', result.submitFinished)}
      {renderLabelValue('Abgabe Erfolg', result.submitResult?.success)}
      {renderLabelValue(
        'Abgabe Fehlernachricht',
        result.submitResult?.errorMessage,
      )}
      {renderLabelValue('Abgabe Fehlercode', result.submitResult?.errorCode)}
      {renderLabelValue('Abgabe Ergebnis', result.submitResult?.response)}
      {renderLabelValue(
        'Abgabe Serverantwort',
        result.submitResult?.server_response,
      )}
      {renderLabelValue(
        'Validierung Fehlernachricht',
        result.validateResult?.error_message,
      )}
      {renderLabelValue(
        'Validierung Ergebnis',
        result.validateResult?.response,
      )}
      {renderLabelValue('Mapper Daten', result.mapperResponse?.estdata)}
      {renderLabelValue(
        'Mapper Fehlernachricht',
        result.mapperResponse?.errorMessage,
      )}
    </div>
  );
};

const renderLabelValue = (l: string, v: any) => {
  if (!v) {
    return null;
  }
  return (
    <>
      <div className={styles.label}>{l}</div>
      <pre>{v}</pre>
    </>
  );
};
