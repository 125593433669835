import React from 'react';
import { all } from '../../common/style';
import styles from './Table.module.scss';

interface Props {
  coloumns: TableCol[];
  data: any[];
  rowNo?: boolean;
  noHeader?: boolean;
  collapsed?: boolean;
  emptyText?: string;
  variant?: 'minimal' | 'material' | 'default';
  onRowClicked?: (e: React.MouseEvent, idx: number) => void;
}

export const Table = ({
  emptyText,
  coloumns,
  data,
  rowNo,
  collapsed,
  noHeader,
  variant = 'default',
  onRowClicked,
}: Props) => {
  if (emptyText && data.length <= 0) {
    return (
      <div>
        <b>{emptyText}</b>
      </div>
    );
  }

  const cols = coloumns.filter(({ collapsable }) => !collapsed || !collapsable);

  const renderHeader = () =>
    noHeader ? null : (
      <tr>
        {!!rowNo && <th style={{ width: 20 }}></th>}
        {cols.map(({ label, width }) => (
          <th style={{ width: width }} key={label}>
            {label}
          </th>
        ))}
      </tr>
    );

  const renderRow = (i: any, idx: number) => {
    return (
      <tr
        key={i.id + ' ' + idx}
        onClick={(e) => {
          if (!!onRowClicked) {
            onRowClicked(e, idx);
          }
        }}>
        {rowNo && <td style={{ width: 20 }}>{idx + 1}.</td>}
        {cols.map(({ id, width, renderFunc }, colIndex) => (
          <td
            key={id + ' ' + colIndex}
            style={{ width: width, maxWidth: width }}>
            {!!renderFunc && renderFunc(i, idx)}
            {!renderFunc && !!id && i[id]}
          </td>
        ))}
      </tr>
    );
  };

  const tableClass = {
    default: styles.default,
    minimal: styles.minimal,
    material: styles.material,
  };

  const hover = !!onRowClicked ? styles.rowHover : '';

  return (
    <div className={styles.container}>
      <table className={all(styles.table, tableClass[variant], hover)}>
        <thead>{renderHeader()}</thead>
        {data && <tbody>{data.map(renderRow)}</tbody>}
      </table>
    </div>
  );
};

export interface TableCol<T = any> {
  label: string;
  collapsable?: boolean;
  width?: number;
  id?: string;
  renderFunc?: (
    v: T,
    idx: number,
  ) => React.ReactElement | string | number | null | undefined;
}
