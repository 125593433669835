import { Button, LinearProgress } from '@material-ui/core';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import { Spacer } from '../../../../components/Spacer/Spacer';
import { Coupon, CouponListRequest } from '../../../../home/state/userApi';
import styles from './CouponList.module.scss';

interface Props {
  coupons: Coupon[];
  createList: (data: CouponListRequest) => Promise<string>;
}

const monthStart = moment().startOf('M');
const monthEnd = moment().endOf('M');

export const CouponList = ({ coupons, createList }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [request, setRequest] = useState<CouponListRequest>({
    from: monthStart.format('DD.MM.YYYY'),
    until: monthEnd.format('DD.MM.YYYY'),
  });
  const [couponSelection, setCouponSelection] = useState<
    Record<string, boolean>
  >({});
  const [couponSelectionExpanded, setCouponSelectionExpanded] = useState(false);

  const updateRequest = (key: string, value: string) => {
    setRequest((s) => ({
      ...s,
      [key]: inputToGerString(value),
    }));
  };

  const onCreateList = async () => {
    setLoading(true);
    try {
      request.couponIds = Object.keys(couponSelection).filter(
        (id) => !!couponSelection[id],
      );

      const csv = await createList(request);
      if (csv !== '') {
        const blob = new Blob([csv || ''], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, getFilename('Gutscheinauswertung_', request));
      }
    } catch (e) {
      setError('Die Liste konnte nicht erstellt werden mit Fehler: ' + e);
    }
    setLoading(false);
  };

  const toggleAll = (checked: boolean) => {
    if (!checked) {
      setCouponSelection({});
      return;
    }
    const newSelection = coupons.reduce((p, c) => ({ ...p, [c.id]: true }), {});
    setCouponSelection(newSelection);
  };

  const toggleSingle = (id: string, checked: boolean) => {
    setCouponSelection((s) => ({ ...s, [id]: checked }));
  };

  const onSelectionClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    setCouponSelectionExpanded((s) => !s);
  };

  const selectedCouponCount = Object.keys(couponSelection).filter(
    (id) => couponSelection[id],
  ).length;

  return (
    <div onClick={() => setCouponSelectionExpanded(false)}>
      <h3>Gutscheinauswertung</h3>
      <Spacer size="small" />
      <div style={{ fontSize: 'small' }}>
        Hier kann eine Liste der eingelösten Gutscheine erstellt werden. Bitte
        beachte, dass bei den Enddaten immer das Ende des Tages gilt.
      </div>
      <Spacer size="large" />
      <div className={styles.orderListInputContainer}>
        <div>Gutscheinauswertung von</div>
        <Input
          type="date"
          value={gerStringToInput(request.from)}
          onChange={(e) => updateRequest('from', e.target.value)}
        />
        <div> bis </div>
        <Input
          type="date"
          value={gerStringToInput(request.until)}
          onChange={(e) => updateRequest('until', e.target.value)}
        />
        <div>Gutscheine</div>
        <CouponSelection
          onClick={onSelectionClick}
          expanded={couponSelectionExpanded}
          coupons={coupons}
          selection={couponSelection}
          toggleAll={toggleAll}
          toggle={toggleSingle}
        />
      </div>

      <Spacer size="large" />

      <div style={{ display: 'flex' }}>
        <Button
          className={styles.orderListButton}
          variant={'outlined'}
          size="small"
          disabled={loading || selectedCouponCount <= 0}
          onClick={onCreateList}>
          {!loading ? 'Auswertung erstellen' : 'Auswertung wird erstellt'}
        </Button>

        <Spacer />

        {loading && <LinearProgress style={{ height: 4 }} />}
      </div>
      {!!error && <div>{error}</div>}

      <Spacer />
    </div>
  );
};

const CouponSelection = ({
  expanded,
  coupons,
  selection,
  onClick,
  toggleAll,
  toggle,
}: {
  expanded: boolean;
  coupons: Coupon[];
  selection: Record<string, boolean>;
  onClick: (e: React.MouseEvent) => void;
  toggleAll: (checked: boolean) => void;
  toggle: (id: string, checked: boolean) => void;
}) => {
  const selected = (id: string) => {
    return !!selection[id];
  };
  const selectedCoupons = Object.keys(selection)
    .filter((id) => !!selection[id])
    .map((id) => coupons.find((c) => id === c.id))
    .filter((c) => !!c);
  const selectedCount = selectedCoupons.length;
  const shownSelected = selectedCoupons.slice(0, Math.min(selectedCount, 5));
  const allSelected = selectedCount === coupons.length;

  const onExpandedClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
  };

  return (
    <div className={styles.couponSelectionContainer}>
      <div className={styles.couponSelectionSummary} onClick={onClick}>
        {selectedCount <= 0 && (
          <div className={styles.couponSelectionSummaryCoupon}>
            Keine Coupons ausgewählt
          </div>
        )}
        {allSelected && (
          <div className={styles.couponSelectionSummaryCoupon}>
            Alle Coupons ausgewählt
          </div>
        )}
        {selectedCount > 0 &&
          !allSelected &&
          shownSelected.map((c, idx) => (
            <div
              key={c?.id || idx}
              className={styles.couponSelectionSummaryCoupon}>
              {couponShortTitle(c)}
            </div>
          ))}
        {selectedCount > 5 && !allSelected && (
          <div className={styles.couponSelectionSummaryCoupon}>
            {`${selectedCount - 5} Weitere`}
          </div>
        )}
      </div>
      {!!expanded && (
        <div className={styles.couponSelectionList} onClick={onExpandedClick}>
          <div className={styles.couponSelectionCoupon}>
            <input
              type="checkbox"
              checked={allSelected}
              onChange={(e) => toggleAll(!!e.target.checked)}
            />
            <div>Alle auswählen</div>
          </div>
          {coupons.map((c) => (
            <div
              key={c.id}
              className={styles.couponSelectionCoupon}
              onClick={() => toggle(c.id, !selection[c.id])}>
              <input type="checkbox" checked={selected(c.id)} readOnly />
              <div>{couponTitle(c)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function gerStringToInput(s: string) {
  const [day, month, year] = s.split('.');
  return [year, month, day].join('-');
}

function inputToGerString(s: string) {
  const [year, month, day] = s.split('-');
  return [day, month, year].join('.');
}

function getFilename(
  title: string,
  { from, until }: CouponListRequest,
): string {
  from = from.replaceAll('.', '_');
  until = until.replaceAll('.', '_');

  return `${title}_von_${from}_bis_${until}.csv`;
}

const couponTitle = (c: Coupon) => {
  if (!c.codes || c.codes.length <= 0) {
    return `[ohne Code]: ${c.title}`;
  }
  if (c.codes.length > 5) {
    return `[${c.codes.length} Codes]: ${c.title}`;
  }
  return `[${c.codes.join(', ')}]: ${c.title}`;
};

const couponShortTitle = (c?: Coupon) => {
  if (!c) {
    return '?';
  }
  if (!!c.codes && c.codes.length > 0) {
    return c.codes[0];
  }
  if (c.title) {
    return c.title;
  }
  return '?';
};
