import { Button, Chip, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { compareBots, mapBotsYear } from '../../../common/bot';
import { dateToString } from '../../../common/text';
import { BotStatusSelection } from '../../../components/BotStatus/BotStatusSelection';
import { Card } from '../../../components/Card/Card';
import { CreditDetails } from '../../../components/CreditDetails/CreditDetails';
import { DeviceDetail } from '../../../components/DeviceDetail/DeviceDetail';
import { FaxViewer } from '../../../components/FaxViewer/FaxViewer';
import { InfoTable } from '../../../components/InfoTable/InfoTable';
import { Input } from '../../../components/Input/Input';
import { ShortTextCopy } from '../../../components/ShortTextCopy/ShortTextCopy';
import { User, UserDevice, userApi } from '../../../home/state/userApi';
import { selectProfile } from '../../../home/state/userReducer';
import { InfoApi, Information } from '../../../info/state/infoApi';
import { selectSettingsUsername } from '../../../settings/state/settingsReducer';
import {
  OrderUpdateType,
  ShopApi,
  ShopCredit,
  TrustResult,
  shopUrl,
} from '../../state/shopApi';
import {
  fetchOrder,
  selectOrder,
  updateOrderStatus,
} from '../../state/shopReducer';
import styles from './OrderDetailPage.module.scss';

let timeoutId: any;

export const OrderDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oid } = useParams<{ oid: string }>();
  const richOrder = useSelector(selectOrder(oid || ''));
  const [user, setUser] = useState<User | null>(null);
  const selectedUser = useSelector(selectProfile);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  // const [otherOrders, setOtherOrders] = useState<RichOrder[]>([]);
  const [devices, setDevices] = useState<UserDevice[]>([]);
  const [credit, setCredit] = useState<ShopCredit | undefined>(undefined);
  const [comment, setComment] = useState('');
  const [mentions, setMentions] = useState<string[]>([]);
  const [error, setError] = useState('');
  const monitorUser = useSelector(selectSettingsUsername);

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      try {
        await dispatch(fetchOrder(oid || ''));
      } catch {
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [dispatch, oid]);

  useEffect(() => {
    if (!selectedUser || !richOrder) {
      return;
    }
    if (selectedUser.id !== richOrder.order.userId) {
      history.push('/shop');
    }
  }, [selectedUser, richOrder, history]);

  useEffect(() => {
    if (richOrder && oid !== richOrder.order._id) {
      history.push(`/shop/${richOrder.order._id}`);
    }
  }, [history, oid, richOrder]);

  useEffect(() => {
    const f = async () => {
      if (richOrder) {
        const { userId } = richOrder.order;
        const { user } = await userApi.fetchProfile(userId);
        setUser(user || null);
        // const { orders } = await ShopApi.fetchOrders({ userId });
        // setOtherOrders(orders);
        const { devices: dd } = await userApi.fetchDevices(userId);
        setDevices(dd);
        const { credit: c } = await ShopApi.fetchCredit(userId);
        setCredit(c);
      }
    };
    f();
  }, [richOrder, dispatch]);

  if (loading && !richOrder) {
    return <div>Lade Bestellung mit ID {oid}...</div>;
  }

  if (!loading && !richOrder) {
    return <div>Bestellung mit ID {oid} konnte nicht gefunden werden</div>;
  }

  const { profile, order, swOrder, trust } = richOrder || {};

  const changeOrderState = (t: OrderUpdateType) => async () => {
    setUpdating(true);
    try {
      const msg =
        mentions.length <= 0 ? comment : `${comment} (${mentions.join(', ')})`;
      console.log('message', msg);
      await dispatch(updateOrderStatus(order.userId, order._id, t, msg));
      setComment('');
      setMentions([]);
    } catch {
      setError('Die Bestellung konnte nicht geändert werden');
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setError(''), 3000);
    } finally {
      setUpdating(false);
    }
  };

  const createTicket = () => async () => {
    setUpdating(true);
    try {
      await ShopApi.createTicket(order._id);
      await dispatch(fetchOrder(order._id));
      setComment('');
      setMentions([]);
    } catch {
      setError('Ticket konnte nicht erstellt werden');
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setError(''), 3000);
    } finally {
      setUpdating(false);
    }
  };

  const handleToggle = (s: string) => () => {
    const mm = [...mentions];
    const index = mm.indexOf(s);
    if (index < 0) {
      mm.push(s);
    } else {
      mm.splice(index, 1);
    }
    setMentions(mm);
  };

  // const otherVisible = !!otherOrders && otherOrders.length > 1;

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.topRow}>
          {renderOrder(order)}
          {renderProfile(user || profile)}
          {renderTrust(trust)}
          {renderSwOrder(swOrder)}
          {renderSwOrderAddresses(swOrder)}
        </div>

        <div className={styles.bottomRow}>
          {renderDevices(devices)}
          {renderChanges(order)}
          {renderCredits(credit)}
        </div>
        {/* {otherVisible && (
          <div style={{ margin: 10 }}>
            <Card title="Weitere Bestellungen">
              <>
                {otherOrders && otherOrders.length > 0 ? (
                  <OrdersTable
                    selected={oid}
                    hideUserId
                    orders={otherOrders}
                  ></OrdersTable>
                ) : (
                  'Keine weiteren Bestellung'
                )}
              </>
            </Card>
          </div>
        )} */}
      </div>

      <div className={styles.inputContainer}>
        <br />
        <h3>Aktionen</h3>
        <br />
        {error && (
          <Alert className={styles.alert} severity="warning">
            {error}
          </Alert>
        )}
        <Input
          placeholder="Kommentar"
          value={comment}
          className={styles.inputComment}
          autoFocus
          onChange={(ev) => setComment(ev.target.value)}></Input>

        <div className={styles.mentionContainer}>
          <h4>Ticket zuweisen</h4>
          <div>
            {mentionConfig
              .filter(({ s }) => s !== monitorUser)
              .map(({ l, s }) => (
                <Chip
                  key={l}
                  variant={mentions.includes(s) ? 'default' : 'outlined'}
                  label={l}
                  color={mentions.includes(s) ? 'primary' : 'default'}
                  size="small"
                  clickable
                  style={{ marginRight: 4, marginBottom: 4 }}
                  onClick={handleToggle(s)}
                  onDelete={handleToggle(s)}
                  deleteIcon={<DoneIcon />}
                />
              ))}
          </div>
        </div>

        <Button
          disabled={updating}
          onClick={changeOrderState(OrderUpdateType.DECLINE)}>
          Bestellung Ablehnen
        </Button>
        <Button
          disabled={updating}
          onClick={changeOrderState(OrderUpdateType.CANCEL)}>
          Bestellung Stornieren
        </Button>
        <Button
          disabled={updating}
          onClick={changeOrderState(OrderUpdateType.REVIEW)}>
          Bestellung Untersuchen
        </Button>
        <Button
          disabled={updating}
          onClick={changeOrderState(OrderUpdateType.PERMIT)}>
          Bestellung Freigeben
        </Button>
        <Button
          disabled={updating}
          onClick={changeOrderState(OrderUpdateType.COMMENT)}>
          Kommentieren
        </Button>
        <Button disabled={updating} onClick={createTicket()}>
          Ticket erstellen
        </Button>

        <br />
      </div>
      <br />
      <h2>Abgaben</h2>
      {user && <OrderInfos user={user} />}

      <h2>Faxe</h2>
      <FaxViewer botStatus={user?.graphStatus || []} userId={user?.id || ''} />
    </div>
  );
};

const renderProfile = (user?: User) => {
  if (!user) return null;
  const { forename, surname, email, id, added } = user;
  return (
    <Card title="Profil">
      <>
        <div>
          Name: {forename} {surname}
        </div>
        <div>ID: {id}</div>
        <div>E-Mail: {email}</div>
        <div>Erstellt: {dateToString(added || '')}</div>
      </>
    </Card>
  );
};

const renderTrust = (t?: TrustResult) => (
  <Card title="Trust">
    <>
      {(t?.scores || []).map(({ name, score, description, annotation }) => (
        <div key={name}>
          {name}: {score} - {description} - {annotation}
        </div>
      ))}
    </>
  </Card>
);

const renderOrder = (o: any) => {
  return (
    <Card title="Steuerbot Bestellung">
      <>
        <b>Status: {o.status}</b>
        <div>
          <span>Nummer: </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={shopUrl + '/admin#/sw/order/detail/' + o._id}>
            {o.orderNumber}
          </a>
        </div>
        <div>Betrag: {o.amount}€</div>
        <div>Offen: {o.openAmount} €</div>
        <div>Mit Soforterstattung: {o.refund || 0} €</div>
        <div>
          <b>
            Bezahlmethode: {o.isRefundPayment ? 'Soforterst.' : 'Lastschrift'}
          </b>
        </div>
        <div>Zeitpunkt: {dateToString(o.dateTime)}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ID: <ShortTextCopy text={o._id} length={8} fontSize={14} />
        </div>
      </>
    </Card>
  );
};

const renderChanges = (o: any) => {
  const { supportInformation } = o;
  if (!supportInformation)
    return <Card title="Änderungsverlauf" className={styles.cardMedium} />;
  return (
    <Card title="Änderungsverlauf" className={styles.cardMedium}>
      <div className={styles.supportInfos}>
        {supportInformation.map(
          (
            { userAgent, timestamp, comment, value, previousValue }: any,
            idx: number,
          ) => (
            <div key={idx} className={styles.supportInfoContainer}>
              <div className={styles.supportHeader}>
                {userAgent} <br /> {dateToString(timestamp)}
              </div>
              <div className={styles.supportComment}>{comment}</div>
              <div className={styles.supportPrev}>
                {value !== previousValue ? (
                  <>
                    <b>{previousValue}</b> ⇒ <b>{value}</b>
                  </>
                ) : (
                  <>
                    Keine Änderung: <b>{value}</b>
                  </>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </Card>
  );
};

const renderSwOrder = (o: any) => {
  const { amountTotal, lineItems } = o || {};

  return (
    <Card title="Shopware Bestellung" className={styles.cardMedium}>
      <table>
        <thead>
          <tr>
            <th>
              <b>Artkel</b>
            </th>
            <th>
              <b>Anzahl</b>
            </th>
            <th>
              <b>Preis</b>
            </th>
            <th>
              <b>Gesamtwert</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {lineItems &&
            lineItems.map(({ label, quantity, unitPrice, totalPrice }: any) => (
              <tr key={label}>
                <td>{label}</td>
                <td>{quantity}</td>
                <td>{unitPrice} €</td>
                <td>{totalPrice} €</td>
              </tr>
            ))}
          <tr>
            <td>
              <b>Summe</b>
            </td>
            <td></td>
            <td></td>
            <td>
              <b>{amountTotal} €</b>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

const renderSwOrderAddresses = (o: any) => {
  if (!o) return <Card title={'Adresse'}></Card>;

  return (o.addresses || []).map((a: any, i: number) => (
    <Card key={i} title={'Adresse' + (i + 1)}>
      <>
        <div>
          {a.firstName} {a.lastName}
        </div>
        <div>{a.street}</div>
        <div>
          {a.zip} {a.city}
        </div>
        <div>{a.country}</div>
      </>
    </Card>
  ));
};

const OrderInfos = ({ user }: { user: User }) => {
  const { id: userId, graphStatus } = user;
  const bots = graphStatus
    .sort(compareBots)
    .map(mapBotsYear)
    .filter(({ year }) => !!year);
  const [botId, setBotId] = useState('');
  const [loading, setLoading] = useState(false);
  const [infos, setInfos] = useState<{ [key: string]: Information[] }>({});

  useEffect(() => {
    const loadData = async () => {
      if (!botId) {
        return;
      }
      setLoading(true);
      const { data } = await InfoApi.fetchInfo([], userId, botId);
      setLoading(false);
      setInfos((s) => ({ ...s, [botId]: data }));
    };
    loadData();
  }, [botId, userId]);

  const handleBotClick = (id: string) => () => setBotId(id);
  const botInfos = infos[botId];
  return (
    <div>
      <div className={styles.infoHeader}>
        {bots.map((s) => (
          <BotStatusSelection
            key={s.id}
            status={s}
            selected={s.id === botId}
            handleBotClick={handleBotClick(s.id)}
          />
        ))}
      </div>
      <div className={styles.infoTable}></div>
      {loading && <CircularProgress size={30} />}
      {botInfos && botInfos.length > 0 && (
        <InfoTable infos={botInfos} minimal />
      )}
    </div>
  );
};

const renderDevices = (devices: UserDevice[]) => (
  <Card title="Geräte">
    <>
      {(!devices || devices.length <= 0) && <div>Keine Geräte gefunden</div>}
      {devices && devices.length > 0 && (
        <div className={styles.devices}>
          {devices.map((d) => (
            <DeviceDetail key={d.id} device={d} minimal />
          ))}
        </div>
      )}
    </>
  </Card>
);

const renderCredits = (credit?: ShopCredit) => (
  <Card title="Credits" className={styles.cardMedium}>
    <CreditDetails credit={credit} />
  </Card>
);

const mentionConfig = [
  { l: 'Jochen', s: 'jochen@steuerbot.com' },
  { l: 'Marc', s: 'marc@steuerbot.com' },
  { l: 'Tim', s: 'tim@steuerbot.com' },
  { l: 'Dominic', s: 'dominic@steuerbot.com' },
  { l: 'Mattias', s: 'matthias@steuerbot.com' },
  { l: 'Friedolin', s: 'friedolin@steuerbot.com' },
  { l: 'Jens', s: 'jens@steuerbot.com' },
];
