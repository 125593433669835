import React, { useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { shortenText } from '../../common/text';

declare let ClipboardItem: {
  prototype: ClipboardItem;
  new (objects: Record<string, Blob>): ClipboardItem;
};

interface ClipboardItem {
  readonly types: string[];
  getType: (type: string) => Promise<Blob>;
}

export interface Props {
  text: string;
  title?: string;
  fontSize?: number;
  length?: number;
}

export const ShortTextCopy: React.FC<Props> = ({
  text,
  title,
  length,
  fontSize,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const textHTML = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const onCopy = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    const clipboard = navigator.clipboard as any;
    clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
        'text/html': new Blob([textHTML], { type: 'text/html' }),
      }),
    ]);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };
  let shortText = text;
  if (length === 0) {
    shortText = '';
  } else if (!!length && length > 0) {
    shortenText(text, length);
  }

  return (
    <div
      style={{
        display: 'flex',
        cursor: 'default',
        color: copied ? 'green' : '',
        alignItems: 'center',
      }}>
      <div
        style={{ fontSize, fontFamily: 'Courier, monospace', marginRight: 4 }}>
        {title || shortText}
      </div>
      <FileCopyIcon style={{ fontSize }} onClick={onCopy} />
    </div>
  );
};
