import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { FormContainer } from '../../../components/FormContainer/FormContainer';
import { Input } from '../../../components/Input/Input';
import { LoadingNavHeader } from '../../../components/LoadingNavHeader/LoadingNavHeader';
import { MapDispatch, RootState } from '../../../store';
import { NotificationMessage } from '../../state/notificationApi';
import {
  isApiError,
  notificationsActions,
} from '../../state/notificationsActions';
import { selectMessage } from '../../state/notificationsReducer';
import { NotificationMessageBuilder } from '../NotificationMessageBuilder/NotificationMessageBuilder';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  fetchMessage: notificationsActions.fetchMessage,
  createMessage: notificationsActions.createMessage,
  updateMessage: notificationsActions.updateMessage,
  deleteMessage: notificationsActions.deleteMessage,
  sendMessage: notificationsActions.sendMessage,
};

type Props = ReturnType<typeof mapStateToProps> &
  MapDispatch<typeof mapDispatchToProps>;

const NotificationMessageDetail = ({
  fetchMessage,
  createMessage,
  updateMessage,
  deleteMessage,
  sendMessage,
}: Props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: messageId } = useParams<{ id: string }>();
  const message = useSelector(selectMessage(messageId));
  const [loading, setLoading] = useState(false);
  const backPath = path.replaceAll('/:id', '');

  const inCreateMode = messageId === 'new';

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const err = await fetchMessage(messageId);
      if (!!err) {
        history.replace(backPath);
        return;
      }
      setLoading(false);
    };
    if (!inCreateMode) {
      loadData();
    }
  }, []);

  const onUpdate = async (m: NotificationMessage) => {
    setLoading(true);
    if (!inCreateMode) {
      await updateMessage(m); // TODO: handle error
      setLoading(false);
      return;
    }

    const res = await createMessage(m);
    if (isApiError(res)) {
      return; // TODO: handle error
    }
    setLoading(false);
    const newPath = path.replace(':id', res.id);
    history.replace(newPath);
  };

  const onDelete = async (id: string) => {
    if (inCreateMode) {
      history.replace(backPath);
      return;
    }

    setLoading(true);
    const err = await deleteMessage(id);
    if (!err) {
      history.replace(backPath);
    }
  };

  const onSendMessage = async (userIds: string[]) => {
    setLoading(true);
    await sendMessage(message.id, userIds);
    setLoading(false);
  };

  return (
    <div>
      <LoadingNavHeader
        title="Nachricht bearbeiten"
        loading={loading}
        backPath={backPath}
        backTitle="Nachrichten"
      />

      {!!message && (
        <NotificationMessageBuilder
          notification={message}
          onUpdateNotification={onUpdate}
          onDeleteNotification={onDelete}
          createMode={inCreateMode}
          onSendMessage={onSendMessage}
        />
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationMessageDetail);
