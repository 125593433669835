import moment, { Moment, unitOfTime } from 'moment';

export const datePatternMinutes = 'DD.MM.YY, HH:mm';
export const datePatternDays = 'DD.MM.YY';
export const datePatternInput = 'DD.MM.YYYY';
export const dateTimePatternInput = 'YYYY-MM-DDTHH:mm';

// copied from moment library
const allowedDurationSuffixes: unitOfTime.Base[] = [
  'y',
  'M',
  'w',
  'd',
  'h',
  'm',
  's',
  'ms',
];

const regexp = new RegExp(`(^\\d*)(${allowedDurationSuffixes.join('|')})`);

export const nanosecondToString = (v: number) => {
  const dur = moment.duration(v / 1_000_000, 'ms');
  if (dur.asMilliseconds() < 1) {
    return undefined;
  }

  for (let i = 0; i < allowedDurationSuffixes.length; i++) {
    const s = allowedDurationSuffixes[i];
    const ds = dur.as(s);
    if (ds % 1 === 0) {
      return `${ds}${s}`;
    }
  }

  const s = allowedDurationSuffixes[allowedDurationSuffixes.length - 1];
  const ds = dur.as(s);
  return `${Math.round(ds)}${s}`;
};

export const stringToNanosecond = (s: string): number | undefined => {
  const match = s.match(regexp);
  if (match === null) {
    return undefined;
  }
  const [_, n, u] = match;
  const dur = moment.duration(n, u as any);
  return dur.asMilliseconds() * 1_000_000;
};

export const inputStringToDate = (d?: string) => {
  if (!d) {
    return undefined;
  }
  const m = moment(d, datePatternInput, true);
  return m.isValid() ? m.toDate() : undefined;
};

export const inputStringToDateTime = (d?: string) => {
  if (!d) {
    return undefined;
  }
  const m = moment(d, dateTimePatternInput, true);
  return m.isValid() ? m.toDate() : undefined;
};

export const isoStringToDate = (d?: string) => {
  if (!d) {
    return undefined;
  }
  const m = moment(d);
  return m.isValid() ? m.toDate() : undefined;
};

export const dateToISOString = (d?: Date) => {
  if (!d) {
    return undefined;
  }
  const m = moment(d);
  return m.isValid() ? m.toISOString() : undefined;
};

export const dateToInputString = (d: Date) =>
  moment(d).format(datePatternInput);

export const dateTimeToInputString = (d: Date) =>
  moment(d).format(dateTimePatternInput);

export const timeDiff = (
  from?: string,
  until?: string,
): moment.Duration | undefined => {
  if (!from || !until) {
    return undefined;
  }
  const dateFrom = moment(from);
  const dateUntil = moment(until);
  if (!dateFrom.isValid() || !dateUntil.isValid()) {
    return undefined;
  }
  if (dateFrom.isAfter(dateUntil)) {
    return undefined;
  }
  return moment.duration(dateUntil.diff(dateFrom));
};

export const readableDuration = (dur: moment.Duration): string => {
  return [
    { v: dur.years(), l: 'y' },
    { v: dur.weeks(), l: 'w' },
    { v: dur.days(), l: 'd' },
    { v: dur.hours(), l: 'h' },
    { v: dur.minutes(), l: 'm' },
    { v: dur.seconds(), l: 's' },
    { v: dur.milliseconds(), l: 'ms' },
  ]
    .filter(({ v }) => v > 0)
    .filter((_, idx) => idx < 2)
    .map(({ v, l }) => v + l)
    .join(' ');
};

export const timeDiffReadable = (from?: string, until?: string) => {
  const dur = timeDiff(from, until);
  if (dur === undefined) {
    return undefined;
  }
  return readableDuration(dur);
};

export const formatMonthly = (m: Moment) => {
  return m.format('MM.YYYY');
};

export const formatWeekly = (m: Moment) => {
  const week = `${m.isoWeek()}`.padStart(2, '0');
  return `KW${week}.${m.isoWeekYear()}`;
};

export const parseWeekly = (s: string) => {
  s = s.replace('KW', '');
  const kwYYYY = s.split('.');
  const isoWeek = parseInt(kwYYYY[0]);
  const isoWeekYear = parseInt(kwYYYY[1]);
  return moment()
    .isoWeek(isoWeek)
    .isoWeekYear(isoWeekYear)
    .isoWeekday(1)
    .startOf('day');
};

export const parseMonthly = (s: string) => {
  return moment(s, 'MM.YYYY');
};
