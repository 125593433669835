import React from 'react';
import { Information } from '../../info/state/infoApi';
import { displayValue } from '../../info/page/InfoPage/InfoPage';
import { Table } from '../Table/Table';

const importantIds = [
  'ByjJU4Rvb',
  'SkHRBVAv-',
  'B1Cf09_8M',
  'SJtmAcu8z',
  'ryj-7O4q-',
  'SyVIdHHGf',
  'SyVIdHHGf',
  'H1XwtfJeM',
  'H1XwtfJeM',
  'SqXqljRMM',
  'HJFRF7PrM',
  'XgI1ldZjR',
  'SkGWkUiIZ',
  'pkHNQbOpp',
  'HyPlZwQDW',
  'SJmbzwQDb',
  'rJZ-fw7PZ',
  'ByURUGZEz',
  'SylFLvSGf',
  'Bk9C8wHMz',
  'HypoIwHGf',
  'B17l94XeM',
  'SkBcRown-',
  'B1W5Oa9Lf',
  'H1AjHQUHM',
];

const cols = [
  { id: 'label', label: 'Beschreibung', width: 300 },
  {
    id: 'ericValue',
    label: 'ERIC-Wert',
    renderFunc: displayValue('ericValue'),
    width: 300,
  },
];

export const InfoOverview = ({ ii }: { ii: Information[] }) => {
  return (
    <div>
      <Table
        coloumns={cols}
        data={ii.filter(
          ({ informationId, ericId }) =>
            !!ericId || importantIds.includes(informationId),
        )}
      />
    </div>
  );
};
