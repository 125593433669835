import React from 'react';
import { Table } from '../Table/Table';
import { Information } from '../../info/state/infoApi';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { displayValue } from '../../info/page/InfoPage/InfoPage';

export const InfoTable = ({
  infos,
  minimal,
}: {
  infos: Information[];
  minimal: boolean;
}) => {
  return <Table data={infos} coloumns={cols} collapsed={minimal} />;
};

function displayId(v: Information): React.ReactElement {
  return <ShortTextCopy fontSize={12} text={v.informationId} length={10} />;
}

const cols = [
  { id: 'informationId', label: 'ID', renderFunc: displayId, width: 90 },
  { id: 'label', label: 'Beschreibung' },
  {
    id: 'value',
    label: 'Wert',
    renderFunc: displayValue('value'),
    collapsable: true,
  },
  { id: 'ericValue', label: 'ERIC-Wert', renderFunc: displayValue('value') },
  { id: 'type', label: 'Typ', width: 60, collapsable: true },
  {
    id: 'position',
    label: 'Position',
    width: 50,
    renderFunc: displayValue('position'),
  },
  { id: 'context', label: 'Kontext', width: 50 },
  { id: 'ericId', label: 'ERIC-ID', width: 110 },
];
