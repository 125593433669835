import React from 'react';
import { User } from '../../home/state/userApi';
import { mapBotsYear } from '../../common/bot';
import { ShortTextCopy } from '../ShortTextCopy/ShortTextCopy';
import { dateToString } from '../../common/text';

export const legacyIBAN = 'DE58 6807 0030 0052 8083 01';

export const ProfileDataTable = ({ profile }: { profile: User }) => {
  const {
    email,
    state,
    viban,
    forename,
    surname,
    added,
    id,
    graphStatus,
    verification,
    passwordSet,
    inviteCode,
    inviteCodeDisabled,
  } = profile;
  const finished = graphStatus
    .filter(({ status }) => status === 'sent')
    .map(mapBotsYear)
    .filter(({ year }) => !!year)
    .map(({ year }) => year)
    .join(', ');

  return (
    <table>
      <tbody>
        <tr>
          <td>E-Mail</td>
          <td>{email}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{state}</td>
        </tr>
        <tr>
          <td>Verifikation</td>
          <td>{verification}</td>
        </tr>
        <tr>
          <td>Passwort</td>
          <td>{passwordSet ? 'Passwort' : 'Passwortlos'}</td>
        </tr>
        <tr>
          <td>Vorname</td>
          <td>{forename}</td>
        </tr>
        <tr>
          <td>Nachname</td>
          <td>{surname}</td>
        </tr>
        <tr>
          <td>{viban ? 'VIBAN' : 'IBAN (nicht pers.)'}</td>
          <td>{viban ? viban : legacyIBAN}</td>
        </tr>
        <tr>
          <td>ID</td>
          <td>
            <ShortTextCopy text={id} />
          </td>
        </tr>
        <tr>
          <td>Hinzugefügt</td>
          <td>{dateToString(added)}</td>
        </tr>
        <tr>
          <td colSpan={finished.length > 0 ? 1 : 2}>
            {finished.length > 0
              ? 'Abgeschlossene Jahre'
              : 'Keine abgeschlossenen Jahre'}
          </td>
          {finished.length > 0 && <td>{finished}</td>}
        </tr>
        <tr>
          <td>Einladungscode</td>
          {inviteCodeDisabled ? (
            <td style={{ color: 'red' }}>Deaktiviert ({inviteCode})</td>
          ) : (
            <td>https://app.steuerbot.com/i/{inviteCode}</td>
          )}
        </tr>
      </tbody>
    </table>
  );
};
