import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useQueryPage } from '../../../common/router';
import { OrdersSidenav } from '../../../components/OrdersSidenav/OrdersSidenav';
import { SideNav } from '../../../components/SideNav/SideNav';
import { UserOrdersTable } from '../../../components/UserOrdersTable/UserOrdersTable';
import { UserOrders } from '../../state/shopApi';
import {
  fetchMatchingOrders,
  selectCounts,
  selectQuery,
  selectStates,
  selectUserOrders,
} from '../../state/shopReducer';
import styles from './OrdersPage.module.scss';

export const OrdersPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const states = useSelector(selectStates);
  const orderQuery = useSelector(selectQuery);
  // const username = useSelector(selectSettingsUsername);
  const [loading, setLoading] = useState(false);
  const [autorefresh, setAutorefresh] = useState(false);
  const page = useQueryPage();
  const [limit] = useState(30);
  const userOrders: UserOrders[] = useSelector(selectUserOrders);
  const { all, pending, selected, review } = useSelector(selectCounts);

  const loadOrders = useDebouncedCallback(
    async (states: string[], query: string, limit: number, page: number) => {
      setLoading(true);
      const offset = page * limit;
      await dispatch(fetchMatchingOrders(states, query, limit, offset));
      setLoading(false);
    },
    500,
  );

  useEffect(() => {
    let intervalId: any;
    if (autorefresh) {
      intervalId = setInterval(
        () => loadOrders(states, orderQuery, limit, page),
        10000,
      );
    }
    loadOrders(states, orderQuery, limit, page);
    return () => clearInterval(intervalId);
  }, [autorefresh, states, limit, page, loadOrders, orderQuery]);

  const handleChange = (_: React.ChangeEvent<unknown>, p: number) =>
    history.push({ pathname: '/shop', search: `page=${p - 1}` });

  const pageCount = Math.ceil(selected / limit);
  const hideContent = loading && userOrders.length <= 0;
  const toggleAutorefresh = () => setAutorefresh((s) => !s);
  const handleRefreshClick = () => loadOrders(states, orderQuery, limit, page);
  const handleUserClick = (uid: string) =>
    history.push(`/shop/customer/${uid}`);

  return (
    <SideNav expanded sideNav={<OrdersSidenav loadingOrders={loading} />}>
      <div className={styles.container}>
        <div className={styles.header}>
          <CircularProgress
            style={{ visibility: loading ? 'visible' : 'hidden' }}
            size={20}
          />
          {!hideContent && (
            <>
              <LabelValue label="In Auswahl" value={selected} />
              <LabelValue label="Alle Bestellungen" value={all} />
              <LabelValue label="Warten auf Freigabe" value={pending} />
              <LabelValue label="In Untersuchung" value={review} />
              <FormControlLabel
                control={
                  <Switch checked={autorefresh} onChange={toggleAutorefresh} />
                }
                label="Autorefresh"
              />
              <Button onClick={handleRefreshClick}>Refresh</Button>
            </>
          )}
        </div>

        {!hideContent && (
          <div className={styles.table}>
            <UserOrdersTable users={userOrders} onUserClick={handleUserClick} />
          </div>
        )}

        {!hideContent && (
          <div className={styles.pager}>
            <Pagination
              count={pageCount}
              shape="rounded"
              variant="outlined"
              showFirstButton
              showLastButton
              page={page + 1}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </SideNav>
  );
};

const LabelValue = ({ label, value }: { label: string; value: number }) => {
  return (
    <div className={styles.labelValue}>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  );
};
