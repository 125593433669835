import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  handleUnauthorizedRequest,
  selectPermissionHint,
} from '../../settings/state/settingsReducer';

export const PermissionSnackbar = () => {
  const hint = useSelector(selectPermissionHint);
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const action = () => {
    switch (hint) {
      case undefined:
        return null;
      case 'logged_out':
        return (
          <Button
            onClick={() => {
              dispatch(handleUnauthorizedRequest(undefined));
              keycloak.login();
            }}>
            Einloggen
          </Button>
        );
      case 'missing-permission':
        return (
          <Button
            onClick={() => {
              dispatch(handleUnauthorizedRequest(undefined));
              history.push('/settings');
            }}>
            Einstellungen
          </Button>
        );
    }
  };

  const label =
    hint === 'logged_out'
      ? 'Du bist nicht eingeloggt.'
      : hint === 'missing-permission'
      ? 'Dir fehlen Berechtigungen um diese Aktion durchzuführen.'
      : '';

  const onClose = () => {
    dispatch(handleUnauthorizedRequest(undefined));
  };

  return (
    <Snackbar
      open={!!hint}
      autoHideDuration={6000}
      onClose={onClose}
      action={action()}>
      <Alert onClose={onClose} severity="error" action={action()}>
        {label}
      </Alert>
    </Snackbar>
  );
};
