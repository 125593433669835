import { Api } from '../../..';
import { queryParams } from '../../common/api';
import { SupportInformation } from '../../info/state/infoApi';
import FileSaver from 'file-saver';

export const userApi = {
  checkToken: (userId: string): Promise<unknown> =>
    Api.fetch({
      method: 'GET',
      url: `monitor/${userId}/check-token`,
    }),
  findProfile: (email: string): Promise<UserResponse> =>
    Api.fetch({
      method: 'GET',
      url: 'user?',
      params: queryParams({ email }),
    }),
  fetchProfile: (uid: string): Promise<UserResponse> =>
    Api.fetch({
      method: 'GET',
      url: `user`,
      params: queryParams({ uid }),
    }),

  queryProfile: (query: string): Promise<UserResponse> =>
    Api.fetch({
      method: 'GET',
      url: `user/query`,
      params: queryParams({ query }),
    }),

  fetchProfiles: (...userIds: string[]): Promise<UsersResponse> =>
    Api.fetch({
      method: 'GET',
      url: `users`,
      data: { userIds },
    }),

  fetchDevices(uid: string): Promise<{ devices: UserDevice[] }> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/devices`,
    });
  },

  unlockUserBot(uid: string, bid: string): Promise<User | null> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/bot/state?bid=${bid}`,
    });
  },

  verifyUserPhone(uid: string): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/verification/verify`,
    });
  },

  verifySendSMS(uid: string, phoneNo: string): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/verification`,
      data: { phoneNo },
    });
  },

  unverifyUser(uid: string): Promise<User | null> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/verification`,
    });
  },

  deleteUser(uid: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}`,
    });
  },

  disableInviteCodes(uid: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/invite-code`,
    });
  },

  grantPayment(
    uid: string,
    botId: string,
    monitorUser: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/payment/inapp`,
      data: { botId, monitorUser, comment },
    });
  },

  cancelPayment(
    paymentId: string,
    uid: string,
    botId: string,
    monitorUser: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/payment/inapp/${paymentId}`,
      data: { botId, monitorUser, comment },
    });
  },

  delayPayment(
    paymentId: string,
    uid: string,
    botId: string,
    monitorUser: string,
    date: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/payment/inapp/${paymentId}`,
      data: { botId, monitorUser, comment, date },
    });
  },

  delayPaymentReminder(
    paymentId: string,
    uid: string,
    botId: string,
    monitorUser: string,
    date: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/payment/reminder/${paymentId}`,
      data: { botId, monitorUser, comment, date },
    });
  },

  restartPurchase(
    paymentId: string,
    uid: string,
    botId: string,
    accountId: string,
    monitorUser: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/payment/inapp/${paymentId}/restart`,
      data: { botId, accountId, monitorUser, comment },
    });
  },

  refundPurchase(
    paymentId: string,
    uid: string,
    botId: string,
    monitorUser: string,
    refundMethod: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/payment/inapp/${paymentId}/refund`,
      data: { botId, monitorUser, refundMethod, comment },
    });
  },

  resetPayLaterPurchase(
    paymentId: string,
    uid: string,
    botId: string,
    monitorUser: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/payment/inapp/${paymentId}/reset`,
      data: { botId, monitorUser, comment },
    });
  },

  finalizePayLaterPurchase(
    paymentId: string,
    uid: string,
    refund: number,
    monitorUser: string,
    comment?: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/payment/inapp/${paymentId}/finalize`,
      data: { refund, monitorUser, comment },
    });
  },

  confirmTransfer(orderNo: string, monitorUser: string): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `payment/transfer`,
      data: { orderNo, monitorUser },
    });
  },

  confirmTransferList(
    list: OrderTransfer[],
    monitorUser: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `payment/transfer`,
      data: { list, monitorUser },
    });
  },

  createOrderInvoice(
    orderNo: string,
    uid: string,
    monitorUser: string,
    filename: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/invoice/${orderNo}`,
      data: { monitorUser },
      responseType: 'blob',
    }).then((response) => {
      FileSaver(response, filename);
    });
  },

  searchPurchase(
    orderNo: string | undefined,
    amount: number | undefined,
    orderList: string | undefined,
    monitorUser: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `payment/search`,
      data: { orderNo, amount, orderList, monitorUser },
    });
  },

  fetchTroyCases(
    csv: string,
    monitorUser: string,
  ): Promise<{ cases: TroyCase[]; valid: boolean }> {
    return Api.fetch({
      method: 'POST',
      url: `payment/troy/search`,
      data: { csv, monitorUser },
    });
  },

  syncTroyCases(
    cases: TroyCase[],
    monitorUser: string,
  ): Promise<{ cases: TroyCase[] }> {
    return Api.fetch({
      method: 'PATCH',
      url: `payment/troy/sync`,
      data: { cases, monitorUser },
    });
  },

  hasPurchase(uid: string, bid: string): Promise<HasPurchaseResponse> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/payment/inapp`,
      params: queryParams({ bid }),
    });
  },

  fetchDeclarationPayments(uid: string): Promise<any> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/payments`,
    });
  },

  linkUser(uid: string, comment?: string): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/activation`,
      data: { comment },
    });
  },

  faxPdf(uid: string, faxId: string): Promise<any> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/fax/${faxId}`,
    });
  },

  createFunnel(
    botId: string,
    nodeIds: string[],
    from: string,
    until: string,
  ): Promise<FunnelResponse> {
    return Api.fetch({
      method: 'POST',
      url: `statistics/node-funnel`,
      data: { botId, nodeIds, from, until },
    });
  },

  getOrderListStatus(id: string): Promise<OrderListStatus> {
    return Api.fetch({
      method: 'GET',
      url: `accounting/order-list-status/${id}`,
    });
  },

  getOrderList(data: OrderPayoutListRequest): Promise<string> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/order-list`,
      data,
    });
  },

  getInvitePayoutList(data: OrderPayoutListRequest): Promise<string> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/invite-payout-list`,
      data,
    });
  },

  getInviteList(data: OrderPayoutListRequest): Promise<string> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/invite-list`,
      data,
    });
  },

  getCoupons(): Promise<{ coupons: Coupon[] }> {
    return Api.fetch({
      method: 'GET',
      url: `accounting/coupons`,
    });
  },

  getCouponList(data: CouponListRequest): Promise<string> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/coupon-list`,
      data,
    });
  },

  createCoupon(data: Coupon): Promise<{ coupon: Coupon }> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/coupons`,
      data,
    });
  },

  disableCoupon(
    id: string,
    acceptance?: boolean,
    consumption?: boolean,
  ): Promise<{ coupon: Coupon }> {
    return Api.fetch({
      method: 'PATCH',
      url: `accounting/coupons/${id}/disable`,
      params: queryParams({ acceptance, consumption }),
    });
  },

  enableCoupon(id: string): Promise<{ coupon: Coupon }> {
    return Api.fetch({
      method: 'PATCH',
      url: `accounting/coupons/${id}/enable`,
    });
  },

  getPartnerList(data: PartnerListRequest): Promise<string> {
    return Api.fetch({
      method: 'POST',
      url: `accounting/partner-list`,
      data,
    });
  },

  updateInvite(uid: string, invite: Invite): Promise<any> {
    return Api.fetch({
      method: 'PUT',
      url: `user/${uid}/invites/${invite.id}`,
      data: { invite },
    });
  },

  addInvite(uid: string, invite: Invite): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/invites`,
      data: { invite },
    });
  },

  deleteInvite(uid: string, inviteId: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/invites/${inviteId}`,
    });
  },

  getUserInvitePayouts(uid: string): Promise<{ payouts: InvitePayout[] }> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/invite-payouts`,
    });
  },

  getPendingInvitePayouts(
    format: 'json' | 'csv' | 'xml',
    ids?: string,
  ): Promise<{ payouts: InvitePayout[]; csv: any; xml: any }> {
    return Api.fetch({
      method: 'GET',
      url: `invite-payouts`,
      params: queryParams({ format, ids }),
    });
  },

  invitePayoutChangeStatus(payoutId: string, status: string): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `invite-payouts/${payoutId}`,
      params: queryParams({ status }),
    });
  },

  acceptInvitePayout(uid: string, payoutId: string): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/invite-payouts/${payoutId}`,
    });
  },

  deleteCoupon(uid: string, couponId: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/coupons/${couponId}`,
    });
  },

  addSupportCoupon(uid: string, cid: string): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/coupons/${cid}`,
    });
  },

  getSupportCoupons(uid: string): Promise<{ coupons: SupportCoupon[] }> {
    return Api.fetch({
      method: 'GET',
      url: `user/${uid}/coupons`,
    });
  },

  addVastUpdateTask(uid: string, idNr: string, debug: boolean): Promise<any> {
    return Api.fetch({
      method: 'POST',
      url: `user/${uid}/vast/tasks/update`,
      data: { idNr, debug },
    });
  },

  updateVastTask(uid: string, taskId: string, status: string): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/vast/tasks/${taskId}`,
      data: { status },
    });
  },

  deleteVastTask(uid: string, taskId: string): Promise<any> {
    return Api.fetch({
      method: 'DELETE',
      url: `user/${uid}/vast/tasks/${taskId}`,
    });
  },

  updateVastPermission(
    uid: string,
    permissionId: string,
    status: string,
  ): Promise<any> {
    return Api.fetch({
      method: 'PATCH',
      url: `user/${uid}/vast/permissions/${permissionId}`,
      data: { status },
    });
  },
};

export interface UsersResponse {
  user: User[];
}

export interface UserResponse {
  user?: User;
  deletions?: DeletedUserData[];
}

export interface User {
  id: string;
  state: string;
  email: string;
  viban?: string;
  forename: string;
  surname: string;
  added: string;
  graphStatus: BotStatus[];
  verification: string;
  phoneConfirmations?: PhoneConfirmation[];
  sentInvites?: Invite[];
  usedInvite?: Invite;
  coupons?: UserCoupon[];
  vastTasks?: VastTask[];
  vastPermissions?: VastPermission[];
  passwordSet?: boolean;
  inviteCode?: string;
  inviteCodeDisabled?: boolean;
}

export interface PhoneConfirmation {
  id: string;
  userId: string;
  phoneNo: string;
  status: string;
  code: string;
  created: string;
  confirmed?: string;
  smsService?: string;
}

export interface BotStatus {
  id: string;
  status: string;
  active: boolean;
  year?: string;

  deliveryState?: DeliveryState;
  faxId?: string;
  faxSendDate?: string;
  postalSendDate?: string;
  authenticatedSendDate?: string;

  refund: number;
  submitId: string;
  submitDate: string;
  assessmentDate: boolean;

  // custom monitor fields
  submitCount: number;
  hasElster2Submit?: boolean;
  hasPostalSubmit?: boolean;
  hasFaxSubmit?: boolean;
  faxCount: number;
  assessmentAvailable: boolean;
  purchaseInformation?: PurchaseInformation;
  paymentStatus: string;
  vastDocuments?: any[];

  // payment data
  shownPaymentReasons?: string[];
  assessmentPaymentReasons?: string[];
}

export enum DeliveryState {
  START = 'start',
  PENDING = 'faxPending',
  SENDING = 'faxSending',
  ERROR = 'faxError',
  FINISHED = 'finished',
}

export interface DeletedUserData {
  userId: string;
  emailHash: string;
  state: string;
  added: string;
  deleted: string;
}

export interface UserDevice {
  id: string;
  name: string;
  platform: string;
  version: string;
  type: string;
  pushId: string;
  appName: string;
  appVersion: string;
  model: string;
  brand: string;
  os: string;
  osVersion: string;
  lang: string;
}

export interface PurchaseInformation {
  id: string;
  type: string;
  userId: string;
  botId: string;
  rawPaymentData: string;
  identifier: string;
  provider: string;
  timestamp: string;
  valid: boolean;
  priceInformation?: PriceInformation;
  validationError?: string;
}

export interface PriceInformation {
  final: number;
  total: number;
  vatRatePerc: number;
  timestamp: string;
  discounts: PriceDiscount[];
}

export interface PriceDiscount {
  type: string;
  amountInCent: number;
  percent: number;
  complete: boolean;
  notApplicable: boolean;
  hint: string;
}

export interface HasPurchaseResponse {
  status: string;
  failed: boolean;
  hasPurchase: boolean;
  cancelable: boolean;
  delayable: boolean;
  restartable: boolean;
  refundable: boolean;
  refundMethod: string;
  reminderDelayable: boolean;
  refunded: boolean;
  payLaterResettable: boolean;
  payLaterFinalizable: boolean;
  refundId: string;
  nextReminder?: string;
  purchaseInformation?: PurchaseInformation;
  orderPayments?: Payment[];
}

export interface Payment {
  id: string;
  userId: string;
  type: string;
  amount: number;
  currency: string;
  status: string;
  scheme: string;
  createdAt: string;
  updatedAt?: string;
  paymentCreatedAt?: string;
  finishedAt?: string;
  lastTryAt?: string;
  failCount: number;
  error: string;
  reference: string;
  chargeDate?: string;
  description: string;
  internalNote: string;
  mandateId: string;
  goCardlessPaymentId: string;
  goCardlessStatus: string;
  goCardlessError: any;
  orderInstalments: { [key: string]: string };
  paidByTransfer: boolean;

  reminder?: PaymentReminder;
  supportInformation?: SupportInformation;
}

export interface PaymentReminder {
  status: string;
  error: string;
  lastNotified: string;
  notifications: string[];
  debtCollector: string;
  troyCustomerId: string;
  troyCaseId: string;
}

export interface OrderPaymentInformation {
  id: string;
  status: string;
  gcId: string;
  amount: number;
  paidByTransfer: boolean;
  gcStatus?: GocardlessPayment;
}

export interface GocardlessPayment {
  id: string;
  created_at: string;
  charge_date: string;
  amount: number;
  description: string;
  currency: string;
  status: string;
  reference: string;
}

export interface FunnelResponse {
  // data: { [key: string]: FunnelNodeCount };
  data: FunnelNodeCount[];
}

export interface FunnelNodeCount {
  id: string;
  name: string;
  count: number;
}

export interface OrderPayoutListRequest {
  from: string;
  until: string;
  untilPayment: string;
}

export interface CouponListRequest {
  from: string;
  until: string;
  couponIds?: string[];
}

export interface PartnerListRequest {
  from: string;
  until: string;
  partnerId: string;
}

export interface OrderListStatus {
  progress: number;
  result?: string;
}

// Invite represents a user to user recommendation
export interface Invite {
  id: string;
  userId: string;
  senderId: string;
  senderName: string;
  status: string;
  acceptDate: string;

  // once redeemed
  redeemDate: string;
  botId: string;

  // once paid out
  payoutDate: string;
}

// InvitePayout represents a requested invite payout
export interface InvitePayout {
  id: string;
  userId: string;
  amount: number;
  inviteIds: string[];
  status: string;
  bankAccountId?: string;
  bankAccountHolder?: string;
  iban?: string;
  requested: string;
  approved?: string;
}

export interface UserCoupon {
  id: string;
  userId: string;
  couponId: string;
  couponCode: string;
  status: string;
  claimedAt: string;
  consumedAt: string;
}

export interface VastTask {
  id: string;
  createdAt: string;
  type: string;
  debug: boolean;

  idNr: string;
  userId: string;

  status: string;
  lastTryAt?: string;
  errors: string[];
  lastErrorCode: number;
  userNotifiedAt?: string;
}

export interface VastPermission {
  idInternal: string;
  id: string;
  date: string;
  validUntil: string;
  status: string;
  approveUntil: string;
  type: string;
  process: string;
  userId: string;
  idNr: string;
  context: string;
  certificateMapping: string;
}

export interface OrderTransfer {
  reference: string;
  sender: string;
  amount: number;
  date: string;

  confirmed?: boolean;
  status?: string;
  viban?: string;
  name?: string;

  missingAmount?: number;
  orderMapping?: OrderMapping[];
  orderSuggestions?: OrderMapping[];
}

export interface OrderMapping {
  no: string;
  openAmount: number;
  distributedAmount: number;
  description?: string;
}

export interface Coupon {
  id: string;
  codes: string[];
  title: string;
  description: string;
  hint: string;
  acceptExpiryDate?: string;
  consumeExpiryDate?: string;
}

export interface SupportCoupon {
  id: string;
  sudoOnly: string;
  title: string;
}

export interface TroyCase {
  userId: string;
  amount: number;
  collectedAmount: number;
  takenAmount: number;
  openAmount: number;
  reason: string;
  error: string;
  raw: string;
  chargeDate?: string;
  closeDate?: string;
  paymentIds: string[];
  synced: boolean;
}

export interface TroyCSVSummaryItem {
  userId: string;
  amount: number;
  openAmount: number;
  reason: string;
}
